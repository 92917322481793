import React, { useMemo,useState,useEffect } from 'react';
import { Box } from '@mui/system';
import { DataTable } from '../shared/data_table/DataTable';
import ReportServices from '../../services/reportServices';
import { Typography, Paper } from '@mui/material';
import {Breadcrumb} from '../shared/Breadcrumbs';
import DropDown from '../shared/DropDown';
import CommonDropDown from '../common/commonDropdown';

const OverSpeedReport = () => {

    const [responseData, setResponseData] = useState([])

  useEffect(async () => {
      const responseData = (await ReportServices.getOverSpeed()).data.data
      setResponseData(responseData)
  }, [])


  const columns = useMemo(
    () =>
      [
        {
          Header: 'Organization',
          accessor: 'value1'
        },
        {
          Header: 'Vehicle No',
          accessor: 'value2'
        },
        {
          Header: 'Date Time',
          accessor: 'value3'
        },
        {
          Header: 'GPS Location (Click to view location in map)',
          accessor: 'value4',
        },
        {
          Header: 'Speed',
          accessor: 'value5'
        }
      ],
    []
  )

  const handleSubmit = (formData) => {
    console.log({formData})
  }

  return (
    
    <Box sx={{padding:'15px'}}>
    <Breadcrumb mainDiv='Reports' subDiv='Over Speed Report'></Breadcrumb>
    <Paper elevation={5} sx={{padding: '1em'}}>
     <Typography  sx={{fontSize:'14px'}}>Over Speed Report</Typography>
     <Typography sx={{fontSize:'11px'}}>Overspeed report shows various instances when the vehicle crossed the threshold speed.</Typography>
    </Paper>
    <CommonDropDown onSubmit={handleSubmit}/>
    <Box display="flex" flexDirection="column">
      <DataTable columns={columns} data={responseData} styles={{maxWidth: '89vw'}} numberOfStickyColumns={1} />
    </Box>
    </Box>
   
  )
}

export default OverSpeedReport