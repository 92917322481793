import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: "auto",
    marginTop: "3vh",
    overflowX: "initial",
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },

  tableCell: {
    borderRightStyle: "solid",
    borderRightColor: "black",
    borderRightWidth: 1,
    padding: "8px",
    color: "#fff",
  },
  tableHead: {
    borderBottomStyle: "solid",
    borderBottomColor: "black",
    borderBottomWidth: 2,
    background: "#61CE70",
  },
}));
const ReactTable = ({ slotCount, editSlotData, swapToEdit, swapToView }) => {
  const classes = useStyles();
  const key = useRef(null);
  console.log(editSlotData);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell}>Slot.No</TableCell>
            <TableCell className={classes.tableCell}>
              Output Voltage(Volt)
            </TableCell>
            <TableCell className={classes.tableCell}>Battery Model</TableCell>
            <TableCell className={classes.tableCell}>Voltage(Volt)</TableCell>
            <TableCell className={classes.tableCell}>
              Capacity(Ampere Hour)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(slotCount).keys()].map((item, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell} align="left">
                <TextField
                  disabled={swapToEdit ? true : false}
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={
                    editSlotData[index] ? editSlotData[index].slotNumber : ""
                  }
                  size="small"
                  color="primary"
                  ref={key}
                  name={`slot${index + 1}Number`}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <TextField
                  disabled={swapToEdit ? true : false}
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={
                    editSlotData[index]
                      ? editSlotData[index].slotOutputVoltage
                      : ""
                  }
                  size="small"
                  color="primary"
                  ref={key}
                  name={`slot${index + 1}OutputVoltage`}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextField
                  disabled={swapToEdit ? true : false}
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={
                    editSlotData[index] ? editSlotData[index].batteryModel : ""
                  }
                  size="small"
                  color="primary"
                  ref={key}
                  name={`battery${index + 1}Model`}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextField
                  disabled={swapToEdit ? true : false}
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={
                    editSlotData[index] ? editSlotData[index].slotVoltage : ""
                  }
                  size="small"
                  color="primary"
                  ref={key}
                  name={`slot${index + 1}Voltage`}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextField
                  disabled={swapToEdit ? true : false}
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={
                    editSlotData[index] ? editSlotData[index].slotCapacity : ""
                  }
                  size="small"
                  color="primary"
                  ref={key}
                  name={`slot${index + 1}Capacity`}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ReactTable;
