import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSticky } from "react-table-sticky";
import React, { useState } from "react";
import { useTable, useBlockLayout } from "react-table";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBox from "../shared/SearchBox";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SlotTable from "./slotTable";
const DataTable = ({
  includeSearchBox = false,
  onSearch,
  columns,
  data,
  styles,
  numberOfStickyColumns,
  onEdit,
  onDelete,
  onCheckbox,
  onView,
  onSelect,
  callbackChangePage,
  page = 0,
  rowsPerPage = 5,
  totalElements,
  totalPages = 1,
}) => {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  //const [open, setOpen] = React.useState('');
  const [openRows, setOpenRows] = useState({});
  const [swapId, setSwapId] = useState(0);

  const toggleRow = (cell, rowIndex) => {
    console.log(cell.row.original.id);
    setSwapId(cell.row.original.id);
    setOpenRows((previous) => ({
      ...previous,
      [rowIndex]: !(previous[rowIndex] ?? false),
    }));
  };

  const handleChangePage = (event, newPage) => {
    //setPage(newPage)
    page = newPage;
    callbackChangePage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(+event.target.value);
    //setPage(page);
    callbackChangePage(page, +event.target.value);
    page = page;
  };

  if (numberOfStickyColumns) {
    for (let i = 0; i < numberOfStickyColumns; i++) {
      columns[i].sticky = "left";
    }
  }

  const useStyles = makeStyles({
    table: {
      "& [data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      "& [data-sticky-td]": {
        position: "sticky",
      },
      overflow: "scroll",
    },
  });

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    width: "auto",
    backgroundColor: "#5c6bc0",
    boxShadow: "0px 2px 0px #374850",
    position: "sticky",
    top: 0,
    zIndex: 1,
    width: "fit-content",
    border: "1px solid darkgrey",
  }));

  const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#61CE70",
    overflow: "hidden",
    textAlign: "left",
    padding: "1px",
    borderInline: "1px solid #98aeb5",
    fontSize: "16px !important",
    fontWeight: "revert",
    paddingLeft: "10px",
  }));

  const BodyTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.table.header.text.color,
    overflow: "hidden",
    textAlign: "left",
    borderInline: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "14px !important",
    lineHeight: "1.43",
    padding: "8px",
    overflowX: "auto",
  }));

  const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
    "&.MuiTablePagination-toolbar": {
      minHeight: "25px ! important",
      maxHeight: "25px",
    },
  }));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSticky
    );

  const handleClick = (cell, event, e) => {
    console.log("ITs clll");
    if (event.toLowerCase() === "edit") {
      onEdit(cell.row.original);
    } else if (event.toLowerCase() === "delete") {
      onDelete(cell.row.original);
    } else if (event.toLowerCase() === "checkbox") {
      if (e.target.checked === true) {
        onCheckbox(cell.row.original);
      }
    } else if (event.toLowerCase() === "view") {
      onView(cell.row.original);
    }
  };

  const SearchBoxWrapper = () => {
    const [filterText, setFilterText] = useState("");
    const handleChange = (e) => {
      setFilterText(e.target.value);
      onSearch(e.target.value);
    };

    return (
      <SearchBox
        style={{
          alignSelf: "end",
          marginTop: "0px",
          marginBottom: "10px",
          mr: "10px",
        }}
        onFilter={handleChange}
        filterText={filterText}
      />
    );
  };

  const firstPageRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const classes = useStyles();
  return (
    <Paper
      elevation={1}
      sx={{ padding: "10px", display: "flex", flexDirection: "column" }}
    >
      {includeSearchBox && <SearchBoxWrapper />}
      <TableContainer component={Paper} sx={{ ...styles }}>
        <Table {...getTableProps()} className={classes.table}>
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderTableCell
                    {...column.getHeaderProps()}
                    sx={{ textAlign: "center" }}
                  >
                    {column.render("Header")}
                  </HeaderTableCell>
                ))}
              </TableRow>
            ))}
          </StyledTableHead>
          <TableBody
            {...getTableBodyProps()}
            style={{ position: "relative", zIndex: 0 }}
          >
            {rows.length > 0 &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment>
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        if (cell.column.Header === "Action") {
                          return (
                            <BodyTableCell
                              {...cell.getCellProps()}
                              sx={{ textAlign: "center" }}
                            >
                              {cell.value.map((v, index) => {
                                return (
                                  <IconButton
                                    key={index}
                                    size="small"
                                    sx={{ height: "10px" }}
                                    onClick={(e) => handleClick(cell, v, e)}
                                  >
                                    {v.toLowerCase() === "edit" && (
                                      <EditIcon style={{ fontSize: "15px" }} />
                                    )}
                                    {v.toLowerCase() === "delete" && (
                                      <DeleteIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    )}
                                    {v.toLowerCase() === "view" && (
                                      <VisibilityIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    )}
                                  </IconButton>
                                );
                              })}
                            </BodyTableCell>
                          );
                        } else if (cell.column.Header === "Select") {
                          return (
                            <BodyTableCell
                              {...cell.getCellProps()}
                              sx={{ textAlign: "center" }}
                            >
                              <IconButton
                                //onClick = {() => setOpen(!open)}
                                //onClick={(e) => {handleClick(cell, cell.value, e)}}
                                onClick={() => toggleRow(cell, row.index)}
                                size="small"
                                sx={{ height: "10px" }}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            </BodyTableCell>
                          );
                        } else if (cell.column.Header === "") {
                          return (
                            <BodyTableCell
                              {...cell.getCellProps()}
                              sx={{ textAlign: "center" }}
                            >
                              {cell.value === "checkBox" && (
                                <Checkbox
                                  onChange={(e) =>
                                    handleClick(cell, cell.value, e)
                                  }
                                  style={{ fontSize: "15px" }}
                                />
                              )}
                            </BodyTableCell>
                          );
                        } else {
                          return (
                            <BodyTableCell
                              {...cell.getCellProps()}
                              sx={{ textAlign: "center" }}
                            >
                              {cell.render("Cell")}
                            </BodyTableCell>
                          );
                        }
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                      >
                        <Collapse
                          in={openRows[row.index] ?? false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <SlotTable swapIdSlot={swapId} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <MuiTablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;

//onClick={(() => setOpen(!open)) &&( (e) => handleClick(cell, cell.value, e))}
//{(cell.value === 'select') && ( open ? <KeyboardArrowUpIcon  /> : <KeyboardArrowDownIcon />)}
