import Service from "./service";

const SlotServices = {
  getSwapStationSlotLists: function (data) {
    return Service.post("/simulator/listSimulator", data, null);
  },
  getSlotList: function (data) {
    return Service.post("/simulator/getSlotForSwap", data, null);
  },
  getBatteryList: function (data) {
    return Service.post("/simulator/availableBattery", data, null);
  },
  getAssignBattery: function (data) {
    return Service.post("/simulator/assignBattery", data, null);
  },
  getUnAssignBattery: function (data) {
    return Service.post("/simulator/unassignBattery", data, null);
  },
  getSwapStation: function (data) {
    return Service.post("/simulator/getSwapStationName", data, null);
  },
};
export default SlotServices;
