import axios from "axios";
import {
  getToken,
  getRefreshToken,
  saveToken,
  saveExpToken,
  deleteExpJwToken,
} from "../Utils";
import { initializeAxiosMockAdapter, isMockEnabled } from "../mock/mock";

class Service {
  constructor() {
    let service = axios.create({
      //baseURL: process.env.REACT_APP_API_BASE_URL,
      //baseURL: "http://3.213.108.14:8080",
      //baseURL: "https://portal.evzap.in:9443",
      baseURL: "https://portalevzap.nesh.live:9443",
      // baseURL: "http://localhost:8080",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (isMockEnabled()) {
      initializeAxiosMockAdapter(service);
    }
    service.interceptors.request.use((config) => {
      getToken() &&
        (config.headers["Authorization"] =
          "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")));
      return config;
    });

    service.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/authenticate" && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await service.post("/refreshtoken", {
                refreshToken: getRefreshToken(),
              });
              const { accessToken, refreshToken } = rs.data;
              deleteExpJwToken();
              saveToken(accessToken, refreshToken);
              return service(originalConfig);
            } catch (_error) {
              //deleteToken();
              //deleteRefreshToken();
              saveExpToken();
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
    this.service = service;
  }

  get(path, params = {}) {
    return this.service.get(path, { params });
  }

  post(path, payload, params = {}, headers = {}) {
    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
      params,
      headers,
    });
  }
  postImg(path, payload, params = {}) {
    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
      params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new Service();
