import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import routes from "./routes";
import { getElement } from "../Utils/helper";
import { styled } from "@mui/system";
import Scrollbars from "react-custom-scrollbars-2";
import GlobalStyleComponent from "../components/styles/GlobalStyleComponent";
import PageNotFound from "../components/page-not-found/PageNotFound";
import Login from "../components/login/login";
const RouteView = () => {
 
    var globalJson={};
    if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
    } else{
      globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
    }
    if(JSON.stringify(globalJson) === '{}'){
        window.location.href=window.location.origin+"/#/login";
    }
    const getRoutes = (parent) => {
        var count=window.location.href.split("#").length;
        
            if(JSON.stringify(globalJson) === '{}'){
                if(count===1){
                window.location.href=window.location.origin+"/#/login";
            }
            }
        return (
            <React.Fragment key={parent.path}>
                <Route path={parent.path} element={getElement(parent)}>
                    {parent.children && parent.children.map(child => getRoutes(child))}
                </Route>
                <Route path="page-not-found" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to={'page-not-found'} />} />
            </React.Fragment>
        )
    }

    const Container = styled('div')(
        ({ theme }) => ({
            backgroundColor: theme.backgroundColor
        })
    )
    

    return (
        <Container>
            <GlobalStyleComponent />
            <Scrollbars style={{ height: '100vh' }}>
                <HashRouter >
                    <Routes>
                        {routes.map((parent) => getRoutes(parent))}
                    </Routes>
                </HashRouter>
            </Scrollbars>
        </Container>
    )
}

export default RouteView