import Login from "../components/login/login";
import SidebarLayout from "../layout/SidebarLayout";
import { Outlet } from "react-router-dom";
import GoogleMap from "../components/google-maps/MarkerPolyline";
import PlayBack from "../components/google-maps/index";
import OverSpeedReport from "../components/Reports/OverSpeedReport";
import GeoZone from "../components/geozone/GeoZone";
import Organization from "../components/organization/index";
import User from "../components/user/index";
import Device from "../components/device/index";
import Vehicles from "../components/vehicle";
import AccountSettings from "../components/account/accountSettings";
import ProfileSettings from "../components/profile-settings/index";
import Sim from "../components/sim/index";
import Alerts from "../components/alerts/index";
import Feature from "../components/feature/index";
import Rolesettings from "../components/rolesettings/index";
import viewAlert from "../components/view-alerts/view";
import ServiceAlert from "../components/service-alerts";
import TravelDetailReport from "../components/Reports/TravelDetailReport";
import TravelSummaryReport from "../components/Reports/TravelSummary";
import IdleReport from "../components/Reports/IdleReport";
import GeoZoneDetail from "../components/Reports/GeoZoneDetail";
import Stoppage from "../components/Reports/Stoppage";
import TripSummary from "../components/Reports/TripSummary";
import TripReport from "../components/Reports/TripReport";
import AvlDataReport from "../components/Reports/AvlDataReport";
import SinocastelData from "../components/Reports/SinocastelData";
import TripRangeReport from "../components/Reports/TripRangeReport";
import TyrePressureReport from "../components/Reports/TyrePressureReport.js";
import EVDataReport from "../components/Reports/EVDataReport";
import Calendar from "../components/calendar/index";
import SwapStation from "../components/swap-station";
import Battery from "../components/battery";
import AssignSlots from "../components/simulator/AssignSlots";
import SwapView from "../components/swapView/index";
import SwapTreeView from "../components/swaptreeview/swaptreeview";
import FeedLog from "../components/feed-log/index";



export default [
  {
    path: "/",
    component: SidebarLayout,
    isProtected: true,
    children: [
      {
        path: "/",
        component: SwapView,
      },
      {
        path: "mapviews",
        component: GoogleMap,
      },
      {
        path: "playback",
        component: PlayBack,
      },
      {
        path: "geozone",
        component: GeoZone,
      },
      {
        path: "overSpeedReport",
        component: OverSpeedReport,
      },
      {
        path: "organization",
        component: Organization,
      },
      {
        path: "user",
        component: User,
      },
      {
        path: "device",
        component: Device,
      },
      {
        path: "feature",
        component: Feature,
      },
      {
        path: "rolesettings",
        component: Rolesettings,
      },
      {
        path: "swapStation",
        component: SwapStation,
      },
      {
        path: "battery",
        component: Battery,
      },
      {
        path: "simulator",
        component: AssignSlots,
      },

      {
        path: "vehicle",
        component: Vehicles,
      },
      {
        path: "accountSettings",
        component: AccountSettings,
      },
      {
        path: "profileSettings",
        component: ProfileSettings,
      },
      {
        path: "sim",
        component: Sim,
      },
      {
        path: "viewAlert",
        component: viewAlert,
      },
      {
        path: "serviceAlert",
        component: ServiceAlert,
      },
      {
        path: "alerts",
        component: Alerts,
      },
      {
        path: "calendar",
        component: Calendar,
      },
      {
        path: "swapView",
        component: SwapView,
      },
      {
        path: "swaptreeview",
        component: SwapTreeView,
      },
      {
        path: "feedLog",
        component: FeedLog,
      },
    
      
    ],
  },
  {
    path: "login",
    component: Login,
  },
];
