import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { Grid, Paper } from "@mui/material";
import customerService from "../../services/customerService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel, Button, Typography, InputLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectWithSearch from "../shared/SelectWithSearch";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";

import BatteryServices from "../../services/batteryServices";
import DraggableDialog from "../shared/DailogDrag";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const containsText = (text, searchText) =>
  text.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) >
  -1;
const ListBattery = ({ onViewBat }) => {
  const [responseData, setResponseData] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [model, setModel] = useState("All");
  const [typeList, setTypeList] = useState([]);
  const [type, setType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [batteryModel, setBatteryModel] = useState("All");
  const [batteryType, setBattteryType] = useState("All");
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();

  useEffect(async () => {
    await fetchBatDetails();
    var payload = {};
    payload.value = "string";
    payload.param = "string";
    payload.paramValue = "string";
    payload.searchText = "string";
    payload.headerValue = "string";
    payload.lableOption = "string";
    payload.page = newPage;
    payload.size = rowsPerPage;
    payload.headerRequired = true;
    const batteryModel = (await BatteryServices.getModelList(payload)).data;
    setModelList(batteryModel);
    const batteryType = (await BatteryServices.getTypeList(payload)).data;
    setTypeList(batteryType);
  }, []);
  const handleChangeModel = (event) => {
    setModel(event.target.value);
    setBatteryModel(event.target.value);
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
    setBattteryType(event.target.value);
  };

  const handleClick = async () => {
    var request = {};

    request.batteryId = 2;
    request.page = newPage;
    request.sortBy = "desc";
    request.batteryModel = batteryModel;
    request.batteryType = batteryType;
    request.size = rowsPerPage;

    const responseData = (await BatteryServices.getGridViewForBat(request))
      .data;
    setResponseData(responseData.data.content);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Battery Id",
        accessor: "batteryId",
      },
      {
        Header: "Battery Model",
        accessor: "batteryModel",
      },
      {
        Header: "Battery Name",
        accessor: "batteryName",
      },
      {
        Header: "Battery Type",
        accessor: "batteryType",
      },
      {
        Header: "Battery Voltage(in V)",
        accessor: "batteryVoltage",
      },
      {
        Header: "Battery Capacity(in Ah)",
        accessor: "batteryCapacity",
      },

      {
        Header: "Battery Weight(in Kg)",
        accessor: "batteryWeight",
      },
      {
        Header: "Battery Size(in cm)",
        accessor: "batterySize",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Action",
        accessor: "buttonView",
      },
    ],
    []
  );

  const onView = (itemViewed) => {
    onViewBat(itemViewed.batteryId);
  };

  const fetchBatDetails = async () => {
    var request = {};

    request.batteryId = 2;
    request.page = newPage;
    request.sortBy = "desc";
    request.batteryModel = "All";
    request.batteryType = "All";
    request.size = rowsPerPage;

    const responseData = (await BatteryServices.getGridViewForBat(request)).data
      .data;
    setResponseData(responseData.content);

    setTotalElements(responseData.totalElements);
    console.log(responseData.totalElements);

    setTotalPages(responseData.totalPages);
  };

  const handleSearch = (searchText) => {
    console.log({ searchText });
  };

  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};

    request.batteryId = 2;
    request.page = newPage;
    request.sortBy = "desc";
    request.batteryModel = "All";
    request.batteryType = "All";
    request.size = rowsPerPage;

    const responseData = (await BatteryServices.getGridViewForBat(request)).data
      .data;
    setResponseData([]);
    setResponseData(responseData.content);
    setTotalElements(5);
    setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  const labelStyle = {
    fontSize: theme.text.fontSize,
    fontWeight: 600,
    fontSize:"16px",
    marginBottom:"10px"
  };
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: "1",
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "8.5px 14px"
    },
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}>
        <FormControl sx={{ m: 1, width: 160 }} size="small">
          <FormLabel sx={labelStyle}>Battery Model</FormLabel>

          <Select
            value={model}
            onChange={handleChangeModel}
            MenuProps={MenuProps}
            sx={selectDropdown}
            onClose={() => setSearchText("")}
          >
            <ListSubheader>
              <TextField
                size="small"
                sx={{
                  width: "auto-width",
                  mt: 1,
                  marginBottom: 1,
                  "& .MuiInputBase-input": {
                    height: "14px",
                    fontSize: "14px",
                  },
                }}
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: "14px" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {modelList.map((model) => (
              <MenuItem value={model.title} sx={menuStyles}>
                {model.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 160 }} size="small">
          <FormLabel sx={labelStyle}>Battery Type</FormLabel>
          <Select
            value={type}
            onChange={handleChangeType}
            MenuProps={MenuProps}
            sx={selectDropdown}
            onClose={() => setSearchText("")}
          >
            <ListSubheader>
              <TextField
                size="small"
                sx={{
                  width: "auto-width",
                  mt: 1,
                  marginBottom: 1,
                  "& .MuiInputBase-input": {
                    height: "14px",
                    fontSize: "14px",
                  },
                }}
                autoFocus
                placeholder="Type to search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: "14px" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {typeList.map((type) => (
              <MenuItem value={type.title} sx={menuStyles}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleClick}
          size="small"
          sx={{
            textTransform: "none",
            backgroundColor: "#374850",
            color: "white",
            fontSize: "16px",
            marginRight: "5px",
            padding:"5px 20px",
            m: 4,
            "&:hover": {
              backgroundColor: "#61CE70",
            },
          }}
        >
          Submit
        </Button>
      </Paper>
      <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
        <DataTable
          includeSearchBox={false}
          onSearch={handleSearch}
          onView={onView}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={callbackChangePage}
          totalElements={totalElements}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};

export default ListBattery;
