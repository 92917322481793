import { FormControl, FormLabel, MenuItem, Select, TextField } from '@mui/material'
import { useTheme } from '@mui/styles';
import React from 'react'

const SelectWithSearch = ({value, name, label, disabled, handleChange, style, options, settings}) => {

  const {
    includeSearchBox = false,
    handleSearchTextChange,
    includeAllOption = false,
    searchBoxStyles
  } = settings

  const theme = useTheme()
  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: 'black',
    fontWeight: 600
  }

  const _handleSearchTextChange = (e) => handleSearchTextChange(e.target.value)
  const _handleChange = (e) => handleChange(e.target.value)

  const defaultMenuStyles = {
    height: '25px',
    width: '100%'
  }

  return (
    <FormControl sx={{ m: 1, width: 160 }} size="small">
      <FormLabel sx={labelStyle}>{label}</FormLabel>
      <Select
        value={value}
        onChange={_handleChange}
        disabled={disabled}
        name={name}
        size={'small'}
        sx={{
          ...style
        }}
      >
        {includeSearchBox && <TextField size='small' sx={{...searchBoxStyles}} onChange={_handleSearchTextChange}/>}
        {includeAllOption && <MenuItem  sx={defaultMenuStyles} value={'All'}>All</MenuItem>}
        {options.map((option, index) => <MenuItem key={index} sx={defaultMenuStyles} value={option.id}>{option.title}</MenuItem>)}
      </Select>
    </FormControl>
    
  )
}

export default SelectWithSearch