import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import userService from "../../services/userService";
import DraggableDialog from "../shared/DailogDrag";

export default function NameDialog(props) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(async () => {
    var userLogginData = {};
    if (
      "null" === localStorage.getItem("userData") ||
      null === localStorage.getItem("userData")
    ) {
    } else {
      userLogginData = JSON.parse(localStorage.getItem("userData"));
    }
    console.log("console:" + userLogginData.id);
    setId(userLogginData.id);
    setUserName(userLogginData.userName);
    // if (userToEdit) {
    //   const responseData = (await userService.editUserById(userToEdit)).data
    //     .formData;
    //   setUser(responseData);
    // } else {
    //   setUser(null);
    // }
  }, [setId]);

  // const handleChange = (name, value) => {
  //   setFormData({ ...formData, [name]: value });
  // };
  // var userLogginData = {};
  // if (
  //   "null" === localStorage.getItem("userData") ||
  //   null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   userLogginData = JSON.parse(localStorage.getItem("userData"));
  // }
  // console.log("console:" + userLogginData.id);
  // const id = userLogginData.id;
  // const userName = userLogginData.userName;

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    updateFullName(e, Object.fromEntries(new FormData(target)));
    props.setName(e.target.firstName.value + " " + e.target.lastName.value);
  };

  const updateFullName = async (e, obj) => {
    e.preventDefault();

    const responseData = await userService.updateFullName(obj);

    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClearCls = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "Bold",
                }}
              >
                Edit Your Name
              </DialogTitle>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <DialogContent>
            <Typography sx={{ mb: 1, fontWeight: "Bold" }}>
              Firstname
            </Typography>
            <TextField
              autoFocus
              id="firstName"
              name="firstName"
              placeholder="Enter the Firstname"
              type="text"
              sx={{ mb: 1 }}
              size="small"
              // value={firstName}
              // onChange={(event) =>
              //   handleChange("firstName", event.target.value)
              // }
              //onChange={(event) => props.setName(event.target.firstName.value)}
            />
            <Typography sx={{ mb: 1, fontWeight: "Bold" }}>Lastname</Typography>
            <TextField
              id="lastName"
              name="lastName"
              placeholder="Enter the Lastname"
              type="text"
              sx={{ mb: 1 }}
              size="small"
              // value={lastName}
              // onChange={(event) => handleChange("lastName", event.target.value)}
              //onChange={(event) => props.setName(event.target.lastName.value)}
            />
            <input type="hidden" name="id" value={id} />
            <input type="hidden" name="userName" value={userName} />
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleClose}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleClose={handleClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleClose={handleClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
