import React, { useState, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, FormLabel, TextField, InputAdornment, ListSubheader } from '@mui/material';
import { useTheme } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160
    },
  },
};

const names = [
  { id: '0', Age:'All', name: 'All', address: 'All' },
  { id: '1', Age:'All', name: 'Oliver Hansen', address: 'aaa' },
  { id: '2', Age:'All', name: 'Van Henry', address: 'bbb' },
  { id: '3', Age:'All', name: 'April Tucker', address: 'ccc' },
  { id: '4', Age:'All', name: 'Ralph Hubbard', address: 'ddd' },
  { id: '5', Age:'All', name: 'Omar Alexander', address: 'eee' },
  { id: '6', Age:'All', name: 'Carlos Abbott', address: 'fff' },
  { id: '7', Age:'All', name: 'Miriam Wagner', address: 'ggg' },
  { id: '8', Age:'All', name: 'Bradley Wilkerson', address: 'hhh' },
  { id: '9', Age:'All', name: 'Virginia Andrews', address: 'iii' },
  { id: '10', Age:'All', name: 'Kelly Snyder', address: 'jjj' },
];
 
// const containsText = (text, searchText) =>
// text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

 function MultipleSelect() {
  const [personName, setPersonName] = useState('0')
  const [personAge, setPersonAge] = useState('0')
  const [personAddress, setPersonAddress] = useState('0')
  const [searchText, setSearchText] = useState("");
  const theme = useTheme()

//   const displayedOptions = useMemo(
//     () => names.filter((option) => containsText(option, searchText)),
//     [searchText]
//   );

  const handleChangeName = (event) => {
    setPersonName(event.target.value)
  }
  const handleChangeAge = (event) => {
    setPersonAge(event.target.value)
  }
  const handleChangeAddress = (event) => {
    setPersonAddress(event.target.value)
  }

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: 'black',
    fontWeight: 600
  }
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: '1'
  }
  const selectDropdown = {
    '.MuiOutlinedInput-input': { fontSize: theme.text.fontSize, padding: '1.5px 4px' }
  }

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: 'none',
    width:"100px",
    color: '#fff',
    my:2.5
  }

  return (
    <>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>Customer</FormLabel>
        <Select
          value={personName}
          onChange={handleChangeName}
          onClose={() => setSearchText("")}
          MenuProps={MenuProps}
          sx={selectDropdown}
          //renderValue={() => personName}
        >
            <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
            </ListSubheader>
          {names.map((name) => (
            <MenuItem
              key={name.id}
              value={name.id}
              sx={menuStyles}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>Fleet</FormLabel>
        <Select
          value={personAge}
          onChange={handleChangeAge}
          MenuProps={MenuProps}
          sx={selectDropdown}
        >
          {names.map((age) => (
            <MenuItem
              key={age.id}
              value={age.id}
              sx={menuStyles}
            >
              {age.Age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>Vehicle</FormLabel>
        <Select
          value={personAddress}
          onChange={handleChangeAddress}
          MenuProps={MenuProps}
          sx={selectDropdown}
        >
          {names.map((address) => (
            <MenuItem
              key={address.id}
              value={address.id}
              sx={menuStyles}
            >
              {address.address}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>IMEI</FormLabel>
        <Select
          value={personAddress}
          onChange={handleChangeAddress}
          MenuProps={MenuProps}
          sx={selectDropdown}
        >
          {names.map((address) => (
            <MenuItem
              key={address.id}
              value={address.id}
              sx={menuStyles}
            >
              {address.address}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>Customer Assigned</FormLabel>
        <Select
          value={personAddress}
          onChange={handleChangeAddress}
          MenuProps={MenuProps}
          sx={selectDropdown}
        >
          {names.map((address) => (
            <MenuItem
              key={address.id}
              value={address.id}
              sx={menuStyles}
            >
              {address.address}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 120 }} size="small">
        <FormLabel sx={labelStyle}>Create Date</FormLabel>
        <Select
          value={personAddress}
          onChange={handleChangeAddress}
          MenuProps={MenuProps}
          sx={selectDropdown}
        >
          {names.map((address) => (
            <MenuItem
              key={address.id}
              value={address.id}
              sx={menuStyles}
            >
              {address.address}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
      <Button variant="contained" sx={buttonStyle} type='submit'>Search</Button>
      </FormControl>    
    </>
  );
}
export default React.memo(MultipleSelect);





