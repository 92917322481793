import Service from "./service";

const OrganizationServices = {
  getGridViewForOrg: function (resource) {
    //return Service.post('/gridviewfororg')
    //return Service.get('/gridviewfordevice')
    return Service.post("/organization/listOrganization", resource, null);
  },
  saveOrgDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/organization/createOrganization", data, null);
  },
  deleteOrgById: (orgId) => {
    //console.log('i am here...')
    return Service.post("/organization/deleteOrganization", orgId, null);
  },
  editOrgById: (orgId) => {
    return Service.post("/organization/getOrganization", { orgId });
  },
  updateOrgDetails: function (data) {
    return Service.post("/organization/updateOrganization", data, null);
  },  
  getOrganization: (orgId) => {
    return Service.post('organization/getOrganization', orgId)
  },
  updateOrganization: (orgId) => {
    return Service.post('/organization/updateOrganization', orgId,null)
  }

};

export default OrganizationServices;
