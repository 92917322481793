const USER_DETAIL = [
  {
    type: "text",
    label: "First Name",
    name: "firstName",
    placeholder: "FIRSTNAME",
  },
  {
    type: "text",
    label: "Last Name",
    name: "lastName",
    placeholder: "LASTNAME",
  },
  {
    type: "text",
    label: "User Name [Eg: abc@gmail.com]",
    name: "userName",
    placeholder: "USERNAME",
  },
  {
    type: "password",
    label: "Password",
    name: "password",
    placeholder: "PASSWORD",
  },
  {
    type: "text",
    label: "Email Id",
    name: "email",
    placeholder: "EMAIL",
  },
  {
    type: "select",
    label: "Country Code",
    name: "countryId",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: "-1",
      },
      {
        id: 2,
        title: "India+91",
        value: "4",
      },
      {
        id: 3,
        title: "Australia+61",
        value: "2",
      },
      {
        id: 4,
        title: "Canada+1",
        value: "3",
      },
      {
        id: 4,
        title: "United States+1",
        value: "5",
      },
    ],
  },
  {
    type: "number",
    label: "Mobile",
    name: "mobile",
    placeholder: "MOBILE NUMBER",
  },
];

const USER_LIST_DETAIL = [
  {
    type: "select",
    label: "Organization Type",
    name: "orgState",
    options: [
      {
        id: 1,
        title: "Organization",
        value: "1",
      },
      {
        id: 2,
        title: "Customer",
        value: "2",
      },
      {
        id: 3,
        title: "Dealer",
        value: "3",
      },
    ],
  },
  {
    type: "select",
    label: "Time Zone",
    name: "orgTimezone",

    options: [
      {
        id: 1,
        title: "select",
        value: "1",
      },
    ],
  },
  {
    type: "select",
    label: "Fleet",
    name: "orgTimezone",
    options: [
      {
        id: 1,
        title: "select",
        value: "1",
      },
    ],
  },
];
module.exports = {
  USER_DETAIL,
  USER_LIST_DETAIL,
};
