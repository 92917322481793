import React from "react";
import { Box } from "@mui/system";
import { Typography, Paper, Divider, Stack, Grid } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import DropDown from "../shared/DropDown";
import InsuranceCard from "./insurance";
import FitnessCard from "./fitness";
import MaintenanceCard from "./maintenance";

const ServiceAlert = () => {
  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Alerts" subDiv="Service Alerts"></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={4} sx={{ padding: "1em" }}>
          <Typography sx={{ fontSize: "14px" }}>Service Alerts</Typography>
          <br />
          <Divider sx={{ borderBottomWidth: 2 }} />
          <Paper sx={{ padding: "0.8em", marginTop: 3 }}>
            <DropDown />
          </Paper>
          {/* <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginLeft: 2,
              marginTop: 2,
              "& > :not(style)": {
                m: 3,
                width: 400,
                height: 290,
              },
            }}
          > */}
          <Grid
            container
            sx={{
              marginTop: 1,
              marginBottom: 2,
            }}
            spacing={6}
          >
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  INSURANCE
                </Typography>
                <InsuranceCard />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  FITNESS CERTIFICATE
                </Typography>
                <FitnessCard />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2}>
                <Typography sx={{ color: "white", backgroundColor: "#00b8d4" }}>
                  MAINTENANCE
                </Typography>
                <MaintenanceCard />
              </Paper>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Paper>
      </Box>
    </Box>
  );
};
export default ServiceAlert;
