import React, { useState,useCallback,useRef } from "react";

import SwapBlack from '../../assets/images/SwappingIcons.png';
import SwapBlackIcon from '../../assets/images/swapImage.png';

import { Marker, InfoWindow, Polyline, GoogleMap, LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAP_KEY } from './../../constants/appConstant';
//import MainTreeview from '../treeview/mainTreeview.js';
import treeviewService from '../../services/treeviewService';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns';

const refDisplay={display:"none"};
const SwapTreeMap = ({path,lat,lng,editSwapView,objData,clickedType} ) => {
  //console.log("objData1::"+objData[0]);

  const containerStyle = {
    height: "95vh",
    width: "100%"
  };
  const defaultProps = {
    center: { lat: 21.28, lng: 78.83 },
    zoom: 5
  };
  // const places = [
  //   { id: '1', lat: 12.9810684, lng: 80.2620270, vehicleNo: 'KA 53 HA 9911' },
  //   { id: '2', lat: 12.9816218, lng: 80.2325604, vehicleNo: 'KA 53 HA 9912' },
  //   { id: '3', lat: 12.9720858, lng: 80.2195642, vehicleNo: 'KA 53 HA 9913' },
  //   { id: '4', lat: 13.0242297, lng: 78.7877174, vehicleNo: 'KA 53 HA 9914' },
  //   { id: '5', lat: 12.8764823, lng: 78.2083755, vehicleNo: 'KA 53 HA 9915' },
  //   { id: '6', lat: 13.0262544, lng: 77.2017760, vehicleNo: 'KA 53 HA 9916' }
  // ]; 
  var globalOrgId=1;
  var globaluserId=1;
  var globalJson={};
  if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
  } else{
    globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
  }
  globalOrgId=globalJson.orgId;
  globaluserId=globalJson.id;
  const [placeVals, setPlaceVals] =useState([]);  
    /*
  const fetchdata=async (objData)=>{
    if(objData.regionId!==undefined){
      objData.resellerId=globalOrgId;
      var data1=await swaptreeviewService.getSwapStationList(objData, null);
      setPlaceVals(data1.data.data);
    }
    return "";
  }
  */
 
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState('')
  //const [isOpen, setIsOpen] = useState(false);
 
  const [fromDate,setFromDate] =useState("");
  const [toDate,setToDate] =useState("");
  const [imageIconShow,setImageIconShow] =useState(false);

  const refFromDate=useRef("");
  const refToDate=useRef("");
  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };
  const handleToggleOpen = (markerId) => {  
    setOpenInfoWindowMarkerId("markerId");  
    setOpenInfoWindowMarkerId(markerId);    
  };
  const clickVal=(e)=>{
    setFromDate(format(e[0], 'yyyy-MM-dd hh:mm:ss'));
    setToDate(format(e[1], 'yyyy-MM-dd hh:mm:ss'));   
  }
  
  const fetchBusinesses = async (checkval,val) => {
    var request = {};
    //request.resellerId = props.dataObj.key;
    request.orgId = 0;
    request.page = 0;
    request.size = 10;
    request.value = "vehicleId";
    request.searchText = "";
    request.vehModel = "1";
    request.vehicleId = val;
    if(refFromDate.current.innerHTML===undefined || ""===refFromDate.current.innerHTML)
    {      
      request.fromDate=format(new Date(), 'yyyy-MM-dd hh:mm:ss');
      request.toDate=format(new Date(), 'yyyy-MM-dd hh:mm:ss');
    }else{
      request.fromDate=refFromDate.current.innerHTML;
      request.toDate=refToDate.current.innerHTML;      
    }   
    var region = {};
    region.region = 1;
    return treeviewService.getVehicleGridViewTreeview(request, region)
      .then(res => {
        let stockdata = res.data.dataList;    
        setOpenInfoWindowMarkerId("");    
        setPlaceVals([]);         
        setPlaceVals(stockdata.content);
        setOpenInfoWindowMarkerId("markerId");
        setImageIconShow(true);     
        return res;      
      })
      .catch(err => {
        console.log(err);
      });
  };
  const { afterToday } = DateRangePicker;
    const expandFleetDataShowfn = useCallback((requestFrom) => { 
    //fetchBusinesses(request); 
    var request = {};
    request.resellerId = requestFrom.resellerId;
    request.regionId = requestFrom.regionId;
    request.dealerId = requestFrom.dealerId;
    request.customerId = requestFrom.customerId;
    request.accountId = requestFrom.accountId;
    request.orgId = requestFrom.orgId;
    request.page = 0;
    request.size = 1000;
    request.region = 0;
    request.vehModel = "2";
    request.value = "fleetViewVehicle";
    request.searchText = "";
    request.vehModel = "1";
    //request.vehicleId = val;
    if(requestFrom.checked===true){
      var region = {};
      region.region = 1;
      return treeviewService.getVehicleGridViewTreeview(request, region)
        .then(res => {
          let stockdata = res.data.dataList;
          setImageIconShow(false);
          setOpenInfoWindowMarkerId("");    
          setPlaceVals([]);         
          setPlaceVals(stockdata.content);
          setOpenInfoWindowMarkerId("markerId");   
          return res;      
        })
        .catch(err => {
          console.log(err);
        });
    }else{
      setImageIconShow(false);
      setOpenInfoWindowMarkerId("");    
      setOpenInfoWindowMarkerId("markerId");   
    }
    return null;
}, []);
  return (
    <>
      <LoadScript
        googleMapsApiKey={GOOGLE_MAP_KEY}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
        >
           { objData.length>0 &&
            objData.map((location, i) => (
              <Marker
                key={location.swapstationId}
                position={location}
                isMarkerShown={true}
                icon={clickedType==="dealer"?SwapBlackIcon:SwapBlack}  //MarkerImage
                onClick={() => handleToggleOpen(location.swapstationId)}
              >
             {openInfoWindowMarkerId === location.swapstationId && (
                  <InfoWindow >
                  <span sx={{backgroundColor:"brown"}}><p>SwapStationId:{location.swapstationId}</p>
                  <p>SwapStation Name:{location.swapstationName}</p>
                  </span>  
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
          {imageIconShow===true && 
            <Polyline
              options={options}
              path={placeVals}
            />
          }
        </GoogleMap>
      </LoadScript>
    </>
  )
}

export default SwapTreeMap