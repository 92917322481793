import React, { useState,useCallback } from "react";
import MainDataLine5 from "./MainDataLine5";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import swaptreeviewService from '../../services/swaptreeviewService';

const MainDataLine4 = (prop) => {
  var orgType=prop.orgType;
  var list2DivStyleLeft=13;  
  var list2DivStylepngLeft="13px";
  if(orgType===5){
     list2DivStyleLeft=13;
     list2DivStylepngLeft="13px";
  }if(orgType===2){
     list2DivStyleLeft=62;  
     list2DivStylepngLeft="58px";
   }
  //color: "#ffffff"
  //background: "#d2edf1 !important",color:"#6A6463"
  const list2DivStyle = { minHeight: '28px !important', maxHeight: '38px !important', gap: 0.5, pointerEvents: "auto", background: "#91AFDC !important", color: "#ffffff", paddingLeft:list2DivStylepngLeft
, fontWeight: "bold", fontSize: "14px",fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",position: "relative",marginTop:"0px",
paddingTop:"-1px",marginBottom: "-5px",
  "&:before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 32,
        left: list2DivStyleLeft,
        top: 0,     
        borderLeft: "1px solid white",
        height: "18px",
        fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: list2DivStyleLeft,
        top: 16,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
        }    
};
  
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");
  const onClickSlotfn = useCallback((checkval,val,swapstationId) => {   
      prop.onClickSlotfn(checkval,val,swapstationId);  
      return null;
  }, []);

  const expandValChk = useCallback((val) => {  
    prop.expandValChk(val);
    return null;
  }, []);

  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);     
      return null;
  }, []);

  const handleChange5 = () => {
    }; 

  const onloadMoreLastfn = useCallback(async (count,index,requestObj) => {  
    var request = {};
        request.resellerId = prop.selectedObject.resellerId;
        request.regionId = prop.selectedObject.regionId;
        request.dealerId = prop.selectedObject.dealerId;
        request.customerId = requestObj.customerId;
        request.accountId = 0;
        request.orgId = 0;
        request.page = 0;
        request.size = parseInt(requestObj.size)+parseInt(10);
        request.region = 0;
        request.vehModel = "2";
        request.value = "fleet";
        request.searchText = "";
        request.vehModel = "1";
        var region = {};
        region.region = 1;
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
        let stockdata = {};
        stockdata = await responseData.data.data;
        var request1 = {};
        request1.resellerId = prop.selectedObject.resellerId;
        request1.regionId = prop.selectedObject.regionId;
        request1.dealerId = prop.selectedObject.dealerId;
        request1.customerId = requestObj.customerId;
        request1.accountId = 0;
        request1.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
        request1.page = 0;
        request1.size = parseInt(requestObj.size)+parseInt(10);
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "vehicle";
        request1.searchText = "";
        request1.vehModel = "1";
        var region1 = {};
        region1.region = 1;
        let responseData1 = await treeviewService.getOrgGridViewForTreeview(request1, region1)
        let stockdata1 = {};
        stockdata1 = await responseData1.data.data;
        setElementListServer(elementDataServer(request, stockdata.content, request1, stockdata1.content));
        // setExpandVal(true);
        //setExpandedInsdL1((newExpanded ? panel : false));
    return null;
  }, []);


  const elementDataServer = (request, dataArray, request1, dataArray1) => {
    //console.log("request:"+request.swapstationId)
    if(4===prop.orgType){
      //return <MainDataAccount3 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickSlotfn={onClickSlotfn} loadMoreLast={onloadMoreLastfn} expandFleetDataShowfn={expandFleetDataShowfn} expandValChk={expandValChk} orgType={orgType} />;
    }if(6===prop.orgType){
      //return <MainDataAccount3 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickSlotfn={onClickSlotfn} loadMoreLast={onloadMoreLastfn} expandFleetDataShowfn={expandFleetDataShowfn} expandValChk={expandValChk} orgType={orgType} />;
    }else{
      return <MainDataLine5 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickSlotfn={onClickSlotfn} loadMoreLast={onloadMoreLastfn} expandValChk={expandValChk} orgType={orgType} />;
    }
  }
  const handleChange3 =
    (index, panel) => async (newExpanded) => {
      if (expandVal === true) {
        setExpandVal(false);
        setExpandedInsdL1(false);
        prop.expandValChk(false);
        var request = {};
        request.checked=false;
        setElementListServer("");
        prop.expandFleetDataShowfn(request);
      } else {
        prop.expandValChk(true);        
        var request={}; 
        request.swapstationId = index;
        request.flag=3;
        var response = await swaptreeviewService.getMapViewTreeCommonData(request, null)
        var stockdataContent1 = response.data.data[0].slots;
        var request1={};
        setElementListServer("");
        setElementListServer(elementDataServer(request, stockdataContent1, request1, stockdataContent1));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
      }
    };
    const callbackSwpStnfn = useCallback((request) => {     
      prop.callbackSwpStnfn(request);     
        return null;
    }, []);
  const returnData = (checkboxList1) => {    
    return <div>
      {checkboxList1.map((d, index) => (
        <div
          className="text"
          key={d.swapstationId} 
        >
          <Accordion sx={{borderTop:"1px solid white"}} expanded={expandedInsdL1 === "panelInside" + d.swapstationId} onChange={handleChange3(d.swapstationId, 'panelInside' + d.swapstationId)} >
            <AccordionSummary sx={list2DivStyle} >
            <Box sx={{ width: '100%', alignContent: 'flex-start',marginTop:"-2%" }}>&nbsp;
                        <Radio
                            onChange={handleChange5}
                            value="a"
                            name="radio-buttons"
                            width= "4%"
                            height= "4%"
                            sx={{                                        
                                pointerEvents: "auto"
                            }}
                            inputProps={{ 'aria-label': 'A' }} label={d.swapstationName}
                        /><span style={{marginLeft:"-3%"}} onClick={()=>{callbackSwpStnfn(d.swapstationId);}} >{d.swapstationName}</span>
                       
                    </Box><Box sx={{ alignSelf: 'center',marginTop:"-2.5%" }}>
                        <IndeterminateCheckBoxIcon
                            sx={{
                                pointerEvents: "auto",
                                //color: "#a0adaa",
                                color: "#f0dfdf"
                            }}
                            style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.swapstationId)) ? "block" : 'none' }}
                        />
                        <AddBoxIcon sx={{
                            pointerEvents: "auto",
                            //color: "#a0adaa",
                            color: "#f0dfdf"
                        }}
                            style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.swapstationId)) ? "none" : 'block' }}
                        />   </Box> 
                  </AccordionSummary>
            <AccordionDetails>
              {elementListServer}                
            </AccordionDetails> 
                      
          </Accordion>
          
        </div>
        
      ))}
    </div>;
  }
  const listElementData = returnData(prop.dataArray);  
  return (
    <div>{listElementData}   
    </div>
  );
}
export default React.memo(MainDataLine4);
