import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateDevice from "./CreateDevice";
import ListDevice from "./ListDevice";
import { Breadcrumb } from "../shared/Breadcrumbs";

const Device = () => {
  const [deviceToEdit, setDeviceToEdit] = React.useState(null);
  const [tabValue, setTabValue] = useState(1);

  const handleDevEdit = (devEditedId) => {
    setDeviceToEdit(devEditedId);
    setTabValue(0);
  };

  const handleTabChange = (event, newValue) => {
    setDeviceToEdit(null);
    setTabValue(newValue);
  };

  const handleCancel = () => {
    setTabValue(1);
  };

  const TABS = [
    {
      label: "Create Device",
      children: (
        <CreateDevice deviceToEdit={deviceToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} />,
    },
  ];

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Device"></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
    </Box>
  );
};

export default Device;
