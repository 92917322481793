import React, { useMemo, useState, useEffect } from "react";
import DataTable from "./DataTableSlot";
import SwapStationServices from "../../services/swapStationService";
 


const SlotTable = ({swapIdSlot}) => {
  console.log(swapIdSlot)

  const[responseData, setResponseData]=useState([]);
  
  const columns = useMemo(
    () => [
      {
        Header: "Slot No.",
        accessor: "slotNumber",
      },
      {
        Header: "Output Voltage",
        accessor: "slotOutputVoltage",
      },
      {
        Header: "Voltage",
        accessor: "slotVoltage",
      },
      {
        Header: "Capacity",
        accessor: "slotCapacity",
      },
      {
        Header: "Battery Model",
        accessor: "batteryModel",
      },
    ],
    []
  );
   
  

   const fetchSlotDetails = async () => {
     console.log("swapId - slot table: "+swapIdSlot)
    var request={}
    request.id= swapIdSlot;
    request.dealerId = 0;
    request.regionId = 0;
    request.customerId = 0;
    request.stationType = "string";
    const responseData= (await SwapStationServices.getSlotDetails(request)).data;
    setResponseData(responseData.data);
  };

  useEffect(async () => {
    await fetchSlotDetails();
  }, [swapIdSlot]);

  return (    
        <DataTable
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
        />
         
     
  );
};

export default SlotTable;
