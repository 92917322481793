import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ text, open1, handleClear }) {
  //   const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    handleClear();
  };

  return (
    <div>
      <Dialog
        open={open1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <AppBar sx={{ position: "relative", background: "#b71c1c" }}>
          <Toolbar>
            <DialogTitle style={{ cursor: "pointer", color: "#fff" }}>
              {text}
            </DialogTitle>
            {/* <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {text}
              </DialogContentText>
            </DialogContent> */}
            <DialogActions>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
}
