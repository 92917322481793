import Service from "./service";

const calendarService = {
  getGridViewForCalendar: function (resource) {
    return Service.post('/booking/listBooking',resource,null)
  },
  getBookingBySwapStationMonth: function (resource) {
    return Service.post('/booking/getBookingBySwapStationMonth',resource,null)
  },
  getBookingBySwapStationDay: function (resource) {
    return Service.post('/booking/getBookingBySwapStationDay',resource,null)
  },
  getBookingBySwapStationWeek: function (resource) {
    return Service.post('/booking/getBookingBySwapStationWeek',resource,null)
  },
  getAvailableSlotsByTime: function (resource) {
    return Service.post('/booking/getAvailableSlotsByTime',resource,null)
  },
  saveBookingSlots: function (resource) {
    return Service.post('/booking/saveBooking',resource,null)
  },
  getnearestSwapStation: function (resource) {
    return Service.post('/service/getnearestSwapStation',resource,null)
  }

};

export default calendarService;
