import { Box, Tab, Tabs, alpha, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { TabItem, TabsList } from '../components/style/StyledComponets';

function CustomTabs(props) {
    const { tabsList, handleTabValue, tabValue } = props
    let theme = useTheme();
    const handleTabs = (event, newValue) => {
        handleTabValue(newValue);
    }
  return (
    <Box sx={{borderBottom:`0.1px solid ${theme.palette.divider}`, px:2, background:'#fff', }}>
        <TabsList value={tabValue} onChange={handleTabs}>
            {
                tabsList.map((tab, index) => {
                    return(
                        <TabItem key={index} label={tab.label} disabled={tab.disabled}/>
                    )
                })
            }
        </TabsList>
    </Box>
  )
}

export default CustomTabs