import Service from "./service";

const deviceServices = {
  getGridViewForDevice: function (resource) {
    return Service.post('/device/listDevice',resource,null)
  },
  saveDeviceDetails: function (data) {
    return Service.post('/device/createDevice',data,null)
  },
  deleteDeviceById: (deviceId) => {
    return Service.post('/device/deleteDevice',deviceId, null)
  },
  editOrgById: (deviceId) =>{
    return Service.get('/device/getDevice',{deviceId})
  },
  updateDeviceDetails: function (data) {
    return Service.post('/device/updateDevice',data,null)
  },
  getLicenseKeyList:function (data){
    return Service.post('/device/getLicenseKey',data,null)
  },
  getlistofPlansList:function(data){
    return Service.post('/vehicle/getlistofPlans',data,null)
  },
  getlistofDevicePlans:function(data){
    return Service.post('/device/getDevicePlan',data,null)
  },
  getlistofMFGSiteCode:function(data){
    return Service.post('/device/getMFGSiteCode',data,null)
  },
  getlistofPartNumberList:function(data){
    return Service.post('/device/getPartNumber',data,null)
  },
  getDeviceModelNameList:function(data){
    return Service.post('/device/getDeviceModelName',data,null)
  },
  getSimPlanStatusList:function(data){
    return Service.post('/sim/getSimPlanStatus',data,null)
  },
  getDeviceStatusList:function(data){
    return Service.post('/device/getDeviceStatus',data,null)
  },
  getDeviceSimNumberLists:function(data){
    return Service.post('/device/getSIMNumberList',data,null)
  },
  getDeviceSimDetails:function(data){
    return Service.post('/device/getSimDetails',data,null)
  }  
};

export default deviceServices;
