import React from 'react';
import { Box } from '@mui/system';
import { Typography, Paper, Divider } from '@mui/material';
import {Breadcrumb} from '../shared/Breadcrumbs';
import DropDown from '../shared/DropDown';
import BasicTable from './dtable';


const viewAlert = () => {


return (
    <Box sx={{padding: '5px'}}>
    <Breadcrumb mainDiv='Alerts' subDiv='View Alerts'></Breadcrumb>
    <Box sx={{padding:'15px'}}>
    <Paper elevation={4} sx={{padding: '1em'}}>
     <Typography  sx={{fontSize:'14px'}}>View Alerts</Typography>
     <Typography sx={{fontSize:'11px'}}><b>Note: </b>Alerts captured are based on assignment (via Alerts {">"} Assign Alerts) for each vehicle at the time of occurrence.</Typography>
     <Divider />
     <Paper sx={{padding: '0.8em', marginTop: 3}}>
    <DropDown />
    </Paper>
    <Box sx={{ marginTop: 3}}>
    <BasicTable />
    </Box>
    </Paper>
    </Box>
    </Box>
)
}
export default viewAlert