import * as React from "react";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";

export default function ContentCard({ slot }) {
  return (
    <Stack sx={{ marginTop: 3 }}>
      <FormLabel
        sx={{
          fontWeight: "Bold",
          color: "#212121",
          marginLeft: 2,
          fontSize: "16px",
          p: 1,
        }}
      >
        Slot: {slot.slotNumber === undefined ? "" : slot.slotNumber}
      </FormLabel>

      <FormLabel
        sx={{
          fontWeight: "Bold",
          color: "#212121",
          marginLeft: 2,
          fontSize: "16px",
          p: 1,
        }}
      >
        Battery Status: {slot.batteryName === undefined ? "" : slot.batteryName}
      </FormLabel>

      <FormLabel
        sx={{
          fontWeight: "Bold",
          color: "#212121",
          marginLeft: 2,
          fontSize: "16px",
          p: 1,
        }}
      >
        BIN Number: {slot.batteryId === undefined ? "" : slot.batteryId}
      </FormLabel>
    </Stack>
  );
}
