import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";

export const PageHeader = ({ title }) => {
  const theme = useTheme();

return(
<Typography variant="h6" align="left" margin="dense" borderBottom={theme.title.borderLine} sx={{color:theme.text.primary, fontSize:theme.text.fontSize}}>
        {title}
      </Typography>
)
}

