import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CreateUser from "./CreateUser";
import ListUser from "./ListUser";
import { Breadcrumb } from "../shared/Breadcrumbs";

const User = () => {
  const [userToEdit, setUserToEdit] = React.useState(null);
  const [tabValue, setTabValue] = useState(1);
  const [create, setCreate] = useState("Create User");

  const handleTabChange = (event, newValue) => {
    setUserToEdit(null);
    setTabValue(newValue);
    setCreate("Create User");
  };

  const handleUserEdit = (userEditedId) => {
    setTabValue(0);
    setUserToEdit(userEditedId);
    setCreate("Edit User");
  };

  const handleCancel = () => {
    setTabValue(1);
    setCreate("Create User");
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="User"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="user tabs" sx={{margin:"20px 0px"}}>
        <Tab label={create} />
        <Tab label="Users" />
      </Tabs>
      {tabValue === 0 ? (
        <CreateUser userToEdit={userToEdit} onCancel={handleCancel} sx={{margin:"20px"}} />
      ) : (
        <ListUser onEditUser={handleUserEdit} sx={{margin:"20px"}}/>
      )}
    </Box>
  );
};

export default User;

