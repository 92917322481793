import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { Paper } from "@mui/material";
import customerService from "../../services/customerService";
import UserServices from "../../services/userServices";
import CommonDropDown from "../common/commonDropdown";
import DraggableDialog from "../shared/DailogDrag";

const ResellerId = 0;
const ListUser = ({ onEditUser }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);
  const [regionId, setRegionId] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const columns = useMemo(
    () => [
      {
        Header: "Org Type",
        accessor: "userType",
      },
      {
        Header: "Org Name",
        accessor: "userOrgName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      // {
      //   Header: "Primary Contact",
      //   accessor: "primaryContact",
      // },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile No",
        accessor: "mobile",
      },
      {
        Header: "Role",
        accessor: "userRole",
      },
      {
        Header: "Status",
        accessor: "statusType",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const options = [
    {
      id: 1,
      value: "test1",
      title: "test1",
    },
    {
      id: 2,
      value: "test2",
      title: "test2",
    },
    {
      id: 3,
      value: "test3",
      title: "test3",
    },
  ];

  var globaluserId = 1;
  var globalresellerId = "1";
  var globalorgId = "1";
  var globalJson = {};
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globaluserId = globalJson.id;
  globalresellerId = globalJson.resellerId;
  globalorgId = globalJson.orgId;
  console.log(globalorgId);
  const selectSettings = {
    includeSearchBox: true,
    handleSearchTextChange: async (newText) => {
      const customerList = await customerService.getSubResellerList({
        ResellerId,
      });
    },
    includeAllOption: true,
    searchBoxStyles: {},
  };

  const onEdit = (itemEdited) => {
    onEditUser(itemEdited.id);
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  useEffect(async () => {
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = regionId;
    request.dealerId = dealerId;
    try {
      const responseData = (await UserServices.getUser(request)).data.data;
      setResponseData(responseData.content);
      setTotalElements(responseData.totalElements);
      setTotalPages(responseData.totalPages);
    } catch (error) {}
  }, [regionId, dealerId]);

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(false);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContent(obj);
      setDataStatus(true);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };
  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await UserServices.deleteUserById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
    setOpen(true);
  };

  // const UserListDropDown = () => {
  //   const [hideDropDown, setHideDropDown] = useState(true);
  //   const [hideFleetDropDown, setHideFleetDropDown] = useState(false);

  //   const handleClick = (event) => {
  //     const orgType = event.target.value;
  //     if (orgType === "Agency" || orgType === "Support") {
  //       setHideDropDown(false);
  //       setHideFleetDropDown(false);
  //     } else if (orgType === "Fleet") {
  //       setHideDropDown(true);
  //       setHideFleetDropDown(true);
  //     } else {
  //       setHideDropDown(true);
  //       setHideFleetDropDown(false);
  //     }
  //   };
  //   return (

  //   );
  // };

  const handleSearch = (searchText) => {
    console.log({ searchText });
  };
  const handleSubmit = async (formData) => {
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = regionId;
    request.dealerId = dealerId;
    try {
      const responseData = (await UserServices.getUser(request)).data.data;
      setResponseData(responseData.content);
    } catch (error) {}
    setRegionId(formData.regionId);

    setDealerId(formData.dealerId);
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = -1;
    request.dealerId = -1;
    try {
      const responseData = (await UserServices.getUser(request)).data.data;
      setResponseData([]);
      setResponseData(responseData.content);
      setTotalElements(5);
      setTotalPages(0);
      setTotalElements(responseData.totalElements);
      setTotalPages(responseData.totalPages);
    } catch (error) {}
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
        <CommonDropDown
          onSubmit={handleSubmit}
          isShowStation={false}
          isShowType={false}
          shouldIncludeAllOption={true}
        />
      </Paper>
      <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
        <DataTable
          includeSearchBox={false}
          onSearch={handleSearch}
          onEdit={onEdit}
          onDelete={onDelete}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={callbackChangePage}
          totalElements={totalElements}
          totalPages={totalPages}
        />
      </Box>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
};

export default ListUser;
