import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GOOGLE_MAP_KEY } from '../constants/appConstant';
import { Box, IconButton, Stack } from '@mui/material';
import { GoPlus } from 'react-icons/go';
import { PiMinus } from 'react-icons/pi';

function GoogleMapWrapper(props) {
    const { children, renderArray, position = {lat: 25.9124, lng: 74.7873} , mapZoom = 5} = props;
  //map options
  const [center, setCenter] = useState(position);
  const [zoom, setZoom] = useState(mapZoom);
  const [map, setMap] = useState(null);
  const [libraries] = useState(["drawing", "places", "geometry"]);
  let mapRef = useRef();
    const options = {
        zoom: zoom,
        center: center,
        minZoom: 2,
        maxZoom: 22,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
        featureType: "poi",
        stylers: [{ visibility: "off" }],
        tilt: 90,
        heading: 320,
        disableDoubleClickZoom: false,
    
      };

      const mapContainerStyle = {
        height: "100%",
        position: "relative",
      };


   

  

    const onLoad = useCallback(
        function callback(map) {
          const bounds = new window.google.maps.LatLngBounds(center);
          map.fitBounds(bounds);
          setMap(map);
          setZoom(mapZoom);
          mapRef.current = map;
          //setZoomLevel(map.getZoom());
        },
        []);

        const { isLoaded } = useJsApiLoader({
            id: "google-map-script",
            //googleMapsApiKey: "AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA",
            googleMapsApiKey: GOOGLE_MAP_KEY,
            libraries,
            //version:'beta'
          });

          useEffect(() => {
            if(isLoaded && mapRef.current !== undefined){
                console.log('mapRef.current', mapRef.current);
                mapRef.current.setZoom(mapZoom);
                setZoom(5);
                setZoomControl();
            }
          },[isLoaded, mapRef.current]);

          const setZoomControl = () => {
              setTimeout(() => {
                mapRef.current.setZoom(mapZoom);
              }, 1000);
          }

    const onChangeZoom = () => {
        setZoom(map?.zoom)
    }

    const chagenedCenter = () => {
        if(!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setCenter(newPos);
      }

    const mapMemo = useMemo(() => {
        return(
            isLoaded &&
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                options={options}
                onLoad={onLoad}
                onZoomChanged={onChangeZoom}
                onCenterChanged={chagenedCenter}
            >
                {children}
            </GoogleMap>
        )
    },[renderArray, isLoaded, zoom])


    const MapControls = () => {
       return(
        <Stack sx={{position:'absolute', bottom:'0px', right:'10px', zIndex:9999}} rowGap={1}>
        <IconButton sx={{background:'#fff', '&:hover':{background:'#ddd'}}} size='small' disabled={map?.zoom === 22} onClick={() => setZoom(map?.zoom + 1)}><GoPlus /></IconButton>
        <IconButton sx={{background:'#fff', '&:hover':{background:'#ddd'}}} size='small' disabled={map?.zoom === 2}  onClick={() => setZoom(map?.zoom - 1)}><PiMinus /></IconButton>
      </Stack>
       )
    }


  return (
    <Box sx={{height:'100%'}}>
      <MapControls />
      {mapMemo}
    </Box>
  )
}

export default GoogleMapWrapper