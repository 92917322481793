import React, { useState,useEffect } from 'react';
import { Polyline, Marker } from '@react-google-maps/api';
import MarkerImage from '../../assets/images/Bike.png';

const PlayBackMarker=({path})=>  {

const [progress, setProgress]= useState([])
let velocity = 100;
let initialDate = new Date();
let interval =0;


const getDistance = () => {
  // seconds between when the component loaded and now
  const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
  return differentInTime * velocity; // d = v*t -- thanks Newton!
};


//Re render google map
// useEffect(() => {
//   if (map) {
//     var bounds = new window.google.maps.LatLngBounds();
//     for(var i = 0; i < markers.length; i++) {
//       bounds.extend( new window.google.maps.LatLng(markers[i].lat, markers[i].lng));
//     }
//     map.fitBounds(bounds)
//   }
// }, [markers, map])

useEffect(() => {
  interval = window.setInterval(moveObject, 1000);
}, []);

useEffect(() => {
  return () => {
    window.clearInterval(interval);
  }
}, [interval]);

const moveObject = () => {
  const distance = getDistance();
  if (!distance) {
    return;
  }

  let progress = path.filter(
    coordinates => coordinates.distance < distance
  );

  const nextLine = path.find(
    coordinates => coordinates.distance > distance
  );
  if (!nextLine) {
    setProgress(progress );
    return; // it's the end!
  }
  const lastLine = progress[progress.length - 1];

  const lastLineLatLng = new window.google.maps.LatLng(
    lastLine.lat,
    lastLine.lng
  );

  const nextLineLatLng = new window.google.maps.LatLng(
    nextLine.lat,
    nextLine.lng
  );

  // distance of this line
  const totalDistance = nextLine.distance - lastLine.distance;
  const percentage = (distance - lastLine.distance) / totalDistance;

  const position = window.google.maps.geometry.spherical.interpolate(
    lastLineLatLng,
    nextLineLatLng,
    percentage
  );

  //progress = progress.concat(position);
  setProgress( progress );
};


useEffect( () => {
  path = path.map((coordinates, i, array) => {
    if (i === 0) {
      return { ...coordinates, distance: 0 }; // it begins here!
    }
    const { lat: lat1, lng: lng1 } = coordinates;
    const latLong1 = new window.google.maps.LatLng(lat1, lng1);

    const { lat: lat2, lng: lng2 } = array[0];
    const latLong2 = new window.google.maps.LatLng(lat2, lng2);

    // in meters:
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      latLong1,
      latLong2
    );

    return { ...coordinates, distance };
  });

}, []);

    return (
      <>
          { progress && (
            <>
               <Polyline path={progress} options={{ strokeColor: "#FF0000 " }} />
              <Marker
              position={progress[progress.length - 1]} icon={MarkerImage}  />
            </>
          )}

          </>
    )
  
}


export default PlayBackMarker