import Autocomplete from "react-google-autocomplete";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import { borderRadius, height, width } from "@mui/system";

const AutocompleteInput = ({
  label,
  value,
  name,
  defaultValue,
  textInputCallBackFn,
  callbackfnStatus = false,
  onPlaceSelected,
}) => {
  const libraries = ["drawing", "places", "geometry"];
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const [defaultVal, setDefaultVal] = useState(defaultValue);

  const labelStyle = {
    fontSize: 12,
    color: "black",
    fontWeight: 600,
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });
  const selectDropdown = { 
    ".MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 5px",
      width:"350px",
      height:"30px"
    },
    padding: "10px 5px",
    width:"350px",
    height:"30px",
    borderRadius:"5px"
  };
  const callbackfn = (e) => {
    setDefaultVal(e.target.value);
    textInputCallBackFn(e.target.value, name);
  };

  return (
    <InputWrapper>
      <FormControl  sx={{width:"350px"}}>
        <ThemeProvider theme={asterisk}>
          {/* <FormLabel sx={labelStyle} required>
            {label}
          </FormLabel> */}
        </ThemeProvider>
        <Autocomplete
          value={value}
          sx={{selectDropdown}}
          apiKey={GOOGLE_MAP_KEY}
          MenuProps={MenuProps}
          libraries={libraries}
          defaultValue={defaultValue}
          name={name}
          // onPlaceSelected={(place) => {
          //   console.log(
          //     "lat:" +
          //       place.geometry.location.lat() +
          //       " lng" +
          //       place.geometry.location.lng()
          //   );
          //   // onPlaceSelected(
          //   //   place.geometry.location.lat(),
          //   //   place.geometry.location.lng()
          //   // );
          // }}

          onPlaceSelected={(place, e) => {
            console.log(
              "lat" +
                place.geometry.location.lat() +
                " lng" +
                place.geometry.location.lng() +
                "value:::::" +
                e.value
            );
            onPlaceSelected(
              place.geometry.location.lat(),
              place.geometry.location.lng(),
              e.value
            );
            setDefaultVal(e.value);
            //callbackfnStatus === true ? callbackfn(e) : setDefaultVal(e.value);

            // <input type="hidden" name="locationTest" value={place.geometry.location.lat()} />  }}
            //   options={{
            //     types: ["(regions)"],
            //     // componentRestrictions: { country: "ru" },
          }}
          // onBlur={(e) => {

          // }}
          //   defaultValue="Amsterdam"
        />
      </FormControl>
    </InputWrapper>
  );
};

export default AutocompleteInput;
