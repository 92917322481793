import { takeEvery } from "redux-saga/effects";
import { AUTH_LOGIN } from "../constants/actionType";
//import loginService from "../services/loginService";

function* loginUser(params) {
   try {      
        const image='https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg';
        const data = params.payload;
        //const userData=yield loginService.loginUser(data);
        //localStorage.setItem('logoImage',image);                
        
    } catch (e) {
        console.error(e);
    }
}

function* loginSaga() {
    yield takeEvery(AUTH_LOGIN, loginUser);
}

export default loginSaga;