import React, { useState,useCallback } from "react";
import Box from "@mui/material/Box";

import MainDataLine4 from "./MainDataLine4";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
const list2DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 2, pointerEvents: "none", background: "#B7C7DE !important", color: "black", fontSize: '14px',paddingLeft:"67px",
position: "relative",marginTop:"0px",
paddingTop:"10px",
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 68,
    top: 0,     
    borderLeft: "1px solid white",
    height: "170px",
    fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 68,
        top: 17,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
        }        
};

const MainDataLine3 = (prop) => {
    var orgType=prop.orgType;
    const [expandedInsdL1, setExpandedInsdL1] = useState("panelInside" + prop.dataArray[0].key);
    const [expandVal, setExpandVal] = useState(true);
    const [loadmorediv,setLoadmorediv] = useState({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    
    const fnval=(checkval,val)=>{
        prop.onClickfn(checkval,val);
    }

    const onClickfn = useCallback((checkval,val) => {  
        return fnval(checkval.checked,val);
    }, []);

    const elementDataServer = (selectedObj, dataArray, selectedObj1, dataArray1) => {
        return <MainDataLine4 selectedId={selectedObj} dataArray={dataArray} selectedObj1={selectedObj1} dataArray1={dataArray1} onClickfn={onClickfn} orgType={orgType} />;
    }
    const handleChange3 =
        (index, panel) => (newExpanded) => {          
            if (expandVal === true) {
                setExpandVal(false);
                setExpandedInsdL1(false);
                prop.expandValChk(false);
            } else {
                prop.expandValChk(true);
                setExpandVal(true);
                setExpandedInsdL1((newExpanded ? panel : false));
            }
        };
    const handleChange5 = () => {
    };   

    const elementListServer = elementDataServer(prop.selectedObj, prop.dataArray, prop.selectedObj1, prop.dataArray1);
    const returnData = (checkboxList1) => {       
        return <div>
            {checkboxList1.length > 0 && checkboxList1.map((d, index) => (
                <div
                    className="text"
                    key={d.key}
                >
                    <Accordion expanded={expandedInsdL1 === "panelInside" + d.key} onChange={handleChange3(d.key, 'panelInside' + d.key)} >
                        <AccordionSummary sx={list2DivStyle} >
                            <Box sx={{ width: '100%', alignContent: 'flex-start',marginTop:"-2%" }}>&nbsp;
                            <span className={expandVal === true ?"listdataLine3":""}></span>
                                <Radio
                                    onChange={handleChange5}
                                    value="a"
                                    name="radio-buttons"
                                    width= "4%"
                                    height= "4%"
                                    sx={{                                        
                                        pointerEvents: "auto"
                                    }}
                                    inputProps={{ 'aria-label': 'A' }} label={d.value}
                                />
                                <span>{d.value} ({d.count})</span>
                            </Box><Box sx={{ alignSelf: 'center',marginTop:"-2.5%" }}>
                                <IndeterminateCheckBoxIcon
                                    sx={{
                                        pointerEvents: "auto",
                                        color: "#fffbfb",
                                    }}
                                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "block" : 'none' }}
                                />
                                <AddBoxIcon sx={{
                                    pointerEvents: "auto",
                                    color: "#fffbfb"
                                }}
                                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "none" : 'block' }}
                                />   </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {elementListServer}
                            <div style={loadmorediv} >
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span onClick={()=>prop.loadMoreLast(prop.dataArray1.length,d.key,prop.selectedObj)}>Load More</span>
                                    </Grid>
                                    <Grid item xs={4}>                        
                                    </Grid>
                                </Grid>
                                </div>
                        </AccordionDetails>
                    </Accordion>
                    <Divider /> 
                </div>
            ))}
        </div>;
    };
     const listElementData = returnData(prop.dataArray);
    //  if(prop.dataArray.length > 0 && orgType===8){
    //     setExpandedInsdL1("panelInside"+prop.dataArray[0].key);
    // }   
    return (
        <div> {listElementData}
        </div>
    );
}
export default MainDataLine3;
