import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { Typography, Paper } from "@mui/material";
//import DropDown from '../shared/DropDown';
import SelectWithSearch from "../shared/SelectWithSearch";
import customerService from "../../services/customerService";
import DeviceService from "../../services/deviceService";
//import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
import DraggableDialog from "../shared/DailogDrag";

const ResellerId = 0;
const ListDevice = ({ onEditDevice }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await fetchDeviceDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "buttonCheckBox",
      },
      {
        Header: "Region",
        accessor: "region",
      },
      {
        Header: "Dealer",
        accessor: "dealer",
      },
      {
        Header: "Customer",
        accessor: "customerId",
      },
      {
        Header: "Device IMEI Number",
        accessor: "imei",
      },
      {
        Header: "Device Model",
        accessor: "model",
      },
      {
        Header: "Device Status",
        accessor: "deviceStatus",
      },
      {
        Header: "SIM Number",
        accessor: "simNumber",
      },
      {
        Header: "IMSI Number",
        accessor: "imsiNumber",
      },
      {
        Header: "SIM Status",
        accessor: "simStatus",
      },
      {
        Header: "Machine VIN",
        accessor: "uinNo",
      },
      {
        Header: "Last Connected",
        accessor: "activeDate",
      },
      {
        Header: "Last Received Packet",
        accessor: "fittedDate",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Modified Date",
        accessor: "modifiedDate",
      },
      {
        Header: "Framware Version",
        accessor: "firmwareVersionId",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const options = [
    {
      id: 1,
      value: "test1",
      title: "test1",
    },
    {
      id: 2,
      value: "test2",
      title: "test2",
    },
    {
      id: 3,
      value: "test3",
      title: "test3",
    },
  ];

  const selectSettings = {
    includeSearchBox: true,
    handleSearchTextChange: async (newText) => {
      const customerList = await customerService.getSubResellerList({
        ResellerId,
      });
    },
    includeAllOption: true,
    searchBoxStyles: {},
  };

  const handleSelectChange = async (id) => {
    const { id: ResellerId } = options.find((option) => option.id === id);
    const customerList = await customerService.getSubResellerList({
      ResellerId,
    });
  };

  const onEdit = (itemEdited) => {   
    onEditDevice(itemEdited.id);  
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  const onCheckbox = (itemEdited) => {};

  const fetchDeviceDetails = async () => {
    var request={};
    request.resellerId=10;
    request.orgId=2;
    request.page=0;
    request.size=10;
    request.searchText="";

    try {
    const responseData=(await DeviceService.getGridViewForDevice(request)).data 
    setResponseData(responseData.data.content);  
    }    catch (error) {

    }
  }

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(true);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContentConfirm(obj);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await DeviceService.deleteDeviceById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
  };

  return (
    <>
      <Paper elevation={5} sx={{ height: "10vh" }}>
        <SelectWithSearch
          handleChange={handleSelectChange}
          label={"Reseller"}
          options={options}
          settings={selectSettings}
       />
     </Paper>
    <Box display="flex" flexDirection="column">     
      {responseData.length>0 &&      
       <DataTable onEdit={onEdit} onDelete={onDelete} onCheckbox={onCheckbox} columns={columns} data={responseData} styles={{maxWidth: '100vw'}} numberOfStickyColumns={1} />
      }
    </Box>
   { (dataStatusConfirm!==false && dataStatus===true) && <DraggableDialog open={open} dialogButtonText={dialogContentConfirm.dialogButtonText} handleSbmtClose={handleSbmtClose} submitButtonText={dialogContentConfirm.submitButtonText} dialogText={dialogContentConfirm.dialogText} closeButtonText={dialogContentConfirm.closeButtonText} handleClearCls={handleClearCls} />  }
    { (dataStatus!==false && dataStatusConfirm===false) &&  
    <DraggableDialog  open={open}  dialogButtonText={dialogContent.dialogButtonText} handleSbmtClose={handleSbmtClose} submitButtonText={dialogContent.submitButtonText} dialogText={dialogContent.dialogText} closeButtonText={dialogContent.closeButtonText} handleClearCls={handleClearCls} />
    }   

    </>
  );
};

export default ListDevice;
