import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {Grid,Box,Chip, ListItem, Card, Divider, Stack, List, ListItemButton, Icon, CardMedia} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import BookingServices from '../../services/bookingServices';
import TicketConfirmation from "./ticketConfirmation";
import { GoDotFill } from "react-icons/go";
import { useTheme } from "@mui/material";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%"
});

export default function UpcomingSwapList() {  
  const[bookingList,setBookingList]=useState([])
  const[loadRightData,setLoadRightData]=useState();
  var globaluserId=1;
  var globalJson={};
  if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
  } else{
    globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
  }
  globaluserId=globalJson.id;
  const fetchBookingDetails = async () => {
    var request={};
    request.userId= globaluserId;
    request.status= "upComing";
    try{
      const responseData= (await BookingServices.listBookingSlot(request)).data
      setBookingList(responseData.bookedSlotResponseDTOs);
    }
    catch(error){
    }
  }

  useEffect( () => {
    fetchBookingDetails()
  }, [])

  const dataViewfn=(data)=>{
       return <TicketConfirmation data={data} />;
    }
    const [selectId, setSelectId] = useState(null);
  const viewDatafn=async (data)=>{
    var request={};
    request.userId= globaluserId;
    request.bookingId=data.id;
    setSelectId(data.id);
    var responseData= (await BookingServices.getBookingConfirmation(request)).data;
    setLoadRightData(dataViewfn(responseData));
    //console.log("data::"+data);
  }

  let theme = useTheme();
  return (
    // <Grid container spacing={2} sx={{marginTop:"0.5%"}}>
    //   <Grid item xs={8}>
    //     <div >
    // <Box sx={{p: 0, gap: 0,ml:13, maxWidth:690, maxHeight:660,backgroundColor:'#90caf9', overflow:'scroll'}}>
    // {bookingList.map((data) => (
    //   <ListItem>
    // <Paper
    //   sx={{
    //     p: 2, 
    //     margin: "auto",
    //     maxWidth: 650,
    //     flexGrow: 1,
    //     marginBottom:-3,
    //     backgroundColor: (theme) =>
    //       theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    //   }}
    //   onClick={()=>{viewDatafn(data)}}
    // >
    //   <Grid container spacing={2}>
    //     <Grid item>
    //       <ButtonBase sx={{ width: 128, height: 128 }}  >
    //         <Img alt="complex"  src={data.image} />
    //       </ButtonBase>
    //     </Grid>
    //     <Grid item xs={12} sm container>
    //       <Grid item xs container direction="column" spacing={2}>
    //         <Grid item xs>
    //           <Typography gutterBottom variant="subtitle1">
    //             {data.stationName}
    //           </Typography>
    //           <Typography variant="body2" gutterBottom color="text.secondary">
    //             StartDate: {data.bookingStartTime}
    //           </Typography>
    //           <Typography variant="body2" color="text.secondary">
    //             EndDate: {data.bookingEndTime}
    //           </Typography>
    //         </Grid>
    //         {/* <Grid item>
    //           <Typography sx={{ cursor: "pointer" }} variant="body2">
    //             Booked_Date
    //           </Typography>
    //         </Grid> */}
    //       </Grid>
    //       <Grid item>
    //         <Typography variant="body2" gutterBottom >Booking_code</Typography>
    //         <Typography gutterBottom>{data.bookingCode}</Typography>
    //         <Chip label={data.statusMsg==="Booked"?"confirmed":""} color="success" sx={{marginTop:"60px"}}/>
    //         </Grid>
    //       </Grid> 
    //   </Grid>
    // </Paper>
    // </ListItem>
    //     ))}
    // </Box>
    // </div>
    // </Grid>
    // <Grid item xs={4}>
    // <div> {loadRightData}</div>
    // </Grid>
    // </Grid>
    <Box sx={{height:'100%'}}>
    <Grid container spacing={2} sx={{height:'100%'}}>
        <Grid item lg={6}>
            <Card sx={{mt:1.5}}>
                <Box sx={{p:2}}>
                <Stack direction={'row'} spacing={1}>
                    <Typography>Upcoming</Typography>
                    {
                      bookingList.length > 0 && <Chip color="success" label={bookingList.length} size="small"/>
                    }
                    
                </Stack>
                </Box>
                <Divider />
                <Box sx={{p:2, height:'76vh', overflow:'auto'}}>
                <List sx={{m:0}} >
                  {bookingList.map((data, index) => {
                  console.log('data', data);
                  return(
                    data !== null && data !== undefined &&
                    <ListItem sx={{m:0, p:0, border:'.5px solid #f8f8f8', mb:.5}} disableGutters disablePadding>
                        {/* <ListItemIcon sx={{background:theme.palette.primary.main, minWidth:'5px', height:'35px', display:'flex', alignItems:'center', justifyContent:'center', ml:0, borderRadius:'0px 5px 5px 0px'}}>
                        </ListItemIcon> */}
                        <ListItemButton sx={{p:0}} onClick={()=>{viewDatafn(data)}} selected={selectId === data.id ? true : false}>
                            <Box sx={{width:'100%'}}>
                                  <Stack direction={'row'} justifyContent={'space-between'}>
                                <Stack direction={'row'}>
                                    <Box sx={{width:'100px', height:'auto'}}>
                                        <CardMedia 
                                            src={data.image}
                                            component={'img'}
                                            sx={{width:'100%'}}
                                        />
                                    </Box>
                                    <Box sx={{p:1.5}}>
                                        <Box>
                                          <Typography variant="subtitle2" sx={{fontWeight:500}}>{data?.stationName}</Typography>
                                          <Typography variant="body2" sx={{fontWeight:400, fontSize:'14px', color:theme.palette.info.main}}>{data?.bookingCode}</Typography>
                                        </Box>
                                      </Box>
                                    </Stack>
                                    <Box sx={{p:1.5}}>
                                        <Stack direction={'row'} gap={1}>
                                          <Box>
                                              <Typography variant="body2"><Icon sx={{fontSize:'12px'}} color="success"><GoDotFill /></Icon> {data?.bookingStartTime}</Typography>
                                              <Typography variant="body2"><Icon sx={{fontSize:'12px'}} color="error"><GoDotFill /></Icon> {data?.bookingStartTime}</Typography>
                                          </Box>
                                          <Box>
                                            <Chip size="small" label={data?.statusMsg} color={data?.statusMsg === 'Booked' ? "success" : 'error'}/>
                                          </Box>
                                        </Stack>
                                        
                                    </Box>
                                   
                                </Stack>
                            </Box>
                        </ListItemButton>
                    </ListItem>
                  )
                })}
                </List>
                </Box>
            </Card> 
        </Grid>
        <Grid item lg={6}>
            <Box>
              {loadRightData}
            </Box>
        </Grid>
    </Grid>
    {/* <Grid container spacing={1}>
    <Grid item xs={8}>
  <Box sx={{p: 0, gap: 0,ml:13, maxWidth:690, maxHeight:660,backgroundColor:'#90caf9', overflow:'scroll'}}>
  {bookingList.map((data) => (
    <ListItem>
  <Paper
    sx={{
      p: 2, 
      margin: "auto",
      maxWidth: 650,
      flexGrow: 1,
      marginBottom:-3,
      backgroundColor: (theme) =>
        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    }}
    onClick={()=>{viewDatafn(data)}}
  >
    <Grid container spacing={1}>
      <Grid item >
        <ButtonBase sx={{ width: 128, height: 128 }}>
          <Img alt="complex" src={data.image} />
        </ButtonBase>
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle1">
              {data.stationName}
            </Typography>
            <Typography variant="body2" gutterBottom color="text.secondary">
              StartDate: {data.bookingStartTime}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              EndDate: {data.bookingEndTime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ cursor: "pointer" }} variant="body2">
              Booked_Date
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2" gutterBottom >Booking code</Typography>
          <Typography gutterBottom>{data.bookingCode}</Typography>
          <Chip label={data.statusMsg==="Booked"?"confirmed":""} color="success" sx={{marginTop:"60px"}}/>
          </Grid>
        </Grid> 
    </Grid>
  </Paper>
  </ListItem>
  ))}
  </Box>
  </Grid>
  <Grid item xs={4}>
    {/* <TicketConfirmation/> 
    <div> {loadRightData}</div>
  </Grid>
   </Grid> */}
  </Box>
  );
}
