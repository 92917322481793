import { AppBar, Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography, useTheme } from '@mui/material'
import React from 'react'
import { HeaderComp } from '../style/StyledComponets'
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from 'react-router';

function Header() {
    let theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
    const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("expjwToken");
    window.localStorage.removeItem("jwToken");
    window.localStorage.removeItem("rshjwToken");
    navigate('/login');
  }


  return (
    <AppBar sx={{width:'calc(100% - 180px)'}}>
        <Toolbar>
            <HeaderComp>
            <Stack direction={'row'} sx={{width:'100%'}} alignItems={'center'}>
                <Box sx={{flex:1}}>
                    {/* <Typography sx={{fontWeight:'500', color:theme.palette.text.primary}}>Booking</Typography> */}
                </Box>
                <Box>
                    <Stack direction={'row'} alignItems={'center'}>
                        <ListItem dense disableGutters disablePadding sx={{margin:'0px'}}
                            secondaryAction={
                                <IconButton size='small'>
                                        <MdKeyboardArrowDown />
                                </IconButton>
                            }
                        >
                            <ListItemButton disableGutters sx={{paddingRight:'26px !important'}} disableTouchRipple disableRipple 
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            >
                                <ListItemAvatar  sx={{minWidth:'45px'}}>
                                    <Avatar sizes='small' sx={{width:'35px', height:'35px', border:`2px solid ${theme.palette.primary.main}`}} src='https://cdn3d.iconscout.com/3d/premium/thumb/man-avatar-6299539-5187871.png?f=webp'/>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={<Typography variant='body2' sx={{fontWeight:'500', color:theme.palette.text.primary}}>EV Users</Typography>}
                                />
                            </ListItemButton>
                        </ListItem>
                    </Stack>
                </Box>
            </Stack>
            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
            </HeaderComp>
        </Toolbar>
    </AppBar>
  )
}

export default Header