import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { Grid, Paper } from "@mui/material";
import CommonDropDown from "../common/commonDropdown";
import LogServices from "../../services/logServices";

const RawFeed = () => {
  const [responseData, setResponseData] = useState([]);
  const [swapId, setSwapId] = useState("");
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    var request = {};
    request.strStartDate = "2022-11-07 00:00:00";
    request.strEndDate = "2022-11-07 23:59:59";
    request.search = "";
    request.orderdir = "desc";
    request.swapId = swapId;
    request.start = newPage;
    request.length = rowsPerPage;

    const responseData = (await LogServices.getRawFeedLog(request)).data;
    setResponseData(responseData.data.content);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  }, [swapId]);

  const columns = useMemo(
    () => [
      {
        Header: "Device Capture Date",
        accessor: "gpsDate",
      },
      {
        Header: "Gps Feed",
        accessor: "gpsFeed",
      },
      {
        Header: "Portal Received Date",
        accessor: "createdDate",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const handleSearch = (searchText) => {
    console.log({ searchText });
  };

  const handleSubmit = (formData) => {
    console.log({ formData });
    setSwapId(formData.swapId);
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};
    request.strStartDate = "2022-11-07 00:00:00";
    request.strEndDate = "2022-11-07 23:59:59";
    request.search = "";
    request.orderdir = "desc";
    request.swapId = swapId;
    request.start = newPage;
    request.length = rowsPerPage;

    const responseData = (await LogServices.getRawFeedLog(request)).data;
    setResponseData([]);
    setResponseData(responseData.data.content);
    setTotalElements(5);
    setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
        <CommonDropDown
          onSubmit={handleSubmit}
          isShowStation={true}
          isShowType={false}
          shouldIncludeAllOption={false}
        />
      </Paper>
      <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
        <DataTable
          includeSearchBox={false}
          onSearch={handleSearch}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={callbackChangePage}
          totalElements={1}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};

export default RawFeed;
