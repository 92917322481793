import React, { useState, useMemo } from "react";
import {Box,FormControl,Select,MenuItem,ListSubheader,TextField,InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FormLabel } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles'

const allOptions = ["Select","nesh.live","naveenroy.babu@nesh.live"];
  
const containsText = (text, searchText) =>
text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const SearchableDropDown = ({label}) => {

    const labelStyle = {
      fontSize: "12px !important",
      color: 'black',
      fontWeight: 600
    }
    const asterisk = createTheme({
      components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
    }
    })

  const [selectedOption, setSelectedOption] = useState(allOptions[0]);
  
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => allOptions.filter((option) => containsText(option, searchText)),
    [searchText]
  );

  return (
    <Box sx={{ m: 10 }}>
      <FormControl sx={{  width:'300px'}}>
      <ThemeProvider theme={asterisk}>
      <FormLabel sx={labelStyle} required>{label}</FormLabel>
      </ThemeProvider>
         <Select
          MenuProps={{ autoFocus: false }}
          id="search-select"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          onClose={() => setSearchText("")}
          renderValue={() => selectedOption}
         >
           
            <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SearchableDropDown