import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import UpcomingSwap from "./UpcomingSwap";
import CompletedSwap from "./CompletedSwap";
import { MaincontentArea } from "../style/StyledComponets";
const BookingTab = () => {
  const [value, setValue] = useState(1);
  
  const handleTabChange = (event, newValue) => {
    if(newValue !== null){
      setValue(newValue);
    }
  }

  return (
    <MaincontentArea sx={{height:'100%'}}>
        <Box sx={{height:'100%'}}>
            <ToggleButtonGroup size="small" value={value} onChange={handleTabChange} exclusive color="primary">
                <ToggleButton value={1}>Upcoming</ToggleButton>
                <ToggleButton value={2}>Completed</ToggleButton>
            </ToggleButtonGroup>

            { value === 1 && <UpcomingSwap/> }
            { value === 2 && <CompletedSwap/> }
        </Box>
    </MaincontentArea>
  );
};

export default BookingTab;

