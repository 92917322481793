import { Box } from '@mui/material'
import React, { useState } from 'react'
import StyledTabs from '../shared/StyledTabs'
import CreateVehicle from './CreateVehicle'
import ListVehicle from './ListVehicle'
import RecentActivities from './RecentActivities'
import { Breadcrumb } from '../shared/Breadcrumbs'

const Vehicles = () => {

    const [vehToEdit, setVehToEdit] = useState(null)
    const [tabValue, setTabValue] = useState(2)

    const handleTabChange = (event, newValue) => {
        setVehToEdit(null)
        setTabValue(newValue);
    };   

    const handleVehEdit = (vehEditedId) => {
        setTabValue(1)
        setVehToEdit(vehEditedId)
    }

    const handleCancel = () => {
        setTabValue(2)
      }
    
      
    const TABS = [
        {
            label: 'Recent Activities',
            children: <RecentActivities />
        },
        {
            label: 'Create Vehicle',
            children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel}/>
        },
        {
            label: 'Vehicles',
            children: <ListVehicle onEditVeh={handleVehEdit} />
        }

    ]

    return (
        <Box sx={{ padding: '0px 15px' }}>
            <Breadcrumb mainDiv='Admin' subDiv='Vehicles'></Breadcrumb>
            <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
        </Box>
    )
}

export default Vehicles