import React, { useState, useEffect } from "react";
import dropdownServices from "../../services/dropdownServices";
import customerService from "../../services/customerService";
import { Paper, Button,FormLabel,Box } from "@mui/material";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { height, padding } from "@mui/system";
const CommonDropDown = ({
  onSubmit,
  isShowStation,
  isShowType,
  shouldIncludeAllOption,
}) => {
  const theme = useTheme();
console.log(",isShowType",isShowType)
  const dropdownStyle = {
    boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap", flexDirection: "row",columnGap:"10px" 
  };

  const ButtonAlign = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#374850",
    color: "white",
    fontSize: "12px",
    marginRight: "5px",
    padding: "1px 12px",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  }));

  const [selectedResellerId, setSelectedResellerId] = useState(null);
  const [selectedDealerId, setSelectedDealerId] = useState(null);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedSwapId, setSelectedSwapId] = useState(null);
  const [selectedStationId, setSelectedStationId] = useState(null);

  const [resellers, setResellers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [swapStation, setSwapStation] = useState([]);
  const [stationType, setStationType] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));

  // const shouldIncludeAllOption = true; //TODO: add logic
  const [globalRegionId, setGlobalRegionId] = useState("1");

  const getResellerId = (listOfResellers) => {
    return listOfResellers[0].key;
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };

  useEffect(async () => {
    const resellersListPayload = {
      value: "reseller",
      searchText: "",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
    };
    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
    const resellerId = resellersList[0].key;
    setSelectedResellerId(resellerId);

    const regionListPayload = {
      value: "region",
    };
    const { data: regionResponseBody } = await dropdownServices.getRegions(
      regionListPayload
    );
    const _regions = getOptions(regionResponseBody);
    setRegions(_regions);
    setSelectedRegionId(_regions[0].key);

    const dealerListPayload = {
      value: "dealer",
      resellerId,
      dealerId: 0,
      searchText: "",
      regionId: _regions[0].key,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    const _dealers = getOptions(dealerList);
    setDealers(_dealers);
    setSelectedDealerId(_dealers[0]?.key);

    const swapStationListPayload = {
      dealerId: _dealers[0]?.key,
      regionId: _regions[0].key,
    };
    const { data: stationListResponse } = await dropdownServices.getSwapStation(
      swapStationListPayload
    );
    const _stationListResponse = getOptions(stationListResponse);

    setSwapStation(_stationListResponse);
    setSelectedSwapId(_stationListResponse[0]?.key);

    const { data: stationTypeListResponse } =
      await dropdownServices.getStationType({});
    const _stationTypeListResponse = getOptions(stationTypeListResponse);
    setStationType(_stationTypeListResponse);
    setSelectedStationId(_stationTypeListResponse[0].key);
  }, []);

  const handleRegionSelect = async (regionId) => {
    setGlobalRegionId(regionId);
    const dealerListPayload = {
      value: "dealer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText: "",
      regionId: regionId,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;

    const _dealers = getOptions(dealerList);
    setDealers(_dealers);

    setSelectedRegionId(regionId);
    setSelectedDealerId(_dealers[0].key);

    const swapStationListPayload = {
      dealerId: selectedDealerId,
    };
    const { data: stationListResponse } = await dropdownServices.getSwapStation(
      swapStationListPayload
    );
    const _swapStation = getOptions(stationListResponse);
    setSwapStation(_swapStation);
    setSelectedSwapId(_swapStation[0]?.key);
  };

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    height:"20px",
    width:"100px",
    padding:"20px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  const handleDealerSelect = async (dealerId) => {
    const swapStationListPayload = {
      dealerId: dealerId,
      regionId: globalRegionId,
    };
    const { data: stationListResponse } = await dropdownServices.getSwapStation(
      swapStationListPayload
    );
    const _swapStation = getOptions(stationListResponse);
    setSwapStation(_swapStation);
    setSelectedDealerId(dealerId);
    setSelectedSwapId(_swapStation[0]?.key);
  };

  const handleSwapStationSelect = async (swapId) => {
    setSelectedSwapId(swapId);
  };
  const handleStationSelect = async (stationId) => {
    setSelectedStationId(stationId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      resellerId: selectedResellerId,
      regionId: selectedRegionId,
      dealerId: selectedDealerId,
      swapId: selectedSwapId,
      stationId: selectedStationId,
    });
  };

  const handleRegionSearch = async (searchText) => {
    console.log(searchText);
  };

  return (
    // onSubmit={handleSubmit}
    <form>
      <Paper elevation={6} sx={dropdownStyle}>
      <DropDown
          // label="Organization"
          options={resellers}
          optionsDisplayKey="value"
          isShow={false}
          fieldName={"orgId"}
        />
      <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
      <FormControl sx={{ m: 1, width: 160 }} size="small">
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Region</FormLabel>
            <DropDown
          options={regions}
          onChange={handleRegionSelect}
          optionsDisplayKey="value"
          onSearch={handleRegionSearch}
          fieldName={"regionId"}
        />
        </FormControl>
        </Box>
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ m: 1, width: 160 }} size="small">
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Dealer</FormLabel>
            <DropDown
         sx={{padding:"10px"}}
          options={dealers}
          optionsDisplayKey="value"
          onChange={handleDealerSelect}
          fieldName={"dealerId"}
        />
        </FormControl>
        </Box>
        {isShowStation && (
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ m: 1, width: 160 }} size="small">
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Station</FormLabel>
            <DropDown
          options={swapStation}
          optionsDisplayKey="value"
          fieldName={"swapId"}
          isShow={isShowStation}
          onChange={handleSwapStationSelect}
        />
        </FormControl>
        </Box>
          )}

        {isShowType && (
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
            <FormControl sx={{ m: 1, width: 160 }} size="small">
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Station Type</FormLabel>
            <DropDown
              options={stationType}
              optionsDisplayKey="value"
              isShow={isShowType}
              fieldName={"stationId"}
              onChange={handleStationSelect}
            />
            </FormControl>
          </Box>
        )}


<Box sx={{ display: 'flex', justifyContent: 'center', marginTop:"10px",flexDirection:'column-reverse' }}> {/* Centering the button */}
          <ButtonAlign
            sx={buttonStyle}
            type="button"
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </ButtonAlign>
        </Box>
      </Paper>
    </form>
  );
};

export default CommonDropDown;
