import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import ImageUpload from "./ImageUpload";
import { Breadcrumb } from "../shared/Breadcrumbs";
import BasicTable from "./TimeTable";

const Settings = ({ swapToEdit, handleSetngsCancel }) => {
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCancel = () => {
    handleSetngsCancel();
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="settings tabs" sx={{margin:"20px 0px"}}>
        <Tab label="Images" />
        <Tab label="TimeTable" />
      </Tabs>
      {tabValue === 0 && (
        <ImageUpload swapToEdit={swapToEdit} onCancel={handleCancel} />
      )}
      {tabValue === 1 && (
        <BasicTable swapToEdit={swapToEdit} onCancel={handleCancel} />
      )}
    </Box>
  );
};

export default Settings;
