import Service from "./service";

const treeviewService = {
  getGridViewForTreeview: function (resource,param) {
    return Service.post('/vehicle/mapViewCommontreeDataByLimit',null,param)
  },
  getOrgGridViewForTreeview: function (resource,param) {
    return Service.post('/organization/listOrganizationTreeViewWithPagination',resource,param)
  },
  getVehicleGridViewTreeview: function (resource,param) {
    return Service.post('/organization/listOrganizationTreeViewByVehicleId',resource,param)
  },
  saveTreeviewDetails: function (data) {
    return Service.post('/vehicle/createDevice',data,null)
  },
  deleteTreeviewById: (deviceId) => {
    return Service.post('/vehicle/deleteDevice',deviceId, null)
  },
  editOrgById: (deviceId) =>{
    return Service.get('/vehicle/getDevice',{deviceId})
  },
  updateTreeviewDetails: function (data) {
    return Service.post('/vehicle/updateDevice',data,null)
  },
};

export default treeviewService;