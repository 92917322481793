import Service from "./service";

const reportServices = {
  getOverSpeed: function (resource) {
    return Service.get('/overspeed', (status, data) => {
      if (status === 200) {
        return data;
      }
    })
  }
};

export default reportServices;