import Service from "./service";

const userService = {
  chkUserName: function (data) {
    return Service.post("​/user​/chkUserName", data);
  },
  chkUserEmail: function (data) {
    return Service.post("/user/chkUserEmail", data);
  },
  updatePhoneNo: function (data) {
    return Service.post("/user/phoneNoUpdate", data, null);
  },
  updateFullName: function (data) {
    return Service.post("/user/fullNameUpdate", data, null);
  },
  updateEmail: function (data) {
    return Service.post("/user/emailUpdate", data, null);
  },
  EmailVerificationCode: function (data) {
    return Service.post("/user/EmailVerificationCode", data);
  },
  MobileVerificationCode: function (data) {
    return Service.post("/user/MobileVerificationCode", data);
  },
  ChangeUserPassword: function (data) {
    return Service.post("/user/ChangePassword", data, null);
  },
  editUserById: (id) => {
    return Service.post("/user/getUser", { id });
  },
};

export default userService;
