import React, { useState,useRef } from "react";
import SwapBlackIcon from '../../assets/images/swapImage.png';
import { Marker, InfoWindow, Polyline, GoogleMap, LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAP_KEY } from './../../constants/appConstant';
import 'rsuite/dist/rsuite.min.css';

const refDisplay={display:"none"};
const MapTree = ({objData}) => {
  console.log("Maps::");
  const containerStyle = {
    height: "55vh",
    width: "100%"
  };
  const defaultProps = {
    center: { lat: objData.lat, lng: objData.lng },
    zoom: 5
  };
  const [placeVals, setPlaceVals] =useState([]);  
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState('')
  const [fromDate,setFromDate] =useState("");
  const [toDate,setToDate] =useState("");
  const [imageIconShow,setImageIconShow] =useState(false);

  const refFromDate=useRef("");
  const refToDate=useRef("");
  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };
  const handleToggleOpen = (markerId) => {  
    setOpenInfoWindowMarkerId("markerId");  
    setOpenInfoWindowMarkerId(markerId);    
  };
  
  return (    
      <LoadScript
        googleMapsApiKey={GOOGLE_MAP_KEY}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
        >
           { [objData].length>0 &&
            [objData].map((location, i) => (
              <Marker
                key={location.swapstationId}
                position={location}
                isMarkerShown={true}
                icon={SwapBlackIcon}  //MarkerImage
                onClick={() => handleToggleOpen(location.swapstationId)}
              >
             {openInfoWindowMarkerId === location.swapstationId && (
                  <InfoWindow >
                  <span sx={{backgroundColor:"brown"}}><p>SwapStationId:{location.swapstationId}</p>
                  <p>SwapStation Name:{location.swapstationName}</p>
                  </span>  
                  </InfoWindow>
                )}
              </Marker>
            ))
          }        
        </GoogleMap>
      </LoadScript>   
  )
}

export default MapTree