import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  FormControl,
  Paper,
  FormLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/styles";
//import DropDown from '../shared/DropDown';
import SelectWithSearch from "../shared/SelectWithSearch";
import customerService from "../../services/customerService";
import roleSettingService from "../../services/roleSetttingService";
//import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
import DraggableDialog from "../shared/DailogDrag";
import dropdownServices from "../../services/dropdownServices";
import { styled } from "@mui/material/styles";

const ResellerId = 0;
const ListRoleSettings = ({ onEditFeature }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);
  const [resellers, setResellers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selLastOrgId, setSelLastOrgId] = useState("-1");

  const ButtonAlign = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#374850",
    color: "white",
    fontSize: "12px",
    marginRight: "5px",
    padding: "1px 12px",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  }));

  const userData = JSON.parse(localStorage.getItem("userData"));
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;

  const ORG_TYPE_OPTIONS = [
    {
      id: 2,
      value: "Organization",
      key: "2",
    },
    {
      id: 5,
      value: "Dealer",
      key: "5",
    },
  ];
  const [selectedOrgType, setSelectedOrgType] = React.useState(
    ORG_TYPE_OPTIONS[0].key
  );
  const [roleOptions, setRoleOptions] = React.useState([]);

  useEffect(async () => {
    const resellersListPayload = {
      value: "reseller",
      searchText: "",
      orgType: selectedOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
    };
    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
    const resellerId = resellersList[0].key;
    //setSelectedRoleId(resellerId);
    setSelLastOrgId(resellerId);

    /*
    const dealerListPayload = {
      value: "dealer",
      resellerId,
      dealerId: 0,
      searchText: "",
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    setDealers(dealerList);
    setSelectedOrgId(dealerList[0]?.key);
    */
    switch (selectedOrgType) {
      case "2":
        setRoleOptions([
          { value: "Organization Admin", key: 6 },
          { value: "Organization Supervisor", key: 7 },
          { value: "Organization Executive", key: 8 },
        ]);
        break;
      case "5":
        setRoleOptions([
          { value: "Dealer Admin", key: 20 },
          { value: "Dealer Supervisor", key: 21 },
          { value: "Dealer Executive", key: 22 },
          { value: "Operator", key: 23 },
        ]);
        break;
    }
    setSelectedRoleId(selectedOrgType === "2" ? 6 : 20);

    await fetchRoleSttngsDetails(resellerId, selectedOrgType === "2" ? 6 : 20);
  }, []);

  /*
  React.useEffect(() => {
    switch (selectedOrgType) {
      case "2":
        setRoleOptions([
          { value: "Organization Admin", key: 6 },
          { value: "Organization Supervisor", key: 7 },
          { value: "Organization Executive", key: 8 },
        ]);
        break;
      case "5":
        setRoleOptions([
          { value: "Dealer Admin", key: 20 },
          { value: "Dealer Supervisor", key: 21 },
          { value: "Dealer Executive", key: 22 },
          { value: "Operator", key: 23 },
        ]);
        break;
    }
    setSelectedRoleId(roleOptions[0]?.key);
  }, [selectedOrgType]);
  */

  const columns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'buttonCheckBox'
      // }, {
      {
        Header: "Org Type",
        accessor: "orgType",
      },
      {
        Header: "OrgName",
        accessor: "orgName",
      },
      {
        Header: "Role Name",
        accessor: "roleName",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const options = [
    {
      id: 1,
      value: "test1",
      title: "test1",
    },
    {
      id: 2,
      value: "test2",
      title: "test2",
    },
    {
      id: 3,
      value: "test3",
      title: "test3",
    },
  ];

  const selectSettings = {
    includeSearchBox: true,
    handleSearchTextChange: async (newText) => {
      const customerList = await customerService.getSubResellerList({
        ResellerId,
      });
    },
    includeAllOption: true,
    searchBoxStyles: {},
  };

  const handleSelectChange = async (id) => {
    const { id: ResellerId } = options.find((option) => option.id === id);
    const customerList = await customerService.getSubResellerList({
      ResellerId,
    });
  };

  const onEdit = (itemEdited) => {
    onEditFeature(itemEdited.orgid, itemEdited.roleid);
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  const onCheckbox = (itemEdited) => {};

  const fetchRoleSttngsDetails = async (orgId, roleId) => {
    var request = {};
    //request.resellerId=10;

    request.orgId = orgId;
    //request.page=0;
    //request.size=10;
    request.roleId = roleId;
    //request.searchText="";
    try {
      const responseData = (
        await roleSettingService.getGridViewForFeature(request)
      ).data;
      setResponseData(responseData.dataResponse);
    } catch (error) {}
  };

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(true);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContentConfirm(obj);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await roleSettingService.deleteDeviceById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
  };

  const theme = useTheme();
  const dropdownStyle = {
    boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
  };

  const handleOrgTypeSelect = async (val) => {
    setSelectedOrgType(val);
    if (val === "2") {
      const resellersListPayload = {
        value: "reseller",
        searchText: "",
        orgType: selectedOrgType,
        roleId: userData.roleId,
        id: userData.resellerId,
      };
      const { data: responseBody } = await dropdownServices.getResellers(
        resellersListPayload
      );
      const { content: resellersList } = responseBody.data;
      setResellers(resellersList);
      setSelLastOrgId(resellersList[0].key);
    } else {
      const resellersListPayload = {
        value: "reseller",
        searchText: "",
        orgType: selectedOrgType,
        roleId: userData.roleId,
        id: userData.resellerId,
      };
      const { data: responseBody } = await dropdownServices.getResellers(
        resellersListPayload
      );
      const { content: resellersList } = responseBody.data;
      setResellers(resellersList);
      const resellerId = resellersList[0].key;

      const dealerListPayload = {
        value: "dealer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealers(dealerList);
      setSelectedOrgId(dealerList[0]?.key);
      setSelLastOrgId(dealerList[0]?.key);
    }

    switch (val) {
      case "2":
        setRoleOptions([
          { value: "Organization Admin", key: 6 },
          { value: "Organization Supervisor", key: 7 },
          { value: "Organization Executive", key: 8 },
        ]);
        break;
      case "5":
        setRoleOptions([
          { value: "Dealer Admin", key: 20 },
          { value: "Dealer Supervisor", key: 21 },
          { value: "Dealer Executive", key: 22 },
          { value: "Operator", key: 23 },
        ]);
        break;
    }
    setSelectedRoleId(val === "2" ? 6 : 20);
    //await fetchRoleSttngsDetails(resellerId, roleOptions[0]?.key);

    // if (selectedOrgType === "5") {
    //   setSelectedOrgId(orgId);
    // } else {
    //   setSelectedOrgId(globalOrgId);
    // }
  };
  const handleDealerSelect = async (e) => {
    setSelLastOrgId(e);
  };
  const handleRoleSelect = async (roleId) => {
    setSelectedRoleId(roleId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("SelectedRoleId" + selectedRoleId);
    var request = {};
    //request.resellerId=10;
    request.orgId = selLastOrgId;
    //request.page=0;
    //request.size=10;
    request.roleId = selectedRoleId;
    //request.searchText="";
    try {
      const responseData = (
        await roleSettingService.getGridViewForFeature(request)
      ).data;
      setResponseData(responseData.dataResponse);
    } catch (error) {}
  };
  return (
    <>
      <Paper elevation={5} sx={dropdownStyle}>
        {/* <SelectWithSearch 
          handleChange={handleSelectChange}
          label={'Reseller'}
          options={options}
          settings={selectSettings}
       /> */}
        <Dropdown
          autoFetchOptions={false}
          label={"Organization Type"}
          options={ORG_TYPE_OPTIONS}
          fieldName={"userOrgType"}
          // value={selectedOrgType}
          onChange={handleOrgTypeSelect}
        />
        {selectedOrgType === "2" && (
          <Dropdown
            autoFetchOptions={true}
            label={"Organization"}
            fieldName={"resellerId"}
            optionsKeyIdentifier={resellers}
          />
        )}
        {selectedOrgType === "5" && (
          <Dropdown
            autoFetchOptions={true}
            label={"Dealer"}
            fieldName={"dealerId"}
            optionsKeyIdentifier={dealers}
            onChange={handleDealerSelect}
          />
        )}

        <Dropdown
          autoFetchOptions={true}
          label={"Roles"}
          fieldName={"roleId"}
          //options={roleOptions}
          onChange={handleRoleSelect}
          optionsKeyIdentifier={roleOptions}
        />
        <ButtonAlign
          sx={{ m: 4 }}
          type="button"
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </ButtonAlign>
      </Paper>
      <Box display="flex" flexDirection="column">
        {responseData.length > 0 && (
          <DataTable
            onEdit={onEdit}
            onDelete={onDelete}
            onCheckbox={onCheckbox}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            totalElements={1}
          />
        )}
      </Box>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </>
  );
};
const Dropdown = ({
  autoFetchOptions = true,
  options: _options = [],
  label,
  optionsKeyIdentifier,
  onChange,
  fieldName,
  statefulOptions,
}) => {
  const [options, setOptions] = React.useState(_options);
  const [value, setValue] = React.useState(options[0]?.key || 0);
  //const [defaultValue, setDefaultValue] = React.useState( options[0]?.key);
  const theme = useTheme();
  const handleChange = (e) => {
    //setDefaultValue(e.target.value);
    setValue(e.target.value);
    if (onChange && typeof onChange === "function") onChange(e.target.value);
  };

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };

  React.useEffect(() => {
    if (statefulOptions) {
      setValue(optionsKeyIdentifier.length > 0 ? statefulOptions[0]?.key : "");
    }
  }, [statefulOptions]);

  React.useEffect(() => {
    //TODO: get options from api using optionsKeyIdentifier

    if (autoFetchOptions && optionsKeyIdentifier) {
      setOptions(optionsKeyIdentifier);
      setTimeout(() => {
        setValue(
          optionsKeyIdentifier.length > 0 ? optionsKeyIdentifier[0].key : ""
        );
      }, 100);
    }
  }, [optionsKeyIdentifier]);
  return (
    <FormControl sx={{ m: 1 }} size="small">
      <FormLabel sx={labelStyle}>{label}</FormLabel>
      <Select
        label={label}
        onChange={handleChange}
        name={fieldName}
        value={value}
        sx={{ width: "200px" }}
      >
        {(statefulOptions || options).map((option) => {
          return (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ListRoleSettings;
