import React, { useState,useEffect,useCallback,useRef } from "react";
import MainTreeview from './mainTreeview.js';
import SwapTreeMap from './swapTreeMap.js';
//import treeviewService from '../../services/treeviewService';
import swaptreeviewService from '../../services/swaptreeviewService';
import RecentActivity from './RecentActivity';

import 'rsuite/dist/rsuite.min.css';


const SwapTreeView = () => {  
    const [selLatitude,setSelLatitude]=useState(12.9830);
    const [selLongitude,setSelLongitude]=useState(80.2517);
    const [path,setSelPath]=useState(false);
    const [swaptreeMapView,setSwaptreeMapView]=useState();
    const [marginTopDiv,setMarginTopDiv]=useState("0.1%");
    const editSwapView=()=>{
    }

    const expandFleetDataShowfn = useCallback((requestFrom) => { 
    }, []);
    var globalOrgId=1;
    var globaluserId=1;
    var globalRoleId=0;
    var globalJson={};
    var globalOrgType=2;
    if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
    } else{
      globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
    }
    globalOrgId=globalJson.orgId;
    globalOrgType=globalJson.userOrgType;
    globaluserId=globalJson.id;
    globalRoleId=globalJson.roleId;
    //globalRoleId=24;
    const selectval=(path,selLatitude,selLongitude,objData,clickedType)=>{
        //console.log("objData.regionId::"+objData.regionId+"globalJson.orgId"+globalJson.orgId);
        if(clickedType==="swapstation"){
          //commonAry={commonAry}
          return <RecentActivity  data={objData} />;
        }else if(clickedType==="slot"){
          //commonAry={commonAry}
          return <RecentActivity  data={objData} />;
        }else{
          return <SwapTreeMap path={path} lat={selLatitude} lng={selLongitude} editSwapView={editSwapView} objData={objData} clickedType={clickedType} />;
        }
     }
    useEffect(() => {
        var commonAry=[];
        var clickedType="region";
        setSwaptreeMapView(selectval(path,selLatitude,selLongitude,commonAry,clickedType));
      }, [])

      const onClickSlotfn = useCallback((checkval,val,swapstationId) => {   
        var chkd=checkval.checked;
        var objData={};
        objData.swapstationId=swapstationId;
        objData.selSlotId=val;        
        var commonAry={};
        var clickedType="slot";
        setMarginTopDiv("0.9%");
        objData.resellerId=globalOrgId;        
        swaptreeviewService.getSwapSlotList(objData, null)
        .then(res => {
          commonAry = res.data.data;
          var arayObj=[];
          for(var i=0;i<commonAry.slots.length;i++){            
            if(commonAry.slots[i].slotId===val){
              arayObj.push(commonAry.slots[i]);
              break;
            }
          }
          //commonAry.slots=[];          
          commonAry.selSlotId=val;     
          //commonAry.slots.push(arayObj[0]);
          //commonAry.slots.push(arayObj);
          commonAry.slotsSel=[];
          commonAry.slotsSel.push(JSON.parse(JSON.stringify(arayObj.length>0?arayObj[0]:{})));
          commonAry.clickedType="slot";  
          
          setSwaptreeMapView("");
          setSwaptreeMapView(selectval(path,selLatitude,selLongitude,commonAry,clickedType));
          return res;
          })
        .catch(err => {
          });            
      return null;
    }, []);
    
    const callbackRgnfn = useCallback((regionId) => {     
      //prop.callbackRgnfn(regionId);   
        var objData={};
        objData.regionId=regionId;
        var clickedType="region";
        var commonAry=[];
        if(objData.regionId!==undefined){
          objData.resellerId=globalOrgId;        
          swaptreeviewService.getSwapStationList(objData, null)
        .then(res => {
          commonAry = res.data.data;
          setSwaptreeMapView(selectval(path,selLatitude,selLongitude,commonAry,clickedType));
          return res;
           })
        .catch(err => {
          });      
        }
        return null;
    }, []);

    const callbackDlrfn = useCallback((request) => {  
        var objData={};
        var clickedType="dealer";
        var commonAry=[];
        if(request!==undefined){
          if(globalRoleId===23){
            objData.operatorId=request;
          }else{
            objData.dealerId=request;
          }
          objData.resellerId=globalOrgId;        
          swaptreeviewService.getSwapStationList(objData, null)
        .then(res => {
          commonAry = res.data.data;
          setSwaptreeMapView(selectval(path,selLatitude,selLongitude,commonAry,clickedType));
          return res;
           })
        .catch(err => {
          });      
        }
        //prop.callbackDlrfn(request);     
        //console.log("dealerId:"+request);  
        return null;
    }, []);
    
    const callbackSwpStnfn = useCallback((request) => {     
      //prop.callbackDlrfn(request);     
      //console.log("callbackSwpStnfn:"+request);  
      var objData={};
      if(request!==undefined){
        // if(globalRoleId===23){
        //   objData.operatorId=request;
        // }else{
        //   objData.dealerId=request;
        // }
        var commonAry={};
        var clickedType="swapstation";
        setMarginTopDiv("0.9%");
        objData.swapstationId=request;
        objData.resellerId=globalOrgId;        
        swaptreeviewService.getSwapSlotList(objData, null)
        .then(res => {
          commonAry = res.data.data;
          setSwaptreeMapView("");
          setSwaptreeMapView(selectval(path,selLatitude,selLongitude,commonAry,clickedType));
          return res;
          })
        .catch(err => {
          });      
      }
      return null;
  }, []);

  return (
    <>
    {/*
    <Box >
    <Grid container sx={{marginTop:"-0.6%",marginBottom:"0.4%"}}> 
        <Grid item xs={3} >
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={3} >                               
        </Grid>
        <Grid item xs={3} style={{ marginTop: '1%',fontSize:"98%" }}>                                   
            <DateRangePicker format="dd-MM-yyyy" defaultValue={[new Date(), new Date()]}
                style={{ width: 250 }} disabledDate={afterToday()}
                placeholder="Select Date Range"
                onChange={(e)=>{setFromDate(format(e[0], 'yyyy/MM/dd hh:mm:ss'));clickVal(e)}}
                />                                     
        </Grid>
        <Grid item xs={1} >
        <span ref={refFromDate}  style={refDisplay} >{fromDate}</span><span ref={refToDate} style={refDisplay}>{toDate}</span>                                     
        </Grid>
        
    </Grid>
</Box>
  */}
{(globalRoleId!==24) &&
 <div>
 <MainTreeview onClickSlotfn={onClickSlotfn} expandFleetDataShowfn={expandFleetDataShowfn} callbackRgnfn={callbackRgnfn} callbackDlrfn={callbackDlrfn} callbackSwpStnfn={callbackSwpStnfn} />
 <div>
{swaptreeMapView} 
</div>

</div>

 
  // <Box >
  //   <Grid container sx={{marginTop:"1.5%",marginBottom:"1%"}}> 
  //        {/* <Grid item xs={3} sx={{marginTop:marginTopDiv}}>
  //       </Grid>  */}
  //       <Grid item xs={12}>
         
  //       </Grid>
  //       </Grid>
  //   </Box>
}
    
    </>
  )
}
export default SwapTreeView