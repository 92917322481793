import React, { useState,useRef,useEffect} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "rsuite/esm/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginSchema } from "../../Utils/ValidationSchemas";
import { useDispatch } from "react-redux";
import { AUTH_LOGIN } from "../../constants/actionType";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import loginService from "../../services/loginService.js";
import { saveToken } from "../../Utils";
import service from "../../services/service";
import ButtonAppBar from "./LoginHeader";
import LockIcon from "@mui/icons-material/Lock";
import Avatar from "@mui/material/Avatar";
import Pic from "../../assets/images/Swapp-imgFull4.png";
import { positions } from "@mui/system";
import { Divider } from "rsuite";
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormLabel,
} from "@mui/material";
// import { loadCaptchaEnginge, LoadCanvasTemplate , validateCaptcha} from "react-simple-captcha";
import Captcha from "captcha-image";
import RefreshIcon from '@mui/icons-material/Refresh';
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright  | All rights reserved."}
    </Typography>
  );
}
const initialState = {
  userName: "",
  password: "",
  captcha: ""
};
const initialErrorState = {
  userName: "",
  password: "",
  captcha: ""
};


export default function SignIn() {
  const theme = useTheme();
  const [logIn, setLogIn] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const valueRef = useRef(null);
  const [elementData,setElementData] =useState();
  const [error, setErrorState] = useState(initialErrorState);

  const validateForm = () => {
    let prevcaptcha = document.images.item(1).getAttribute("data-key");
      var obj={};
      if(logIn.userName === ''){
        
        obj.userName="UserName Required";
        setErrorState(obj);
      
      }else{
        obj.userName='';
          setErrorState(obj);

      }
      if(logIn.password === ''){
          
        obj.password="Password Required";
        setErrorState(obj);
      
      }else{
        obj.password='';
          setErrorState(obj);

      }
      if(logIn.captcha === '' || logIn.captcha !== prevcaptcha){
        obj.captcha="Invalid Captcha";
        setErrorState(obj);
      
      }else{
        obj.captcha='';
          setErrorState(obj);

      }
    
    
  };

 
  const handleSave = (e) => {
    let prevcaptcha = document.images.item(1).getAttribute("data-key");
    e.preventDefault();
    validateForm();
    
   
    const payload = {
      username: logIn.userName,
      password: logIn.password,
      usertype: logIn.usertype,
    };
    if((logIn.userName !== '') && (logIn.password !== '') && (logIn.captcha !== '') && (logIn.captcha === prevcaptcha)){
    service
      .post("/authenticate", payload)
      .then((response) => {
        var userInfo = response.data;
        saveToken(userInfo.accessToken, userInfo.refreshToken);
        loginService
          .loginUserData(logIn)
          .then((res) => {
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            if (null !== localStorage.getItem("userData")) {
                navigate("/");
            }
            return res;
          })
          .catch((err) => {
            localStorage.removeItem("userData");
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
      dispatch({ type: AUTH_LOGIN, payload: logIn });
    }
    //loginSchema.validate(logIn);
   
   
  };
  
  function createMarkup(source) {

    return { __html: source };
  }
  
  function MyCaptcha() {
    const captchaImage = new Captcha(
      "20px Courier",
      "center",
      "middle",
      200,
      50,
      "#7a868636",
      "black",
      4
    ).createImage();
    
    return <div dangerouslySetInnerHTML={createMarkup(captchaImage)} />;
  }
  useEffect( () => {
    setElementData(returnData(<MyCaptcha/>));
},[]);
  const returnData=()=>{
    return <MyCaptcha/>;
  }
  const reloadcaptcha= ()=>{
    setElementData(returnData(<MyCaptcha/>));
  };
  const handleChange = (e) => {
  
    setLogIn({
      ...logIn,
      [e.target.name]: e.target.value,
    });
  };
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        background: "#fff",
        height: "100vh",
        // backgroundImage: url({ Pic }),
      }}
    >
      <ButtonAppBar />

      <Container
        sx={{
          background: "#fff",
          maxWidth: "100vw !important",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid white",
            boxShadow: theme.palette.shadow.secondary,
            height: "auto",
            background: "#fff",
            width: "auto",
            padding: "25px 50px",
            marginTop: "-140px",
            zIndex: "5",
            borderRadius: "15px",
          }}
        >
          <Avatar sx={{ backgroundColor: "#61CE70" }}>
            <LockIcon sx={{ color: "#fff" }} />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mt: 1 }}>
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSave}
            sx={{ mt: 1, width: 350, mb: 3 }}
          >
            <Box sx={{margin: "10px 0px"}}>
            <FormLabel sx={{fontSize:"16px",fontWeight:"600"}}>UserName</FormLabel>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              id="userName"
              type="text"
              value={logIn.userName}
              onChange={handleChange}
              name="userName"
              autoComplete="userName"
            
            />
             <Typography sx={{color:"red"}}>
             <p>{ error.userName ? error.userName:''}</p>
             </Typography>
             </Box>
             <Box sx={{margin: "10px 0px"}}>
             <FormLabel sx={{fontSize:"16px",fontWeight:"600"}}>Password</FormLabel>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              name="password"
              value={logIn.password}
              onChange={handleChange}
              type="password"
              id="password"
              autoComplete="current-password"
              // {...register('password')}
              // error={errors.password ? true : false}
            />
            
            <Typography sx={{color:"red"}}>
             <p>{ error.password ? error.password:''}</p>
             </Typography>
             </Box>
            {/* <FormControlLabel
              control={
              <MyCaptcha/>
            } label={          
              <Typography>
               <RefreshIcon  onClick={reloadcaptcha}/> 
            </Typography>
               
              }
            /> */}
                        
         <Box sx={{margin: "10px 0px"}}> 
            <FormLabel sx={{fontSize:"16px",fontWeight:"600"}}>Enter the Captcha</FormLabel>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              id="captcha"
              type="text"
              value={logIn.captcha}
              onChange={handleChange}
              name="captcha"
              // {...register('captcha')}
              // error={errors.captcha ? true : false}
            />
            <Typography sx={{color:"red"}}>
             <p>{ error.captcha ? error.captcha:''}</p>
             </Typography>
             </Box>  

             <Grid  container spacing={2} >
              <Grid item xs={10} id="setid">
                {elementData} 
              </Grid>
            
              <Grid item xs={2}>
                <RefreshIcon sx={{mt:1}}  onClick={reloadcaptcha}/>
              </Grid>
              
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox size="small" value="remember" color="primary" />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary" sx={{fontWeight:"600"}}>
                      Remember me
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    ml: 4,
                    background: "#61CE70",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "#61CE70",
                    },
                  }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body1" sx={{fontWeight:"600"}}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {/* <Box
        sx={{
          position: "fixed",
          bottom: "0px",
          left: "50%",
          margin: "auto",
          zIndex: "1",
          transform: "translate(-50%, 0%)",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={Pic}
          style={{
            height: "auto",
            width: "60%",
          }}
        /> */}
      {/* <Divider
          sx={{
            background: "red",
            height: "3px",
            zIndex: "3",
          }}
        /> */}
      {/* </Box> */}
      {/* <Copyright sx={{ ml: 104, mt: 5 }} /> */}
      {/* <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "50%",
              paddingLeft: "15px",
            }}
          >
            
          </Box>
        </Grid> */}
    </Box>
  );
}
