import Service from "./service";

const dropdownServices = {
  getResellers: function (payload) {
    return Service.post(
      "/organization/organizationDropdownWithPagination",
      payload,
      null
    );
  },

  getFleetList: (payload) => {
    return Service.post("/vehicle/vehiclePaginationDropdown", payload);
  },

  getRegions: function (payload) {
    return Service.post("/swapstation/getAllRegion", payload, null);
  },

  getSwapStation: function (payload) {
    return Service.post("/simulator/getSwapStationName", payload, null);
  },
  getStationType: function (payload) {
    return Service.post("/swapstation/getStationType", payload, null);
  },
  getDealerList: function (payload) {
    return Service.post("/swapstation/getAllDealer", payload, null);
  },
  getOperatorList: function (payload) {
    return Service.post("/swapstation/getOperatorList", payload, null);
  },
};

export default dropdownServices;
