import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography,FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ORGANIZATION_DETAIL,
  DOMAIN_DETAIL,
  ORGANIZATION_ADMIN_DETAIL,
  TODO,
} from "./../../constants/orgFormFields";
import TextInput from "../shared/TextInput";
import RadioInput from "../shared/RadioInput";
import NumberInput from "../shared/NumberInput";
import SelectInput from "../shared/SelectInput";
import OrganizationServices from "../../services/organizationServices";
import { PageHeader } from "../shared/PageHeader";
import DraggableDialog from "../shared/DailogDrag";
import AlertDialogSlide from "../shared/ValidationDialog";
import { VALID_EMAIL, VALID_PHONE } from "../shared/FormValidation";
// const RESTRICTED_ID = DOMAIN_DETAIL[0].options[1].value;
const RESTRICTED_ID = 1;

export default function CreateOrganizationForm({ orgToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [org, setOrg] = useState(null);
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [assignedobj, setAssignedobj] = useState({});
  const [statusEdit, setStatusEdit] = useState(false);

  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;

  useEffect(async () => {
    if (orgToEdit) {
      setStatusEdit(true);
      const responseData = (await OrganizationServices.editOrgById(orgToEdit))
        .data.data;
      setOrg(responseData);
      setAssignedobj(responseData);
    } else {
      setOrg(null);
    }
  }, [setOrg]);

  const theme = useTheme();

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "35px",width:"350px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: theme.fieldset.border,borderRadius:"12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary,fontSize:"16px" }}>
            {" "}
            {title}{" "}
          </legend>

          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };
  function switchCaseVal(param) {
    switch (param) {
      case "regionId":
        if (org != null) {
          if (statusEdit === true) {
            return (org["regionId"] = org["regionId"]);
          } else {
            return (org["regionId"] =
              assignedobj.regionId === undefined ? "" : assignedobj.regionId);
          }
        }
        return assignedobj.regionId === undefined ? "" : assignedobj.regionId;
      case "orgType":
        if (org != null) {
          if (statusEdit === true) {
            return (org["orgType"] = org["orgType"]);
          } else {
            return (org["orgType"] =
              assignedobj.orgType === undefined ? "" : assignedobj.orgType);
          }
        }
        return assignedobj.orgType === undefined ? "" : assignedobj.orgType;
      case "name":
        if (org != null) {
          if (statusEdit === true) {
            return (org["name"] = org["name"]);
          } else {
            return (org["name"] =
              assignedobj.name === undefined ? "" : assignedobj.name);
          }
        }
        return assignedobj.name === undefined ? "" : assignedobj.name;
      case "address":
        if (org != null) {
          if (statusEdit === true) {
            return (org["address"] = org["address"]);
          } else {
            return (org["address"] =
              assignedobj.address === undefined ? "" : assignedobj.address);
          }
        }
        return assignedobj.address === undefined ? "" : assignedobj.address;
      case "countryId":
        if (org != null) {
          if (statusEdit === true) {
            return (org["countryId"] = org["countryId"]);
          } else {
            return (org["countryId"] =
              assignedobj.countryId === undefined ? "" : assignedobj.countryId);
          }
        }
        return assignedobj.countryId === undefined ? "" : assignedobj.countryId;
      case "stateId":
        if (org != null) {
          if (statusEdit === true) {
            return (org["stateId"] = org["stateId"]);
          } else {
            return (org["stateId"] =
              assignedobj.stateId === undefined ? "" : assignedobj.stateId);
          }
        }
        return assignedobj.stateId === undefined ? "" : assignedobj.stateId;
      case "cityId":
        if (org != null) {
          if (statusEdit === true) {
            return (org["cityId"] = org["cityId"]);
          } else {
            return (org["cityId"] =
              assignedobj.cityId === undefined ? "" : assignedobj.cityId);
          }
        }
        return assignedobj.cityId === undefined ? "" : assignedobj.cityId;
      case "zipCode":
        if (org != null) {
          if (statusEdit === true) {
            return (org["zipCode"] = org["zipCode"]);
          } else {
            return (org["zipCode"] =
              assignedobj.zipCode === undefined ? "" : assignedobj.zipCode);
          }
        }
        return assignedobj.zipCode === undefined ? "" : assignedobj.zipCode;
      case "firstName":
        if (org != null) {
          if (statusEdit === true) {
            return (org["firstName"] = org["firstName"]);
          } else {
            return (org["firstName"] =
              assignedobj.firstName === undefined ? "" : assignedobj.firstName);
          }
        }
        return assignedobj.firstName === undefined ? "" : assignedobj.firstName;
      case "lastName":
        if (org != null) {
          if (statusEdit === true) {
            return (org["lastName"] = org["lastName"]);
          } else {
            return (org["lastName"] =
              assignedobj.lastName === undefined ? "" : assignedobj.lastName);
          }
        }
        return assignedobj.lastName === undefined ? "" : assignedobj.lastName;
      case "userName":
        if (org != null) {
          if (statusEdit === true) {
            return (org["userName"] = org["userName"]);
          } else {
            return (org["userName"] =
              assignedobj.userName === undefined ? "" : assignedobj.userName);
          }
        }
        return assignedobj.userName === undefined ? "" : assignedobj.userName;
      case "email":
        if (org != null) {
          if (statusEdit === true) {
            return (org["email"] = org["email"]);
          } else {
            return (org["email"] =
              assignedobj.email === undefined ? "" : assignedobj.email);
          }
        }
        return assignedobj.email === undefined ? "" : assignedobj.email;
      case "mobile":
        if (org != null) {
          if (statusEdit === true) {
            return (org["mobile"] = org["mobile"]);
          } else {
            return (org["mobile"] =
              assignedobj.mobile === undefined ? "" : assignedobj.mobile);
          }
        }
        return assignedobj.mobile === undefined ? "" : assignedobj.mobile;
      case "mobileCountryCode":
        if (org != null) {
          if (statusEdit === true) {
            return (org["mobileCountryCode"] = org["mobileCountryCode"]);
          } else {
            return (org["mobileCountryCode"] =
              assignedobj.mobileCountryCode === undefined
                ? ""
                : assignedobj.mobileCountryCode);
          }
        }
        return assignedobj.mobileCountryCode === undefined
          ? ""
          : assignedobj.mobileCountryCode;
      default:
        if (org != null) {
          return org[param];
        } else {
          return "";
        }
    }
  }

  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    var obj = { ...assignedobj };

    setStatusEdit(false);
    if (name === "regionId") {
      obj.regionId = val;
    } else if (name === "orgType") {
      obj.orgType = val;
    } else if (name === "name") {
      obj.name = val;
    } else if (name === "address") {
      obj.address = val;
    } else if (name === "countryId") {
      obj.countryId = val;
    } else if (name === "stateId") {
      obj.stateId = val;
    } else if (name === "cityId") {
      obj.cityId = val;
    } else if (name === "zipCode") {
      obj.zipCode = val;
    } else if (name === "firstName") {
      obj.firstName = val;
    } else if (name === "lastName") {
      obj.lastName = val;
    } else if (name === "userName") {
      obj.userName = val;
    } else if (name === "email") {
      obj.email = val;
    } else if (name === "mobile") {
      obj.mobile = val;
    } else if (name === "mobileCountryCode") {
      obj.mobileCountryCode = val;
    }

    setAssignedobj(obj);
  };

  const handleSubmit = (e) => {
    if (!orgToEdit) {
      e.preventDefault();
      var obj = {};
      const { target } = e;
      obj = Object.fromEntries(new FormData(target));
      if (obj.regionId === "-1") {
        setTextData("Please Select Region");
        setError(true);
      } else if (obj.orgType === "-1") {
        setTextData("Please Select OrganizationType");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.email)) {
        setTextData("Please Enter Valid Email");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.userName)) {
        setTextData("Please Enter Valid UserName");
        setError(true);
      } else if (obj.name === "") {
        setTextData("Please Enter OrganizationName");
        setError(true);
      } else if (obj.address === "") {
        setTextData("Please Enter Address");
        setError(true);
      } else if (obj.countryId === "-1") {
        setTextData("Please Select Country");
        setError(true);
      } else if (obj.stateId === "-1") {
        setTextData("Please Select State");
        setError(true);
      } else if (obj.cityId === "-1") {
        setTextData("Please Select City");
        setError(true);
      } else if (obj.zipCode === "") {
        setTextData("Please Enter PinCode");
        setError(true);
      } else if (obj.userName === "") {
        setTextData("Please Enter UserName");
        setError(true);
      } else if (obj.firstName === "") {
        setTextData("Please Enter FirstName");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.email === "") {
        setTextData("Please Enter Email");
        setError(true);
      } else if (obj.mobileCountryCode === "-1") {
        setTextData("Please Select CountryCode");
        setError(true);
      } else if (obj.mobile === "-1") {
        setTextData("Please Enter MobileNumber");
        setError(true);
      } else {
        saveOrgDetails(e, Object.fromEntries(new FormData(target)));
      }
    } else {
      e.preventDefault();
      var obj = {};
      const { target } = e;
      obj = Object.fromEntries(new FormData(target));
      if (obj.regionId === "-1") {
        setTextData("Please Select Region");
        setError(true);
      } else if (obj.orgType === "-1") {
        setTextData("Please Select OrganizationType");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.email)) {
        setTextData("Please Enter Valid Email");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.userName)) {
        setTextData("Please Enter Valid UserName");
        setError(true);
      } else if (obj.name === "") {
        setTextData("Please Enter OrganizationName");
        setError(true);
      } else if (obj.address === "") {
        setTextData("Please Enter Address");
        setError(true);
      } else if (obj.countryId === "-1") {
        setTextData("Please Select Country");
        setError(true);
      } else if (obj.stateId === "-1") {
        setTextData("Please Select State");
        setError(true);
      } else if (obj.cityId === "-1") {
        setTextData("Please Select City");
        setError(true);
      } else if (obj.zipCode === "") {
        setTextData("Please Enter PinCode");
        setError(true);
      } else if (obj.userName === "") {
        setTextData("Please Enter UserName");
        setError(true);
      } else if (obj.firstName === "") {
        setTextData("Please Enter FirstName");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.email === "") {
        setTextData("Please Enter Email");
        setError(true);
      } else if (obj.mobileCountryCode === "-1") {
        setTextData("Please Select CountryCode");
        setError(true);
      } else if (obj.mobile === "-1") {
        setTextData("Please Enter MobileNumber");
        setError(true);
      } else {
        updateOrgDetails(e, Object.fromEntries(new FormData(target)));
      }
    }
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const saveOrgDetails = async (e, obj) => {
    obj.parentOrgId = globalOrgId;
    obj.createdBy = globalOrgId;
    const responseData = await OrganizationServices.saveOrgDetails(obj);

    var obj1 = {};

    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateOrgDetails = async (e, obj) => {
    e.preventDefault();
    obj.parentOrgId = globalOrgId;
    const responseData = await OrganizationServices.updateOrgDetails(obj);

    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const InputWrapper = ({ children }) => {
    return (
      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
        {children}
      </Grid>
    );
  };

  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
              >
                <FormLabel sx={{ fontSize: "16px", fontWeight: "600",textAlign: "center" }}>{field.label}</FormLabel>
                <TextInput
                  //defaultValue={org !== null ? org[field.name] : ""}
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
              </Grid>
              
            );
          } else if (field.type === "number") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" ,padding:"10px"}}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center"}}>{field.label}</FormLabel>
               <NumberInput
                  //defaultValue={org !== null ? org[field.name] : ""}
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
            </Grid>
          
            );
          } else if (field.type === "select") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <SelectInput
                  name={field.name}
                  label={field.label}
                  options={field.options}
                  // defaultValue={
                  //   org !== null ? org[field.name] : field.options[0].value
                  // }
                  defaultValue={
                    org !== null
                      ? switchCaseVal(field.name) == ""
                        ? org[field.name]
                        : switchCaseVal(field.name)
                      : switchCaseVal(field.name) === ""
                      ? field.options !== null
                        ? field.options[0].value
                        : 0
                      : switchCaseVal(field.name)
                  }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                />
            </Grid>
              
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}
              >
               </Grid>
            );
          }
        })}
      </>
    );
  };

  // const DomainDetailForm = () => {
  //   const [hideDomainEmail, setHideDomainEmail] = useState(false);

  //   const handleRadioClick = (event) => {
  //     if (event.target.value == RESTRICTED_ID) {
  //       setHideDomainEmail(true);
  //     } else {
  //       setHideDomainEmail(false);
  //     }
  //   };

  //   return (
  //     <Grid container>
  //       <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
  //         <RadioInput
  //           handleRadioClick={handleRadioClick}
  //           name={DOMAIN_DETAIL[0].name}
  //           options={DOMAIN_DETAIL[0].options}
  //           defaultVal={org !== null ? org[DOMAIN_DETAIL[0].name] : "1"}
  //         />
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleCountrySelect = (e) => {
    const selectedCountry = e.target.value;
    console.log({ selectedCountry });
    // make api call to get state list and set to a state
  };

  const handleStateSelect = (e) => {
    const selectedState = e.target.value;
    console.log({ selectedState });
    // make api call to get city list and set to a state
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setError(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={org}>
        <FormLayout title={"Organization Detail"}>
          <FormFields fields={ORGANIZATION_DETAIL} />
        </FormLayout>
        {/* <FormLayout title={"Domain Detail"}>
          <DomainDetailForm />
        </FormLayout> */}
        <FormLayout title={"Dealer Admin Detail"}>
          <FormFields fields={ORGANIZATION_ADMIN_DETAIL} />
        </FormLayout>

        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input type="hidden" name="id" value={org != null ? org.id : ""} />

          <input type="hidden" name="kycCustomerId" value={0} />

          <Button
            variant="contained"
            sx={buttonStyle}
            type="submit"
            // onClick={handleCancelClick}
          >
            {orgToEdit ? "Update" : "Save"}
          </Button>
          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Grid>
      </form>
      {
        <AlertDialogSlide
          open1={error}
          text={textData}
          handleClear={handleClear}
        />
      }
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
