import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Grid } from "@mui/material";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16
    },
    "&:before": {
      content: "'on'",
      color: "white",
      left: 12
    },
    "&:after": {
      content: "'off'",
      color: "white",
      right: 12
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2
  }
}));

export default function SimpleAccordion() {

  return (
    <Box sx={{width:'600px', marginLeft:'300px'}}>
      <Typography sx={{marginTop:'10px', fontSize:'10px'}}>List of Alerts</Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>} 
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "#14a37f" }}
        >
          <Typography sx={{color:"white", fontSize:'small'}}>APPLICATION ALERT</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid >
         <Grid  
         container
          direction="row"
          justifyContent="space-between"
             alignItems="center"
            >

            <Typography sx={{fontSize:'small'}}>Speed Alert  
            <FormControlLabel control={<Android12Switch sx={{marginLeft:'150px'}}/>} />                 
            </Typography>
            </Grid>
          
              <Typography sx={{fontSize:'small'}}>
                Idle Alert
                <FormControlLabel control={<Android12Switch sx={{marginLeft:'167px'}}/>} />
              </Typography>
              <Typography sx={{fontSize:'small'}}>
                Idle Ac Alert
                <FormControlLabel
                  control={<Android12Switch defaultChecked sx={{marginLeft:'150px'}}/>}
                />
              </Typography>
              <Typography sx={{fontSize:'small'}}>
                Geo Zone Alert
                <FormControlLabel
                  control={<Android12Switch defaultChecked sx={{marginLeft:'130px'}}/>}
                />
              </Typography>
              <Typography sx={{fontSize:'small'}}>
                Harsh Cornering Alert
                <FormControlLabel
                  control={<Android12Switch defaultChecked sx={{marginLeft:'90px'}}/>}
                />
              </Typography>
              </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ backgroundColor: "#14a37f" }}
        >
          <Typography sx={{color:"white", fontSize:'small'}}>DEVICE ALERT</Typography>
        </AccordionSummary>
        <AccordionDetails>
              <Typography sx={{fontSize:'small'}}>
                Ignition On Alert
                <FormControlLabel
                  control={<Android12Switch defaultChecked sx={{marginLeft:'120px'}}/>}
                />
              </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ backgroundColor: "#14a37f" }}
        >
          <Typography sx={{color:"white", fontSize:'small'}}>SYSTEM ALERT</Typography>
        </AccordionSummary>
        <AccordionDetails>
              <Typography sx={{fontSize:'small'}}>
                Main Power Removal Alert
                <FormControlLabel
                  control={<Android12Switch defaultChecked sx={{marginLeft:'60px'}}/>}
                />
              </Typography>
        </AccordionDetails>
      </Accordion>
      </Box>
  );
}
