import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {Grid, Button} from '@mui/material';
import { useTheme } from '@mui/styles';
import { ORGANISATION_DETAIL, VEHICLE_DETAIL, LICENSE_KEY_DETAIL } from '../../constants/vehicleFormFields.js';
import TextInput from '../shared/TextInput';
import RadioInput from '../shared/RadioInput';
import SelectInput from '../shared/SelectInput';
import DatePicker from '../shared/DatePicker.js';
import SearchableDropDown from './searchableDropDown.js';
import vehicleServices from '../../services/vehicleServices';


export default function CreateVehicleForm({vehToEdit,onCancel}) { 

  const [dataStatus,setDataStatus]=useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false)  
  const [dialogContent, setDialogContent] = useState({})  
   const [open,setOpen] =useState(false);
  const [vehicle, setVehicle] = useState([])
  useEffect( async ()=>{
    if(vehToEdit){
        console.log("editVeh::"+vehToEdit);
        const responseData = (await vehicleServices.getVehicle(vehToEdit)).data.data
        setVehicle(responseData)
        console.log(responseData)
    }
  }, [setVehicle])
  const theme = useTheme()
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: 'none',
    width:"100px",
    color: '#fff',
    m:1
  }

  const FormLayout = ({ children, title }) => {
    return (
      <Box sx={{ marginY: '40px', marginX: '20px', '& .MuiOutlinedInput-root':{height:'25px'}, 
      '& .MuiFormControlLabel-label':{fontSize: theme.text.fontSize},
      '& .MuiSvgIcon-root':{ height:'15px', width:'15px'} }}>
        <fieldset style={{ border: '1px solid black' }}>
          <legend style={{fontWeight: 600}}> {title} </legend>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    )
  }


  const handleSubmit = (e) => {
    if(!vehToEdit){
      
      e.preventDefault();    
      const { target } = e;
      createVehicle(e,Object.fromEntries(new FormData(target)))
    }else{
      console.log("print");
      e.preventDefault();
      const { target } = e;
      updateVehicle(e,Object.fromEntries(new FormData(target)))
    }
  }

  const handleCancelClick = () => {    
    onCancel()
  }
  const createVehicle = async (e,obj) => {
    console.log("save submit");
    const responseData=  (await vehicleServices.createVehicle(obj))
    var obj1={};
    if(responseData.data===1){
      obj1.dialogButtonText="Saved Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";
      obj1.dialogText="Data Saved Successfully";     
    }else{
      obj1.dialogButtonText="Saved Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";  
      obj1.dialogText="Data Not Saved Properly";
    }
    setDataStatus(true);   
    setDialogContent(obj1);
    setDataStatusConfirm(false);   
    setOpen(true);  
    if(responseData.status === 200){
      setTimeout(
        () => onCancel(), 
         2000
       );   
    }   
  }

  const updateVehicle = async (e,obj) => {
    e.preventDefault();
    
    const responseData=(await vehicleServices.updateVehicle(obj))

    var obj1={};  
      obj1.dialogButtonText="Update Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";
      obj1.dialogText=responseData.data.resultText;   
      setDataStatus(true);     
      setDialogContent(obj1);
      setDataStatusConfirm(false);   
      setOpen(true);       
      if(responseData.status === 200){
        setTimeout(
          () => onCancel(),   
           2000
         );   
      }
  }

  const FormFields = ({ fields }) => {
    return (<>
      {
        fields.map((field, index) => {
          if (field.type === 'text') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput label={field.label} name={field.name} defaultValue={vehicle!==null?vehicle[field.name]:''} />
              </Grid>
            )
          } else if (field.type === 'select') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <SelectInput name={field.name} label={field.label} options={field.options} defaultValue={vehicle!==null?(vehicle[field.name]):(field.options[0].value)}  />
              </Grid>
            )
          } else if (field.type === 'radio') {
            return(
            <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <RadioInput name={field.name} options={field.options} />
              </Grid>
            )
          } else if (field.type === 'date'){
            return(
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <DatePicker name={field.name} label={field.label} />
                </Grid>
              )
            } 
        })
      }
    </>
    )
  }

  return (
    
    <form onSubmit={handleSubmit} defaultValue={vehicle}>
      <FormLayout title={'Organization Details'}>
      <FormFields fields={ORGANISATION_DETAIL} />
      </FormLayout>
      <FormLayout title={'Vehicle Details'}>
        <FormFields fields={VEHICLE_DETAIL} />
      </FormLayout>
      <FormLayout title={'Device Details'}>
        <Grid  item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <SearchableDropDown label={"Device IMEI"}/> 
        </Grid>
      </FormLayout> 
      <FormLayout title={'License Key Details'}>
        <FormFields fields={LICENSE_KEY_DETAIL} />
      </FormLayout>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <input type="hidden" name="id" value={vehicle!=null?vehicle.id:""} />
      <input type="hidden" name="number" value={"string"}/>
      <input type="hidden" name="deviceId" value={0} />
      <Button variant="contained" sx={buttonStyle} type='submit'>{vehToEdit ? 'Update' : 'Submit'}</Button>
      <Button variant="contained" sx={buttonStyle} onClick={handleCancelClick}>Cancel</Button>
      </Grid>
    </form>
  )
}





