import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button, Typography} from '@mui/material';
import { useTheme } from '@mui/styles';
import { SIM_DETAIL, SIM_SECONDARY_DETAIL, DOMAIN_DETAIL } from './../../constants/simFormFields'
import TextInput from '../shared/TextInput';
import RadioInput from '../shared/RadioInput';
import SelectInput from '../shared/SelectInput';
import SimServices from '../../services/simServices';
import {PageHeader} from '../shared/PageHeader';

const RESTRICTED_ID = 0;


export default function CreateSimForm({ editSim, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false)
  const [dialogContent, setDialogContent] = useState({})
  const [open, setOpen] = useState(false);
  const [sim, setSim] = useState([])
  useEffect(async () => {
    if (editSim) {
      const responseData = (await SimServices.editSimById(editSim.id)).data.formData
      setSim(responseData)
    }
  }, [setSim])

  const theme = useTheme()

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: 'none',
    width:"100px",
    color: '#fff',
    m: 1
  }

  const FormLayout = ({ children, title }) => {
    return (
      <Box sx={{
        marginY: '40px', '& .MuiOutlinedInput-root': { height: '25px' },
        '& .MuiFormControlLabel-label': { fontSize: theme.text.fontSize },
        '& .MuiSvgIcon-root': { height: '15px', width: '15px' }
      }}>
         <fieldset style={{ border: theme.fieldset.border,borderRadius:"12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary,fontSize:"16px" }}>
            {title}
          </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    )
  }
  const handleSubmit = (e) => {
    if (!editSim) {

      e.preventDefault();
      const { target } = e;
      saveSim(e, Object.fromEntries(new FormData(target)))
    } else {
      console.log("print");
      e.preventDefault();
      const { target } = e;
      updateSim(e, Object.fromEntries(new FormData(target)))
    }
  }

  const handleCancelClick = () => {
    onCancel()
  }
  const saveSim = async (e, obj) => {
    console.log("save submit");
    const responseData = (await SimServices.saveSim(obj))
    var obj1 = {};
    if (responseData.data === 1) {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(
        () => onCancel(),
        2000
      );
    }
  }

  const updateSim = async (e, obj) => {
    e.preventDefault();

    const responseData = (await SimServices.updateSim(obj))

    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(
        () => onCancel(),
        2000
      );
    }
  }
  const InputWrapper = ({ children }) => {
    return <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
      {children}
    </Grid>
  }

  const FormFields = ({ fields }) => {
    return (<>
      {
        fields.map((field, index) => {
          if (field.type === 'text') {
            return (
              <InputWrapper key={index}>
                <TextInput defaultValue={sim[field.name] || ''} label={field.label} name={field.name} />
              </InputWrapper>
            )
          } else if (field.type === 'select') {
            return (
              <InputWrapper key={index}>
                <SelectInput name={field.name} label={field.label} options={field.options} defaultValue={(sim[field.name] === undefined ? 0 : sim[field.name]) || field.options[0].value} />
              </InputWrapper>
            )
          } else if (field.type === 'radio') {
            return (
              <InputWrapper key={index}>
                <RadioInput name={field.name} options={field.options} />
              </InputWrapper>
            )
          }
        })
      }
    </>
    )
  }
  return (
    <form onSubmit={handleSubmit} defaultValue={sim}>
      <PageHeader title='Create Sim'></PageHeader>
        <FormLayout title={'Sim Details'}>
          <Grid item xs={12} sx={{ display: 'flex', border: 'fullwidth' }}>
            <Grid item xs={12}> 
                 <FormLayout>
                 <FormFields fields={SIM_DETAIL} />
              </FormLayout>
              <FormLayout>
                <FormFields fields={SIM_SECONDARY_DETAIL} />
              </FormLayout>
              <FormLayout>
                  <FormFields fields={DOMAIN_DETAIL} />
              </FormLayout> 
             </Grid> 
          </Grid>
        </FormLayout>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <input type="hidden" name="id" value={sim != null ? sim.id : ""} />
        <Button variant="contained" sx={buttonStyle} type='submit' >{editSim ? 'Update' : 'Submit'}</Button>
        <Button variant="contained" sx={buttonStyle} onClick={handleCancelClick}>Cancel</Button>
      </Grid>
    </form>
  )
}

