import { Box, Typography } from '@mui/material'
import React,{useState} from 'react'
import StyledTabs from '../shared/StyledTabs'
import CreateFeature from './CreateFeature'
import ListFeature from './ListFeature'
import {Breadcrumb} from '../shared/Breadcrumbs'

const Feature = () => {
  const [featureToEdit, setFeatureToEdit] = React.useState(null)  
  const [tabValue, setTabValue] = useState(1)  
  const handleDevEdit  = (featureEditedId) => {
    setFeatureToEdit(featureEditedId);
    setTabValue(0) 
  }
  
  const handleTabChange = (event, newValue) => {
    setFeatureToEdit(null)
    setTabValue(newValue);    
  };

  const handleCancel = () => {
    setTabValue(1)
  };

  const TABS = [   
    {
      label: 'Create Feature',
      children: <CreateFeature featureToEdit={featureToEdit} onCancel={handleCancel}/>
    }, {
      label: 'Feature List',
      children: <ListFeature onEditFeature={handleDevEdit}  />
    }
  ]

  return (
    <Box  sx={{padding: '0px 15px'}}>
     <Breadcrumb mainDiv='Admin' subDiv='Feature'></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
    </Box>
  )
}

export default Feature