import React from 'react'
import GlobalStyles from '@mui/material/GlobalStyles';

const GlobalStyleComponent = () => {
  return (
    <GlobalStyles
      styles={(theme) => ({
        // add global css classes here
        '.nav-btn-active': {
          color: 'blue'
        }
      })}
    />
  )
}

export default GlobalStyleComponent