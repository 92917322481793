import { Box, CardMedia, Drawer, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MenuList, SidebarComp } from '../style/StyledComponets'
import brand from '../../assets/images/brand.png'
import Menus from './Menus'
import menuListService from '../../services/menuListService'
function Sidebar() {

    const [menu, setMenu] = useState(null);
    console.log("menu",menu)
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log('userData', userData);

    const menuPayload = {
        region:  {},
        page: 0,
        size: 100,
        value: "region",
        searchText: "",
        vehModel: "1",
    }

    const payload2 = {
        region: 1
    }

    const getMenus = async () =>{
        menuPayload.id = userData.id;
        menuPayload.orgId = userData.orgId;
        menuPayload.orgType = userData.userOrgType;
        menuPayload.roleId = userData.roleId;
        await menuListService.getMenuList(menuPayload, payload2) .then((res) => {
            if(res?.data !== null && res?.data !== undefined){
                setMenu(res.data.menus)
            }else{
                setMenu(null)
            }
        })
    }

    useEffect(() => {
        getMenus();
    },[])

    let theme = useTheme();

  return (
    <SidebarComp 
    variant="permanent" 
    open={true} 
    >
        <Box sx={{height:'56px', px:'15px', py:'5px', background:'#fff', borderBottom:'.5px solid rgba(0, 0, 0, 0.12)'}}>
            <CardMedia
                component={'img'}
                src={brand}
                sx={{height:'40px', width:'auto'}}
            />
        </Box>
        <Box sx={{px:'5px', py:'5px'}}>
            <MenuList sx={{p:0, m:0}}>
                  {
                      menu !== null && menu !== undefined && menu?.map((item, index) => {
                          return (
                              <Menus menuListItem={item} key={index} />
                          )
                      })
                  }

            </MenuList>
        </Box>
    </SidebarComp>
  )
}

export default Sidebar