const VALID_PHONE = new RegExp(/^[0-9\b]+$/);

const VALID_EMAIL = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
const VALID_SELECT = new RegExp("^(?!----$).*");

module.exports = {
  VALID_PHONE,
  VALID_EMAIL,
  VALID_SELECT,
};
