import { Box } from '@mui/material'
import React,{useState} from 'react'
import StyledTabs from '../shared/StyledTabs'
import CreateSim from './CreateSim'
import ListSim from './ListSim'
import {Breadcrumb} from '../shared/Breadcrumbs'

const Sim = () => {

  const [simToEdit, setsimToEdit] = React.useState(null)  
 
  
  const [tabValue, setTabValue] = useState(1)
  
  const handleTabChange = (event, newValue) => {
    setsimToEdit()
    setTabValue(newValue);
  };
  
  const handleSimEdit  = (simEditedId) => {
    setTabValue(0)
    setsimToEdit(simEditedId)
  }

  const handleCancel = () => {
    setTabValue(1)
  }

  const TABS = [
    {
      label: 'Create Sim',
      children: <CreateSim editSim={simToEdit} onCancel={handleCancel}/>
    },
    {
      label: 'Sim List',
      children: <ListSim    onEditSim={handleSimEdit} /> 
    }
   
  ]
  
  return (
    <Box  sx={{padding: '0px 15px'}}>
     <Breadcrumb mainDiv='Admin' subDiv='Sim'></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue}/>
    </Box>
  )
}

export default Sim