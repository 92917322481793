import { put, takeEvery } from "redux-saga/effects";
import { saveToken, deleteToken } from "../Utils";
import { AUTH_CLEAN, AUTH_ERRORS, AUTH_INIT, AUTH_LOGIN } from "../constants/actionType";
import service from "../services/service";


function* setAuthError(data) {
    yield put({ type: AUTH_ERRORS, data });
}

function* authLogin(params) {
    try {
        /*
        let userName = params.payload.userName;
        let password = params.payload.password;
        let usertype = params.payload.usertype;
        var userInfo={};
        */
        //const response = yield service.post('/auth/logincheck', { username, password, usertype }),
            yield put({ type: AUTH_CLEAN });
            // const resp = loginService.loginUserData(jsonData);            
            // localStorage.setItem('userData',JSON.stringify(resp.data.data)); 
           /*
        if (userInfo['#result-set-1'][0].error === 0) {
            yield put({ type: AUTH_INIT, data: userInfo['#result-set-2'][0] });
            yield setAuthError([]);
            // yield saveToken(userInfo.token);
            yield saveToken(userInfo['#result-set-2'][0]);
        } else {
            yield setAuthError(userInfo['#result-set-1'][0].error);
        }*/
    } catch (e) {
        //yield setAuthError(e.response.data.errors);
        console.error(e);
    }
}

function* authSaga() {
    yield takeEvery(AUTH_LOGIN, authLogin);
}

export default authSaga;
