import Service from "./service";

const swaptreeviewService = {
  getGridViewForTreeview: function (resource,param) {
    return Service.post('/vehicle/mapViewCommontreeDataByLimit',null,param)
  },
  getMapViewTreeCommonData: function (resource,param) {
    return Service.post('/mapView/mapViewTreeCommonData',resource,param)
  },
  getVehicleGridViewTreeview: function (resource,param) {
    return Service.post('/organization/listOrganizationTreeViewByVehicleId',resource,param)
  },
  getSwapStationList:function(resource,param){
    return Service.post('/mapView/getSwapStationList',resource,param)
  },
  getSwapSlotList:function(resource,param){
    return Service.post('/mapView/getSwapSlotList',resource,param)
  }
  
};

export default swaptreeviewService;