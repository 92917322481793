import React, { useEffect, useState,useCallback } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./treeview.css";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import Draggable from 'react-draggable';
import MainList from "./MainList.js";

const TextFieldStyle = {
  paddingLeft: 5,
  fontSize: "1.2rem",
  height: "18px",
  //marginTop: "4%",
  background: "#ffffff",
  //minHeight: '2px !important',
  //maxHeight: '2px !important',
};

const radioStyle={paddingLeft:"1px !important",color:"white"};

const addstockdata1 = [{
  "id": 9,
  "text": "Nine"
}, {
  "id": 10,
  "text": "Ten"
}, {
  "id": 11,
  "text": "Eleven"
}, {
  "id": 12,
  "text": "Twelve"
}, {
  "id": 13,
  "text": "Thirteen"
}];

const divStyle = { width: '77%',  backgroundColor: "#FFFFFF" };
const mainDivStyle = {
  pointerEvents: "auto",
  color: "#99a9b1e8"
};
export const SearchBar = ({ setSearchQuery }) => (
  <form>
    <div style={{paddingLeft: "10px",  paddingTop: "4px", paddingBottom: "0px"}}> 
    &nbsp;<TextField
      id="search-bar"      
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      inputProps={{
        style: TextFieldStyle,
        autoComplete: "off"
      }}
      //label="Search"
      variant="outlined"
      autoComplete="off"
      placeholder="Search..."
      size="small"      
      style={divStyle}
    />
    <IconButton type="button" aria-label="search">
      <SearchIcon style={{ fill: "blue" }} />
    </IconButton>
    </div>
  </form>
);
const filterData = (query, stockdata) => {
  if (!query) {
    return stockdata;
  } else {
    return stockdata.filter((d) => d.text.toLowerCase().includes(query));
  }
};

const MainTreeview = (prop) => {
  const nodeRef = React.useRef(null);
  const [expandedMain, setExpandedMain] = useState('panel1');
  const [addstockdata, setAddstockdata] = useState([]);
  const [elementList, setElementList] = useState([]);  
  var orgnztype=1;
  if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
  } else{
    orgnztype=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")).userOrgType:0;
  }
  function testfn1 (){
   //console.log("hii testfn1");
  }
  const onClickfn = useCallback((checkval,val) => {     
    prop.onClickfn(checkval,val);  
      return null;
  }, []);
  
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);  
      return null;
  }, []);


  const elementData = (scrolldiv, addstockdata) => {
    return <MainList {...{ scrolldiv: scrolldiv, addstockdata: addstockdata,testfunctn:testfn1,onClickfn:onClickfn,expandFleetDataShowfn:expandFleetDataShowfn,orgType:orgnztype  }} />;
  }
  useEffect(() => {
    setElementList(elementData(scrolldiv, addstockdata));
  }, []);

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpandedMain(newExpanded ? panel : false);
    };
  const [isActive, setActive] = useState(false);
  const [isHeadActive, setHeadActive] = useState(false);

  const [scrolldiv, setScrolldiv] = useState({
    maxHeight: "280px",
    overflow: "auto"
  });

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = (event) => {
    event.stopPropagation();
    var dataScroll = {};
    if (!isHeadActive) {
      setHeadActive(true);
      setAddstockdata(addstockdata1);
      setScrolldiv({
        maxHeight: "350px",
        overflow: "scroll"
      });
      dataScroll = {};
      dataScroll = {
        maxHeight: "350px",
        overflow: "scroll"
      };
      setElementList(elementData(dataScroll, addstockdata1));      
    }
    else {
      setHeadActive(false);
      setAddstockdata([]);
      setScrolldiv({
        maxHeight: "260px",
        overflow: "auto"
      });
      dataScroll = {};
      dataScroll = {
        maxHeight: "260px",
        overflow: "auto"        
      };
      var addstockdt1 = [];
      setElementList(elementData(dataScroll, addstockdt1));
    }
  };

  const accordionProps = {
    sx: {
      pointerEvents: "none",
      background: "#374850",
      minHeight: '35px !important', maxHeight: '30px !important'
    },
    expandIcon: (!isHeadActive ?
      <UnfoldMoreIcon onClick={toggleClass1}
        sx={{
          pointerEvents: "auto",
          color: "#99a9b1e8"
        }}
      /> : <UnfoldLessIcon onClick={toggleClass1}
        sx={{
          pointerEvents: "auto",
          color: "#99a9b1e8"
        }}
      />
    ),
  };

  return (
    <div>
      <Draggable nodeRef={nodeRef} sx={{ '&:hover': {
        bgcolor: 'success.dark', 
        color: 'success.light', 
        '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': { color: 'success.light' } 
       }}}>
        <div className="box" ref={nodeRef} >
           <Accordion expanded={expandedMain === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary {...accordionProps} style={{ paddingLeft: "0px !important", paddingRight: "0px !important", paddingTop: "1px !important" }} >
              <CloseFullscreenIcon onClick={toggleClass} style={{ display: !isActive ? 'block' : 'none' }} sx={mainDivStyle} />
              <OpenInFullIcon onClick={toggleClass} style={{ display: isActive ? 'block' : 'none' }} sx={mainDivStyle} />
            </AccordionSummary>
            <AccordionDetails>
              {elementList}
            </AccordionDetails>
          </Accordion>   
        </div>
      </Draggable>
    </div>
  )
};
export default React.memo(MainTreeview);