import * as React from 'react';
import {Grid,Paper,Typography,styled, Button, Card, Box, Stack, Divider, CardMedia, useTheme} from '@mui/material';
import { useState,useEffect } from 'react';
import BookingServices from '../../services/bookingServices';

export default function TicketConfirmation(data) {
  //const[slotConfirm,setSlotConfirm]=useState(data)
  let theme = useTheme();
  return (
      // <Grid container alignItems='center' justifyContent='center'>
      //   <Grid item>
      //       <Item sx={{}}>
      //           <Typo>Booking Confirm</Typo>
      //           <Typo sx={{fontWeight:'bold',lineHeight:3}}>{data?.data?.stationName}</Typo>
      //           <Grid>
      //               <Typo sx={{marginBottom:'20px'}}>{data?.data?.address}</Typo>
      //           </Grid>
      //           <Grid >
      //           <Grid container spacing={2}>
      //           <Grid item xs={4}>
      //           <Typo>Slot</Typo>
      //           <Typo sx={{fontWeight:'bold'}}>{data?.data?.bookedSlotId}</Typo>
      //           </Grid>
      //            <Grid item xs={4}>
      //            <Typo>Time </Typo>
      //              <Typo sx={{fontWeight:'bold'}}>10:00 AM</Typo>
      //            </Grid>
      //           <Grid item xs={4}>
      //            <Typo>Duration </Typo>
      //           <Typo sx={{fontWeight:'bold'}}>{data?.data?.duration}</Typo>
      //          </Grid>
      //         </Grid>
      //           </Grid>
      //           <Typo sx={{lineHeight:2}}>Status Confirm</Typo>
      //          <Grid container>
      //          <Item sx={{backgroundColor:'white'}}>
      //          <Typo sx={{fontSize:'small',color:'black'}}>Please scan the QR code image at charging station</Typo> 
      //          <Grid item alignContent='center' justifyContent='center'>
      //            <Img sx={{marginTop:2, width:"300px" }} height="200"  alt="QR" src={data?.data?.qrcode}/>
      //            </Grid>
      //            {/* <Grid item>               
      //              <Button sx={{backgroundColor:'#4caf50',width:'100%',color:'whitesmoke',marginTop:'100px'}}>Done</Button>
      //            </Grid> */}
      //          </Item>
      //          </Grid>
      //       </Item>
      //   </Grid>
      // </Grid>
     <Box>
        <Card sx={{width:'45%', margin:'auto', borderRadius:'25px'}} elevation={0}>
            <Box>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{background:theme.palette.success.main}}>
                  <Box sx={{px:2, py:1}}>
                      <Typography variant='body1' sx={{color:'#fff'}}>Booking Confirm</Typography>
                  </Box>
                </Stack>
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>Station Name</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.stationName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>Booking Code</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.bookingCode}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>Start Time</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.bookingStartTime}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>End Time</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.bookingEndTime}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>Duration</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.duration}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2, pb:0}}>
                          <Typography variant='body2' color={'text.disabled'}>Status</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.statusMsg}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{p:2}}>
                          <Typography variant='body2' color={'text.disabled'}>Slot No</Typography>
                          <Typography variant='body2' sx={{fontWeight:500}}>{data?.data?.bookedSlotId}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box 
                sx={{
                  pb:2,
                  position:'relative',
                  '&:before, &:after':{
                    content:"''",
                    position:'absolute',
                    top:'-10px',
                    background:'#f9f9f9',
                    width:'20px',
                    height:'20px',
                    borderRadius:'50px',
                  },
                  '&:before':{
                    left:'-10px',
                  },
                  '&:after':{
                    right:'-10px',
                  },
                  }}>
                  <Divider sx={{borderStyle:'dashed'}}/>
                </Box>
                <Box sx={{margin:'auto', display:'flex', justifyContent:'center'}}>
                    <CardMedia 
                      component={'img'}
                      src={data?.data?.qrcode}
                      sx={{width:'50%', marginTop:'-15px'}}
                    />
                </Box>
                <Box sx={{p:2}}>
                  <Typography sx={{textAlign:'center', mt:-2}} variant='body2'>
                    Please scan the QR code image at charging station
                  </Typography>
                  {/* <Divider sx={{borderStyle:'dashed'}}/> */}
                </Box>
                {/* <Box sx={{pb:2, display:'flex', justifyContent:'center', position:'relative'}}>
                    <Typography variant='h5' sx={{position:'absolute', top:'-60px', right:'35px', transform:'rotate(-35deg)', fontWeight:600, color:'green', }} color={'success'}>Booked</Typography>
                </Box> */}
            </Box>
        </Card>
     </Box>
    
  );
}


