import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { Grid, Paper } from "@mui/material";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";
import OrganizationServices from "../../services/organizationServices";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "../common/commonDropdown";

const ListOrganization = ({ onEditOrg }) => {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [open, setOpen] = useState(false);
  const [regionId, setRegionId] = useState(-1);
  const [dealerId, setDealerId] = useState(-1);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    await fetchOrgDetails();
  }, [regionId, dealerId]);

  const columns = useMemo(
    () => [
      {
        Header: "Org Type",
        accessor: "orgTypeStr",
      },
      {
        Header: "Organization Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "ZipCode",
        accessor: "zipCode",
      },
      {
        Header: "Created Date Time",
        accessor: "createdDate",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditOrg(itemEdited.id);
  };
  const handleClearCls = () => {
    setOpen(false);
  };
  var globaluserId = 1;
  var globalresellerId = "1";
  var globalorgId = "1";
  var globalJson = {};
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globaluserId = globalJson.id;
  globalresellerId = globalJson.resellerId;
  globalorgId = globalJson.orgId;

  const fetchOrgDetails = async (searchText) => {
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = regionId;
    request.dealerId = dealerId;
    request.searchText = searchText;
    const responseData = (await OrganizationServices.getGridViewForOrg(request))
      .data.data;
    setResponseData(responseData.content);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  const handleSubmit = async (formData, searchText) => {
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = regionId;
    request.dealerId = dealerId;
    request.searchText = searchText;
    const responseData = (await OrganizationServices.getGridViewForOrg(request))
      .data;
    setResponseData(responseData.data.content);

    setRegionId(formData.regionId);

    setDealerId(formData.dealerId);
  };

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(false);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContent(obj);
      setDataStatus(true);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.orgId = savedData.id;
    await OrganizationServices.deleteOrgById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
    setOpen(true);
  };

  // const OrgListDropDown = () => {
  //   return (
  //     <CommonDropDown
  //       onSubmit={handleSubmit}
  //       isShowStation={false}
  //       isShowType={false}
  //     />
  //   );
  // };

  const handleSearch = (searchText) => {
    console.log({ searchText });
    fetchOrgDetails(searchText);
  };
  const callbackChangePage = async (newPage, rowsPerPage, searchText) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    var request = {};
    request.resellerId = globalresellerId;
    request.orgId = globalorgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.regionId = regionId;
    request.dealerId = dealerId;
    request.searchText = searchText;
    const responseData = (await OrganizationServices.getGridViewForOrg(request))
      .data.data;
    setResponseData([]);
    setResponseData(responseData.content);
    setTotalElements(5);
    setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Paper sx={{ padding: "0.8em",}}>
        <CommonDropDown
          onSubmit={handleSubmit}
          isShowStation={false}
          isShowType={false}
          shouldIncludeAllOption={true}
        />
      </Paper>

      <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
        {responseData.length > 0 && (
          <DataTable
            includeSearchBox={false}
            handleChange={handleSearch}
            onEdit={onEdit}
            onDelete={onDelete}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={callbackChangePage}
            totalElements={totalElements}
            totalPages={totalPages}
          />
        )}
      </Box>

      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
};

export default ListOrganization;
