const ORGANIZATION_DETAIL = [
  {
    type: "select",
    label: "Dealer",
    name: "dealerName",
    options: [
      {
        id: 1,
        title: "Dealer A",
        value: "Dealer A",
      },
      {
        id: 1,
        title: "DealerB",
        value: "1",
      },
      {
        id: 2,
        title: "DealerC",
        value: "2",
      },
      {
        id: 3,
        title: "DealerD",
        value: "3",
      },
      {
        id: 4,
        title: "Unassign Dealer",
        value: "4",
      },
    ],
  },
  {
    type: "select",
    label: "Operator",
    name: "customerName",
    options: [
      {
        id: 1,
        title: "demooperator@gmail.com",
        value: "109927",
      },
      // {
      //   id: 2,
      //   title: "Unaasign Location",
      //   value: "Unaasign Location",
      // },
    ],
  },
  // {
  //   type: "select",
  //   label: "Group",
  //   name: "group",
  //   options: [
  //     {
  //       id: 1,
  //       title: "Unassigned Group",
  //       value: 2,
  //     },
  //     {
  //       id: 2,
  //       title: "VINassigned",
  //       value: 1,
  //     },
  //   ],
  // },
];

const SWAP_STATION_DETAIL = [
  {
    type: "select",
    label: "Station Type",
    name: "stationType",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "Swapping Station",
        value: "Swapping Station",
      },
      {
        id: 3,
        title: "Charging Station",
        value: "Charging Station",
      },
    ],
  },
  // {
  //   type: "text",
  //   label: "Part Number",
  //   name: "partNumber",
  // },
  {
    type: "slot-select",
    label: "No. of Slots",
    name: "slots",
    options: [
      {
        id: 1,
        title: "2",
        value: 2,
      },
      {
        id: 2,
        title: "4",
        value: 4,
      },
      {
        id: 3,
        title: "8",
        value: 8,
      },
      {
        id: 4,
        title: "16",
        value: 16,
      },
    ],
  },
  {
    type: "autocomplete",
    label: "Address",
    name: "address",
  },
  {
    type: "text",
    label: "Station Number",
    name: "name",
    placeholder: "STATION NUMBER",
  },
  {
    type: "select",
    label: "Dimensions" + "(Eg:100X20X20)",
    name: "dimensions",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "100X20X30",
        value: "100X20X30",
      },
      {
        id: 3,
        title: "150X20X40",
        value: "150X20X40",
      },
    ],
  },
  {
    type: "select",
    label: "Connector Type",
    name: "connectorType",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "Type 2 AC Connector",
        value: "Type 2 AC Connector",
      },
      {
        id: 3,
        title: "GB/T Connector",
        value: "GB/T Connector",
      },
    ],
  },

  {
    type: "time",
    label: "Start Time",
    name: "startTime",
    callbackfnStatus: "true",
  },
  {
    type: "time",
    label: "End Time",
    name: "endTime",
    callbackfnStatus: "true",
  },
  {
    type: "number",
    label: "Contact Number",
    name: "contactNo",
    placeholder: "CONTACTNUMBER",
  },
];
module.exports = {
  ORGANIZATION_DETAIL,
  SWAP_STATION_DETAIL,
};
