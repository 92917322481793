import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CreateSwapView from "./CreateSwapView";
import AlignItemsList from "./AlignItemsList";
import { Breadcrumb } from "../shared/Breadcrumbs";
import SwapMap from "./SwapMap";
import MyBookings from "./MyBookings";
import CustomTabs from "../../Utils/CustomTabs";
//import BookingHistory from "./BookingHistory"
//import TicketConfirmation from "./TicketConfirmation";

const SwapView = () => {
  const [swapToEdit, setSwapToEdit] = React.useState(null);

  const handleDevEdit = (devEditedId) => {
    setSwapToEdit(devEditedId);
    setTabValue(0);
  };

  const handleCancel = () => {
    setTabValue(1);
  };


  const editSwapView = (obj) => {

    setTabValue(3);
    setSwapToEdit(obj);
    setTabValue(0);
  }
  const handleTabChange = (event, newValue) => {
    setSwapToEdit(null);
    setTabValue(newValue);
  };
  
  const TABS = [
     {
      label: "Slots",
      children: (
        <CreateSwapView swapToEdit={swapToEdit}  />
      ),
    },  
    {
    label: "List View",
    children: <AlignItemsList editSwapView={editSwapView}/>,
  },
    {
      label: "Map View",
      children: (
        <SwapMap  editSwapView={editSwapView} />
      ),
    },
    
    {
      label: "My Bookings",
      children: <MyBookings />,
    }
    // ,
    // {
    //   label: "Booking History",
    //   children: <TicketConfirmation/>,
    // }
    
  ];

  const tabsList = [
      {
        id:1,
        label:'Map view',
        disabled:false,
      },
      // {
      //   id:2,
      //   label:'List View',
      //   disabled:false,
      // },
      {
        id:3,
        label:'My Booking',
        disabled:false,
      },
      {
        id:4,
        label:'Slots',
        disabled:true,
      },
  ]

  const [tabValue, setTabValue] = useState(0);
  const handleTabValue = (value) => {
    setTabValue(value);
  }

  const goListView = () => {
    setTabValue(1);
  }

  const BookingSlot = (bookingData) => {
    setTabValue(2);
  }




  return (
    <Box sx={{position:'relative', height:'90%'}}>
      {/* <Breadcrumb mainDiv="Admin" subDiv="Calendar"></Breadcrumb> */}
      <CustomTabs tabsList={tabsList} handleTabValue={handleTabValue} tabValue={tabValue}/>
      { tabValue === 0 && <SwapMap  editSwapView={editSwapView} goListView={goListView} BookingSlot={BookingSlot}/> }
      {/* {
        tabValue === 1 && <AlignItemsList editSwapView={editSwapView}/>
      } */}
      {
        tabValue === 1 && <MyBookings />
      }
      {
        tabValue === 2 && <CreateSwapView swapToEdit={swapToEdit}  />
      }
      {/* <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} /> */}
    </Box>
  );
};

export default SwapView;
