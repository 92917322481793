import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSticky } from "react-table-sticky";
import React, { useState } from "react";
import { useTable, useBlockLayout } from "react-table";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBox from "../SearchBox";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const DataTable = ({
  includeSearchBox = false,
  onSearch,
  columns,
  data,
  styles,
  numberOfStickyColumns,
  onEdit,
  onDelete,
  onCheckbox,
  onView,
  callbackChangePage,
  page = 0,
  rowsPerPage = 5,
  totalElements,
  totalPages = 1,
}) => {
  // const [order, setOrder] = useState('asc');
  // const [orderBy, setOrderBy] = useState(columns[0].accessor);
  //const [page, setPage] = useState(pageNew);
  //const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageNew);
  //const [data, setData] = useState(dataN);
  // useEffect(() => {
  //     //setData(dataN);
  //     //setTimeout(() => {setPage(pageNew)}, 200);

  //   }, []);

  // const handleRequestSort = (event, property) => {
  //     console.log(property);
  //     const isAsc = orderBy === property && order === 'asc';
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(property);
  // };

  const handleChangePage = (event, newPage) => {
    //setPage(newPage)
    page = newPage;
    callbackChangePage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(+event.target.value);
    //setPage(page);
    callbackChangePage(page, +event.target.value);
    page = page;
  };

  if (numberOfStickyColumns) {
    for (let i = 0; i < numberOfStickyColumns; i++) {
      columns[i].sticky = "left";
    }
  }

  const useStyles = makeStyles({
    table: {
      "& [data-sticky-last-left-td]": {
        boxShadow: "2px 0px 3px #ccc",
      },
      "& [data-sticky-td]": {
        position: "sticky",
      },
      overfow: "scroll",
    },
  });

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    width: "auto",
    backgroundColor: "#5c6bc0",
    boxShadow: "0px 2px 0px #374850",
    position: "sticky",
    top: 0,
    zIndex: 1,
    width: "fit-content",
    border: "1px solid darkgrey",
    fontSize:"24px"
  }));

  const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#61CE70",
    overflow: "hidden",
    textAlign: "left",
    padding: "1px",
    borderInline: "1px solid #98aeb5",
    fontWeight: "revert",
    paddingLeft: "10px",
    fontSize:"16px"
  }));

  const BodyTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.table.header.text.color,
    overflow: "hidden",
    textAlign: "left",
    borderInline: "1px solid rgba(224, 224, 224, 1)",
    lineHeight: "1.43",
    padding: "8px",
    overflowX: "auto",
    fontSize:"14px"
  }));

  const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
    "&.MuiTablePagination-toolbar": {
      minHeight: "25px ! important",
      maxHeight: "25px",
    },
  }));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSticky
    );

  const handleClick = (cell, event, e) => {
    if (event.toLowerCase() === "edit") {
      onEdit(cell.row.original);
    } else if (event.toLowerCase() === "delete") {
      onDelete(cell.row.original);
    } else if (event.toLowerCase() === "checkbox") {
      if (e.target.checked === true) {
        onCheckbox(cell.row.original);
      }
    } else if (event.toLowerCase() === "view") {
      onView(cell.row.original);
    }
  };

  const SearchBoxWrapper = () => {
    const [filterText, setFilterText] = useState("");
    const handleChange = (e) => {
      setFilterText(e.target.value);
      onSearch(e.target.value);
    };

    return (
      <SearchBox
        style={{
          alignSelf: "end",
          marginTop: "0px",
          marginBottom: "10px",
          mr: "10px",
        }}
        onFilter={handleChange}
        filterText={filterText}
      />
    );
  };

  const firstPageRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const classes = useStyles();
  return (
    <Paper
      elevation={1}
      sx={{ padding: "10px", display: "flex", flexDirection: "column" }}
    >
      {includeSearchBox && <SearchBoxWrapper />}
      <TableContainer component={Paper} sx={{ ...styles }}>
        <Table {...getTableProps()} className={classes.table}>
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderTableCell
                    {...column.getHeaderProps()}
                    sx={{ textAlign: "center" }}
                  >
                    {column.render("Header")}
                  </HeaderTableCell>
                ))}
              </TableRow>
            ))}
          </StyledTableHead>
          <TableBody
            {...getTableBodyProps()}
            style={{ position: "relative", zIndex: 0 }}
          >
            {rows.length > 0 &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Action") {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center" }}
                          >
                            {cell.value.map((v, index) => {
                              return (
                                <IconButton
                                  key={index}
                                  size="small"
                                  sx={{ height: "10px" }}
                                  onClick={(e) => handleClick(cell, v, e)}
                                >
                                  {v.toLowerCase() === "edit" && (
                                    <EditIcon style={{ fontSize: "15px" }} />
                                  )}
                                  {v.toLowerCase() === "delete" && (
                                    <DeleteIcon style={{ fontSize: "15px" }} />
                                  )}
                                  {v.toLowerCase() === "view" && (
                                    <VisibilityIcon
                                      style={{ fontSize: "15px" }}
                                    />
                                  )}
                                </IconButton>
                              );
                            })}
                          </BodyTableCell>
                        );
                      } else if (cell.column.Header === "") {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center" }}
                          >
                            {cell.value === "checkBox" && (
                              <Checkbox
                                onChange={(e) =>
                                  handleClick(cell, cell.value, e)
                                }
                                style={{ fontSize: "15px" }}
                              />
                            )}
                          </BodyTableCell>
                        );
                      } else {
                        return (
                          <BodyTableCell
                            {...cell.getCellProps()}
                            sx={{ textAlign: "center" }}
                          >
                            {cell.render("Cell")}
                          </BodyTableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <MuiTablePagination
        rowsPerPageOptions={[5, 10, 25, 100, 200]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
