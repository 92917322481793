import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import DataTable from "./DataTableNew";
import { Grid, Paper } from "@mui/material";
import customerService from "../../services/customerService";
// import CommonDropDown from "../shared/CommonDropDown";
import SelectWithSearch from "../shared/SelectWithSearch";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";
import SwapStationServices from "../../services/swapStationService";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "../common/commonDropdown";

const ListSwapStation = ({ onEditSwap, onViewSwap }) => {
  const [responseData, setResponseData] = useState([]);
  const [stationType, setStationType] = useState(-1);
  const [regionId, setRegionId] = useState(-1);
  const [dealerId, setDealerId] = useState(-1);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    await fetchSwapDetails();
  }, [regionId, dealerId, stationType]);

  const columns = useMemo(
    () => [
      {
        Header: "Select",
        accessor: "subtable",
      },
      {
        Header: "Dealer Name",
        accessor: "dealerName",
      },
      {
        Header: "Station Type",
        accessor: "stationType",
      },
      {
        Header: "Station Name",
        accessor: "name",
      },
      {
        Header: "No. of Slots",
        accessor: "slots",
      },
      {
        Header: "Dimensions",
        accessor: "dimensions",
      },

      {
        Header: "Action",
        accessor: "buttonEditView",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditSwap(itemEdited.id);
  };
  const onView = (itemEdited) => {
    //onEditSwap(itemEdited.id);
    onViewSwap(itemEdited.id);
  };

  //   const handleClearCls = () => {
  //     setOpen(false);
  //   };

  //   //const onCheckbox = (itemEdited) => {};

  const fetchSwapDetails = async () => {
    var request = {};
    request.id = 2;
    request.dealerId = dealerId;
    request.regionId = regionId;

    request.stationType = stationType;
    request.sortBy = "ID";
    request.page = newPage;
    request.size = rowsPerPage;

    const responseData = (await SwapStationServices.getGridViewForSwap(request))
      .data.data;
    setResponseData(responseData.content);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  const handleSearch = (searchText) => {
    console.log({ searchText });
  };

  const handleSubmit = async (formData) => {
    var request = {};
    request.id = 2;
    request.dealerId = dealerId;
    request.regionId = regionId;

    request.stationType = stationType;
    request.sortBy = "ID";
    request.page = 0;
    request.size = 100;
    if (dealerId !== -1 && regionId !== -1 && stationType !== -1) {
      const responseData = (
        await SwapStationServices.getGridViewForSwap(request)
      ).data;
      setResponseData(responseData.data.content);
      setStationType(formData.stationId);
      setRegionId(formData.regionId);

      setDealerId(formData.dealerId);
    }
  };
  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);

    var request = {};
    request.id = 2;
    request.dealerId = dealerId;
    request.regionId = regionId;

    request.stationType = stationType;
    request.sortBy = "ID";
    request.page = newPage;
    request.size = rowsPerPage;

    const responseData = (await SwapStationServices.getGridViewForSwap(request))
      .data.data;
    setResponseData([]);
    setResponseData(responseData.content);
    setTotalElements(5);
    setTotalPages(0);
    setTotalElements(responseData.totalElements);
    setTotalPages(responseData.totalPages);
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}>
        <CommonDropDown
          onSubmit={handleSubmit}
          isShowStation={false}
          isShowType={true}
          shouldIncludeAllOption={true}
        />
      </Paper>
      <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
        <DataTable
          includeSearchBox={false}
          onSearch={handleSearch}
          onEdit={onEdit}
          onView={onView}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={callbackChangePage}
          totalElements={totalElements}
          totalPages={totalPages}
        />
      </Box>
      {/* 
      {dataStatusConfirm !== false && dataStatus === true && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && dataStatusConfirm === false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )} */}
    </Box>
  );
};

export default ListSwapStation;
