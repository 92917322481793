  const ORGANISATION_DETAIL =[
  {
    type: 'select',
    label: 'Customer',
    name: 'customerName'
  },
  {
    type: 'select',
    label: 'Fleet',
    name: 'fleetName'
  }
]


 const VEHICLE_DETAIL = [
    {
      type: 'text',
      label: 'Vehicle Vin No',
      name: 'chassisNo'
    }, {
      type: 'text',
      label: 'Vehicle No Plate',
      name: 'vehiclePlateNumber'
    }, {
      type: 'select',
      label: 'Vehicle Model',
      name: 'model'
    }, {
      type: 'select',
      label: 'Vehicle Color',
      name: 'type'
    },  
    {
      type: 'text',
      label: 'Vehicle Name',
      name: 'parentName'
    }, 
    {
      type: 'text',
      label: 'Motor No',
      name: 'engineNo'
    },
    {
      type: 'text',
      label: 'Battery No',        
      name: 'mobileNumber1'
    },
    {
      type: 'text',
      label: 'Controller No',
      name: 'mobileNumber2'
    },
    {
      type: 'text',
      label: 'Charger No',
      name: 'dealerName'
    },
    {
      type: 'text',
      label: 'Display No',
      name: 'createdBy'
    },
    {
      type: 'text',
      label: 'Display Board No',
      name: 'licenseKeyText'
    },
    {
      type: 'date',
      label: 'Manufacture Date',
      name: 'manufacturedDate'
    },
    {
      type: 'date',
      label: 'Dispatch Date',
      name: 'deletedDate'
    },
    {
      type: 'date',
      label: 'Registration Date',
      name: 'modifiedDate'
    }

]

 const DEVICE_DETAIL =[
  {
    type: 'select',
    label: 'Device IMEI',
    name: 'imei'

  }
]

 const LICENSE_KEY_DETAIL =[
  {
    type: 'select',
    label: 'Plan',
    name: 'listofplans'
  },
  {
    type: 'date',
    label: 'Plan Activation Date ',
    name: 'tyre'
  }
]



module.exports={
  ORGANISATION_DETAIL,
  VEHICLE_DETAIL,
  DEVICE_DETAIL,
  LICENSE_KEY_DETAIL
}