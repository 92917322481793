import React, { useState,useCallback } from "react";
import MainDataAccount3 from "./MainDataAccount3";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';

const list2DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "none", background: "#91AEEC !important", color: "#ffffff" ,paddingLeft:"60px"
, fontWeight: "bold", fontSize: "14px",fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",position: "relative",marginTop:"-3px",
paddingTop:"1px",
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 52,
    top: 0,     
    borderLeft: "1px solid white",
    height: "170px",
    fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 52,
        top: 16,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
    }    
};

const MainDataAccount2 = (prop) => {
  var orgType=prop.orgType;
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");

  const onClickfn = useCallback((checkval,val) => {     
      prop.onClickfn(checkval,val);  
      return null;
  }, []);

  const expandValChk = useCallback((val) => {  
    prop.expandValChk(val);
    return null;
  }, []);

  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);     
      return null;
  }, []);


  const onloadMoreLastfn = useCallback(async (count,index,requestObj) => {  
    var request = {};
        request.resellerId = prop.selectedObject.resellerId;
        request.regionId = prop.selectedObject.regionId;
        request.dealerId = prop.selectedObject.dealerId;
        request.customerId = requestObj.customerId;
        request.accountId = 0;
        request.orgId = 0;
        request.page = 0;
        request.size = parseInt(requestObj.size)+parseInt(10);
        request.region = 0;
        request.vehModel = "2";
        request.value = "fleet";
        request.searchText = "";
        request.vehModel = "1";
        var region = {};
        region.region = 1;
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)        
        let stockdata = {};
        stockdata = await responseData.data.data;
        /*
        var request1 = {};
        request1.resellerId = prop.selectedObject.resellerId;
        request1.regionId = prop.selectedObject.regionId;
        request1.dealerId = prop.selectedObject.dealerId;
        request1.customerId = requestObj.customerId;
        request1.accountId = 0;
        request1.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
        request1.page = 0;
        request1.size = parseInt(requestObj.size)+parseInt(10);
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "vehicle";
        request1.searchText = "";
        request1.vehModel = "1";
        var region1 = {};
        region1.region = 1;
        let responseData1 = await treeviewService.getOrgGridViewForTreeview(request1, region1)
        let stockdata1 = {};
        stockdata1 = await responseData1.data.data;
        */
        setElementListServer(elementDataServer(request, stockdata.content));
        // setExpandVal(true);
        //setExpandedInsdL1((newExpanded ? panel : false));
    return null;
  }, []);


  const elementDataServer = (request, dataArray) => {     
    return <MainDataAccount3 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />;
  }
  const handleChange3 =
    (index, panel) => async (newExpanded) => {
      //console.log("Its cammmmm he");
      if (expandVal === true) {
        setExpandVal(false);
        setExpandedInsdL1(false);
        prop.expandValChk(false);
        var request = {};
        request.checked=false;
        prop.expandFleetDataShowfn(request);
      } else {
        prop.expandValChk(true);
        var request = {};
        request.resellerId = prop.selectedObject.resellerId;
        request.regionId = prop.selectedObject.regionId;
        request.dealerId = prop.selectedObject.dealerId;
        if("9"===orgType){
          request.resellerId = 0;
          request.regionId = 0;
          request.dealerId = 0;
        }       
        request.customerId = index;
        request.accountId = 0;
        request.orgId = 0;
        request.page = 0;
        request.size = 10;
        request.region = 0;
        request.vehModel = "2";
        request.value = "fleet";
        request.searchText = "";
        request.vehModel = "1";
        var region = {};
        region.region = 1;
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
        request.checked=true;
        prop.expandFleetDataShowfn(request);
        let stockdata = {};
        stockdata = await responseData.data.data;
        /*
        var request1 = {};
        request1.resellerId = prop.selectedObject.resellerId;
        request1.regionId = prop.selectedObject.regionId;
        request1.dealerId = prop.selectedObject.dealerId;
        request1.customerId = index;
        request1.accountId = 0;
        request1.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "vehicle";
        request1.searchText = "";
        request1.vehModel = "1";
        var region1 = {};
        region1.region = 1;
        let responseData1 = await treeviewService.getOrgGridViewForTreeview(request1, region1)
        let stockdata1 = {};
        stockdata1 = await responseData1.data.data;
        */
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
      }
    };
  const returnData = (checkboxList1) => {    
    return <div>
      {checkboxList1.map((d, index) => (
        <div
          className="text"
          key={d.key}
        >
          <Accordion expanded={expandedInsdL1 === "panelInside" + d.key} onChange={handleChange3(d.key, 'panelInside' + d.key)} >
            <AccordionSummary sx={list2DivStyle} >
            <span className={expandVal === true ?"listdataLine2":""}></span>
            <PersonIcon sx={{marginTop:"-1%",width:"19px",height:"25px"}}/> {d.value} ({d.count})
              <IndeterminateCheckBoxIcon
                sx={{
                  marginLeft: "auto",
                  marginTop:"0.2%",
                  pointerEvents: "auto",
                  color: "#fffbfb"
                }}
                style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "block" : 'none' }}
              />
              <AddBoxIcon sx={{
                marginLeft: "auto",
                marginTop:"0.2%",
                pointerEvents: "auto",
                color: "#fffbfb"
              }}
                style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "none" : 'block' }}
              />
            </AccordionSummary>
            <AccordionDetails>
              {elementListServer}   
            </AccordionDetails>
          </Accordion>
          <Divider sx={{borderColor: "white",borderTopWidth: "4px" }}/>
        </div>
      ))}
    </div>;
  }
  const listElementData = returnData(prop.dataArray);  
  return (
    <div>{listElementData}   
    </div>
  );
}
export default React.memo(MainDataAccount2);
