import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";

export default function MaintenanceCard() {
  const [value, setValue] = React.useState(null);
  const [endValue, setEndValue] = React.useState(null);
  const [remValue, setRemValue] = React.useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleChangeEnd = (newValue) => {
    setEndValue(newValue);
  };
  const handleChangeRem = (newValue) => {
    setRemValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack sx={{ marginTop: 3 }}>
        <FormLabel
          sx={{
            fontWeight: "Bold",
            color: "GREY",
            marginLeft: 2,
            fontSize: "12px",
          }}
        >
          Last Service Date
        </FormLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{
                width: "auto-width",
                marginRight: 20,
                marginLeft: 2,
                marginBottom: 1,
                "& .MuiInputBase-input": {
                  height: "14px",
                  fontSize: "14px",
                },
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
        <FormLabel
          sx={{
            fontWeight: "Bold",
            color: "GREY",
            marginLeft: 2,
            fontSize: "12px",
          }}
        >
          Next Service Date
        </FormLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={endValue}
          onChange={handleChangeEnd}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{
                width: "auto-width",
                marginRight: 20,
                marginLeft: 2,
                marginBottom: 1,
                "& .MuiInputBase-input": {
                  height: "14px",
                  fontSize: "14px",
                },
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
        <FormLabel
          sx={{
            fontWeight: "Bold",
            color: "GREY",
            marginLeft: 2,
            fontSize: "12px",
          }}
        >
          Remainder Date
        </FormLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={remValue}
          onChange={handleChangeRem}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{
                width: "auto-width",
                marginRight: 20,
                marginLeft: 2,
                "& .MuiInputBase-input": {
                  height: "14px",
                  fontSize: "14px",
                },
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
        <Stack
          direction="row"
          sx={{ mt: 3, display: "flex", justifyContent: "right", mr: 2 }}
        >
          <Button
            variant="contained"
            sx={{ color: "white", mb: 2 }}
            size="small"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}
