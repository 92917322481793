import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CreateOrganization from "./CreateOrganization";
import ListOrganization from "./ListOrganization";
import { Breadcrumb } from "../shared/Breadcrumbs";

const Organization = () => {
  const [orgToEdit, setOrgToEdit] = useState(null);
  const [tabValue, setTabValue] = useState(1);
  const [create, setCreate] = useState('Create Organization');

  const handleTabChange = (event, newValue) => {
    setOrgToEdit(null);
    setTabValue(newValue);
    setCreate('Create Organization');
  };

  const handleOrgEdit = (orgEditedId) => {
    setTabValue(0);
    setOrgToEdit(orgEditedId);
    setCreate('Edit Organization');
  };

  const handleCancel = () => {
    setTabValue(1);
    setCreate('Create Organization');
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Organization"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="organization tabs" sx={{margin:"20px 0px"}}>
        <Tab label={create} />
        <Tab label="Organization List" />
      </Tabs>
      {tabValue === 0 ? (
        <CreateOrganization orgToEdit={orgToEdit} onCancel={handleCancel} />
      ) : (
        <ListOrganization onEditOrg={handleOrgEdit} />
      )}
    </Box>
  );
};

export default Organization;
