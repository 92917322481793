import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { USER_DETAIL } from "../../constants/userFormFeilds";
import TextInput from "../shared/TextInput";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import PasswordInput from "../shared/PassInput";
import NumberInput from "../shared/NumberInput";
import userServices from "../../services/userServices";
import DraggableDialog from "../shared/DailogDrag";
import { FormControl, Paper, Select, MenuItem } from "@mui/material";
import dropdownServices from "../../services/dropdownServices";
import { VALID_EMAIL } from "../shared/FormValidation";
import AlertDialogSlide from "../shared/ValidationDialog";
import { textAlign } from "@mui/system";

const Dropdown = ({
  autoFetchOptions = true,
  options: _options = [],
  label,
  optionsKeyIdentifier,
  onChange,
  fieldName,
  statefulOptions,
  defaultValue,
}) => {
  const [options, setOptions] = React.useState(_options);
  const [value, setValue] = React.useState(
    defaultValue ? defaultValue : options[0]?.key || 0
  );
  const theme = useTheme();
  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange && typeof onChange === "function") onChange(e.target.value);
  };

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };

  React.useEffect(() => {
    if (statefulOptions) setValue(statefulOptions[0]?.key);
  }, [statefulOptions]);

  React.useEffect(() => {
    //TODO: get options from api using optionsKeyIdentifier

    if (autoFetchOptions && optionsKeyIdentifier) {
      setOptions(optionsKeyIdentifier);
      // setTimeout(() => {
      setValue(defaultValue ? defaultValue : optionsKeyIdentifier[0]?.key);
      // }, 100);
    }
  }, [optionsKeyIdentifier]);
  return (
    <FormControl sx={{ m: 1 }} size="small">
      <FormLabel sx={labelStyle}>{label}</FormLabel>
      <Select
        label={label}
        onChange={handleChange}
        name={fieldName}
        value={value}
        sx={{ width: "200px" }}
      >
        {(statefulOptions || options).map((option) => {
          return (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default function CreateUserForm({ userToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [resellers, setResellers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [assignedobj, setAssignedobj] = useState({});
  const [statusEdit, setStatusEdit] = useState(false);
  const [org, setOrg] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;

  const selectDropdown = {
    ".MuiOutlinedInput-input": { fontSize: 12, padding: "1.5px 8px" },
  };
  useEffect(async () => {
    if (userToEdit) {
      setStatusEdit(true);

      const responseData = (await userServices.editUserById(userToEdit)).data
        .data;
      setUser(responseData);
      setAssignedobj(responseData);
      setOrg(responseData);
      {
        ORG_TYPE_OPTIONS.map((ORG_TYPE_OPTIONS, index) => {
          if (ORG_TYPE_OPTIONS.key == responseData.userOrgType) {
            setSelectedOrgType(ORG_TYPE_OPTIONS?.key);
          }
        });
      }
    }
  }, [setUser]);

  useEffect(async () => {
    const resellersListPayload = {
      value: "reseller",
      searchText: "",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
    };
    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
    const resellerId = resellersList[0].key;

    const dealerListPayload = {
      value: "dealer",
      resellerId,
      dealerId: 0,
      searchText: "",
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    setDealers(dealerList);
  }, []);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  function switchCaseVal(param) {
    switch (param) {
      case "firstName":
        if (user !== null) {
          if (statusEdit === true) {
            return (user["firstName"] = user["firstName"]);
          } else {
            return (user["firstName"] =
              assignedobj.firstName === undefined ? "" : assignedobj.firstName);
          }
        }
        return assignedobj.firstName === undefined ? "" : assignedobj.firstName;
      case "lastName":
        if (user !== null) {
          if (statusEdit === true) {
            return (user["lastName"] = user["lastName"]);
          } else {
            return (user["lastName"] =
              assignedobj.lastName === undefined ? "" : assignedobj.lastName);
          }
        }
        return assignedobj.lastName === undefined ? "" : assignedobj.lastName;
      case "userName":
        if (user !== null) {
          if (statusEdit === true) {
            return (user["userName"] = user["userName"]);
          } else {
            return (user["userName"] =
              assignedobj.userName === undefined ? "" : assignedobj.userName);
          }
        }
        return assignedobj.userName === undefined ? "" : assignedobj.userName;
      case "email":
        if (user !== null) {
          if (statusEdit === true) {
            return (user["email"] = user["email"]);
          } else {
            return (user["email"] =
              assignedobj.email === undefined ? "" : assignedobj.email);
          }
        }
        return assignedobj.email === undefined ? "" : assignedobj.email;
      case "mobile":
        if (user != null) {
          if (statusEdit === true) {
            return (user["mobile"] = user["mobile"]);
          } else {
            return (user["mobile"] =
              assignedobj.mobile === undefined ? "" : assignedobj.mobile);
          }
        }
        return assignedobj.mobile === undefined ? "" : assignedobj.mobile;
      case "countryId":
        if (user != null) {
          if (statusEdit === true) {
            return (user["countryId"] = user["countryId"]);
          } else {
            return (user["countryId"] =
              assignedobj.countryId === undefined ? "" : assignedobj.countryId);
          }
        }
        return assignedobj.countryId === undefined ? "" : assignedobj.countryId;
      case "roleId":
        if (user != null) {
          if (statusEdit === true) {
            return (user["roleId"] = user["roleId"]);
          } else {
            return (user["roleId"] =
              assignedobj.roleId === undefined ? "" : assignedobj.roleId);
          }
        }
        return assignedobj.roleId === undefined ? "" : assignedobj.roleId;
      case "password":
        if (user != null) {
          if (statusEdit === true) {
            return (user["password"] = user["password"]);
          } else {
            return (user["password"] =
              assignedobj.password === undefined ? "" : assignedobj.password);
          }
        }
        return assignedobj.password === undefined ? "" : assignedobj.password;
      default:
        if (user != null) {
          return user[param];
        } else {
          return "";
        }
    }
  }

  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    setStatusEdit(false);

    var obj = { ...assignedobj };

    if (name === "firstName") {
      obj.firstName = val;
    } else if (name === "lastName") {
      obj.lastName = val;
    } else if (name === "userName") {
      obj.userName = val;
    } else if (name === "email") {
      obj.email = val;
    } else if (name === "mobile") {
      obj.mobile = val;
    } else if (name === "countryId") {
      obj.countryId = val;
    } else if (name === "roleId") {
      obj.roleId = val;
    } else if (name === "password") {
      obj.password = val;
    }

    setAssignedobj(obj);
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "35px",width:"350px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: theme.fieldset.border,borderRadius:"12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary,fontSize:"16px" }}> {title} </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };

  const handleSubmit = (e) => {
    if (!userToEdit) {
      e.preventDefault();

      var obj = {};
      const { target } = e;
      obj = Object.fromEntries(new FormData(target));
      if (obj.firstName === "") {
        setTextData("Please Enter FirstName");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.email)) {
        setTextData("Please Enter Valid Email");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.userName)) {
        setTextData("Please Enter Valid UserName");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.userName === "") {
        setTextData("Please Enter UserName");
        setError(true);
      } else if (obj.password === "") {
        setTextData("Please Enter Password");
        setError(true);
      } else if (obj.email === "") {
        setTextData("Please Enter email");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.countryId === "-1") {
        setTextData("Please Select CountryCode");
        setError(true);
      } else if (obj.mobile === "") {
        setTextData("Please Enter MobileNumber");
        setError(true);
      } else {
        saveUser(e, Object.fromEntries(new FormData(target)));
      }
    } else {
      e.preventDefault();
      var obj = {};
      const { target } = e;
      obj = Object.fromEntries(new FormData(target));
      if (obj.firstName === "") {
        setTextData("Please Enter FirstName");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.email)) {
        setTextData("Please Enter Valid Email");
        setError(true);
      } else if (!VALID_EMAIL.test(obj.userName)) {
        setTextData("Please Enter Valid UserName");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.userName === "") {
        setTextData("Please Enter UserName");
        setError(true);
      } else if (obj.password === "") {
        setTextData("Please Enter Password");
        setError(true);
      } else if (obj.email === "") {
        setTextData("Please Enter email");
        setError(true);
      } else if (obj.lastName === "") {
        setTextData("Please Enter LastName");
        setError(true);
      } else if (obj.countryId === "-1") {
        setTextData("Please Select CountryCode");
        setError(true);
      } else if (obj.mobile === "") {
        setTextData("Please Enter MobileNumber");
        setError(true);
      } else {
        updateUser(e, Object.fromEntries(new FormData(target)));
      }
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };
  const saveUser = async (e, obj) => {
    obj.orgId =
      obj.dealerId === undefined || obj.dealerId === ""
        ? globalOrgId
        : obj.dealerId;
    obj.createdBy = globaluserId;
    obj.parentOrgId = globalOrgId;

    const responseData = await userServices.saveUser(obj);
    var obj1 = {};
    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateUser = async (e, obj) => {
    e.preventDefault();
    obj.orgId =
      obj.dealerId === undefined || obj.dealerId === ""
        ? globalOrgId
        : obj.dealerId;
    obj.createdBy = globaluserId;
    obj.parentOrgId = globalOrgId;
    const responseData = await userServices.updateUser(obj);
    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const FormFields = ({ fields, children }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"15px" }}
              >
                <FormLabel sx={{ fontSize: "16px", fontWeight: "600",textAlign: "center" }}>{field.label}</FormLabel>
                <TextInput
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                  sx={{ flex: "2" }}
                />
              </Grid>
            );
          } else if (field.type === "password") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}
              >
                 <FormLabel sx={{ fontSize: "16px", fontWeight: "600",textAlign: "center" }}>{field.label}</FormLabel>
                <PasswordInput
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
              </Grid>
            );
          } else if (field.type === "number") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}
              >
                 <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center"}}>{field.label}</FormLabel>
                <NumberInput
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
              </Grid>
            );
          } else if (field.type === "select") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}
              >
                 <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
                <SelectInput
                  name={field.name}
                  options={field.options}
                  defaultValue={
                    user !== null
                      ? switchCaseVal(field.name) == ""
                        ? user[field.name]
                        : switchCaseVal(field.name)
                      : switchCaseVal(field.name) === ""
                      ? field.options !== null
                        ? field.options[0].value
                        : 0
                      : switchCaseVal(field.name)
                  }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                />
              </Grid>
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}
              >
                <RadioInput name={field.name} options={field.options} />
              </Grid>
            );
          }
        })}
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" }}>
          {children}
        </Grid>
      </>
    );
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setError(false);
  };

  const dropdownStyle = {
    boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
    margin:"20px 0px"
  };

  const ORG_TYPE_OPTIONS = [
    {
      id: 2,
      value: "Organization",
      key: "2",
    },
    {
      id: 5,
      value: "Dealer",
      key: "5",
    },
  ];
  const [selectedOrgType, setSelectedOrgType] = React.useState(
    ORG_TYPE_OPTIONS[0]?.key
  );
  const [roleOptions, setRoleOptions] = React.useState([]);

  React.useEffect(() => {
    switch (selectedOrgType) {
      case "2":
        setRoleOptions([
          { title: "Organization Admin", value: 6 },
          { title: "Organization Supervisor", value: 7 },
          { title: "Organization Executive", value: 8 },
        ]);
        break;
      case "5":
        setRoleOptions([
          { title: "Dealer Admin", value: 20 },
          { title: "Dealer Supervisor", value: 21 },
          { title: "Dealer Executive", value: 22 },
          { title: "Operator", value: 23 },
        ]);
        break;
    }
  }, [selectedOrgType]);

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={user}>
        <Paper elevation={6} sx={{ display: "flex", flexDirection: "row", padding: "20px 10px",columnGap:"10px",marginTop:"40px" }}>
          {/* <Breadcrumb mainDiv="Admin" subDiv="User"></Breadcrumb> */}

          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Organization Type</FormLabel>
            <Dropdown
              autoFetchOptions={true}
              options={ORG_TYPE_OPTIONS}
              fieldName={"userOrgType"}
              defaultValue={org !== null ? org.userOrgType : ""}
              onChange={(value) => setSelectedOrgType(value)}
              sx={{ width: "100%", padding: "8px", borderRadius: "4px" }}
            />
          </Box>

          <Box  sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
            <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Organization</FormLabel>
            <Dropdown
              autoFetchOptions={true}
              fieldName={"resellerId"}
              optionsKeyIdentifier={resellers}
              defaultValue={org !== null ? org.resellerId : ""}
              sx={{ width: "100%", padding: "8px", borderRadius: "4px" }}
            />
          </Box>

          {selectedOrgType === "5" && (
          <Box  sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Dealer</FormLabel>
              <Dropdown
                autoFetchOptions={true}
                fieldName={"dealerId"}
                optionsKeyIdentifier={dealers}
                defaultValue={org !== null ? org.dealerId : ""}
              />
            </Box>
          )}
        </Paper>
        <FormLayout title={"User Details"}>
          <FormFields fields={USER_DETAIL}>
          <Box  sx={{ mb: 2, display: 'flex', flexDirection: 'column',justifyContent: "flex-start" }}>
          <FormLabel sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>Roles</FormLabel>
            <SelectInput
              name={"roleId"}
              label={"Roles"}
              options={roleOptions}
              // value={roleOptions[0].key}
              // defaultValue={
              //   user !== null ? user["roleId"] : roleOptions[0]?.value
              // }
              callBackFn={callBackFn1}
              defaultValue={
                user !== null
                  ? switchCaseVal("roleId") === ""
                    ? user["roleId"]
                    : switchCaseVal("roleId")
                  : switchCaseVal("roleId") === ""
                  ? roleOptions !== null
                    ? roleOptions[0]?.value
                    : 0
                  : switchCaseVal("roleId")
              }
              callbackfnStatus={true}
            />
               </Box>
            {/* <Dropdown 
              autoFetchOptions={false}
              label={"Roles"}
              statefulOptions={roleOptions}
              fieldName={"role"}
            /> */}
          </FormFields>
        </FormLayout>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input type="hidden" name="id" value={user != null ? user.id : ""} />
          <Button variant="contained" sx={buttonStyle} type="submit">
            {userToEdit ? "Update" : "Submit"}
          </Button>

          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          {error === true && (
            <AlertDialogSlide
              open1={error}
              text={textData}
              handleClear={handleClear}
            />
          )}
        </Grid>
      </form>

      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
