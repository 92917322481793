// import * as React from "react";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
// import SnackbarContent from "@mui/material/SnackbarContent";
// import Paper from "@mui/material/Paper";
// import { Typography } from "@mui/material";
// import Divider from "@mui/material/Divider";

// const responseData = [
//   { id: 1, message: "Slot", action: "sl1" },
//   { id: 2, message: "SlotStatus", action: "Available" },
//   { id: 3, message: "BatteryStatus", action: "Not Available" },
//   { id: 4, message: "SlotVoltage", action: "45 V" },
//   { id: 5, message: "SlotCapacity", action: "45 Ah" },
// ];

// export default function LongTextSnackbar() {
//   return (
//     <Paper elevation={5} sx={{ display: "inline-block" }}>
//       <Typography
//         sx={{
//           fontSize: 16,
//           color: "#0073B7",
//           fontWeight: 500,
//           ml: 2,
//           mt: 2,
//           mb: 2,
//         }}
//       >
//         Slot Details :
//       </Typography>
//       <Divider />

//       {/* <Stack spacing={0} sx={{ maxWidth: 600, p: 5 }}>
//         {responseData.map((item) => (
//           <SnackbarContent
//             sx={{ backGround: "#61CE70" }}
//             message={item.message}
//             action={<Button size="small">{item.action}</Button>}
//           />
//         ))}
//       </Stack> */}
//     </Paper>
//   );
// }

import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#bbdefb",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, value) {
  return { name, value };
}

const rows = [
  createData("slot", "sl1"),
  createData("slot status", "available"),
  createData("Battery status", "Not available"),
  createData("slot voltage", "45v"),
  createData("slot capacity", "45Ah"),
];

const battery = [
  createData("BatteryId", "16"),
  createData("Battery Model", "Model B"),
  createData("Battery Voltage", "48V"),
  createData("Battery Capacity", "25Ah"),
];

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{ color: "green" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function SortDetails({ row }) {
  const [progress, setProgress] = React.useState(20);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 20
      );
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="left">
      <Paper
        sx={{
          marginTop: 2,
          backgroundColor: "lightgrey",
          marginLeft: "20px",
        }}
      >
        <Typography sx={{ marginLeft: "10px", color: "#2979ff" }}>
          Slot Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BatteryFullIcon sx={{ color: "green", fontSize: "150px" }} />
          </Grid>
          <Grid item xs={6}>
            <TableContainer
              component={Paper}
              sx={{ width: "300px", marginBottom: 2 }}
            >
              <Table>
                <TableBody>
                  {row.map((row) => (
                    <StyledTableRow key={row.slotId}>
                      <StyledTableCell component="th" scope="row">
                        {row.slot}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.slotId}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          marginTop: 2,
          backgroundColor: "lightgrey",
          marginLeft: "20px",
        }}
      >
        <Typography sx={{ marginLeft: "10px", color: "#2979ff" }}>
          Battery Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography sx={{ marginLeft: "20px", marginTop: 2 }}>
              Battery SOC-100%
              <CircularProgressWithLabel value={progress} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TableContainer
              component={Paper}
              sx={{ width: "300px", marginBottom: 2 }}
            >
              <Table>
                <TableBody>
                  {battery.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.value}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
