import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import ImageUpload from "./ImageUpload";
import { Box } from "@mui/material/";
import { Grid, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import SwapStationServices from "../../services/swapStationService";
import { makeStyles } from "@mui/styles";
import DraggableDialog from "../shared/DailogDrag";

const rows = [
  { days: "Sunday" },
  { days: "Monday" },
  { days: "Tuesday" },
  { days: "wednesday" },
  { days: "Thursday" },
  { days: "Friday" },
  { days: "Saturday" },
];

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: "auto",
    marginTop: "3vh",
    overflowX: "initial",
    borderWidth: 1,
    borderColor: "green",
    borderStyle: "solid",
  },

  tableCell: {
    padding: "8px",
  },
  tableHead: {
    borderBottomStyle: "solid",
    borderBottomColor: "black",
    borderBottomWidth: 2,
    background: "#61CE70",
  },
}));

export default function BasicTable({ swapToEdit, onCancel }) {
  const [timeDetails, setTimeDetails] = React.useState([]);
  //const [checked, setChecked] = React.useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [responseData, setResponseData] = useState([]);
  const [dataSubEvento, setDataSubEvento] = useState("00:23");
  const [dataObj, setDataObj] = useState({});
  React.useEffect(async () => {
    var timetabledata = [];
    for (var i = 0; i < 8; i++) {
      var array = {};
      array.dayOfWeek = "dayOfWeek" + parseInt(i + 1);
      array.isHoliday = "isHoliday" + parseInt(i + 1);
      array.startTime = "start" + (i + 1) + "Time";
      // array.breakStartTime = `breakStart${i + 1}Time`;
      // array.breakEndTime = `breakEnd${i + 1}Time`;
      array.dayID = "dayID" + parseInt(i + 1);
      array.endTime = `end${i + 1}Time`;
      timetabledata.push(array);
    }
    setTimeDetails(timetabledata);
    await fetchTimingList();
  }, []);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  const fetchTimingList = async () => {
    const request = {
      stationId: swapToEdit,
    };
    const responseData = (await SwapStationServices.getImageList(request)).data;
    setResponseData(responseData.timeData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    var arrayVal = [...timeDetails];
    var differ1arr = [];

    differ1arr = Object.fromEntries(new FormData(target));
    console.log("differ1arr:" + differ1arr);
    var differ2arr = [];
    for (var i = 0; i < arrayVal.length; i++) {
      var testval0 = false;
      var testval = false;
      var testval1 = false;
      var testval2 = false;
      // var testval3 = false;
      var testval4 = false;
      Object.keys(differ1arr).forEach(function (key) {
        if (arrayVal[i].dayOfWeek === key) {
          if (testval0 === false) {
            var differ1 = {};
            differ1.name = key;
            differ1.value = differ1arr[key];
            differ2arr.push(differ1);
            testval = true;
          }
        } else if (arrayVal[i].isHoliday === key) {
          if (testval === false) {
            var differ1 = {};
            differ1.name = key;
            differ1.value = differ1arr[key];
            differ2arr.push(differ1);
            testval = true;
          }
        } else if (arrayVal[i].startTime === key) {
          if (testval1 === false) {
            var differ1 = {};
            differ1.name = key;
            differ1.value = differ1arr[key];
            differ2arr.push(differ1);
            testval1 = true;
          }
        } else if (arrayVal[i].dayID === key) {
          if (testval2 === false) {
            var differ1 = {};
            differ1.name = key;
            differ1.value = differ1arr[key];
            differ2arr.push(differ1);
            testval2 = true;
          }
        } else if (arrayVal[i].endTime === key) {
          if (testval4 === false) {
            var differ1 = {};
            differ1.name = key;
            differ1.value = differ1arr[key];
            differ2arr.push(differ1);
            testval4 = true;
          }
        }
      });
    }
    var map = {};
    for (var i = 0; i < differ2arr.length; i++) {
      var key = differ2arr[i].name;
      var value = differ2arr[i].value;

      if (key in map) {
        map[key].push(value);
      } else {
        map[key] = value;
      }
    }
    var finalArray = [];
    for (let i = 1; i < 8; i++) {
      var finalmap1 = {};
      Object.keys(map).forEach(function (key) {
        if (key === "start" + i + "Time") {
          finalmap1.startTime = map[key];
        }
        // if (key === "breakStart" + i + "Time") {
        //   finalmap1.breakStartTime = map[key];
        // }
        // if (key === "breakEnd" + i + "Time") {
        //   finalmap1.breakEndTime = map[key];
        // }
        if (key === "end" + i + "Time") {
          finalmap1.endTime = map[key];
        }
        if (key === "dayOfWeek" + i) {
          finalmap1.dayOfWeek = map[key];
        }
        if (key === "dayID" + i) {
          finalmap1.dayID = map[key];
        }
        if (key === "isHoliday" + i) {
          finalmap1.isHoliday = map[key];
        }
      });

      finalArray.push(finalmap1);
    }
    var obj = {};
    obj.timingList = finalArray;
    obj.swapStationID = swapToEdit;

    saveSwapStationTiming(e, obj);
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const saveSwapStationTiming = async (e, obj) => {
    const responseData = await SwapStationServices.saveSwapStationTiming(obj);

    var obj1 = {};

    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  const changeFnChk = (e, key) => {
    var arrayObj = [];
    for (var i = 1; i < 8; i++) {
      var finalmap1 = {};
      if (key === "isHoliday" + i) {
        finalmap1.id = i;
        if (e.target.checked === true) {
          finalmap1.name = "isHoliday" + i;
          finalmap1.isHoliday = "on";
        } else {
          finalmap1.name = "isHoliday" + i;
          finalmap1.isHoliday = "off";
        }
        console.log("name:" + i + ":" + e.target.checked);
      }
      if (key === "start" + i + "Time") {
        finalmap1.id = i;
        finalmap1.name = key;
        finalmap1.startTime = e.target.value;
        console.log("e.target.value" + e.target.value);
      }
      // if (key === "breakStart" + i + "Time") {
      //   finalmap1.id = i;
      //   finalmap1.breakStartTime = e.target.value;
      //   console.log("name:" + i + ":" + e.target.value);
      // }
      // if (key === "breakEnd" + i + "Time") {
      //   finalmap1.id = i;
      //   finalmap1.breakEndTime = e.target.value;
      // }
      if (key === "end" + i + "Time") {
        finalmap1.id = i;
        finalmap1.endTime = e.target.value;
      }
      // if (key === "dayofweek" + i) {
      //   finalmap1.id = i;
      //   finalmap1.dayofweek = e.target.value;
      // }
      arrayObj.push(finalmap1);
    }
    var arraytemp = [...responseData];
    for (var i = 0; i < arraytemp.length; i++) {
      for (var j = 0; j < arrayObj.length; j++) {
        if (arraytemp[i].dayID === arrayObj[j].id) {
          arraytemp[i].isHoliday = arrayObj[j].isHoliday;
          arraytemp[i].startTime = arrayObj[j].startTime;
          // arraytemp[i].breakStartTime = arrayObj[j].breakStartTime;
          // arraytemp[i].breakEndTime = arrayObj[j].breakEndTime;
          arraytemp[i].endTime = arrayObj[j].endTime;
        }
      }
    }
    setResponseData([]);
    setResponseData(arraytemp);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TableContainer
          sx={{ width: "80%", mt: 6, ml: 10 }}
          className={classes.tableContainer}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  Days
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  IsHoliday
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  StartTime
                </TableCell>
                {/* <TableCell align="left" className={classes.tableCell}>
                  Break StartTime
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  Break EndTime
                </TableCell> */}
                <TableCell align="left" className={classes.tableCell}>
                  EndTime
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row.dayOfWeek}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <input
                      type="checkbox"
                      value={row.isHoliday}
                      checked={row.isHoliday === "on" ? true : false}
                      onChange={(e) => changeFnChk(e, "isHoliday" + row.dayID)}
                      name={"isHoliday" + row.dayID}
                    />
                    <input
                      type="hidden"
                      value={row.dayOfWeek}
                      name={"dayOfWeek" + row.dayID}
                    />
                    <input
                      type="hidden"
                      value={row.dayID}
                      name={"dayID" + row.dayID}
                    />
                    {/* // onBlur={(event) =>
                    //   updateMyData(
                    //     parseInt(row.row.id),
                    //     row.column.id,
                    //     event.target.checked ? "Yes" : "No"
                    //   )
                    // } */}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <TextField
                      style={{
                        width: "70%",
                        "& .MuiInputBase-input": {
                          height: "14px",
                          fontSize: "14px",
                        },
                      }}
                      size="small"
                      id="outlined-basic"
                      onChange={(e) =>
                        changeFnChk(e, "start" + row.dayID + "Time")
                      }
                      value={row.startTime}
                      variant="outlined"
                      color="primary"
                      type="time"
                      name={`start${row.dayID}Time`}
                    />
                  </TableCell>
                  {/* <TableCell align="left" className={classes.tableCell}>
                    <TextField
                      style={{
                        width: "70%",
                        "& .MuiInputBase-input": {
                          height: "14px",
                          fontSize: "14px",
                        },
                      }}
                      id="outlined-basic"
                      onChange={(e) =>
                        changeFnChk(e, "breakStart" + row.dayID + "Time")
                      }
                      value={row.breakStartTime}
                      variant="outlined"
                      size="small"
                      color="primary"
                      type="time"
                      name={`breakStart${row.dayID}Time`}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <TextField
                      style={{
                        width: "70%",
                        "& .MuiInputBase-input": {
                          height: "14px",
                          fontSize: "14px",
                        },
                      }}
                      id="outlined-basic"
                      onChange={(e) =>
                        changeFnChk(e, "breakEnd" + row.dayID + "Time")
                      }
                      variant="outlined"
                      value={row.breakEndTime}
                      size="small"
                      color="primary"
                      type="time"
                      name={`breakEnd${row.dayID}Time`}
                    />
                  </TableCell> */}
                  <TableCell align="left" className={classes.tableCell}>
                    <TextField
                      style={{
                        width: "70%",
                        "& .MuiInputBase-input": {
                          height: "14px",
                          fontSize: "14px",
                        },
                      }}
                      id="outlined-basic"
                      onChange={(e) =>
                        changeFnChk(e, "end" + row.dayID + "Time")
                      }
                      variant="outlined"
                      value={row.endTime}
                      size="small"
                      color="primary"
                      type="time"
                      name={`end${row.dayID}Time`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" sx={buttonStyle} type="submit">
            Save
          </Button>
          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Grid>
      </form>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
