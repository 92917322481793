import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CreateCalendar from "./CreateCalendar";
import ListCalendar from "./ListCalendar";
import { Breadcrumb } from "../shared/Breadcrumbs";

const Calendar = () => {
  const [deviceToEdit, setDeviceToEdit] = React.useState(null);
  const [tabValue, setTabValue] = useState(1);
  
  const handleDevEdit = (devEditedId) => {
    setDeviceToEdit(devEditedId);
    setTabValue(1);
  };

  const handleTabChange = (event, newValue) => {
    setDeviceToEdit(null);
    setTabValue(newValue);
  };

  const handleCancel = () => {
    setTabValue(1);
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Calendar"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="calendar tabs"  sx={{margin:"20px 0px"}}>
        <Tab label="Create Calendar" />
        <Tab label="Calendar List" />
      </Tabs>
      {tabValue === 0 ? (
        <CreateCalendar  />
      ) : (
        <ListCalendar />
      )}
    </Box>
  );
};

export default Calendar;
