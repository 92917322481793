import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    
    },
    tableRow: {
        height:5,
      },
    TableCell: {
        padding: "0 16px"
    }
  },
  
});


const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "black",
  
    
  }
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "white"
  }
}))(TableCell);

function createData(Date, AlertType, LatLong, Description) {
  return { Date, AlertType, LatLong, Description };
}

const rows = [
 
];

export default function BasicTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();

  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableHeaderCell className={classes.TableCell}>Date</TableHeaderCell>
            <TableHeaderCell className={classes.TableCell}>Alert Type</TableHeaderCell>
            <TableHeaderCell className={classes.TableCell}>Lat Long</TableHeaderCell>
            <TableHeaderCell className={classes.TableCell}>Description</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell  >{row}</TableCell>
              <TableCell >{row}</TableCell>
              <TableCell >{row}</TableCell>
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
    </>
  );
}
