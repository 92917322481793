import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MuiToolbar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import SwapStation from "../../assets/images/ec_White_Logo.png";

export default function ButtonAppBar() {
  const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    height: "55px",
    backgroundColor: "#61CE70",
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{background:'#fff !important', '& MuiPaper-root':{backgroundColor:'#fff !important'}}}>
        <Toolbar
          elevation={0}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            paddingTop: "2px",
            paddingBottom: "0px",
            paddingLeft: "15px",
            backgroundColor:'#fff !important',
            borderStyle:'none !important'
          }}
        >
          <img
            src={SwapStation}
            xs={{ maxWidth: "10px" }}
            style={{ pading: "5px" }}
            alt="Fleetjack Logo"
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
