import React, { useState,useCallback } from "react";
import MainDataLine2 from "./MainDataLine2";
import MainDataAccount2 from "./MainDataAccount2";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import GroupsIcon from '@mui/icons-material/Groups';
import Grid from '@mui/material/Grid';

const list2DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "none", background: "#6684b3 !important", color: "#ffffff",paddingLeft:"44px",
position: "relative",
paddingTop:"5px",
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 32,
    top: 0,     
    borderLeft: "1px solid white",
    height: "170px",
    fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 32,
        top: 18,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
        }
     };
   
const MainDataLine1 = (prop) => {  
  var  orgType=prop.orgType;
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");
  const [sizeCount,setSizeCount] = useState(10);
  const [loadmorediv,setLoadmorediv] = useState({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
  
  const onClickfn = useCallback((checkval,val) => {     
    prop.onClickfn(checkval,val);     
      return null;
  }, []);
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);     
      return null;
  }, []);
  

   
  const expandValChk = useCallback((val) => {    
    if(true===val){
      setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
    }else{
      setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    }
    return null;
}, []);


  const elementDataServer = (request, dataArray1) => {
    if(orgType===3){
      return <MainDataAccount2 selectedObject={request} dataArray={dataArray1} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />; 
    }else{
      return <MainDataLine2 selectedObject={request} dataArray={dataArray1} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />; 
    }
   }


  const returnData = (checkboxList) => {
    return <div>
      {checkboxList?.map((d, index) => (
        <div
          className="text"
          key={d.key}
        >
          <div>
            <div className="text" key={d.key}>
              <Accordion expanded={expandedInsdL1 === "panelInside" + d.key} onChange={handleChange3(d.key, 'panelInside' + d.key)} >
                <AccordionSummary sx={list2DivStyle} >
                <span className={expandVal === true ?"listdataLine1":""}></span>
                <GroupsIcon  sx={{marginTop:"-1%",width:"18px",height:"25px"}}/>&nbsp;{d.value} ({d.count})
                  <IndeterminateCheckBoxIcon
                    sx={{
                      marginLeft: "auto",
                      marginTop:"-1.7%",
                      pointerEvents: "auto",
                      color: "#f0dfdf"
                    }}
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "block" : 'none' }}
                  />
                  <AddBoxIcon sx={{
                    marginLeft: "auto",
                    marginTop:"-1.7%",
                    pointerEvents: "auto",
                    color: "#f0dfdf"
                  }}
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "none" : 'block' }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {elementListServer}                
                  <div style={loadmorediv} >
                  <Grid container spacing={3}>
                     <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <span onClick={()=>loadMore(d.key)}>Load More</span>
                    </Grid>
                    <Grid item xs={4}>                        
                    </Grid>
                  </Grid>
                 </div>
                </AccordionDetails>
              </Accordion>
              <Divider light />
            </div>
          </div>
        </div>
      ))}
    </div>;
  }
  const handleChange3 =
    (index, panel) => async (newExpanded) => {     
      if (expandVal === true) {
        setExpandVal(false);
        setExpandedInsdL1(false);
        setSizeCount(10);
        setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
        } else {
          setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
        
        var request = {};
        request.resellerId = prop.selectedObject.resellerId;
        request.regionId = prop.selectedObject.regionId;
        request.dealerId = index;
        request.orgId = 0;
        request.page = 0;
        request.size = 10;
        request.region = 0;
        request.vehModel = "2";
        request.value = "customer";
        request.searchText = "";
        request.vehModel = "1";
        var region = {};
        region.region = 1;
        /*
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
        let stockdata = {};
        stockdata = await responseData.data.data;
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
        */
        let stockdata = {};
        return await treeviewService.getOrgGridViewForTreeview(request, region)
        .then(res => {
        stockdata = res.data.data;
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
          return res;
        })
        .catch(err => {
          console.log(err);
        });

      }
    };
    const loadMore =async (index)=>{  
      var sizeCnt=sizeCount+10; 
      setSizeCount(sizeCnt);
      var request = {};
      request.resellerId = prop.selectedObject.resellerId;
      request.regionId = prop.selectedObject.regionId;
      request.dealerId = index;
      request.orgId = 0;
      request.page = 0;
      request.size = sizeCnt;
      request.region = 0;
      request.vehModel = "2";
      request.value = "customer";
      request.searchText = "";
      request.vehModel = "1";
      var region = {};
      region.region = 1;
      let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
      let stockdata = {};
      stockdata = await responseData.data.data;      
      setElementListServer(elementDataServer(request, stockdata.content));
      setExpandVal(true);
      setExpandedInsdL1("panelInside" + index);
    }

  const listElement = returnData(prop.dataArray);
  return (
    <div>{listElement}</div>
  );
}
export default React.memo(MainDataLine1);
