import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";

export const Breadcrumb = ({ mainDiv, subDiv, items }) => {
  const theme = useTheme();
  if (items) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {items.map((item, index) => (
          <Typography
            key={item}
            sx={{
              fontSize: theme.text.fontSize,
              color:
                index === items.length - 1
                  ? theme.text.highlightColor
                  : theme.text.primary,
            }}
          >
            {item}
          </Typography>
        ))}
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "-30px" }}>
      <Typography sx={{ fontSize: "16px", color: theme.text.primary }}>
        {mainDiv}
      </Typography>
      <Typography sx={{ fontSize: "16px", color: theme.text.highlightColor }}>
        {subDiv}
      </Typography>
    </Breadcrumbs>
  );
};
