import React, { useState } from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Menus } from "./ps-list";
import ProfileDetails from "./profileDetails";
import ChangePassword from "./ps-password";
import Alert from "./ps-alert";

export default function ProfileSettings() {
  const [hideDetails, setHidedetails] = useState("Profile");
  // const [UserToEdit, setUserToEdit] = React.useState(null);

  const handleChange = (event) => {
    setHidedetails(event.target.innerText);
    console.log(event.target.innerText);
  };

  // const handleUserEdit = (userEditedId) => {
  //   setUserToEdit(userEditedId);
  // };

  return (
    <Box sx={{ display: "flex", p: 3 }}>
      <Box sx={{ overflow: "auto", width: "20%" }}>
        <List>
          {Menus.map((menu) => (
            <ListItem
              sx={{ "&:hover": { color: "#2196f3", cursor: "pointer" } }}
              key={menu.id}
              disableGutters
            >
              <ListItemIcon sx={{ color: "#2196f3" }}>
                <menu.icon />
              </ListItemIcon>
              <ListItemText primary={menu.title} onClick={handleChange} />
            </ListItem>
          ))}
        </List>
      </Box>
      {hideDetails === "Profile" && <ProfileDetails />}
      {hideDetails === "Password" && <ChangePassword />}
      {hideDetails === "Alert Preference" && <Alert />}
    </Box>
  );
}
