export const ORGANIZATION_DETAIL = [
   
  {
    type: 'text',
    label: 'Organization Name',
    name: 'name'
  },
  {
    type: 'text',
    label: 'Address',
    name: 'address'
  }, 
  {
    type: 'select',
    label: 'Country',
    disabled: 'true',
    name: 'countryId',
    options: [
      {
        id: 1,
        title: 'select',
        value: 1
      },
      {
        id: 2,
        title: 'Australia (AUS)',
        value: 2
      },
      {
        id: 3,
        title: 'Canada (CAN)',
        value: 3
      },
      {
        id: 4,
        title: 'India (IND)',
        value: 4
      },
      {
        id: 5,
        title: 'United States (USA)',
        value: 5
      }
    ]
  }, 
  {
    type: 'select',
    label: 'State / Province',
    name: 'stateId',
    options: [
      {
        id: 1,
        title: 'select',
        value: 1
      }
    ]
  }, 
  {
    type: 'select',
    label: 'City',
    name: 'cityId',
    options: [
      {
        id: 1,
        title: 'select',
        value: 1
      }
    ]
  },
  {
    type: 'text',
    label: 'Pin code',
    name: 'zipCode'
  }, 
  {
    type: 'select',
    label: 'Time Zone',
    name: 'timeZoneId',
    options: [ 
      
      {
        id: 1,
        title: 'select',
        value: 1
      }
    ]
  }
]

export const ORGANIZATION_ADMIN_DETAIL = [
  {
   type: 'text',
   label: 'Email',
   name: 'email'
 },
 {
   type: 'select',
   label: 'Country Code',
   name: 'mobileCountryCode',
   options: [
     {
       id: 1,
       title: 'select',
       value: 1
     },
     {
       id: 2,
       title: 'Australia+61',
       value: 1
     },
     {
       id: 3,
       title: 'Canada+1',
       value: 2
     },
     {
       id: 4,
       title: 'India+91',
       value: 3
     },
     {
       id: 5,
       title: 'United States+1',
       value: 4
     }
   ]
 }, 
 {

   type: 'text',
   label: 'Mobile Number',
   name: 'mobile'
 }
]