import Service from "./service";

const BatteryServices = {
  getGridViewForBat: function (resource) {
    //return Service.post('/gridviewfororg')
    //return Service.get('/gridviewfordevice')
    return Service.post("/battery/listBattery", resource, null);
  },
  saveBatDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/battery/createBattery", data, null);
  },

  viewBatById: function (batteryId) {
    return Service.post("/battery/getBattery", { batteryId });
  },
  //   updateOrgDetails: function (data) {
  //     return Service.post("/organization/updateOrganization", data, null);
  //   },
  //   getOrganization: (orgId) => {
  //     return Service.post('organization/getOrganization', orgId)
  //   },
  //   updateOrganization: (orgId) => {
  //     return Service.post('/organization/updateOrganization', orgId,null)
  //   }

  getModelList: function (data) {
    return Service.post("/battery/getBatteryModelDropDown", data, null);
  },
  getTypeList: function (data) {
    return Service.post("/battery/getBatteryTypeDropDown", data, null);
  },
};

export default BatteryServices;
