import React, { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./style.css";
import "./main.css";
import CalendarService from "../../services/calendarService";
import DailogBoxui from "./DailogBoxui";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AlertTitle from "@mui/material/AlertTitle";
import { Box, Card, CardMedia, Checkbox, Chip, Fab, FormControlLabel, Grid, Rating, Typography } from "@mui/material";
import { MaincontentArea } from "../style/StyledComponets";
import { IoIosCall } from "react-icons/io";

//const RESTRICTED_ID = DOMAIN_DETAIL[0].options[1].value
export default function CreateSwapView({ swapToEdit }) {
  const calendarRef = useRef(null);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [initialviewVal, setInitialviewVal] = useState("timeGridDay");
  const [currentEvents, setCurrentEvents] = useState([]);
  const [initalEvents, setInitalEvents] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [elementLoad, setElementLoad] = useState();
  const [open, setOpen] = useState(false);
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [viewMonth, setViewMonth] = useState(false);
  const [viewWeek, setViewWeek] = useState(false);
  const [viewDay, setViewDay] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [slotValue, setSlotValue] = useState(0);
  const [starttime, setStarttime] = useState();
  const [endtime, setEndtime] = useState();
  const [prevNext, setPrevNext] = useState(false);
  const [intialloaddata, setIntialloaddata] = useState(true);
  const [swapStationId, setSwapStationId] = useState("1");
  const [savedBookStatus, setSavedBookStatus] = useState(true);

  useEffect(async () => {
    document.querySelector(".fc-prev-button").addEventListener("click", (e) => {
      setPrevNext(true);
    });
    document.querySelector(".fc-next-button").addEventListener("click", (e) => {
      setPrevNext(true);
    });
    document
      .querySelector(".fc-today-button")
      .addEventListener("click", (e) => {
        setPrevNext(true);
      });
    console.log("initail load");
    //console.log("lat::" + swapToEdit.lat);
    //console.log("lng::" + swapToEdit.lng);
    //console.log("stationType::" + swapToEdit.stationType);
    //console.log("noOfSlots::" + swapToEdit.noOfSlots);
    //console.log("swapStationId::" + swapToEdit.swapStationId);
    var payload = {};
    payload.type = "day";
    var dt = new Date();
    payload.date =
      dt.getFullYear() +
      "-" +
      ("0" + (dt.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dt.getDate()).slice(-2);
    //payload.date="2022-10-26";
    payload.stationId = swapToEdit.swapStationId;
    setSwapStationId(swapToEdit.swapStationId);
    const calendarlist = (
      await CalendarService.getBookingBySwapStationDay(payload)
    ).data;
    setInitalEvents(calendarlist.data);
    var obj = {};
    obj.dialogButtonText = "Slot Booking";
    obj.submitButtonText = "Confirm";
    obj.closeButtonText = "Close";
    obj.dialogText = "";
    setDialogContentConfirm(obj);
    setDataStatusConfirm(false);
  }, []);
  var globaluserId = 1;
  var globalJson = {};
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globaluserId = globalJson.id;
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  function subtractSeconds(numOfSeconds, date = new Date()) {
    date.setSeconds(date.getSeconds() - numOfSeconds);

    return date;
  }

  const handleDateSelect = async (selectInfo) => {
    //let title = prompt('Please enter a new title for your event1');
    //let calendarApi = selectInfo.view.calendar
    //calendarApi.unselect()//clear date selection
    /*
    if(title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });    
      setInitialviewVal("timeGridDay");
      let calendarApi1 = calendarRef.current.getApi()
      calendarApi1.changeView('timeGridDay', selectInfo.startStr);
      console.log(calendarApi1.getEvents())
    }
    */

    if (selectInfo.view.type == "dayGridMonth") {
      setInitialviewVal("timeGridDay");
      let calendarApi1 = calendarRef.current.getApi();
      calendarApi1.changeView("timeGridDay", selectInfo.startStr);
      console.log("month clicked");
      // this.setState({
      //   currentEvents: events
      // })
    } else if (selectInfo.view.type == "timeGridDay") {
      setOpenStatus(true);
      setDataStatusConfirm(true);
      var payload = {};
      payload.stationId = swapStationId;
      payload.userId = globaluserId;
      //var testdata = [{ "title": "Select", "value": 0 }, { "title": "Slot 1", "value": 2 }, { "title": "Slot 2", "value": 3 }, { "title": "Slot 3", "value": 4 }, { "title": "Slot 4", "value": 5 }, { "title": null, "value": 6 }, { "title": null, "value": 7 }];
      payload.startTime =
        selectInfo.start.getFullYear() +
        "-" +
        ("0" + parseInt(selectInfo.start.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(selectInfo.start.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(selectInfo.start.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.start.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.start.getSeconds())).slice(-2);
      payload.endTime =
        selectInfo.end.getFullYear() +
        "-" +
        ("0" + parseInt(selectInfo.end.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(selectInfo.end.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(selectInfo.end.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.end.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.end.getSeconds())).slice(-2);
      const calendarlist = (
        await CalendarService.getAvailableSlotsByTime(payload)
      ).data;
      setDropdownValues(calendarlist.data);
      payload.endTm =
        selectInfo.end.getFullYear() +
        "-" +
        ("0" + parseInt(selectInfo.end.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(selectInfo.end.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(selectInfo.end.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.end.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(selectInfo.end.getSeconds())).slice(-2);
      var obj = {};
      obj.dialogButtonText = "Slot Booking";
      obj.submitButtonText = "Book";
      obj.closeButtonText = "Close";
      const date1 = new Date(payload.strtTm);
      const date2 = new Date(payload.endTm);
      //payload.startTime=subtractSeconds(1, date1).getFullYear()+"-"+("0" + parseInt(subtractSeconds(1, date1).getMonth()+1)).slice(-2)+"-"+("0" + parseInt(subtractSeconds(1, date1).getDate())).slice(-2)+" "+("0" + parseInt(subtractSeconds(1, date1).getHours())).slice(-2)+":"+("0" + parseInt(subtractSeconds(1, date1).getMinutes())).slice(-2)+":"+("0" + parseInt(subtractSeconds(1, date1).getSeconds())).slice(-2);
      payload.endTime =
        subtractSeconds(1, date2).getFullYear() +
        "-" +
        ("0" + parseInt(subtractSeconds(1, date2).getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(subtractSeconds(1, date2).getDate())).slice(-2) +
        " " +
        ("0" + parseInt(subtractSeconds(1, date2).getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(subtractSeconds(1, date2).getMinutes())).slice(-2) +
        ":" +
        "59";
      obj.dialogText = payload.startTime;
      obj.endTime = payload.endTime;
      setDialogContentConfirm(obj);
      setStarttime(payload.startTime);
      setEndtime(payload.endTime);
      //setElementLoad(elementLoadfn(true));
    }
  };

  const handleEventClick = async (clickInfo) => {
    //console.log("clickInfo.event"+clickInfo.view.type);
    //console.log(clickInfo.event.target);
    // let calendarApi = clickInfo.view.calendar
    //calendarApi.unselect()//clear date selection
    if (clickInfo.view.type == "dayGridMonth") {
      var payload = {};
      payload.stationId = swapStationId;
      payload.type = "day";
      payload.date =
        clickInfo.event.start.getFullYear() +
        "-" +
        ("0" + parseInt(clickInfo.event.start.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(clickInfo.event.start.getDate())).slice(-2);
      payload.userId = globaluserId;
      const calendarlist = (
        await CalendarService.getBookingBySwapStationDay(payload)
      ).data;
      //setInitalEvents(calendarlist.data);
      //let datanew=[{"timeSlotID":1,"color":"red","start":"2022-10-17 00:00:00","end":"2022-10-17 00:59:59","slotId":"1","title":"test1 title","status":"Booked"},{"timeSlotID":2,"color":"red","start":"2022-10-17 10:00:00","end":"2022-10-17 10:59:59","slotId":"1","title":"test1 title","status":"Booked"},{"timeSlotID":3,"color":"red","start":"2022-10-17 20:00:00","end":"2022-10-17 20:59:59","slotId":"1","title":"test3 title","status":"Booked"}];
      //var calendar = new Calendar(calendarEl, calendarlist.data);
      let calendarApi1 = calendarRef.current.getApi();
      //calendarApi1.addEventSource(calendarlist.data);
      setInitalEvents(calendarlist.data);
      calendarApi1.changeView("timeGridDay", clickInfo.event.start);
      // this.setState({
      //   currentEvents: events
      // })
    } else if (clickInfo.view.type == "timeGridDay") {
      //console.log("date clicked");
      //console.log("month clicked::"+clickInfo.event.start);
      setOpenStatus(true);
      setDataStatusConfirm(true);
      var payload = {};
      payload.stationId = swapStationId;
      payload.userId = globaluserId;
      var testdata = [
        { title: "Select", value: 0 },
        { title: "Slot 1", value: 2 },
        { title: "Slot 2", value: 3 },
        { title: "Slot 3", value: 4 },
        { title: "Slot 4", value: 5 },
        { title: null, value: 6 },
        { title: null, value: 7 },
      ];
      payload.startTime =
        clickInfo.event.start.getFullYear() +
        "-" +
        ("0" + parseInt(clickInfo.event.start.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(clickInfo.event.start.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(clickInfo.event.start.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.start.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.start.getSeconds())).slice(-2);
      payload.endTime =
        clickInfo.event.end.getFullYear() +
        "-" +
        ("0" + parseInt(clickInfo.event.end.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(clickInfo.event.end.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(clickInfo.event.end.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.end.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.end.getSeconds())).slice(-2);
      const calendarlist = (
        await CalendarService.getAvailableSlotsByTime(payload)
      ).data;
      setDropdownValues(calendarlist.data);
      payload.endTm =
        clickInfo.event.end.getFullYear() +
        "-" +
        ("0" + parseInt(clickInfo.event.end.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(clickInfo.event.end.getDate())).slice(-2) +
        " " +
        ("0" + parseInt(clickInfo.event.end.getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.end.getMinutes())).slice(-2) +
        ":" +
        ("0" + parseInt(clickInfo.event.end.getSeconds())).slice(-2);
      var obj = {};
      obj.dialogButtonText = "Swap Station Slot Booking";
      obj.submitButtonText = "Book";
      obj.closeButtonText = "Close";
      const date1 = new Date(payload.strtTm);
      const date2 = new Date(payload.endTm);
      //payload.startTime=subtractSeconds(1, date1).getFullYear()+"-"+("0" + parseInt(subtractSeconds(1, date1).getMonth()+1)).slice(-2)+"-"+("0" + parseInt(subtractSeconds(1, date1).getDate())).slice(-2)+" "+("0" + parseInt(subtractSeconds(1, date1).getHours())).slice(-2)+":"+("0" + parseInt(subtractSeconds(1, date1).getMinutes())).slice(-2)+":"+("0" + parseInt(subtractSeconds(1, date1).getSeconds())).slice(-2);
      payload.endTime =
        subtractSeconds(1, date2).getFullYear() +
        "-" +
        ("0" + parseInt(subtractSeconds(1, date2).getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + parseInt(subtractSeconds(1, date2).getDate())).slice(-2) +
        " " +
        ("0" + parseInt(subtractSeconds(1, date2).getHours())).slice(-2) +
        ":" +
        ("0" + parseInt(subtractSeconds(1, date2).getMinutes())).slice(-2) +
        ":" +
        "59";
      obj.dialogText = payload.startTime;
      obj.endTime = payload.endTime;
      setDialogContentConfirm(obj);
      setStarttime(payload.startTime);
      setEndtime(payload.endTime);
      // setElementLoad(elementLoadfn(true));
      //console.log(clickInfo.event.start);
    } else {
      /*
      var payload={};
      payload.type="month";
      payload.date="2022-10-17";
      payload.stationId="1";
      const calendarlist = (await CalendarService.getBookingBySwapStationMonth(payload)).data;  
      setInitalEvents(calendarlist.data); 
      let calendarApi1 = calendarRef.current.getApi();  
      calendarApi1.addEventSource(calendarlist.data);
      setInitalEvents(calendarlist.data);       
      calendarApi1.changeView('timeGridDay', clickInfo.event.start);
      setElementLoad(elementLoadfn(false));
      */
    }
    //clickInfo.event.remove();
    //  if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //  }
  };

  const handleEvents = async (events) => {
    if (calendarRef.current !== null) {
      let calendarApi1 = calendarRef.current.getApi();
      if (
        calendarApi1.currentDataManager.state.currentViewType === "dayGridMonth"
      ) {
        setViewWeek(false);
        setViewDay(false);
        var viewMnth = viewMonth;
        if (viewMnth === false || true === prevNext) {
          var payload = {};
          payload.type = "month";
          payload.date =
            calendarApi1.currentDataManager.state.currentDate.getFullYear() +
            "-" +
            (
              "0" +
              parseInt(
                calendarApi1.currentDataManager.state.currentDate.getMonth() + 1
              )
            ).slice(-2) +
            "-01";
          payload.stationId = swapStationId;
          const calendarlist = (
            await CalendarService.getBookingBySwapStationMonth(payload)
          ).data;
          setPrevNext(false);
          setViewMonth((current) => true);
          setInitalEvents(calendarlist.data);
        }
      } else if (
        calendarApi1.currentDataManager.state.currentViewType === "timeGridDay"
      ) {
        if (viewDay === false || true === prevNext) {
          var payload = {};
          payload.stationId = swapStationId;
          payload.type = "day";
          //calendarApi1.incrementDate({ days: -1 });
          if (intialloaddata === true) {
            //payload.date = calendarApi1.currentDataManager.state.currentDate.getFullYear() + "-" + ("0" + parseInt(calendarApi1.currentDataManager.state.currentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + parseInt(calendarApi1.currentDataManager.state.currentDate.getDate())).slice(-2);
            var dt = new Date();
            payload.date =
              dt.getFullYear() +
              "-" +
              ("0" + (dt.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + dt.getDate()).slice(-2);
          } else {
            payload.date =
              calendarApi1.currentDataManager.state.currentDate.getFullYear() +
              "-" +
              (
                "0" +
                parseInt(
                  calendarApi1.currentDataManager.state.currentDate.getMonth() +
                    1
                )
              ).slice(-2) +
              "-" +
              (
                "0" +
                parseInt(
                  calendarApi1.currentDataManager.state.currentDate.getDate()
                )
              ).slice(-2);
          }
          setIntialloaddata(false);
          payload.userId = globaluserId;
          console.log("globalJsonglobalJsonglobalJsonglobalJson:" + globalJson);
          setViewDay(true);
          const calendarlist = (
            await CalendarService.getBookingBySwapStationDay(payload)
          ).data;
          setPrevNext(false);
          setInitalEvents(calendarlist.data);
          //calendarApi1.addEventSource(calendarlist.data);
        }
        setViewMonth((current) => false);
        setViewWeek(false);
      } else if (
        calendarApi1.currentDataManager.state.currentViewType === "timeGridWeek"
      ) {
        //setViewMonth(false);
        if (viewWeek === false || true === prevNext) {
          var payload = {};
          payload.stationId = swapStationId;
          payload.type = "week";
          payload.date =
            calendarApi1.currentDataManager.state.currentDate.getFullYear() +
            "-" +
            (
              "0" +
              parseInt(
                calendarApi1.currentDataManager.state.currentDate.getMonth() + 1
              )
            ).slice(-2) +
            "-" +
            (
              "0" +
              parseInt(
                calendarApi1.currentDataManager.state.currentDate.getDate()
              )
            ).slice(-2);
          payload.userId = globaluserId;
          setViewWeek(true);
          const calendarlist = (
            await CalendarService.getBookingBySwapStationWeek(payload)
          ).data;
          setPrevNext(false);
          setInitalEvents(calendarlist.data);
          //calendarApi1.addEventSource(calendarlist.data);
        }
        setViewMonth(false);
        setViewDay(false);
      }
    } else {
      setViewMonth((current) => false);
      setViewWeek(false);
      setViewDay(false);
    }
    //let calendarApi1 = calendarRef.current.getApi()
    //calendarApi1.changeView('timeGridDay', events.startStr);
    //setCurrentEvents(events);
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  function renderSidebarEvent(event) {
    return (
      <li>
        <b>
          {formatDate(event.start, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </b>
        <i>{event.title}</i>
      </li>
    );
  }
  const handleSbmtClose = async () => {
    //console.log("starttime::" + starttime.split(" ")[0]);
    //console.log("handleSbmtClose");
    var payload = {};
    payload.stationId = swapStationId;
    payload.slotId = slotValue;
    payload.startTime = starttime;
    payload.endTime = endtime;
    payload.bookingType = "charge";
    payload.userId = globaluserId;
    payload.timeSlots = "1,2";

    const calendarlist = (await CalendarService.saveBookingSlots(payload)).data;
    //setInitalEvents(calendarlist.data);
    if (calendarlist.data !== "Slot Booked") {
      setSavedBookStatus(false);
    } else {
      setSavedBookStatus(true);
    }
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);
    let calendarApi1 = calendarRef.current.getApi();
    var payload = {};
    payload.stationId = swapStationId;
    payload.type = "day";
    //var dt = new Date();
    //payload.date = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
    //payload.date = calendarApi1.currentDataManager.state.currentDate.getFullYear() + "-" + ("0" + parseInt(calendarApi1.currentDataManager.state.currentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + parseInt(calendarApi1.currentDataManager.state.currentDate.getDate())).slice(-2);
    payload.date = starttime.split(" ")[0];
    payload.userId = globaluserId;
    setViewDay(true);
    const calendarlist1 = (
      await CalendarService.getBookingBySwapStationDay(payload)
    ).data;
    setInitalEvents(calendarlist1.data);
  };

  const handleClick = () => {
    setOpen(false);
  };
  const handleClearCls = () => {
    setOpenStatus(false);
  };
  const callBackFn = (value) => {
    //console.log("value:" + value);
    setSlotValue(value);
  };
  console.log('swapToEdit', swapToEdit);
  return (
    <MaincontentArea>
      <Box>
      {initalEvents != null &&
          <Grid container spacing={2}>
              <Grid item lg={3}>
                <Card sx={{p:0, position:'relative', overflow:'hidden'}} elevation={1}>
                  <Box sx={{ position:'relative',}}>
                      <CardMedia 
                        component={'img'}
                        src={swapToEdit.fileName}
                      />
                      <Fab size="small" color="success" sx={{position:'absolute', right:'15px', bottom:'-20px'}}>
                      <IoIosCall />
                      </Fab>
                  </Box>
                  
                  <Box sx={{p:2}}>
                    <Stack direction={'row'} rowGap={1} spacing={2} justifyContent={'space-between'}>
                      <Box >
                        <Typography variant="body2" color={'text.disabled'}>Staion Name</Typography>
                        <Typography variant="body2" sx={{fontWeight:600, m:0}}>{swapToEdit.swapStationNumber}</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                          <Rating value={3.5} size="small" readOnly sx={{fontSize:'12px'}}/>
                        </Box>
                      </Box>
                      
                    </Stack>
                    <Grid container spacing={1} sx={{mt:1}}>
                      <Grid item lg={6}>
                        <Typography variant="body2" color={'text.disabled'}>Staion Type</Typography>
                        <Typography variant="body2" sx={{fontWeight:400}}>{swapToEdit.stationType}</Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="body2" color={'text.disabled'}>Staion Timigs</Typography>
                        <Typography variant="body2" sx={{fontWeight:400}}>{'-'}</Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="body2" color={'text.disabled'}>Slot</Typography>
                        <Typography variant="body2" sx={{fontWeight:400}}>{swapToEdit.freeSlots}</Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="body2" color={'text.disabled'}>Charging Slot</Typography>
                        <Typography variant="body2" sx={{fontWeight:400}}>{swapToEdit.chargingSlots}</Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="body2" color={'text.disabled'}>Charging Port</Typography>
                        <Chip size="small" label={swapToEdit.chargingPort} sx={{mt:.5}} color="primary"/>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
                <Card sx={{p:0, mt:1}} elevation={1}>
                    <Box sx={{p:1}}>
                      <Typography>Amenities</Typography>
                      <Typography variant="body2" color={'text.disabled'}>No Amenities</Typography>
                    </Box>
                </Card>
                <Card sx={{p:0, mt:1}} elevation={1}>
                    <Box sx={{p:1}}>
                      <Typography>Reviews</Typography>
                      <Typography variant="body2" color={'text.disabled'}>No reviews</Typography>
                    </Box>
                </Card>
              </Grid>
              <Grid item lg={9}>
                 <Card sx={{p:2, height:'90%', '& .fc-theme-standard':{height:'calc(100% - 15%)', '& .fc-toolbar':{mb:'.5em'}}}} elevation={0}>
                      <FormControlLabel 
                        control={<Checkbox />} label='Show Weekends'
                        sx={{position:'absolute', right:'15%'}}
                        checked={weekendsVisible}
                        onChange={handleWeekendsToggle}
                      />
                      <FullCalendar
                      ref={calendarRef}
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      headerToolbar={{
                        left: "title",
                        //center: "prev,next",
                        right: "today prev,next",
                      }}
                      initialView={initialviewVal}
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      dayMaxEvents={true}
                      weekends={weekendsVisible}
                      initialEvents={initalEvents}
                      events={initalEvents}
                      select={handleDateSelect}
                      eventContent={renderEventContent} // custom render function
                      eventClick={handleEventClick}
                      allDaySlot={true}
                      minTime="00:00:00"
                      maxTime="01:00:00"
                      slotDuration="1:00:00"
                      eventsSet={handleEvents}
                    /> 
                 </Card>
              </Grid>
          </Grid>
        }
      </Box>

      {savedBookStatus === true && (
        <Dialog open={open} onClose={handleClick}>
          <Stack>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Success</AlertTitle>
              Slot Booked Successfully
            </Alert>
          </Stack>
        </Dialog>
      )}
      {savedBookStatus === false && (
        <Dialog open={open} onClose={handleClick}>
          <Stack>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Failure</AlertTitle>
              Slot Booking Fail
            </Alert>
          </Stack>
        </Dialog>
      )}
      {initalEvents != null && (
        <div className="demo-app-main">
          {/* <p>
            <b
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginLeft: "30%",
              }}
            >
              Station Name:{" "}
              <span style={{ color: "#61CE70" }}>
                {swapToEdit !== null &&
                  (swapToEdit.checkedList === "listClicked" ||
                    swapToEdit.checkedList === "iconClicked") &&
                  swapToEdit.swapStationName}
              </span>
            </b>
          </p> */}
          {/* <label>
            <input
              type="checkbox"
              checked={weekendsVisible}
              onChange={handleWeekendsToggle}
            ></input>
            &nbsp;Toggle weekends
          </label> */}
          {/* <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView={initialviewVal}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            initialEvents={initalEvents}
            events={initalEvents}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            allDaySlot={true}
            minTime="00:00:00"
            maxTime="01:00:00"
            slotDuration="1:00:00"
            eventsSet={handleEvents}
          /> */}
        </div>
      )}
      {dataStatusConfirm !== false && (
        <DailogBoxui
          open={openStatus}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          endTime={dialogContentConfirm.endTime}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
          dropdownValues={dropdownValues}
          callBackFn={callBackFn}
        />
      )}
    </MaincontentArea>
  );
  {
    /* <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div> */
  }
}
