import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CreateBattery from "./CreateBattery";
import ListBattery from "./ListBattery";
import RecentActivity from "./RecentActivity";
import { Breadcrumb } from "../shared/Breadcrumbs";

const Battery = () => {
  const [tabValue, setTabValue] = useState(1);
  const [batToView, setBatToView] = React.useState(null);
  const [create, setCreate] = useState("Create Battery");

  const handleBatView = (batViewId) => {
    setBatToView(batViewId);
    setTabValue(0);
    setCreate("Edit Battery");
  };

  const handleTabChange = (event, newValue) => {
    setBatToView(null);
    setTabValue(newValue);
    setCreate("Create Battery");
  };

  const handleCancel = () => {
    setTabValue(1);
    setCreate("CreateBattery");
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Battery"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="battery tabs" sx={{margin:"20px 0px"}}>
        <Tab label={create} />
        <Tab label="Battery List" />
      </Tabs>
      {tabValue === 0 ? (
        <CreateBattery batToView={batToView} onCancel={handleCancel} />
      ) : (
        <ListBattery onViewBat={handleBatView} />
      )}
    </Box>
  );
};

export default Battery;
