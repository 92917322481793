import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import InputWrapper from './InputWrapper'
import { useTheme } from '@mui/styles';

const RadioInput = ({ name, handleRadioClick, options = [],defaultVal }) => {
    const theme = useTheme()  
    return (
      <InputWrapper>
        <FormControl>
          <RadioGroup onChange={handleRadioClick} row defaultValue={defaultVal} name={name}>
            {options.map((option, index) => {
              return <FormControlLabel sx={{ fontSize: theme.text.fontSize}} key={index} value={option.value} control={<Radio />} label={option.label} />
            })}
          </RadioGroup>
        </FormControl>
      </InputWrapper>
    )
  }
export default RadioInput