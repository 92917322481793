import React, { useState, useEffect,useRef } from "react";
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event'
import "./style.css";
import "./main.css";
import CalendarService from '../../services/calendarService';
//const RESTRICTED_ID = DOMAIN_DETAIL[0].options[1].value
  export default  function CreateCalendar() {
  const calendarRef = useRef(null);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [initialviewVal, setInitialviewVal] = useState("dayGridMonth");
  const [currentEvents, setCurrentEvents] = useState([]);
  const [initalEvents, setInitalEvents] = useState([]);
  useEffect(async () => {  
    // var payload=[];
    // payload.type="frameware";
    // const calendarlist = (await CalendarService.getGridViewForCalendar(payload)).data;  
    // setInitalEvents(calendarlist); 
  }, []);
  
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  }

  const handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event1');
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect()//clear date selection

    if(title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });    
      setInitialviewVal("timeGridDay");
      let calendarApi1 = calendarRef.current.getApi()
      calendarApi1.changeView('timeGridDay', selectInfo.startStr);
      console.log(calendarApi1.getEvents())
    }
  }

  const handleEventClick = (clickInfo) => {
    //console.log("clickInfo.event"+clickInfo.view.type);
    //console.log(clickInfo.event.target);
    if(clickInfo.view.type=="dayGridMonth"){
      console.log("month clicked");
      // this.setState({
      //   currentEvents: events
      // })
    }else  if(clickInfo.view.type=="timeGridDay"){
       console.log("date clicked");
    }
     //clickInfo.event.remove();
    //  if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {      
    //  }
  }

  const handleEvents = (events) => {
    console.log("events");
    setCurrentEvents(events);
    
  }



function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}


    return (
      <div className='demo-app'>
        <div className='demo-app-sidebar-section'>
        </div>
        <div className='demo-app-main'>
        &nbsp; &nbsp;
        <label>
            <input
              type='checkbox'
              checked={weekendsVisible}
              onChange={handleWeekendsToggle}
            ></input>
            &nbsp;Toggle weekends
          </label>
          <FullCalendar ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView={initialviewVal}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            initialEvents={INITIAL_EVENTS} 
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        </div>
      </div>
    )
 

  

  {/* <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div> */}

}
