import Service from "./service";

const BookingServices = {
  
   getBookingConfirmation : function(data){
        return Service.post("/getMyBookingSlot",data, null);

    },

    listBookingSlot: function(data){
        return Service.post("/listMyBookedSlot",data, null);
    }
}

export default BookingServices;