const SIM_DETAIL = [
    {
      type: 'text',
      label: 'Sim Number',
      name: 'simNumber'
    },
    {
      type: 'select',
      label: 'Status',
      name: 'status',
      options: [
        {
          id: 1,
          title: 'select',
          value:'0'
        },
        {
          id: 2,
          title: 'Active',
          value:'3'
        },
        {
          id: 3,
          title: 'InActive',
          value:'2'
        }
      ]
    }
  ]
  const DOMAIN_DETAIL = [
    {
      type: 'text',
      label: 'First Phone Number',
      name: 'mobileNumber'
      },  {
        type: 'select',
        label: 'First Provider Name',
        name: 'simProviderId',
        options: [
          {
          id: 1,
          title: 'select',
          value: '1'
          },
          {
          id: 2,
          title: 'Airtel',
          value: '2'
          },
          {
          id: 3,
          title: 'Aircel',
          value: '3'
          },
          {
          id: 4,
          title: 'BSNL',
          value: '4'
          },
          {
            id: 5,
            title: 'IDEA',
            value: '5'
          },
          {
            id: 6,
            title: 'Vodafone',
            value: '6'
          }
      
          ]},
      {
      type: 'select',
      label: 'First Plan Name',
      name: 'simPlanId',
      options: [
        {
        id: 1,
        title: 'select',
        value: '1'
        },
        {
        id: 2,
        title: 'TEST',
        value: '2'
        },
        {
        id: 3,
        title: 'BASIC',
        value: '3'
        },
        {
        id: 4,
        title: 'PLUS',
        value: '4'
        }
        ]}
  ]

  const SIM_LIST_DETAIL = [
    {
      type: 'select',
      label: 'Customer',
      name: 'orgState',
      options: [
        {
          id: 1,
          title: 'Customer',
          value:'1'
        },
        {
          id: 2,
          title: 'Fleet',
          value:'2'
        },
        {
          id: 3,
          title: 'Agency',
          value:'3'
        },
        {
          id: 4,
          title: 'Support',
          value:'4'
        }
      ]
    },
    {
      type:'select',
      label: 'Fleet',
      name:'fleet',
      options:[
        {
          id:1,
          title:'All'
        }
      ]
    },
    {
      type:'select',
      label: 'Vehicle',
      name:'Vehicle',
      options:[
        {
          id:1,
          title:'All',
          value:'1'
        }
      ]
    },
    {
      type:'select',
      label: 'Sim Status',
      name:'simStatus',
      options:[
        {
          id:1,
          title:'All',
          value: 1
        },
        {
          id:2,
          title:'TEST',
          value: 2
        },
        {
          id:3,
          title:'INACTIVE',
          value: 3
        },
        {
          id:4,
          title:'ACTIVE',
          value: 4
        },
        {
          id:5,
          title:'SUSPENDED',
          value: 5
        },
        {
          id:6,
          title:'REPAIR',
          value: 6
        },
        {
          id:7,
          title:'EXPIRED',
          value: 7
        },
        {
          id:8,
          title:'CANCELLED',
          value: 8
        },
        {
          id:9,
          title:'DEACTIVATED',
          value: 9
        }
      ]
    }
  ]
  
  const SIM_SECONDARY_DETAIL = [
  {
  type: 'text',
  label: 'Second Phone Number',
  name: 'mobileNumber1'
  },  {
    type: 'select',
    label: 'Second Provider Name',
    name: 'simProviderId1',
    options: [
    {
    id: 1,
    title: 'select',
    value: '1'
    },
    {
    id: 2,
    title: 'Airtel',
    value: '2'
    },
    {
    id: 3,
    title: 'Aircel',
    value: '3'
    },
    {
    id: 4,
    title: 'BSNL',
    value: '4'
    },
    {
      id: 5,
      title: 'IDEA',
      value: '5'
    },
    {
      id: 6,
      title: 'Vodafone',
      value: '6'
    }

    ]},
  {
  type: 'select',
  label: 'Second Plan Name',
  name: 'simPlanId1',
  options: [
  {
  id: 1,
  title: 'select',
  value: '1'
  },
  {
  id: 2,
  title: 'TEST',
  value: '2'
  },
  {
  id: 3,
  title: 'BASIC',
  value: '3'
  },
  {
  id: 4,
  title: 'PLUS',
  value: '4'
  }
  ]}
]



  module.exports={
    SIM_DETAIL, 
    DOMAIN_DETAIL,
    SIM_SECONDARY_DETAIL,
    SIM_LIST_DETAIL
  }

