import { React, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Checkbox,
  Button,
  Divider,
  Stack,
  Grid,
} from "@mui/material/";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

export default function Alert() {
  const [checked, setChecked] = useState(["email"]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Grid Container spacing={0}>
      <Grid item xs={12} md={4}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            bgcolor: "background.paper",
            borderLeft: "0.1em solid #eeeeee",
            padding: "0.8em",
            marginTop: 2,
          }}
          subheader={
            <ListSubheader sx={{ color: "#2196f3", fontWeight: "Bold" }}>
              My Alert Notification Preferences
            </ListSubheader>
          }
        >
          <Divider />

          <ListItem>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>

            <ListItemText id="controlled" primary="Email" />

            <Checkbox
              edge="end"
              onChange={handleToggle("email")}
              checked={checked.indexOf("email") !== -1}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>

            <ListItemText id="controlled" primary="SMS" />

            <Checkbox
              edge="end"
              onChange={handleToggle("sms")}
              checked={checked.indexOf("sms") !== -1}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <NotificationsNoneIcon />
            </ListItemIcon>
            <ListItemText id="controlled" primary="Push" />

            <Checkbox
              edge="end"
              onChange={handleToggle("push")}
              checked={checked.indexOf("push") !== -1}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          </ListItem>
          <Stack
            spacing={1}
            direction="row"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button variant="contained" color="primary" size="small">
              Save
            </Button>
            <Button variant="contained" color="primary" size="small">
              Cancel
            </Button>
          </Stack>
        </List>
      </Grid>
    </Grid>
  );
}
