import React, { useState } from "react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Breadcrumb } from '../shared/Breadcrumbs';
import { Paper, Box, Typography, ListItemText, ListItem, List, ToggleButtonGroup, ToggleButton, Button, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GOOGLE_MAP_KEY } from '../../constants/appConstant';
import { useTheme } from '@mui/styles';
import GeoZoneMarker from "./GeoZoneMarkers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CreateZone from "./CreateZone";
import GeoZoneSearchBox from "./SearchBox";
import CommonDropDown from "../common/commonDropdown";

const data = [
  {
    id: 1,
    name: "Chennai",
    latitude: "13.0826802",
    longitude: "80.2707184",
    radius: 500,
    isChecked: 0
  },
  {
    id: 2,
    name: "Kelambakkam",
    latitude: "12.783791",
    longitude: "80.217800",
    radius: 500,
    isChecked: 1
  },
  {
    id: 3,
    name: "Vandalor",
    latitude: "13.0164878",
    longitude: "79.950334",
    radius: 500,
    isChecked: 0
  }
];
const assignButtonData = [
  {
    id: 1,
    name: 'Assign'
  },
  {
    id: 2,
    name: 'Cancel'
  }
]

const togButton = [
  {
    id: 1,
    name: 'Manage GeoZones'
  },
  {
    id: 2,
    name: 'Assign GeoZones'
  }
]

const manageButtonData = [
  {
    id: 1,
    name: 'Create'
  },
  {
    id: 2,
    name: 'Edit'
  },
  {
    id: 3,
    name: 'Delete'
  }
]

let defaultProps = {}
if (data !== '') {
  defaultProps = {
    center: { lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude) },
    zoom: 10
  }
} else {
  defaultProps = {
    center: { lat: 21.28, lng: 78.83 },
    zoom: 5
  }
}
const containerStyle = {
  height: "65vh",
  width: "75%",
  float: "left",
  top: "50px"
};

const libraries = ["drawing", "places", "geometry"];

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '10px'
}));

const MuiAccordion = styled(Accordion)(({ theme }) => ({
  width: '12vw',

}));

const MuiAccordionSummary = styled(AccordionSummary)
  (({ theme }) => ({
    flexDirection: "row-reverse",
    minHeight: '20px !important',
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      marginLeft: '0px'
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1)
    },

  }));

const GeoZone = () => {
  const theme = useTheme()
  const [alignment, setAlignment] = useState("Manage GeoZones");
  const [_data, set_data] = useState(data);
  const [expanded, setExpanded] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleChangeExpand = () => {
    setExpanded(!expanded);
  };

  const ButtonAlign = styled(Button)(
    () => ({
      textTransform: "none",
      backgroundColor: '#374850',
      color: 'white',
      fontSize: '12px',
      marginRight: '5px',
      padding: '1px 12px'
    }))
  const buttonText = {
    textTransform: "none",
    fontSize: theme.text.fontSize,
    fontColor: 'black'
  }

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    set_data(data)
    setIsCreate(false)
    setIsEdit(false)
  };

  const handleListGeozoneClick = (zone) => {
    if (alignment === 'Manage GeoZones') {
      set_data([zone])
    }
  };

  const handleManageTab = (name) => {
    console.log(name)
    if (name === 'Create') {
      setIsCreate(true);
      setIsEdit(false);
      set_data([])
    } else if (name === 'Edit') {
      setIsCreate(false);
      setIsEdit(true);
    }
  };

  const handleAssignTab = (id) => {

  };

  const handleSubmit = (formData) => {
    console.log({formData})
  }

  return (
    <>
      <Box sx={{ padding: '15px' }}>
        <Breadcrumb items={["Tracking", "Geo Zones"]} />
        <CommonDropDown  onSubmit={handleSubmit}/>
        <Paper elevation={5} square sx={{ height: '95vh' }}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            sx={{ m: 1, height: 10 }}
          >
            {togButton.map((bts, index) => <ToggleButton
              key={index}
              sx={buttonText}
              value={bts.name}>
              {bts.name}
            </ToggleButton>)
            }
            {alignment === 'Manage GeoZones' && !isCreate ? manageButtonData.map((manageBtList, i) => <Box
              key={i}
              sx={{ marginLeft: '15vw' }} ><ButtonAlign
                type="submit"
                variant="contained" onClick={() => handleManageTab(manageBtList.name)}>
                {manageBtList.name}</ButtonAlign>
            </Box>) : !isCreate && (assignButtonData.map((assignBtList, i) => <Box
              key={i}
              sx={{ marginLeft: '15vw' }} onClick={() => handleAssignTab(i)}><ButtonAlign
                type="submit"
                variant="contained" >{assignBtList.name}</ButtonAlign>
            </Box>
            ))}
          </ToggleButtonGroup>
          {isCreate && (
            <CreateZone />
          )}
          <Typography borderBottom={theme.title.borderLine} sx={{ height: '2vh' }} />
          <LoadScript
            googleMapsApiKey={GOOGLE_MAP_KEY}
            libraries={libraries}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={defaultProps.center}
              zoom={defaultProps.zoom}
            >
              <Demo>
                <MuiAccordion
                  expanded={expanded}
                  onChange={handleChangeExpand}
                >
                  <MuiAccordionSummary sx={{ bgcolor: '#374850', height: '40px' }} expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "15px", color: 'white' }} />}>
                    <Typography sx={{ fontSize: "12px", fontWeight: 'bold', color: 'white' }}>List of GeoZones</Typography>
                  </MuiAccordionSummary>
                  <AccordionDetails sx={{ padding: '-1px important' }}>
                    <List>
                      {data.map(zoneValue => (
                        <ListItem key={zoneValue.id} onClick={() => handleListGeozoneClick(zoneValue)}>
                          {alignment === 'Assign GeoZones' && (
                            <Checkbox checked={zoneValue.isChecked == 1 ? true : false} />
                          )}
                          <ListItemText
                            disableTypography
                            primary={<Typography style={{ fontSize: "12px" }}>{zoneValue.name}</Typography>}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </MuiAccordion>
              </Demo>
              <GeoZoneSearchBox />
              <GeoZoneMarker values={_data} />
            </GoogleMap>
          </LoadScript>
        </Paper>
      </Box>
    </>
  )
}

export default GeoZone

