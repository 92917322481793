import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
//import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import CircularProgress from "@mui/material/CircularProgress";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#bbdefb",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, value) {
  return { name, value };
}

const rows = [
  createData("slot", "sl1"),
  createData("slot status", "available"),
  createData("Battery status", "Not available"),
  createData("slot voltage", "45v"),
  createData("slot capacity", "45Ah"),
];

const battery = [
  createData("BatteryId", "16"),
  createData("Battery Model", "Model B"),
  createData("Battery Voltage", "48V"),
  createData("Battery Capacity", "25Ah"),
];

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{ color: "green" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function SortDetails({ item }) {
  
  const [progress, setProgress] = React.useState(20);
  
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 20
      );
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // let padding = '';
  // if(item.slotStatus === 'Available'){
  //    padding = {};
  // }else{
  //    padding = {paddingBottom:"0%"};
  // }
  return (
    <Paper elevation={5}>
    <Box
      sx={{
        ml: 2,
        mt: 3,
        pb: 4
      }}
    >
    <Grid container spacing={1} alignItems="center" justifyContent="center">
   
    <Grid item  xs={6}>
      <Grid container  sx={{paddingBottom:"0%"}}>
      <Grid item xs={2} ></Grid>
      <Grid item xs={10} alignItems="center" justifyContent="center">
          <Typography sx={{ color: "#2979ff" }}>
              &nbsp;Slot Details
            </Typography>
            </Grid>
        </Grid>
         <Grid container>
          <Grid item xs={2} sx={{paddingLeft:"3.5%"}}>
          {/* {item.slotStatus==="Charged" && <img src={BatteryCharged}></img>}
          {item.slotStatus==="Slot Empty" && <img src={SlotEmpty}></img>}
          {item.slotStatus==="Fault" && <img src={BatteryFault}></img>}
          {item.slotStatus==="Charging" && <img src={BatteryCharging}></img>}
          {item.slotStatus==="LowCharge" && <img src={LowBattery}></img>} */}
          </Grid>
          <Grid item xs={9} sx={{paddingTop:"2%"}}>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", Bottom: 2 }}
            >
              <Table>
                <TableBody>
                    <StyledTableRow>
                      {/* <StyledTableCell component="th" scope="row">
                        {item.slotStatus}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.slotId}
                      </StyledTableCell> */}
                      </StyledTableRow>
                      <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      Slot Id
                      </StyledTableCell>
                     
                      <StyledTableCell align="right">
                        {item.slotId}
                      </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      Slot Number
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.slotNumber}
                      </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      Slot Voltage
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.slotVoltage}
                      </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      Slot capacity
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.slotCapacity}
                      </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      Slot Battery Voltage
                      </StyledTableCell>
                    
                      <StyledTableCell align="right">
                        {item.slotBatteryVoltage}
                      </StyledTableCell>
                    </StyledTableRow>
                    
                 </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
     </Grid>
     {item.slotStatus!=="Slot Empty" && 
    <Grid item xs={6}>
    <Grid container spacing={2} sx={{paddingBottom:"2%",marginTop:"0.1%"}}>
    <Grid item xs={2}></Grid>
   
      <Grid item xs={10}>
    <Typography sx={{ marginLeft: "0px", color: "#2979ff" }}>
          Battery Details
        </Typography>
    </Grid>

    </Grid>
   
        <Grid container spacing={2} sx={{paddingBottom:"2%"}}>
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ width: "93%", marginBottom: 2,paddingLeft:"-6%" }}
            >
              <Table>
                <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        BatteryId
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.batteryId}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Battery Model
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.batteryModel}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Battery Name
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.batteryName}
                      </StyledTableCell>
                    </StyledTableRow>
                    
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Battery Voltage
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.slotBatteryVoltage}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Battery Capacity
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.batteryCapacity}
                      </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>     
    </Grid>   
    } 
    </Grid>
    </Box>
   </Paper>
  );
}
