
import Service from "./service";

const vehicleServices = {
    createVehicle: (data) => {
        return Service.post('vehicle/createVehicle',data,null)
    },

    listVehicle: (resource) => {
        return Service.post('vehicle/listVehicle',resource,null)
    },
    updateVehicle: (data)=>{
        return Service.post('vehicle/updateVehicle',data,null)
    },
    getVehicle: (id)=>{
        return Service.post('vehicle/getVehicle',{id})
    },
    deleteVehicle: (id)=>{
        return Service.post('vehicle/deleteVehicle',{id})
    }

}

export default vehicleServices