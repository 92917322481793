
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    //start: todayStr
    start: '2022-10-19T13:00:00',
    end: '2022-10-20T18:00:00',
    color:'green'
  },
  {
    id: createEventId(),
    title: 'Timed event',
    //start: todayStr + 'T12:00:00'
    start: '2022-10-21T13:00:00',
    end: '2022-10-21T13:30:00',
    color:'red'
  },
    {
      id: 3,
    "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"4 Free Slots",
    "start":"2022-10-07",
    "end":"2022-10-07",
    "description":"",
    "color":"green"
  }
    ,{
      id: 4,
      "type":"slots",    
    "title":"2 Booked Slots",
    "start":"2022-10-07",
    "end":"2022-10-07",
    "description":"",
    "color":"red"}

    ,{
      id: 5,
      "type":"slots",    
    "title":"Slot 1",
    "start":"2022-10-07T08:00:00",
    "end":"2022-10-07T09:30:00",
    "description":"",
    "color":"red"}
    ,{
      id: 6,
      "type":"slots",
    
    "title":"Slot 5",
    "start":"2022-10-07T12:00:00",
    "end":"2022-10-07T12:30:30",
    "description":"",
    "color":"red"}

    , {"type":"slots",
    
    "title":"6 Total Slots",
    "start":"2022-10-07",
    "end":"2022-10-07",
    "description":"",
    "color":"#2196F3"}  

    ,{
      id: 7,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"Offer Available",
    "start":"2022-10-08",
    "end":"2022-10-08",
    "description":"",
    "color":"orange"}

    ,{
      id: 8,
      "type":"slots",
    "url":"",
    "title":"2 Free Slots",
    "start":"2022-10-08",
    "end":"2022-10-08",
    "description":"",
    "color":"green"}

    ,{
      id: 9,
      "type":"slots",
    
    "title":"Slot1",
    "start":"2022-10-18T13:00:00",
    "end":"2022-10-18T13:30:00",
    "description":"Slot1",
    "color":"red"}


    ,{
      id: 10,
      "type":"slots",
    
    "title":"Slot3",
    "start":"2022-10-18T06:00:00",
    "end":"2022-10-18T06:30:00",
    "description":"Slot3",
    "color":"red"}


    ,{
      id: 11,
      "type":"slots",
    
    "title":"Slot4",
    "start":"2022-10-18T17:00:00",
    "end":"2022-10-18T17:30:00",
    "description":"Slot4",
    "color":"red"}

    ,{
      id: 12,
      "type":"slots",
    
    "title":"Slot2",
    "start":"2022-10-18T09:00:00",
    "end":"2022-10-18T09:30:00",
    "description":"Slot2",
    "color":"red"}

    ,{
      id: 13,
      "type":"slots",
    
    "title":"4Booked Slots",
    "start":"2022-10-18",
    "end":"2022-10-18",
    "description":"Slot1",
    "color":"red"}

    ,{
      id: 14,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-09",
    "end":"2022-10-09",
    "description":"",
    "color":"green"}

    ,{
      id: 15,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-09",
    "end":"2022-10-09",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 17,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"4 Free Slots",
    "start":"2022-10-10",
    "end":"2022-10-10",
    "description":"",
    "color":"green"}

    ,{
      id: 18,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-10",
    "end":"2022-10-10",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 19,
      "type":"slots",
    
    "title":"Slot1",
    "start":"2022-10-10T13:00:00",
    "end":"2022-10-10T13:30:00",
    "description":"Slot1",
    "color":"red"}


    ,{
      id: 20,
      "type":"slots",
    
    "title":"Slot3",
    "start":"2022-10-10T06:00:00",
    "end":"2022-10-10T06:30:00",
    "description":"Slot3",
    "color":"red"}


    ,{ 
      id: 21,      
      "type":"slots",
      "title":"Slot4",
    "start":"2022-10-10T17:00:00",
    "end":"2022-10-10T17:30:00",
    "description":"Slot4",
    "color":"red"}

    ,{
      id: 22,
      "type":"slots",
    
    "title":"Slot1",
    "start":"2022-10-10T09:00:00",
    "end":"2022-10-10T09:30:00",
    "description":"Slot des1",
    "color":"orange"}


    ,{
      id: 23,
      "type":"slots",
    "title":"Slot2",
    "start":"2022-10-10 09:00:00",
    "end":"2022-10-10 09:30:00",
    "description":"Slot des2",
    "color":"gray"}
    ,{
      id: 26,
      "type":"slots",
    
    "title":"Slot3",
    "start":"2022-10-10T09:00:00",
    "end":"2022-10-10T09:30:00",
    "description":"Slot des1",
    "color":"orange"}


    ,{
      id: 27,
      "type":"slots",
    
    "title":"Slot4",
    "start":"2022-10-10T09:00:00",
    "end":"2022-10-10T09:30:00",
    "description":"Slot des2",
    "color":"red"}

    ,{
      id: 24,
      "type":"slots",
    
    "title":"4 Booked Slots",
    "start":"2022-10-10",
    "end":"2022-10-10",
    "description":"Slot2",
    "color":"red"}

    ,{
      id: 25,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-11",
    "end":"2022-10-11",
    "description":"",
    "color":"green"}

    ,{
      id: 26,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-11",
    "end":"2022-10-11",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 27,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-12",
    "end":"2022-10-12",
    "description":"",
    "color":"green"}

    ,{
      id: 28,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-12",
    "end":"2022-10-12",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 29,
      "type":"slots",
    "url":"",
    "title":"Offer Available",
    "start":"2022-10-12",
    "end":"2022-10-12",
    "description":"",
    "color":"orange"}


    ,{
      id: 30,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-13",
    "end":"2022-10-13",
    "description":"",
    "color":"green"}

    ,{
      id: 31,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-13",
    "end":"2022-10-13",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 32,
      "type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"4 Free Slots",
    "start":"2022-10-14",
    "end":"2022-10-14",
    "description":"",
    "color":"green"}

    ,{
      id: 33,
      "type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-14",
    "end":"2022-10-14",
    "description":"",
    "color":"2196F3"}

    ,{
      id: 34,
      "type":"slots",
    
    "title":"Slot3",
    "start":"2022-10-14T15:00:00",
    "end":"2022-10-14T15:30:00",
    "description":"Slot1",
    "color":"red"}


    ,{
      id: 35,
      "type":"slots",
    
    "title":"Offers",
    "start":"2022-10-14T09:00:00",
    "end":"2022-10-14T09:30:00",
    "description":"Slot3",
    "color":"orange"}

    ,{"type":"slots",
    
    "title":"Slot6",
    "start":"2022-10-14T08:00:00",
    "end":"2022-10-14T08:30:00",
    "description":"Slot3",
    "color":"red"}


    ,{"type":"slots",
    
    "title":"Slot4",
    "start":"2022-10-14T19:00:00",
    "end":"2022-10-14T19:30:00",
    "description":"Slot4",
    "color":"red"}

    ,{"type":"slots",
    
    "title":"Slot8",
    "start":"2022-10-14T11:00:00",
    "end":"2022-10-14T11:30:00",
    "description":"Slot2",
    "color":"red"}

    ,{"type":"slots",
    
    "title":"4 Booked Slots",
    "start":"2022-10-14",
    "end":"2022-10-14",
    "description":"Slot2",
    "color":"red"}


    ,{"type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-15",
    "end":"2022-10-15",
    "description":"",
    "color":"green"}

    ,{"type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-15",
    "end":"2022-10-15",
    "description":"",
    "color":"2196F3"}

    ,{"type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-16",
    "end":"2022-10-16",
    "description":"",
    "color":"green"}

    ,{"type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-16",
    "end":"2022-10-16",
    "description":"",
    "color":"2196F3"}


    ,{"type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"8 Free Slots",
    "start":"2022-10-17",
    "end":"2022-10-17",
    "description":"",
    "color":"green"}

    ,{"type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-17",
    "end":"2022-10-17",
    "description":"",
    "color":"2196F3"}


    ,{"type":"slots",
    "url":"https://portal.evzap.in/",
    "title":"4 Free Slots",
    "start":"2022-10-18",
    "end":"2022-10-18",
    "description":"",
    "color":"green"}

    ,{"type":"slots",
    
    "title":"8 Total Slots",
    "start":"2022-10-18",
    "end":"2022-10-18",
    "description":"",
    "color":"2196F3"}

    ,{"type":"slots",
    "url":"",
    "title":"Offer Available",
    "start":"2022-10-18",
    "end":"2022-10-18",
    "description":"",
    "color":"orange"}

    

]

export function createEventId() {
  return String(eventGuid++)
}
