import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import CreateSwapStation from "./CreateSwapStation";
import ListSwapStation from "./ListSwapStation";
import RecentActivity from "./RecentActivity";
import Settings from "./SettingsIndex";
import { Breadcrumb } from "../shared/Breadcrumbs";

const SwapStation = () => {
  const [swapToEdit, setSwapToEdit] = React.useState(null);
  const [tabValue, setTabValue] = useState(2);
  const [create, setCreate] = useState("Create Station");
  const [settings, setSettings] = useState(0);
  const [swapToView, setSwapToView] = React.useState("");

  const handleSwapEdit = (swapEditedId) => {
    setSwapToEdit(swapEditedId);
    setTabValue(1);
    setCreate("Edit Station");
    setSettings(1);
    setSwapToView("");
  };

  const handleSwapView = (swapViewId) => {
    setSwapToView(swapViewId);
    setTabValue(1);
    setCreate("View Station");
    setSettings(1);
  };

  const handleTabChange = (event, newValue) => {
    setSwapToEdit(swapToEdit !== null ? swapToEdit : null);
    setSwapToView(swapToView !== null ? swapToView : null);
    setTabValue(newValue);
    if (newValue === 2) {
      setSwapToEdit(null);
      setSwapToView(null);
    }
    setCreate("Create Station");
    setSettings(0);
  };

  const handleCancel = () => {
    setCreate("Create Station");
    setSettings(0);
    setTabValue(2);
  };

  const handleSetngsCancel = () => {
    setCreate("Create Station");
    setSettings(0);
    setTabValue(2);
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Station"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="station tabs" sx={{margin:"20px 0px"}}>
        <Tab label="Settings" />
        <Tab label={create} />
        <Tab label="Station List" />
      </Tabs>
      {tabValue === 0 && (
        <Settings
          swapToEdit={swapToEdit}
          onCancel={handleCancel}
          handleSetngsCancel={handleSetngsCancel}
        />
      )}
      {tabValue === 1 && (
        <CreateSwapStation
          swapToEdit={swapToEdit}
          onCancel={handleCancel}
          swapToView={swapToView}
        />
      )}
      {tabValue === 2 && (
        <ListSwapStation
          onEditSwap={handleSwapEdit}
          onViewSwap={handleSwapView}
        />
      )}
    </Box>
  );
};

export default SwapStation;

