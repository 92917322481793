import React from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './themelist';
import { StylesProvider } from '@mui/styles';

const ThemeProviderWrapper = (props) => {

  const theme = themeCreator();

  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider >
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;