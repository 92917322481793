import  React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import {Grid, Button,} from '@mui/material';
import { useTheme } from '@mui/styles';
import TextInput from '../shared/TextInput';
import DraggableDialog from '../shared/DailogDrag';
import RadioInput from '../shared/RadioInput';
import SelectInput from '../shared/SelectInput';
import DatePicker from '../shared/DatePicker';
import FeatureService from '../../services/featureService';
import RoleSetttingService from '../../services/roleSetttingService';


import moment from 'moment';
import Checkbox from "@mui/material/Checkbox";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import MinimizeIcon from '@mui/icons-material/Minimize';

import { styled } from '@mui/material/styles';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const RESTRICTED_ID = 1;
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin:'-0.1%',
  '&:not(:last-child)': {
    borderBottom: 0,
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      :'rgba(32,177,162,1)',
  flexDirection: 'row-forward',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FormPropsTextFields({featureToEdit, onCancel}) {
  const [dataStatus,setDataStatus]=useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false)  
  const [dialogContent, setDialogContent] = useState({})  
  const [dialogContentConfirm, setDialogContentConfirm] = useState({})  
  const [open,setOpen] =useState(false);
  const [feature, setFeature] = useState(null);
  const [reference, setReference] = useState([]);
  const [listallfeature, setListallfeature] = useState([]);
  const [selectedExpertArr, setSelectedExpertArr] = useState([]);  
  const [selectedButtonArr, setSelectedButtonArr] = useState([]);  
  const [count,setCount]= useState(0);

  const [expandVal, setExpandVal] = useState(false);

  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 2;
  var globalResellerId=2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId=globalJson.resellerId;
  useEffect( ()=>{ 
    setCount(count+1);
    if(Object.entries(featureToEdit).length !== 0){      
      var dataEdit={};
      dataEdit.orgId=featureToEdit.orgId;
      dataEdit.roleId=featureToEdit.roleId;
    
      var request={};
      request.resellerId=globalResellerId;
      request.orgId=globalOrgId;
      request.roleId=globalRoleId;
      request.page=0;
      request.size=100;
      request.searchText="";     
      RoleSetttingService.getEditRoleFeature(dataEdit)       
      .then(res1 => {  
        var clonedExpertArr =[]; 
        var clonedExpertArrBtn=[];
        var sltdExt=[];
          sltdExt=res1.data.dataResponse;
            for(var i=0;i<sltdExt.length;i++){
              clonedExpertArr.push(sltdExt[i].linkfeatureId);
              if(null !==sltdExt[i].buttonoperation && ""!==sltdExt[i].buttonoperation){
                for(var j=0;j<sltdExt[i].buttonoperation.split(",").length;j++){
                  clonedExpertArrBtn.push(sltdExt[i].linkfeatureId+"_"+sltdExt[i].buttonoperation.split(",")[j]);
                }
              }
            }
            setSelectedButtonArr(clonedExpertArrBtn);
            setSelectedExpertArr(clonedExpertArr);
            RoleSetttingService.getFeaturListAll(request)
            .then(res => {        
              setListallfeature(res.data.main);
            }).catch(err => {
            });
          }).catch(err=>{
          });
   }else{
    setFeature(null);
    var request={};
    // request.resellerId=10;
    // request.orgId=1;
    // request.roleId=1;
    request.resellerId=globalResellerId;
      request.orgId=globalOrgId;
      request.roleId=globalRoleId;
    request.page=0;
    request.size=100;
    request.searchText="";
    var dataEdit={};
    dataEdit.orgId=globalOrgId;
    dataEdit.roleId=globalRoleId;
    RoleSetttingService.getEditRoleFeature(dataEdit)       
     .then(res1 => {  
      var clonedExpertArr =[]; 
      var clonedExpertArrBtn=[];
      var sltdExt=[];
        sltdExt=res1.data.dataResponse;
          for(var i=0;i<sltdExt.length;i++){
            clonedExpertArr.push(sltdExt[i].linkfeatureId);
            if(null !==sltdExt[i].buttonoperation && ""!==sltdExt[i].buttonoperation){
              for(var j=0;j<sltdExt[i].buttonoperation.split(",").length;j++){
                clonedExpertArrBtn.push(sltdExt[i].linkfeatureId+"_"+sltdExt[i].buttonoperation.split(",")[j]);
              }
            }
          }
          setSelectedButtonArr(clonedExpertArrBtn);
          setSelectedExpertArr(clonedExpertArr);
         
      RoleSetttingService.getFeaturListAll(request)
      .then(res => {        
        setListallfeature(res.data.main);
      }).catch(err => {
      });
    }).catch(err=>{
    });
  }  
  }, []);

  const theme = useTheme()
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: 'none',
    color: '#fff',
    width:"100px",
    m:1
  }

  const FormLayout = ({ children, title }) => {
    return (
      <Box sx={{ marginY: '20px', '& .MuiOutlinedInput-root':{height:'25px'}, 
      '& .MuiFormControlLabel-label':{fontSize: theme.text.fontSize},
      '& .MuiSvgIcon-root':{ height:'15px', width:'15px'} }}>
        <fieldset style={{ border: '1px solid black' }}>
          <legend style={{fontWeight: 600}}> {title} </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    )
  }

  const handleSubmit = (e) => {
    if(!featureToEdit){
      e.preventDefault();    
      const { target } = e;      
      saveDeviceDetails(e,Object.fromEntries(new FormData(target)))
    }else{
      e.preventDefault();
      const { target } = e;
      updateDeviceDetails(e,Object.fromEntries(new FormData(target)))
    }
  }

  const handleCancelClick = () => {    
    onCancel()
  }

  const saveDeviceDetails = async (e,obj) => {    
    let clonedExpertArr = [...selectedExpertArr];  
    var arryStr;
    for(var i=0;i<clonedExpertArr.length;i++){
      if(i===0){
        arryStr=clonedExpertArr[i];
      }else{
        arryStr=arryStr+","+clonedExpertArr[i];
      }      
    }
    obj.buttonoperation= arryStr;
    const responseData=  (await FeatureService.saveFeatureDetails(obj))
    
    var obj1={};
    if(responseData.data.resultText==="Success"){
      obj1.dialogButtonText="Saved Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";
      obj1.dialogText="Data Saved Successfully";     
    }else{
      obj1.dialogButtonText="Saved Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";  
      obj1.dialogText="Data Not Saved Properly";
    }
    setDataStatus(true);   
    setDialogContent(obj1);
    setDataStatusConfirm(false);   
    setOpen(true);  
    if(responseData.status === 200){
      setTimeout(
        () => onCancel(), 
         2000
       );   
    }     
  }

  const updateDeviceDetails = async (e,obj) => {
    e.preventDefault();    
    let clonedExpertArr = [...selectedExpertArr];  
    var arryStr;
    for(var i=0;i<clonedExpertArr.length;i++){
      if(i===0){
        arryStr=clonedExpertArr[i];
      }else{
        arryStr=arryStr+","+clonedExpertArr[i];
      }      
    }
    obj.buttonoperation= arryStr;
    const responseData=(await FeatureService.updateFeatureDetails(obj))
    var obj1={};  
      obj1.dialogButtonText="Update Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";
      obj1.dialogText=responseData.data.resultText;   
      setDataStatus(true);     
      setDialogContent(obj1);
      setDataStatusConfirm(false);   
      setOpen(true);    
      if(responseData.status === 200){
        setTimeout(
          () => onCancel(), 
           2000
         );   
      }      
  }
  const FormFields = ({ fields }) => {    
    return (<>
      {
        fields.map((field, index) => {
          if (field.type === 'text') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput label={field.label} name={field.name} defaultValue={feature!==null?feature[field.name]:''} />
              </Grid>
            )
          }   
          else if (field.type === 'select') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                {
                <SelectInput name={field.name} label={field.label} options={field.options} defaultValue={feature!==null?(feature[field.name]):(field.options[0].value)}  />             
                }
              </Grid>
            )
          }
          else if (field.type === 'radio') {
            return(
            <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <RadioInput name={field.name} options={field.options} defaultVal={feature!==null?feature[field.name]:"1"} />  
            </Grid>
            )
          }
          else if(field.type='date'){
            return(
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <DatePicker name={field.name} label={field.label} defaultValue={feature===null?Date():moment(feature[field.name],"DD/MM/YYYY") } />  
              </Grid>
            )
          }
        })
      }
    </>
    )
  }
  
  const handleSbmtClose =  () => {      
  }
  const handleClearCls=()=>{   
    setOpen(false);
  }
 
  const [expanded, setExpanded] = React.useState('panel0');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setExpandVal(newExpanded ? panel : false);
  };

  const handleSelectedButtons = (e,id) => {   
    let clonedExpertArr = [...selectedButtonArr];  
    if(e.target.checked === true) {
      clonedExpertArr.push((e.target.value));     
      setSelectedButtonArr(clonedExpertArr);     
    } else {    
      let filtered = clonedExpertArr.filter((expert) => {
          return expert !== (e.target.value);
      });     
      setSelectedButtonArr(filtered); 
      clonedExpertArr=[];
      for(var i=0;i<filtered.length;i++){
        clonedExpertArr.push(filtered[i]);
      }
    }
  }


  const handleSelectedExperts = (e) => {      
    let clonedExpertArr = [...selectedExpertArr];  
    if(e.target.checked === true) {
      clonedExpertArr.push(e.target.value);     
      setSelectedExpertArr(clonedExpertArr);     
    } else {    
      let filtered = clonedExpertArr.filter((expert) => {
          return expert !== (e.target.value);
      });     
      setSelectedExpertArr(filtered);      
    }
    e.preventDefault(); 
  }

  const saveCommon=(e,obj,submunu)=>{
    var array=[];
    var newMainObj={};
        newMainObj.linkfeatureId=obj.linkfeatureId;
        newMainObj.id=obj.id;
        newMainObj.status=obj.status;
        newMainObj.roleId=globalRoleId;
        newMainObj.orgId=globalOrgId;
        newMainObj.featureName=obj.featureName;
        newMainObj.link=obj.link;
        newMainObj.buttonoperation="";
        newMainObj.status="Active";
    submunu.push(newMainObj);
    var bntVal=[];
    var fnlValChked=[];
    for(var i=0;i<submunu.length;i++){
      array.push(submunu[i].linkfeatureId);
      if(null!==submunu[i].buttonoperation && ""!==submunu[i].buttonoperation && undefined!==submunu[i].buttonoperation){
        for(var j=0;j<submunu[i].buttonoperation.split(",").length;j++){
            bntVal.push(submunu[i].linkfeatureId+"_"+submunu[i].buttonoperation.split(",")[j]);
        }
      }
    }
    let clonedExpertArr = [...selectedExpertArr];  
    let newArray=[];
    for(var j=0;j<clonedExpertArr.length;j++){
      for(var i=0;i<array.length;i++){
        if(array[i]===clonedExpertArr[j]){
          newArray.push(array[i]);
        }
      }
    }
   
    let clonedExpertArrBtn = [...selectedButtonArr];     
    let newArrayBtn=[];
    for(var j=0;j<clonedExpertArrBtn.length;j++){
      for(var i=0;i<bntVal.length;i++){
        if(bntVal[i]===clonedExpertArrBtn[j]){
          newArrayBtn.push(bntVal[i]);
        }
      }
    }   
    var fnlSendArry=[];
    for(var i=0;i<submunu.length;i++){
      submunu[i].status=0;
      for(var j=0;j<newArray.length;j++){
        if(submunu[i].linkfeatureId===newArray[j]){
          submunu[i].status=1;   
        }
      }
      fnlSendArry.push(submunu[i]);
    }

    for(var i=0;i<fnlSendArry.length;i++){
      var bntnAdd="";
    for(var j=0;j<newArrayBtn.length;j++){
      if(newArrayBtn[j].split("_")[0]===fnlSendArry[i].linkfeatureId){
        if(j===0){
          bntnAdd=newArrayBtn[j].split("_")[1];
        }else{
          if(""===bntnAdd){
            bntnAdd=newArrayBtn[j].split("_")[1];
          }else{
            bntnAdd=bntnAdd+","+newArrayBtn[j].split("_")[1];
          }          
        }
      }
    }
    fnlSendArry[i].buttonoperation=bntnAdd;
    }

    var fnlSendArryObj=[];
    for(var i=0;i<fnlSendArry.length;i++){
      var newMainObj={};
      newMainObj.linkfeatureId=fnlSendArry[i].linkfeatureId;
      newMainObj.id=fnlSendArry[i].id;
      newMainObj.status=fnlSendArry[i].status;
      newMainObj.roleid=globalRoleId;
      newMainObj.orgid=globalOrgId;
      newMainObj.featureName=fnlSendArry[i].featureName;
      newMainObj.link=fnlSendArry[i].link;
      newMainObj.buttonoperation=fnlSendArry[i].buttonoperation;
      fnlSendArryObj.push(newMainObj);
    }
    var jsonobj={};
    jsonobj.featureForm=fnlSendArryObj;

    RoleSetttingService.getSaveRoleFeature(jsonobj)       
    .then(res1 => {

      var obj1={};
      if(res1.data.resultText==="Success"){
        obj1.dialogButtonText="Saved Status";
        obj1.submitButtonText="";
        obj1.closeButtonText="Close";
        obj1.dialogText="Data Saved Successfully";     
      }else{
        obj1.dialogButtonText="Saved Status";
        obj1.submitButtonText="";
        obj1.closeButtonText="Close";  
        obj1.dialogText="Data Not Saved Properly";
      }
      setDataStatus(true);   
      setDialogContent(obj1);
      setDataStatusConfirm(false);   
      setOpen(true);  
      if(res1.status === 200){
        setTimeout(
          () => onCancel(), 
           2000
         );   
      }

    }).catch(err=>{
    });
  }
  function renderSwitch(param) {
    switch(param) {
      case "1":
        return 'create';
      case "2":
        return 'view';
      case "3":
        return 'edit';
      case "4":
        return 'deleteop';
      case "5":
        return 'excel';
      case "6":
        return 'pdf';
      case "7":
        return 'assign';
      case "8":
        return 'unassign';
      case "9":
        return 'importfunction';
      case "10":
        return 'actionsimstatus';
      case "12":
        return 'changeplan';
      case "13":
        return 'drivertoggle';
      case "14":
        return 'createvehicle';
      case "15":
        return 'firmwareupdate';
      case "16":
        return 'deviceConfigUpdate';
      case "17":
        return 'deviceRegistration';
      case "18":
        return 'recentActivation';
      case "19":
        return 'CancelledLicence';
      case "20":
        return 'DownloadLicence';
      case "21":
        return 'ImportDevicewithLicence';
      case "22":
        return 'DealerOperator';
      case "23":
        return 'helpdeskDashboard';
      case "24":
        return 'UserActivation';
      case "25":
        return 'MultipleOTA';
      case "31":
        return 'CreateCCO';
      case "34":
        return 'oneYearReport';
      case "37":
        return 'vehicleRating';      
      default:
        return 'create';
    }
  }
  return (
    <div> 
        <Box sx={{ paddingLeft:'2.5%',paddingRight:'2.5%', '& .MuiOutlinedInput-root':{height:'22px'}, 
      '& .MuiFormControlLabel-label':{fontSize: theme.text.fontSize},
      '& .MuiSvgIcon-root':{ height:'15px', width:'15px'} }}> 
    {listallfeature.length>0 && listallfeature.map((dataObj, index) => (
      <div
        className="text"
        key={dataObj.id}
        >      
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange('panel'+index)} sx={{paddingBottom:"0.10%",lineHeight:"1.2px"}}>  
        <AccordionSummary aria-controls={"panel"+index} id={"panel"+index} sx={{color:"white",minHeight:"18px !important",borderRadius:"4px",paddingLeft: "3px !important",paddingTop:"0px !important",paddingBottom:"0px !important"}}>
          <Typography sx={{fontWeight:"200",lineHeight:"1.2"}}>  {dataObj.featureName.toUpperCase()} 
          </Typography>
          {expanded === "panel"+index  &&<MinimizeIcon  sx={{
                    marginLeft: "auto",
                    marginTop:"-0.3%",                                       
                    color: "#f0dfdf",                    
                    transform: "scale(1.6)",
                    fontSize: "90",
                    fontWeight:"bold",
                    strokeWidth: 3                    
                  }}
                    // style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" )) ? "none" : 'block' }}
                  />
                  }
                  {(expanded !== "panel"+index) && <AddTwoToneIcon sx={{
                    marginLeft: "auto",
                    marginTop:"0.1%",
                    pointerEvents: "auto",
                    transform: "scale(1.6)",
                    color: "#f0dfdf",
                    fontSize: "90",
                    fontWeight:"bolder !important" ,
                    strokeWidth: 3
                  }}
                    //style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" )) ? "none" : 'block' }}
                  />}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Grid container  sx={{marginLeft:"1%",marginTop:"0.5%"}}>
        <Grid item  xs={11}>    
                  <Checkbox 
                      checked={selectedExpertArr.length>0?((selectedExpertArr.indexOf((dataObj.linkfeatureId)) > -1)? true : false):false } 
                      name={"checkbox"+dataObj.linkfeatureId}
                      onChange={(e)=>handleSelectedExperts(e)}
                      value={dataObj.linkfeatureId || ""}                          
                  />
            {dataObj.featureName}
          </Grid> 
          <Grid item xs={1} sx={{paddingTop:"0.4%"}}>    
             <button style={{background:"#1D8C9F",borderColor:"#1D8C9F",color:"white",fontSize:"87%"}} type='button' onClick={(e)=>{saveCommon(e,dataObj,dataObj.sub)}}>Save</button>
          </Grid> 
      </Grid>
          {dataObj.sub.length>0 && dataObj.sub.map((obj, index) => (
      <div
        className="text"
        key={obj.id}
        >     
        <Grid container  sx={{marginLeft:"1%",marginTop:"0.5%"}}>
        <Grid item xs={2}>    
                  <Checkbox 
                      checked={selectedExpertArr.length>0?((selectedExpertArr.indexOf((obj.linkfeatureId)) > -1)? true : false):false } 
                      name={"checkbox"+obj.linkfeatureId}
                      onChange={(e)=>handleSelectedExperts(e)}
                      value={obj.linkfeatureId || ""}                          
                  />
                   {obj.name}  </Grid>
            {(""!=obj.buttonoperation && null!=obj.buttonoperation) && obj.buttonoperation.split(',').map((ob, i) => (
              <Grid item xs={2}>
             <div
              className="text"
              key={i}
              >  
              <Checkbox 
                      checked={selectedButtonArr.length>0?((selectedButtonArr.indexOf((obj.linkfeatureId+"_"+ob)) > -1)? true : false):false } 
                      name={"checkbox"+obj.name+ob}
                      onChange={(e)=>handleSelectedButtons(e,obj.linkfeatureId+"_"+ob)}
                      value={obj.linkfeatureId+"_"+ob || ""}                         
                  /> {renderSwitch(ob)}
              </div>  
              </Grid>            
            ))
            }
        </Grid>     
        </div> ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
    ))} 
      </Box>
    { dataStatusConfirm!==false && <DraggableDialog open={open}  dialogButtonText={dialogContentConfirm.dialogButtonText} handleSbmtClose={handleSbmtClose} submitButtonText={dialogContentConfirm.submitButtonText} dialogText={dialogContentConfirm.dialogText} closeButtonText={dialogContentConfirm.closeButtonText} handleClearCls={handleClearCls}/>  }
    { dataStatus!==false &&  
    <DraggableDialog open={open}  dialogButtonText={dialogContent.dialogButtonText} handleSbmtClose={handleSbmtClose} submitButtonText={dialogContent.submitButtonText} dialogText={dialogContent.dialogText} closeButtonText={dialogContent.closeButtonText} handleClearCls={handleClearCls}/>
    }      
    </div>
  )
}
