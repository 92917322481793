import React, { useEffect, useState,useCallback } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./treeview.css";
import SwapMainDataLine1 from "./SwapMainDataLine1.js";
import MainDataLine2 from "./MainDataLine2.js";
import MainDataLine4 from "./MainDataLine4.js";
import swaptreeviewService from '../../services/swaptreeviewService';
import treeviewService from '../../services/treeviewService';
import '@szhsin/react-menu/dist/index.css';
import Divider from '@mui/material/Divider';

const TextFieldStyle = {
  paddingLeft: 5,
  fontSize: "1.2rem",
  height: "18px",
  //marginTop: "4%",
  background: "#ffffff"
};

const radioStyle={paddingLeft:"1px !important",color:"white"};
/*
const addstockdata1 = [{
  "id": 9,
  "text": "Nine"
}, {
  "id": 10,
  "text": "Ten"
}, {
  "id": 11,
  "text": "Eleven"
}, {
  "id": 12,
  "text": "Twelve"
}, {
  "id": 13,
  "text": "Thirteen"
}];
*/
const divStyle = { width: '77%',  backgroundColor: "#FFFFFF" };
export const SearchBar = ({ setSearchQuery }) => (
  <form>
    <div style={{paddingLeft: "10px",  paddingTop: "4px", paddingBottom: "0px"}}> 
    &nbsp;<TextField
      id="search-bar"      
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      inputProps={{
        style: TextFieldStyle,
        autoComplete: "off"
      }}
      variant="outlined"
      autoComplete="off"
      placeholder="Search..."
      size="small"      
      style={divStyle}
    />
    <IconButton type="button" aria-label="search">
      <SearchIcon style={{ fill: "blue" }} />
    </IconButton>
    </div>
  </form>
);
const filterData = (query, stockdata) => {
  if (query==="") {
    return stockdata;
  } else {
    return stockdata.filter((d) => d.text.toLowerCase().includes(query));
  }
};

const MainList = (prop) => {
  const scrolldiv = prop.scrolldiv;
  //const addstockdataNew = prop.addstockdata;
  const [searchQuery, setSearchQuery] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [elementList, setElementList] = useState('');
  const [countStatus, setCountStatus] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);

  var globalOrgId=1;
  var globaluserId=1;
  var  globalroleId=6;
  var globalJson={};
  if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
  } else{
    globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
  }
  globalOrgId=globalJson.orgId;
  globaluserId=globalJson.id;
  globalroleId=globalJson.roleId;
  
  const orgType = prop.orgType;
  var listDivStyleColor="#ffffff";
    var listDivStylebgColor="#233958";
    var list1DivStyleColor="#ffffff";
    var list1DivStyleBgColor="#465d80 !important";
    var addBoxIconColor="#ccc3c3";
    var listDivStylePaddingLeft="5%";
    var listDivStyleLeft=17;
    if(orgType===1){
      listDivStyleColor="#ffffff";
      listDivStylebgColor="#233958";
      list1DivStyleColor="#ffffff";
      list1DivStyleBgColor="#465d80 !important";
      addBoxIconColor="#ccc3c3";
    }else if(orgType===5){ 
      listDivStyleColor="#ffffff";
      listDivStylebgColor="#6684b3 !important";
      addBoxIconColor="#f0dfdf";      
    }else if(orgType===3){
    }else if(orgType===2 || orgType===7 || orgType===8 || orgType===10){
      listDivStylePaddingLeft= "4%";
      listDivStyleLeft=26.5;
      //listDivStylebgColor="#465d80";
      listDivStylebgColor="#2194ba";
     }  
    const listDivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.1, pointerEvents: "none",  background: listDivStylebgColor, color: listDivStyleColor,paddingTop:"0.4%",
    marginLeft: "-1.5%",paddingLeft:listDivStylePaddingLeft,
    "&:before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 32,
        left: listDivStyleLeft,
        top: 23,     
        borderLeft: "1px solid white",
        height: "21px !important",
        fontWeight:"400px"
        }    
    };
    const list1DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "none", background: list1DivStyleBgColor, color: list1DivStyleColor,
    paddingTop:"0.3%",position: "relative",
      "&:before": {
          pointerEvents: "none",
          content: '""',
          position: "absolute",
          width: 34,
          left: 8,
          top: 0,     
          borderLeft: "1px solid white",
          height: "52px",
          fontWeight:"400px"
        }, 
        "&:after": {
          pointerEvents: "none",
          content: '""',
          position: "absolute",
          width: 14,
          left: 12,
          top: 16,     
          borderBottom: "1px solid white",        
          fontWeight:"400px"
          }            
    };
  const onClickSlotfn = useCallback((checkval,val,swapstationId) => { 
    prop.onClickSlotfn(checkval,val,swapstationId);  
      return null;
  }, [prop]);

  const callbackRgnfn = useCallback((regionId) => {     
    prop.callbackRgnfn(regionId);     
      return null;
  }, []);

    
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);  
      return null;
  }, [prop]);  

  const expandValChk = useCallback((val) => {    
    if(true===val){
      //setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
    }else{
      //setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    }
    return null;
    }, []);

    const onloadMoreLastfn = useCallback(async (count,index,requestObj) => {       
      return null;
    }, []);

    const callbackDlrfn = useCallback((request) => {     
      prop.callbackDlrfn(request);     
        return null;
    }, []);
   
    const callbackSwpStnfn = useCallback((request) => {     
      prop.callbackSwpStnfn(request);     
        return null;
    }, []);

  const elementData = (request,dataObj, list1DivStyle, scrolldiv, countStatus,dataArray,request1,dataArray1) => {
    if(orgType===5){ 
      if(globalroleId===20){
        return <MainDataLine4 selectedObject={request} dataArray={dataArray} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} callbackRgnfn={callbackRgnfn}  callbackDlrfn={callbackDlrfn} callbackSwpStnfn={callbackSwpStnfn}/>;
      }else if(globalroleId===23){
        return <MainDataLine2 selectedObject={request} dataArray={dataArray} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} callbackDlrfn={callbackDlrfn} orgType={orgType} callbackSwpStnfn={callbackSwpStnfn} />;
       }else{
        return <MainDataLine4 selectedObject={request} dataArray={dataArray} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} callbackRgnfn={callbackRgnfn}  callbackDlrfn={callbackDlrfn} callbackSwpStnfn={callbackSwpStnfn}/>;
       }   
       }else if(orgType===2){  //Region => dealer =>customer-fleet-vehicle     
      return <SwapMainDataLine1 selectedObject={request} dataArray={dataArray} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType}  callbackRgnfn={callbackRgnfn}  callbackDlrfn={callbackDlrfn} callbackSwpStnfn={callbackSwpStnfn} />;
    }
    else{  //Reseller =>region-dealer-customer-fleet-vehicle
        return <MainDataLine4 selectedObject={request} dataArray={dataArray} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} callbackRgnfn={callbackRgnfn}  callbackDlrfn={callbackDlrfn} callbackSwpStnfn={callbackSwpStnfn}/>;
    }
    }
  const fetchData = async () => {
    var request = {};
    var region = {};
    var arrayList1 = [];
    if(orgType===5){
      request = {}; 
      request.orgid = globalOrgId;
      request.flag=10;
      request.userId = globaluserId;
      request.searchText = ""; 
    }else if(orgType===2){ 
      request = {};
      //request.resellerId = 0;
      request.orgid = globalOrgId;
      request.flag=9;
      request.userId = globaluserId;
      request.searchText = ""; 
      request.region = "All";
    }
    else{
      request = {}; 
      request.orgid = globalOrgId;
      request.flag=10;
      request.userId = globaluserId;
      request.searchText = "";
    }
    var response = await swaptreeviewService.getMapViewTreeCommonData(request, null)
    var responseData1 = response.data.data;
    var responseNew=[];
    if(responseData1 !== []) {
      var request1 = {};
      var region1 = {};
      var region2 = {};
     if(orgType===5){ 
        setDataFiltered(responseData1);
        setExpanded("panel" + responseData1[0].resellerOrgId);
        setDataFiltered(responseData1);
        arrayList1 = filterData(searchQuery, responseData1);
        setCountStatus(false);
        request1={};
        request1.dealerId = responseData1[0].resellerOrgId;
        request1.userId = globaluserId;     
        if(globalroleId===20){
          request1.flag=2;
        }else if(globalroleId===23){
          request1.flag=6;
        }else{
          request1.flag=2;
        }        
      }else if(orgType===2){
        var json={};
        json.resellerName=responseData1[0].resellerName;
        json.resellerOrgId=responseData1[0].resellerOrgId;
        responseNew.push(json);
        setDataFiltered(responseNew);
        setExpanded("panel" + responseNew[0].resellerOrgId);
        arrayList1 = filterData(searchQuery, responseNew);
        setCountStatus(false);
        request1 = {};
        //request.resellerId = 0;
        request1.orgid = globalOrgId;
        request1.flag=9;
        request1.userId = globaluserId;
        //request.sessionOrgId="";
        //request.customerId="string";
        //request.page = 0;
        //request.size = 10;
        request1.searchText = ""; 
        request1.region = "All";
        //region1 = {};
      }
      else{
        setDataFiltered(responseData1);
        setExpanded("panel" + responseData1[0].resellerOrgId);
        setDataFiltered(responseData1);
        arrayList1 = filterData(searchQuery, responseData1);
        setCountStatus(false);
        request1={};
        request1.dealerId = responseData1[0].resellerOrgId;
        request1.flag=2;
        request1.userId = globaluserId;      
      }     
       await swaptreeviewService.getMapViewTreeCommonData(request1, null)
        .then(res => {
          var stockdata = res.data.data;
           if(orgType===10){                 
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===11){                
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===12){                 
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }
              else{                
                 setElementList(elementData(request1,stockdata, list1DivStyle, scrolldiv, countStatus,stockdata,null,null)); 
              }                
        })
        .catch(err => {
          console.log(err);
        });  
    }
    return "";
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange3 =
  (index, panel, dataObj) => (event, newExpanded) => {
    console.log("panel::"+panel);
    var request1={};
     setExpanded(newExpanded ? panel : false);
       if (newExpanded) {   
        //const stockdataContent1=[{"reSellerOrgId":"109918","reSellerName":"Dealer A","Orgs":[]}];
        const stockdataContent1=[{"dealerList":[],"regionId":"1","regionName":"NORTH"},{"dealerList":[],"regionId":"2","regionName":"EAST"},{"dealerList":[],"regionId":"3","regionName":"WEST"},{"dealerList":[],"regionId":"4","regionName":"SOUTH"},{"dealerList":[],"regionId":"5","regionName":"UNASSIGNED"}];
        setElementList(elementData(request1,dataObj, list1DivStyle, scrolldiv, countStatus,stockdataContent1,null,null)); 
      }else{
        request1={};
        request1.checked=false;
        prop.expandFleetDataShowfn(request1);
      }
  };

  const handleChange2 =
   (index, panel, dataObj) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
        if (newExpanded) {        
        setCountStatus(true);
        var request1 = {};
        var region1 = {};
        var region2 = {};
        if(orgType===2){ 
            request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===4){ 
            request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===5 || orgType===6){ 
            //request1.resellerId = index;
            request1.dealerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===7){ 
            //request1.resellerId = index;
            request1.regionId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "dealer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===8){ 
            //request1.resellerId = index;
            request1.customerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "fleet";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===9){ 
            request1 = {};
            //request1.resellerId = index;
            request1.customerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "fleet";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===10){ 
            request1 = {};
            //request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            //console.log("index value:"+index);
            request1.orgId = index;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "vehicle";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===11){ 
            request1.resellerId = 0;
            request1.orgId = index;
            request1.page = 0;
            request1.size = 10;
            request1.value = "account";
            request1.vehModel = "1";
            request1.searchText = "";
            region2 = {};
            region2.region = "1";
          }else if(orgType===12){ 
            request1.resellerId = 0;
            request1.accountId = index;
            request1.page = 0;
            request1.size = 10;
            request1.value = "vehicle";
            request1.vehModel = "1";
            request1.searchText = "";
            region2 = {};
            region2.region = "1";
          }else{
            request1.resellerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.value = "region";
            request1.searchText = "";
            request1.vehModel = "1";
            region2 = {};
            region2.region = 1;                
          }     
         treeviewService.getOrgGridViewForTreeview(request1, region2)
          .then(res => {           
            var stockdata = res.data.data;
            if(orgType===8){ 
              request1.checked=true;
              prop.expandFleetDataShowfn(request1);
              var request2 = {};
              request2.resellerId = 0;
              request2.regionId = 0;
              request2.dealerId = 0;
              request2.customerId = index;
              request2.accountId = 0;
              request2.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
              request2.page = 0;
              request2.size = 10;
              request2.region = 0;
              request2.vehModel = "2";
              request2.value = "vehicle";
              request2.searchText = "";
              request2.vehModel = "1";
              var region2 = {};
              region2.region = 1;
              var stockdata1 = {};
              treeviewService.getOrgGridViewForTreeview(request2, region2).then(res1 => { 
                stockdata1 = res1.data.data;
                setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,request2,stockdata1.content));              
              }).catch(err => {
                console.log(err);
              });             
              }else if(orgType===10 || orgType===11){              
                setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));            
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===12){              
                setElementList(elementData(request1,dataObj, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));            
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else{
                setElementList(elementData(request1,dataObj, list1DivStyle, scrolldiv, countStatus,stockdata.content,null,null)); 
               }
              })
          .catch(err => {
            console.log(err);
          });         
        } else {       
          if(orgType===10 || orgType===11 || orgType===12){
            request1={};
            request1.checked=false;
            prop.expandFleetDataShowfn(request1);
          }
      }
    };

  const [hoverVal1,setHoverVal1]=useState("dragMenu");
  const [hoverVal2,setHoverVal2]=useState("dragMenu");
  const [isItalic, setItalic] = useState(false);
  const menuItemClassName = ({ hover }) =>(hover ? 'my-menuitem-hover' : 'dragMenu');
  function fnhoverVal1(){
    // setHoverVal2("my-menuitem-hover");
    // setHoverVal1("dragMenu");
    setHoverVal2("dragMenu");
    setHoverVal1("my-menuitem-hover1");
  }

  function fnhoverVal2(){
    setHoverVal1("dragMenu");
    setHoverVal2("my-menuitem-hover1");
  }

  function fnhoverValRevert(){
    setHoverVal1("dragMenu");
    setHoverVal2("dragMenu");
  }
  function italicVal(e){
    setItalic(e.target.checked);
  } 
  
  const listItems = dataFiltered.map((dataObj, index) => (
    <div
      className="text"
      key={dataObj.resellerOrgId}
    >
      <Accordion expanded={expanded === "panel" + dataObj.resellerOrgId} onChange={handleChange2(dataObj.resellerOrgId, 'panel' + dataObj.resellerOrgId, dataObj)} >
        <AccordionSummary sx={listDivStyle}  >
        {dataObj.resellerName}
            {/* { (orgType!==2 && orgType!==5) && <div> */}
            <div>
          {/*
          <IndeterminateCheckBoxIcon
            sx={{
              marginLeft: "auto",
              pointerEvents: "auto",
              color:addBoxIconColor,
              marginTop:"-0.7%"
            }} style={{ display: (expanded === "panel" + dataObj.resellerOrgId) ? 'block' : 'none' }}
          /><AddBoxIcon
            sx={{
              marginLeft: "auto",
              pointerEvents: "auto",
              color:addBoxIconColor,
              marginTop:"-0.7%"
            }} style={{ display: (expanded === "panel" + dataObj.resellerOrgId) ? 'none' : 'block' }}
          />  */}
           </div>          
        </AccordionSummary>
        <AccordionDetails>
          {elementList}        
        </AccordionDetails>
      </Accordion>
      <Divider light />
    </div>
  ));
  return (
    <div>
        <div style={{ background: "#b5c7d3" }}  >
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}  onClick={prop.testfunctn()}/>
        </div>
        {/* <div style={{ paddingTop: '2%',paddingLeft:'3%', paddingBottom: '2%',backgroundColor:'#2194ba',color:'#FFF',fontWeight:'bold' }}>
          <div>Swapstation </div>
        </div>
        */}
      
          <div style={{ maxHeight: scrolldiv.maxHeight, overflow: scrolldiv.overflow,fontWeight: "bold", fontSize: "14px",fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif" }}>
            {dataFiltered.length>0 && listItems}
          </div>
       
      </div>
   
  );
}

export default React.memo(MainList);