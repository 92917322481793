import * as React from "react";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const SearchTextBox = styled(TextField)(({ theme }) => ({
  width: "25ch",
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    fontsize: "12px",
  },
  "& .MuiInputBase-input": {
    fontsize: "12px",
    padding: "1.5px 14px",
  },
}));

export default function SearchBox({ filterText, onFilter, style }) {
  return (
    <SearchTextBox
      sx={{ ...style }}
      size="small"
      value={filterText}
      onChange={onFilter}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
