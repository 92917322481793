import React,{useState} from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';

const list2DivStyle = { 
position: "relative",
    '&:hover': {
      bgcolor: "primary.light",
      color: 'white',
      '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': { color: 'white' }
      }         
};

const MainDataLine4 = (prop) => {   
    const [checkedAnalysis,setCheckedAnalysis] =useState();   
      const dataFetch = (dataArray1) => {
        return <div>
            {dataArray1.length > 0 && dataArray1.map((d, index) => (
                <div className="text" key={d.key} >
                    <div>
                        <Box >
                            <Grid container  sx={list2DivStyle} >
                                <Grid item xs={3} >
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={1}  sx={{ marginTop: '0%',marginLeft:"-3%" }} >
                               <FormControlLabel control={                            
                               <Radio
                                    onChange={(e)=>{setCheckedAnalysis(d.key);prop.onClickfn(e.target,d.key)}}
                                    name="radio-buttons"
                                    checked={d.key===checkedAnalysis?true:false}
                                    id={d.key+"radio"} 
                                    sx={{                                        
                                        pointerEvents: "auto"
                                    }}
                                />
                               
                               }>
                                    </FormControlLabel>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: '4.2%',fontSize:"98%" }}>
                                    {d.value}
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft:"3%"}}>
                                    <FormControlLabel control={ <Checkbox onClick={(e)=>{if(d.key+"radio"){setCheckedAnalysis(d.key);}prop.onClickfn(e.target,d.key)}}  checked={d.key===checkedAnalysis?true:false} />} >
                                    </FormControlLabel>
                                </Grid>
                            </Grid>
                         </Box>
                    </div>
                    <Divider />
                </div>
            ))}
        </div>
    };
    const listElementData = dataFetch(prop.dataArray1);
    return (
        <div>{listElementData}</div>
    );
}
export default React.memo(MainDataLine4);
