
import * as React from 'react';
import { useState, useEffect } from 'react';
import SwapStationServices from '../../services/swapStationService';
import { Box,Grid,ListItem,ListItemText,Typography, ButtonBase, Paper } from '@mui/material';
import { styled } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width:600,
  height: 200,
  lineHeight: '60px'
}));

const Img = styled('img')({
  //margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  marginTop:"-15%"
});


export default function AlignItemsList({editSwapView}) {

  
  const[dataList,setDataList]=useState([])

  const fetchNearestStationDetails = async () => {
    var request={};
    request.radius=0;
    request.userId=0;
    request.orgId=0;
    request.location="string";
    try{
      const responseData= (await SwapStationServices.getSwapStationList(request)).data
      setDataList(responseData.data)
    }
    catch(error){

    }
  }

  useEffect( () => {
    fetchNearestStationDetails()
  }, [])
  const viewDatafn=(data)=>{
    data.checkedList="listClicked";
    editSwapView(data);
    //console.log("data::"+data);
  }
  return (
    <Grid container spacing={1}>
    <Grid item xs={12}>
  <Box sx={{maxWidth:800, maxHeight:600, overflow:'scroll'}} >
  {dataList.map((data) => (
    <ListItem sx={{display:"inline"}}>
  <Paper
    sx={{
      paddingLeft: 2, 
      marginLeft: "10%",
      maxWidth: 700,
      flexGrow: 1,
      display:'flex',
      backgroundColor:'#b2dfdb',
      // backgroundColor: (theme) =>
      //   theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    }}
    onClick={()=>{viewDatafn(data)}}
  >
    <Grid container spacing={0}>
      <Grid item xs={3}>       
        <ButtonBase sx={{ width: 128, height: 128 }}>
         {(data.fileName!==undefined && data.fileName!=="") && <Img alt="complex"  src={data.fileName}  /> }
         {(data.fileName===undefined || data.fileName==="") && <Img alt="complex" src="https://s3-us-west-2.amazonaws.com/static-fj-imgs/UserProfileImgs/20221103095325739350_swapstation3.jpg" ></Img>
        } 
        </ButtonBase>
      </Grid>
      <Grid item xs={9}>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item >
            <Typography gutterBottom variant="subtitle1">
              {data.swapStationName}
            </Typography>
            <Grid container>
            <LocationOnIcon sx={{color:"#007bb2"}} />
            <Typography variant="body2" gutterBottom color="text.secondary">
             {data.lat+","+data.lng}
            </Typography>
            </Grid>
            <Grid container>
            <CallIcon sx={{color:"#00a152"}}/>
            <Typography variant="body2" color="text.secondary">
             {data.contact}
            </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Grid> 
    </Grid>
  </Paper>
  </ListItem>
      ))}
  </Box>
  </Grid>
  </Grid>
  );
}
