import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import RawFeed from "./RawFeed";
import { Breadcrumb } from "../shared/Breadcrumbs";

const FeedLog = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Breadcrumb mainDiv="Logs" subDiv="Feed Log"></Breadcrumb>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Feed tabs" sx={{margin:"20px 0px"}}>
        <Tab label="Raw Feed" />
      </Tabs>
      {tabValue === 0 && <RawFeed />}
      {/* Add more tabs here if needed */}
    </Box>
  );
};

export default FeedLog;
