import React from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { FormControl, FormLabel, TextField } from "@mui/material";


const GeoZoneSearchBox = () => {

    return (
       
<StandaloneSearchBox>
      <TextField
        type="text"
        placeholder="Customized your placeholder"
        style={{
          border: `1px solid black`,
          width: `240px`,
          height: `25px`,
          marginTop: `27px`,
          padding: `0 5px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
     
    </StandaloneSearchBox>

    )

}
export default GeoZoneSearchBox