import { all } from "redux-saga/effects";
import authSage from "../middleware/auth";
import loginSaga from "../middleware/login";

function* rootSaga() {
    yield all([
      authSage(),
      loginSaga()
    ]);
  }
  export default rootSaga;

