import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import ErrorIcon from "@mui/icons-material/Error";

const icons = { PersonIcon, LockIcon, ErrorIcon };

export const Menus = [
  {
    id: "1",
    title: "Profile",
    icon: icons["PersonIcon"],
  },
  {
    id: "2",
    title: "Password",
    icon: icons["LockIcon"],
  },
  {
    id: "3",
    title: "Alert Preference",
    icon: icons["ErrorIcon"],
  },
];
