import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";

const text =
  "Travel Detail Report gets detailed information of the vehicle for each minute for a selected time period.You can choose to see the vehicle's report upto 'previous month'.";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};
const status = ["All", "Moving", "Idle", "Stop", "Charging"];

const TravelDetailReport = () => {
  const [responseData, setResponseData] = useState([]);
  const [statusCon, setStatus] = useState(status[0]);
  const theme = useTheme();

  useEffect(async () => {
    await fetchTravelDetails();
  }, []);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: "1",
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "1.5px 4px",
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Date Time",
        accessor: "createdDate",
      },
      {
        Header: "GPS Location",
        accessor: "gpsLocation",
      },
      {
        Header: "Status",
        accessor: "tripStatus",
      },
      {
        Header: "Speed (Km/h)",
        accessor: "speed",
      },
      {
        Header: "Odometer (Km)",
        accessor: "odometer",
      },
      {
        Header: "Ignition",
        accessor: "ignition",
      },
      {
        Header: "Extended External Voltage (%)",
        accessor: "",
      },
      {
        Header: "Charging Status",
        accessor: "stateOfCharge",
      },
      {
        Header: "Cummulative Fuel Consumed (Ltr)",
        accessor: "hrlfc",
      },
      {
        Header: "Engine Operational Hours (hrs)",
        accessor: "engineOperatingHours",
      },
      {
        Header: "Engine Coolant Temperature (c)",
        accessor: "engineCoolentTemp",
      },
      {
        Header: "Oil Pressure (Kpa)",
        accessor: "engineOilPressure",
      },
      {
        Header: "Fuel Level (%)",
        accessor: "fuelLevel",
      },
      {
        Header: "Engine Speed (Rpm)",
        accessor: "engineSpeed",
      },
      {
        Header: "Internal Battery",
        accessor: "intBatVoltPercentage",
      },
      {
        Header: "Gps Fix",
        accessor: "gpsFix",
      },
      {
        Header: "External Battery (Volt)",
        accessor: "externalBatteryVolt",
      },
    ],
    []
  );
  const fetchTravelDetails = async () => {
    var request = {};
    request.orgId = 109891;
    request.resellerId = 1;
    request.vehicleId = 25909;
    request.intStatus = 1;
    request.size = 1;
    request.page = 25;
    request.startDate = "2022-09-26 00:00:00";
    request.endDate = "2022-09-26 23:59:59";
    request.sortBy = "desc";
    request.chargeStatus = 1;
    const responseData = (await ReportServices.travelDetailReport(request))
      .data;
    setResponseData(responseData.data.content);
  };

  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Reports" subDiv="Travel Detail Report"></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"Travel Detail Report"} />

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper
            sx={{
              marginTop: 2,
              backgroundColor: "#eeeeee",
            }}
          >
            <ReportDropDown />
            <FormControl sx={{ m: 1, width: 160 }} size="small">
              <FormLabel sx={labelStyle}>Status</FormLabel>
              <Select
                value={statusCon}
                onChange={handleChange}
                MenuProps={MenuProps}
                sx={selectDropdown}
              >
                {status.map((name, i) => (
                  <MenuItem key={i} value={name} sx={menuStyles}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default TravelDetailReport;
