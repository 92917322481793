import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography,FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { BATTERY_DETAILS } from "./../../constants/BatteryFormFields";
import TextInput from "../shared/TextInput";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import BatteryServices from "../../services/batteryServices";
import { PageHeader } from "../shared/PageHeader";
import DraggableDialog from "../shared/DailogDrag";
import { useForm } from "react-hook-form";
import AlertDialogSlide from "../shared/ValidationDialog";
import { VALID_EMAIL, VALID_PHONE } from "../shared/FormValidation";
import NumberInput from "../shared/NumberInput";

const RESTRICTED_ID = 1;

export default function CreateBatteryForm({ batToView, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [bat, setBat] = useState(null);
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [assignedobj, setAssignedobj] = useState({});
  const [statusEdit, setStatusEdit] = useState(false);

  useEffect(async () => {
    if (batToView) {
      setStatusEdit(true);
      const responseData = (await BatteryServices.viewBatById(batToView)).data
        .data;
      setBat(responseData);
      setAssignedobj(responseData);
    } else {
      setBat(null);
    }
  }, [setBat]);

  const theme = useTheme();

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "35px",width:"350px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: theme.fieldset.border,borderRadius:"12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary,fontSize:"16px" }}>
            {" "}
            {title}{" "}
          </legend>

          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };
  function switchCaseVal(param) {
    switch (param) {
      case "batteryModel":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryModel"] = bat["batteryModel"]);
          } else {
            return (bat["batteryModel"] =
              assignedobj.batteryModel === undefined
                ? ""
                : assignedobj.batteryModel);
          }
        }
        return assignedobj.batteryModel === undefined
          ? ""
          : assignedobj.batteryModel;
      case "batteryType":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryType"] = bat["batteryType"]);
          } else {
            return (bat["batteryType"] =
              assignedobj.batteryType === undefined
                ? ""
                : assignedobj.batteryType);
          }
        }
        return assignedobj.batteryType === undefined
          ? ""
          : assignedobj.batteryType;
      case "batteryVoltage":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryVoltage"] = bat["batteryVoltage"]);
          } else {
            return (bat["batteryVoltage"] =
              assignedobj.batteryVoltage === undefined
                ? ""
                : assignedobj.batteryVoltage);
          }
        }
        return assignedobj.batteryVoltage === undefined
          ? ""
          : assignedobj.batteryVoltage;
      case "batteryCapacity":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryCapacity"] = bat["batteryCapacity"]);
          } else {
            return (bat["batteryCapacity"] =
              assignedobj.batteryCapacity === undefined
                ? ""
                : assignedobj.batteryCapacity);
          }
        }
        return assignedobj.batteryCapacity === undefined
          ? ""
          : assignedobj.batteryCapacity;
      case "batteryWeight":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryWeight"] = bat["batteryWeight"]);
          } else {
            return (bat["batteryWeight"] =
              assignedobj.batteryWeight === undefined
                ? ""
                : assignedobj.batteryWeight);
          }
        }
        return assignedobj.batteryWeight === undefined
          ? ""
          : assignedobj.batteryWeight;
      case "batterySize":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batterySize"] = bat["batterySize"]);
          } else {
            return (bat["batterySize"] =
              assignedobj.batterySize === undefined
                ? ""
                : assignedobj.batterySize);
          }
        }
        return assignedobj.batterySize === undefined
          ? ""
          : assignedobj.batterySize;
      case "batteryId":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryId"] = bat["batteryId"]);
          } else {
            return (bat["batteryId"] =
              assignedobj.batteryId === undefined ? "" : assignedobj.batteryId);
          }
        }
        return assignedobj.batteryId === undefined ? "" : assignedobj.batteryId;
      case "batteryName":
        if (bat != null) {
          if (statusEdit === true) {
            return (bat["batteryName"] = bat["batteryName"]);
          } else {
            return (bat["batteryName"] =
              assignedobj.batteryName === undefined
                ? ""
                : assignedobj.batteryName);
          }
        }
        return assignedobj.batteryName === undefined
          ? ""
          : assignedobj.batteryName;

      default:
        if (bat != null) {
          return bat[param];
        } else {
          return "";
        }
    }
  }

  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    var obj = { ...assignedobj };

    setStatusEdit(false);
    if (name === "batteryModel") {
      obj.batteryModel = val;
    } else if (name === "batteryType") {
      obj.batteryType = val;
    } else if (name === "batteryVoltage") {
      obj.batteryVoltage = val;
    } else if (name === "batteryCapacity") {
      obj.batteryCapacity = val;
    } else if (name === "batteryWeight") {
      obj.batteryWeight = val;
    } else if (name === "batterySize") {
      obj.batterySize = val;
    } else if (name === "batteryId") {
      obj.batteryId = val;
    } else if (name === "batteryName") {
      obj.batteryName = val;
    }

    setAssignedobj(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var obj = {};
    const { target } = e;
    obj = Object.fromEntries(new FormData(target));
    if (obj.batteryModel === "-1") {
      setTextData("Please Select BatteryModel");
      setError(true);
    } else if (obj.batteryType === "-1") {
      setTextData("Please Select BatteryType");
      setError(true);
    } else if (obj.batteryVoltage === "-1") {
      setTextData("Please Select BatteryVoltage");
      setError(true);
    } else if (obj.batteryCapacity === "-1") {
      setTextData("Please Select BatteryCapacity");
      setError(true);
    } else if (obj.batteryWeight === "-1") {
      setTextData("Please Select BatteryWeight");
      setError(true);
    } else if (obj.batterySize === "-1") {
      setTextData("Please Select BatterySize");
      setError(true);
    } else if (obj.batteryId === "") {
      setTextData("Please Enter BatteryId");
      setError(true);
    } else if (obj.batteryName === "") {
      setTextData("Please Select BatteryName");
      setError(true);
    } else {
      saveBatDetails(e, Object.fromEntries(new FormData(target)));
    }
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const saveBatDetails = async (e, obj) => {
    const responseData = await BatteryServices.saveBatDetails(obj);

    var obj1 = {};

    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const InputWrapper = ({ children }) => {
    return (
      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
        {children}
      </Grid>
    );
  };

  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
              >
                <FormLabel sx={{ fontSize: "16px", fontWeight: "600",textAlign: "center" }}>{field.label}</FormLabel>
                <TextInput
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  //defaultValue={bat !== null ? bat[field.name] : ""}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
              </Grid>
              
            );
          } else if (field.type === "number") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" ,padding:"10px"}}
              >
                 <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center"}}>{field.label}</FormLabel>
                 <NumberInput
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  // defaultValue={bat !== null ? bat[field.name] : ""}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
              </Grid>
              
            );
          } else if (field.type === "select") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <SelectInput
                  name={field.name}
                  label={field.label}
                  options={field.options}
                  // defaultValue={
                  //   bat !== null ? bat[field.name] : field.options[0].value
                  // }
                  defaultValue={
                    bat !== null
                      ? switchCaseVal(field.name) == ""
                        ? bat[field.name]
                        : switchCaseVal(field.name)
                      : switchCaseVal(field.name) === ""
                      ? field.options !== null
                        ? field.options[0].value
                        : 0
                      : switchCaseVal(field.name)
                  }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                /> 
            </Grid>
              
            );
          }
        })}
      </>
    );
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const handleClear = () => {
    setError(false);
  };
  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={bat}>
        <FormLayout title={"Battery Details"}>
          <FormFields fields={BATTERY_DETAILS} />
        </FormLayout>

        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input type="hidden" name="id" value={bat != null ? bat.id : ""} />
          {batToView ? (
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                sx={buttonStyle}
                type="submit"

                // onClick={handleCancelClick}
              >
                Save
              </Button>{" "}
              <Button
                variant="contained"
                sx={buttonStyle}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </>
          )}
        </Grid>
      </form>
      {
        <AlertDialogSlide
          open1={error}
          text={textData}
          handleClear={handleClear}
        />
      }

      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
