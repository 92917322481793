import { Collapse, Icon, ListItemButton, ListItemIcon, ListItemText,Typography, alpha, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { MenuList, MenuListItem } from '../style/StyledComponets'
import { GrLocation } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { RxTable, RxDashboard } from 'react-icons/rx';
import { RiLogoutCircleRLine} from 'react-icons/ri';
import { TbTableAlias, TbHeadset } from 'react-icons/tb';
import { BiCog,} from 'react-icons/bi';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { HiOutlineChip } from 'react-icons/hi'
import { IoCogOutline } from "react-icons/io5";

function Menus(props) {
    const { menuListItem } = props;
    const [open, setOpen] = useState(false);

    //set Icons
    var icon = <GrLocation />;
    if (menuListItem.icon === "LocationOnIcon") {
        icon = <GrLocation />;
      } else if (menuListItem.icon === "LogoutIcon") {
        icon = <RiLogoutCircleRLine />;
      } else if (menuListItem.icon === "DashboardIcon") {
        icon = <RxDashboard />;
      } else if (menuListItem.icon === "TableViewIcon") {
        icon = <TbTableAlias />;
      } else if (menuListItem.icon === "GridOnIcon") {
        icon = <RxTable />;
      } else if (menuListItem.icon === "SettingsIcon") {
        icon = <BiCog />;
      } else if (menuListItem.icon === "NotificationsNoneIcon") {
        icon = <IoCogOutline /> ;
      } else if (menuListItem.icon === "HelpCenterIcon") {
        icon = <TbHeadset />;
      } else if (menuListItem.icon === "NotificationsIcon") {
        icon = <MdOutlineNotificationsActive />;
      }
      else if (menuListItem.icon === "fa-ota") {
        icon = <HiOutlineChip />
      }


    let theme = useTheme();

   //list Items
   const MenuListItemButton = (props) => {
    const { title, subCount, subMenus = null, item} = props;
        return(
            <>
            <MenuListItem disableGutters disablePadding sx={{margin:'0px',}}
                secondaryAction={ subCount !== 0 && 
                    <Icon sx={{fontSize:'16px', mr:1, transform:`rotate(${open ? '180deg' : '0deg'})`, transition:'.5s'}}>
                          <IoIosArrowDown />  
                    </Icon>
                }
            >
                {
                    subCount > 0 ? 
                    <ListItemButton sx={{p:'5px', background:open ? alpha(theme.palette.primary.main, .15) : 'transparent', }} onClick={() => setOpen(!open)}>
                        <ListItemIcon sx={{minWidth:'25px', fontSize:'16px'}}>
                            {icon}
                        </ListItemIcon>
                        <ListItemText 
                            primary={<Typography variant='body2'>{title}</Typography>}
                        />
                    </ListItemButton> :
                 
                    <ListItemButton sx={{p:'5px', background:open ? alpha(theme.palette.primary.main, .15) : 'transparent', }} to={item.route} component={NavLink}>
                            <ListItemIcon sx={{minWidth:'25px', fontSize:'16px'}}>
                                {icon}
                            </ListItemIcon>
                        <ListItemText 
                            primary={<Typography variant='body2'>{title}</Typography>}
                        />
                    </ListItemButton>

                }
                
            </MenuListItem>
                {
                    subCount > 0 && 
                    <Collapse in={open} sx={{background:theme.palette.primary.dark}}>
                        <MenuList sx={{p:0, m:0}}>
                            {
                                subMenus !== null && subMenus.map((subItem, index) => {
                                    return(
                                        <MenuListItem  disableGutters disablePadding sx={{margin:'0px',}}>
                                        <ListItemButton sx={{p:'5px', pl:3.5}} to={subItem.route} component={NavLink} >
                                            <ListItemText 
                                                primary={<Typography variant='body2'>{subItem.title}</Typography>}
                                            />
                                        </ListItemButton>
                                        </MenuListItem>
                                    )
                                })
                            }
                        </MenuList>
                    </Collapse>
                }
               
            </>
        )
    }



  return (
        <MenuListItemButton title={menuListItem.title} subCount={menuListItem.sub.length} subMenus={menuListItem.sub}  item={menuListItem}/>
  )

}

export default Menus