const DEVICE_DETAIL = [ 
  {
    type: 'text',
    label: 'Device IMEI Number *',
    name: 'deviceImeiNo'   
  },
  {
    type: 'select',
    label: 'Device Status *',
    name: 'deviceStatus',
    options: [
      {
        id: 1,
        title: 'MFG',
        value:1
      }
    ]
  },
  {
    type: 'select',
    label: 'Device Plan',
    name: 'devicePlan',
    options: [
      {
        id: 1,
        title: 'NONE',
        value:0
      },
      {
        id: 2,
        title: '1 Year',
        value:1
      },
      {
        id: 3,
        title: '2 Year',
        value:2
      }
    ]
  },
  {
    type: 'text',
    label: 'Device Serial Number',
    name: 'deviceSerialNo'
  },
  {
    type: 'select',
    label: 'Part Number',
    name: 'partNumber',
    options: [
      {
        id: 1,
        title: '320000',
        value:'320000'
      }
    ]
  },
  {
    type: 'select',
    label: 'MFG Sit Code',
    name: 'mfgSiteCode',
    options: [
      {
        id: 1,
        title: 'select',
        value:'0'
      },
      {
        id: 2,
        title: '101',
        value:'101'
      },
      {
        id: 3,
        title: '102',
        value:'102'
      },
      {
        id: 4,
        title: '103',
        value:'103'
      },
      {
        id: 5,
        title: '104',
        value:'104'
      }
    ]
  },
  {
    type: 'select',
    label: 'MFG Month',
    name: 'mfgMnth',
    options: [
      {
        id: 1,
        title: 'Jan',
        value:1

      },
      {
        id: 2,
        title: 'Feb',
        value:2
      },
      {
        id: 3,
        title: 'Mar',
        value:3
      },
      {
        id: 4,
        title: 'Aprl',
        value:4
      },
      {
        id: 5,
        title: 'May',
        value:5
      },
      {
        id: 6,
        title: 'June',
        value:6
      },
      {
        id: 7,
        title: 'July',
        value:7
      },
      {
        id: 8,
        title: 'Aug',
        value:8
      },
      {
        id: 9,
        title: 'Sept',
        value:9
      },
      {
        id: 10,
        title: 'Oct',
        value:10
      },
      {
        id: 11,
        title: 'Nov',
        value:11
      },
      {
        id: 12,
        title: 'Dec',
        value:12
      }

    ]
  },
  {
    type: 'select',
    label: 'Year',
    name: 'year',
    options: [
      {
        id: 1,
        title: 'select',
        value:0
      }, {
        id: 2,
        title: '2022',
        value:'2022'
      }, {
        id: 3,
        title: '2021',
        value:'2021'
      }, {
        id: 4,
        title: '2020',
        value:'2020'
      }, {
        id: 5,
        title: '2019',
        value:'2019'
      }, {
        id: 6,
        title: '2018',
        value:'2018'
      }, {
        id: 7,
        title: '2017',
        value:'2017'
      }, {
        id: 8,
        title: '2016',
        value:'2016'
      }, {
        id: 9,
        title: '2015',
        value:'2015'
      }
    ]
  },
  {
    type: 'select',
    label: 'Device Model',
    name: 'deviceModel',
    options: [
      {
        id: 1,
        title: 'select',
        value:'select'
      }, {
        id: 2,
        title: '100',
        value:'100'
      }, {
        id: 3,
        title: '200',
        value:'200'
      }, {
        id: 4,
        title: '300',
        value:'300'
      }, {
        id: 5,
        title: '350A',
        value:'350A'
      }, {
        id: 6,
        title: '300OBD',
        value:'300OBD'
      }
    ]
  },
  {
    type: 'select',
    label: 'Fameware',
    name: 'fameWare',
    options: [
      {
        id: 1,
        title: 'select',
        value:'select'
      }, {
        id: 2,
        title: '1.5',
        value:'1.5'
      }, {
        id: 3,
        title: '2.0',
        value:'2.0'
      }, {
        id: 4,
        title: '2.24',
        value:'2.24'
      }, {
        id: 5,
        title: '2.29',
        value:'2.29'
      }, {
        id: 6,
        title: '2.31',
        value:'2.31'
      }
    ]
  },
  {
    type: 'select',
    label: 'Hardware',
    name: 'hardware',
    options: [
      {
        id: 1,
        title: 'select',
        value:'0'
      }, {
        id: 2,
        title: '1.1',
        value:'1.1'
      }, {
        id: 3,
        title: '1.2',
        value:'1.2'
      }, {
        id: 4,
        title: '1.3',
        value:'1.3'
      }, {
        id: 5,
        title: '1.04',
        value:'1.04'
      }, {
        id: 6,
        title: '2.3.9',
        value:'2.3.9'
      }
    ]
  }
]
const DOMAIN_DETAIL = [
  {
    type: 'select',
    label: 'Plan *',
    name: 'plan',
    options: [
      {
        id: 1,
        title: 'NSE-DEFAULT-PLAN',
        value: '0'
      }
    ]
  },
  {
    type: 'select',
    label: 'License Key *',
    name: 'licensekey',
    options: [
      {
        id: 1,
        title: 'NE-URC2XX',
        value: '1'
      },
      {
        id: 2,
        title: 'NE-QSHW36',
        value: '2'        
      },
      {
        id: 3,
        title: 'NE-2MZT2K',
        value: '3'
      }
    ]
  },
  {
    type: 'date',
    label: 'LicenseKey Activation data *',
    name: 'licensekeyActvtnData',

  }
]

const SIM_DETAIL = [
  {
    type: 'select',
    label: 'Sim Number',
    name: 'simNumber',
    callbackfnStatus:'true',
    options: [
      {
        id: 1,
        title: '8247598733',
        value: '8247598733'
      }
    ]
  }, {
    type: 'select',
    label: 'Sim Status',
    name: 'simStatus',
    disabledkey:'true',
    options: [
      {
        id: 1,
        title: 'Active',
        value: 1
      },
      {
        id: 2,
        title: 'InActive',
        value: 0
      }
    ]
  },
  {
    type: 'text',
    label: 'First Mobile Number',
    name: 'firstMobileNum',
    disabledkey:'true'
  }, {
    type: 'text',
    label: 'Service Provider(APN)',
    name: 'firstServiceProvider',
    disabledkey:'true'
  },
  {
    type: 'text',
    label: 'Second Mobile Number',
    name: 'secondMobileNum',
    disabledkey:'true'
  }, {
    type: 'text',
    label: 'Second Provider(APN)',
    name: 'secondServiceProvider',
    disabledkey:'true'
  }
]

const SENSOR = [
  {
    type: 'radio',
    name: 'sensorRadio',
    options: [
      {
        id: 1,
        label: 'Yes',
        value: 1
      },
      {
        id: 2,
        label: 'No',
        value: 2
      },
    ]
  },
  {
    type: 'radio',
    name: 'sensorRadio2',
    options: [
      {
        id: 1,
        label: 'Yes',
        value: 1
      },
      {
        id: 2,
        label: 'No',
        value: 2
      },
    ]
  },{
    type: 'radio',
    name: 'sensorRadio3',
    options: [
      {
        id: 1,
        label: 'Yes',
        value: 1
      },
      {
        id: 2,
        label: 'No',
        value: 2
      },
    ]
  }
]

module.exports = {
  DEVICE_DETAIL,
  DOMAIN_DETAIL,
  SIM_DETAIL,
  SENSOR
}