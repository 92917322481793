import React, { useMemo,useState,useEffect } from 'react';
import { Box } from '@mui/system';
import { DataTable } from '../shared/data_table/DataTable';
import {  Paper } from '@mui/material';
import DropDownVehicle from './DropDownVehicle';
import vehicleServices from '../../services/vehicleServices';

//const ResellerId=0;
const ListVehicle = ({onEditVeh}) => {

    const [responseData, setResponseData] = useState([])

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Customer',
          accessor: 'customerName'
        },
        {
          Header: 'Fleet',
          accessor: 'fleetName'
        },
        {
          Header: 'Vehicle Plate',
          accessor: 'vehiclePlateNumber'
        },
        {
          Header: 'Device IMEI',
          accessor: 'imei'
        },
        {
          Header: 'First No #',
          accessor: 'mobileNumber1'
        },
        {
          Header: 'Second No #',
          accessor: 'mobileNumber2'
        },
        {
          Header: 'Last Connected',
          accessor: 'lastConnected'
        },
        {
          Header: 'Created Date',
          accessor: 'createdDate'
        },
        {
          Header: 'Fuel Type',
          accessor: 'fuelType'
        },
        {
          Header: 'Action',
          accessor: 'buttonEditDelete'
        }
      ],
    []
  )

  const onEdit = (itemEdited) => {
      onEditVeh(itemEdited.id)
  }
  

  const fetchVehicleDetails = async () => {
    var request={};
    request.resellerId=1;
    request.id=1;
    request.orgId=1;
    request.page=0;
    request.size=100;
    request.searchText="String";
    try{
      const responseData = (await vehicleServices.listVehicle(request)).data.data
      setResponseData(responseData.content)
    }
    catch(error){

    }
  }

  useEffect( () => {
     fetchVehicleDetails()
}, [])

  const onDelete = async (itemDeleted) => {

    try{
      await vehicleServices.deleteVehicle(itemDeleted.id)
      await fetchVehicleDetails()
    } catch (err) {
      console.error('something went wrong')
      console.log({err})
    }
  }

  const handleSearch = (searchText) => {
    console.log({searchText})
  }


  return (
    <>
    <Paper elevation={5} sx={{ height:'10vh'}}>
      <DropDownVehicle/>
     </Paper>
    <Box display="flex" flexDirection="column">
      <DataTable includeSearchBox onSearch={handleSearch} onEdit={onEdit} onDelete={onDelete} columns={columns} data={responseData} styles={{maxWidth: '89vw'}} numberOfStickyColumns={1} />
    </Box>
    </>
  )
}

export default ListVehicle
