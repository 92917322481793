import React, { useState } from "react";
import ChairIcon from "@mui/icons-material/Chair";
import BatteryCharged from '../../assets/images/batteryCharged.png';
import BatteryCharging from '../../assets/images/batteryCharging.png';
import BatteryFault from '../../assets/images/Fault.png';
import LowBattery from '../../assets/images/Low.png';
import SlotEmpty from '../../assets/images/Slot_Empty.png';

const StyledChair = ({ swapStatus, onSlot, item }) => {

  let chairColor = "BatteryCharged";
  const chairStyle = {
    fontSize: 50,
    color: chairColor,
  };
  const handleClick = () => {
    onSlot({ item });
    //console.log(event.currentTarget.value);
  };
  if (swapStatus === "Charged") {
    chairColor = "BatteryCharged";
    return <img src={BatteryCharged} onClick={handleClick} height="50%" ></img> 
  } else if (swapStatus === "Slot Empty") {
   
    return <img src={SlotEmpty} onClick={handleClick} height="50%" ></img> 
  } else if (swapStatus === "Charging") {
    
    return <img src={BatteryCharging} onClick={handleClick} height="50%" ></img> 
  }else if (swapStatus === "Fault") {
    chairColor = "red";
    return <img src={BatteryFault} onClick={handleClick} height="50%" ></img> 
  }
  else if (swapStatus === "LowCharge") {
   
    return <img src={LowBattery} onClick={handleClick} height="50%" ></img> 
  }
  
  //return <ChairIcon sx={chairStyle} onClick={handleClick} />;  
};

export default StyledChair;
