import React, { useEffect, useState, useRef } from "react";
import CalendarService from "../../services/calendarService";
import {
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Swapstation from "../../assets/images/SwappingIcons.png";
import { Grid, Box, Button, Fab, Icon, CardMedia, Typography, Rating, Stack } from "@mui/material";
import SelectInput from "../shared/SelectInput";
import GoogleMapWrapper from "../../Utils/GoogleMapWrapper";
import { PiListBulletsBold } from "react-icons/pi";
import { RiBatteryChargeLine } from "react-icons/ri";



const SwapMap = ({ editSwapView, goListView, BookingSlot}) => {
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState("");
  const [placeVals, setPlaceVals] = useState([]);
  const [sellat, setSellat] = useState(12.983);
  const [sellng, setSellng] = useState(80.2517);
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalroleId = 6;
  var globalJson = {};
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globaluserId = globalJson.id;
  globalroleId = globalJson.roleId;

  const handleToggleOpen = (obj) => {
    obj.checkedList = "iconClicked";
    editSwapView(obj);
    BookingSlot(obj);
    setOpenInfoWindowMarkerId("markerId");
    setOpenInfoWindowMarkerId(obj.swapStationId);
  };

  useEffect(() => {
    const fetchBusinesses = async () => {
      var request = {};
      request.userId = globaluserId;
      request.radius = 5;
      request.orgId = globalOrgId;
      request.location = sellat + "," + sellng;
      const listValues = (
        await CalendarService.getnearestSwapStation(request, null)
      ).data;
      setOpenInfoWindowMarkerId("");
      setPlaceVals([]);
      setPlaceVals(listValues.data);
      setOpenInfoWindowMarkerId("markerId");
    };

    fetchBusinesses();
    var arryObj = [];
    for (var i = 1; i < 11; i++) {
      var object = {};
      object.value = i;
      object.title = i;
      arryObj.push(object);
    }
    
  }, []);
  let renderArray = [placeVals];



  const StationInfoWindow = ({data}) => {
      return(
          <Box sx={{background:'#fff', width:'280px', boxShadow:'0px 10px 10px #000'}}>
              <Box>
                  <CardMedia component={'img'} src="https://img.saurenergy.com/2022/06/battery-swapping-station.jpg" sx={{width:'280px', height:'120px',}}/>
              </Box>
              <Box sx={{p:2}}>
                <Stack>
                  <Typography variant="body1">Stay Easy Tiruvanmiyur - Unit of Prohotel</Typography>
                    <Rating 
                      value={4}
                      readOnly
                      size="small"
                      sx={{fontSize:'12px'}}
                    />
                    <Button variant="outlined" size="small" color="secondary" sx={{mt:.5}} startIcon={<RiBatteryChargeLine />}onClick={() =>  handleToggleOpen(data)}>Book a slot</Button>
                </Stack>
                  
              </Box>
          </Box>

      )
  }




  return (
    // <div>
    //   <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} libraries={libraries}>
    //     <Box>
    //       <Grid container sx={{ marginTop: "0.6%", marginBottom: "0.4%" }}>
    //         <Grid item xs={3}></Grid>
    //         <Grid item xs={2}></Grid>
    //         <Grid item xs={3}></Grid>
    //         <Grid item xs={3} style={{ marginTop: "-1%", fontSize: "98%" }}>
    //           <SelectInput
    //             name="radius"
    //             label="Select Radius"
    //             options={commonList}
    //             defaultValue={defaultVal}
    //             callBackFn={callBackFn}
    //             callbackfnStatus={true}
    //           />
    //         </Grid>
    //         {/* <Grid item xs={1} >
    //           </Grid> */}
    //         {/* <span style={refDisplay} >{fromDate}</span>  */}
    //       </Grid>
    //     </Box>

    //     <GoogleMap
    //       mapContainerStyle={containerStyle}
    //       center={defaultProps.center}
    //       zoom={defaultProps.zoom}
    //       onClick={(e) => {
    //         console.log("lat" + e.latLng.lat() + "lng::" + e.latLng.lat());
    //         setSelLatitude(e.latLng.lat());
    //         setSelLongitude(e.latLng.lng());
    //         //console.log("default value:"+defaultVal);
    //         //setSwapMapView(selectval(true,e.latLng.lat(),e.latLng.lng()));
    //         setPath(true);
    //         var request = {};
    //         request.userId = globaluserId;
    //         request.radius = defaultVal;
    //         request.orgId = globalOrgId;
    //         request.location = e.latLng.lat() + "," + e.latLng.lng();
    //         CalendarService.getnearestSwapStation(request, null)
    //           .then((res) => {
    //             setOpenInfoWindowMarkerId("");
    //             setPlaceVals([]);
    //             setPlaceVals(res.data.data);
    //             setOpenInfoWindowMarkerId("markerId");
    //             setImageIconShow(true);
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       }}
    //     >
    //       {placeVals &&
    //         placeVals.map((location, i) => (
    //           <Marker
    //             key={location.swapStationId}
    //             position={location}
    //             // isMarkerShown={true}
    //             //icon={imageIconShow===true?MarkerImage:MarkerImageOffline}  //MarkerImage
    //             icon={Swapstation}
    //             //icon={iconMarker}
    //             onClick={() => handleToggleOpen(location)}
    //             onMouseOver={(e) => {
    //               setOpenInfoWindowMarkerId(location.swapStationId);
    //             }}
    //           >
    //             {location.swapStationId === openInfoWindowMarkerId && (
    //               <InfoWindow>
    //                 <span style={{ color: "green" }}>
    //                   <p>Station No: {location.swapStationId}</p>
    //                   <p>Station Name: {location.swapStationName}</p>
    //                 </span>
    //               </InfoWindow>
    //             )}
    //           </Marker>
    //         ))}
    //     </GoogleMap>
    //   </LoadScript>
    // </div>
    <>
      <GoogleMapWrapper renderArray={renderArray} mapZoom={4}>
          {placeVals &&
             placeVals.map((location, i) => (
               <Marker
                 key={location.swapStationId}
                 position={location}
                 icon={Swapstation}
                 //icon={iconMarker}
                onClick={() => BookingSlot(location)}
                 onMouseOver={(e) => {
                  setOpenInfoWindowMarkerId(location.swapStationId);
               }}
             >
                 {location.swapStationId === openInfoWindowMarkerId && (
                 <InfoWindow>
                    {/* <span style={{ color: "green" }}>
                      <p>Station No: {location.swapStationId}</p>
                         <p>Station Name: {location.swapStationName}</p>
                     </span> */}
                     <StationInfoWindow data={location}/>
                   </InfoWindow>
                 )}
              </Marker>
            ))}
      </GoogleMapWrapper>
        {/* <Fab sx={{position:'fixed', bottom:'30px', left:'50%', transform:'translateX(50%)', zIndex:9999, width:'130px', px:1, py:.8}} variant="extended" color="secondary" onClick={() => goListView()}>
        <Icon sx={{display:'flex', fontSize:'22px', mr:1}}> <PiListBulletsBold /> </Icon>List View
      </Fab> */}
    </>
  );
};

export default SwapMap;
