
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { Toolbar } from "@mui/material";

const SidebarLayout = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const drawerToggle = () => {
    setOpen(!open);
  };

  const MainContent = styled(Box)({   
    width: "100%",
    height:'100vh',
    background:'#f9f9f9',
    overflowY:'hidden'
  });

  const ContainerWrapper = styled(Box)({
    width: "100%",
    padding: "0px 0px",
    overflowY:'hidden',
    height:'100%'
  });

  useEffect(() => {
    setInterval(() => {
      if ("true" === window.localStorage.getItem("expjwToken")) {
        navigate("/login");
      }
    }, 35000);
  }, [navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <Header />
      {/* <Header open={open} drawerToggle={drawerToggle} /> */}
      {/* <Sidebar open={open} /> */}
     <Sidebar />
      <MainContent>
      <Toolbar />
        <ContainerWrapper>
          <Outlet />
        </ContainerWrapper>
      </MainContent>
    </Box>
  );
};

export default SidebarLayout;
