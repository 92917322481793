import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import SwapStationServices from "../../services/swapStationService";

export default function ListImage({ swapToEdit }) {
  const [responseData, setResponseData] = useState([]);

  useEffect(async () => {
    await fetchImageList();
  }, []);

  const fetchImageList = async () => {
    const request = {
      stationId: swapToEdit,
    };
    console.log(swapToEdit);

    const responseData = (await SwapStationServices.getImageList(request)).data;
    setResponseData(responseData.imageData);
  };

  return (
    <ImageList
      sx={{
        position: "absolute",

        width: 750,
        height: 500,
        bgcolor: "background.paper",
        boxShadow: 24,
      }}
      cols={3}
      rowHeight={164}
    >
      {(responseData || []).map(
        (
          item // TODO: remove "|| []"
        ) => (
          <ImageListItem key={item.imageUrl}>
            <div
              style={{
                width: "250px",
                height: "250px",
                objectFit: "contain",
              }}
            >
              <img
                src={`${item.imageUrl}?w=114&h=114&fit=crop&auto=format`}
                // alt={item.title}
                loading="lazy"
                width="100%"
              />
            </div>
          </ImageListItem>
        )
      )}
    </ImageList>
  );
}
