import React, { useEffect, useState,useCallback } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./treeview.css";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Grid from '@mui/material/Grid';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MainDataLine from "./MainDataLine.js";
import MainDataLine1 from "./MainDataLine1.js";
import MainDataLine2 from "./MainDataLine2.js";
import MainDataLine3 from "./MainDataLine3.js";
import MainDataAccount3 from "./MainDataAccount3.js";
import MainDataLine4 from "./MainDataLine4.js";
import MainDataAccount4 from "./MainDataAccount4.js";
import MainDataAccount5 from "./MainDataAccount5.js";
import treeviewService from '../../services/treeviewService';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import '@szhsin/react-menu/dist/index.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import Divider from '@mui/material/Divider';

const TextFieldStyle = {
  paddingLeft: 5,
  fontSize: "1.2rem",
  height: "18px",
  //marginTop: "4%",
  background: "#ffffff",
  //minHeight: '2px !important',
  //maxHeight: '2px !important',
};

const radioStyle={paddingLeft:"1px !important",color:"white"};
/*
const addstockdata1 = [{
  "id": 9,
  "text": "Nine"
}, {
  "id": 10,
  "text": "Ten"
}, {
  "id": 11,
  "text": "Eleven"
}, {
  "id": 12,
  "text": "Twelve"
}, {
  "id": 13,
  "text": "Thirteen"
}];
*/
const divStyle = { width: '77%',  backgroundColor: "#FFFFFF" };
/*
const mainDivStyle = {
  pointerEvents: "auto",
  color: "#99a9b1e8"
};
*/
export const SearchBar = ({ setSearchQuery }) => (
  <form>
    <div style={{paddingLeft: "10px",  paddingTop: "4px", paddingBottom: "0px"}}> 
    &nbsp;<TextField
      id="search-bar"      
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      inputProps={{
        style: TextFieldStyle,
        autoComplete: "off"
      }}
      //label="Search"
      variant="outlined"
      autoComplete="off"
      placeholder="Search..."
      size="small"      
      style={divStyle}
    />
    <IconButton type="button" aria-label="search">
      <SearchIcon style={{ fill: "blue" }} />
    </IconButton>
    </div>
  </form>
);
const filterData = (query, stockdata) => {
  if (!query) {
    return stockdata;
  } else {
    return stockdata.filter((d) => d.text.toLowerCase().includes(query));
  }
};



const MainList = (prop) => {
  const scrolldiv = prop.scrolldiv;
  //const addstockdataNew = prop.addstockdata;
  const [searchQuery, setSearchQuery] = useState("");
  const [expanded, setExpanded] = useState('');
  const [elementList, setElementList] = useState('');
  const [countStatus, setCountStatus] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);

  const orgType = prop.orgType;
    var listDivStyleColor="#ffffff";
    var listDivStylebgColor="#233958";
    var list1DivStyleColor="#ffffff";
    var list1DivStyleBgColor="#465d80 !important";
    var addBoxIconColor="#ccc3c3";
    var listDivStylePaddingLeft="5%";
    var listDivStyleLeft=17;
    if(orgType===1){
    listDivStyleColor="#ffffff";
    listDivStylebgColor="#233958";
    list1DivStyleColor="#ffffff";
    list1DivStyleBgColor="#465d80 !important";
    addBoxIconColor="#ccc3c3";
    }else if(orgType===2){ 
    listDivStyleColor="#ffffff";
    listDivStylebgColor="#64b7c5";
    list1DivStyleColor="#ffffff";
    list1DivStyleBgColor="#6ac3c5 !important";
    addBoxIconColor="#fffdda";
    }else if(orgType===3){
    }else if(orgType===5 || orgType===7 || orgType===8 || orgType===10){
      listDivStylePaddingLeft= "7%";
      listDivStyleLeft=26;
    }
    const listDivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.1, pointerEvents: "none",  background: listDivStylebgColor, color: listDivStyleColor,paddingTop:"0.4%",
    marginLeft: "-1.5%",paddingLeft:listDivStylePaddingLeft,
    "&:before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 32,
        left: listDivStyleLeft,
        top: 23,     
        borderLeft: "1px solid white",
        height: "150px",
        fontWeight:"400px"
        }    
    };
    const list1DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "none", background: list1DivStyleBgColor, color: list1DivStyleColor,
    paddingTop:"0.3%",position: "relative",
      "&:before": {
          pointerEvents: "none",
          content: '""',
          position: "absolute",
          width: 34,
          left: 12,
          top: 0,     
          borderLeft: "1px solid white",
          height: "52px",
          fontWeight:"400px"
        }, 
        "&:after": {
          pointerEvents: "none",
          content: '""',
          position: "absolute",
          width: 14,
          left: 12,
          top: 16,     
          borderBottom: "1px solid white",        
          fontWeight:"400px"
          }            
    };
  const onClickfn = useCallback((checkval,val) => {     
    prop.onClickfn(checkval,val);  
      return null;
  }, [prop]);
    
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);  
      return null;
  }, [prop]);  

  const expandValChk = useCallback((val) => {    
    if(true===val){
      //setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
    }else{
      //setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    }
    return null;
    }, []);

    const onloadMoreLastfn = useCallback(async (count,index,requestObj) => {  
     
      return null;
    }, []);

  const elementData = (request,dataObj, list1DivStyle, scrolldiv, countStatus,dataArray,request1,dataArray1) => {
    if(orgType===2){       //Reseller =>customer-fleet-vehicle
        return <MainDataLine2 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===3){  //Reseller =>region-dealer-customer-fleet-account-vehicle     
        return <MainDataLine {...{ dataObj: dataObj, list1DivStyle: list1DivStyle, scrolldiv: scrolldiv, countStatus,onClickfn:onClickfn,expandFleetDataShowfn:expandFleetDataShowfn,dataArray:dataArray,orgType:prop.orgType  }} />
    }else if(orgType===4){  //Reseller =>customer-fleet-account-vehicle     
       return <MainDataLine2 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===5){  //Dealer =>customer-fleet-account-vehicle     
      return <MainDataLine2 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===6){  //Dealer =>customer-fleet-account-vehicle     
      return <MainDataLine2 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===7){  //Region => dealer =>customer-fleet-vehicle     
      return <MainDataLine1 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===8){  //customer=>fleet-vehicle     
      return <MainDataLine3 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandValChk={expandValChk} orgType={orgType} />;
    }else if(orgType===9){  //customer=>fleet-Account-vehicle     
      return <MainDataAccount3 selectedObject={request} dataArray={dataArray} onClickfn={onClickfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={prop.orgType} />;
    }else if(orgType===10){  //customer=>fleet-Account-vehicle     
      return <MainDataLine4 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandValChk={expandValChk} orgType={orgType} />;
    }else if(orgType===11){  //customer=>fleet-Account-vehicle     
      return <MainDataAccount4 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandValChk={expandValChk} orgType={orgType} />;
    }
    else if(orgType===12){  //Account-vehicle   
      return <MainDataAccount5  selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} orgType={orgType} />;    
    }    
    else{  //Reseller =>region-dealer-customer-fleet-vehicle
        return <MainDataLine {...{ dataObj: dataObj, list1DivStyle: list1DivStyle, scrolldiv: scrolldiv, countStatus,onClickfn:onClickfn,expandFleetDataShowfn:expandFleetDataShowfn,dataArray:dataArray,orgType:prop.orgType  }} />
     }
    }
  const fetchData = async () => {
    var request = {};
    var region = {};
    if(orgType===5 || orgType===6){
      request = {}; 
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "dealer";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }else if(orgType===7){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "region";
      request.vehModel = "All";
      request.searchText = "";     
      region = {};
      region.region = "All";
    }else if(orgType===8){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "customer";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }else if(orgType===9){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "customer";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }else if(orgType===10){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "fleet";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }else if(orgType===11){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "fleet";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }else if(orgType===12){ 
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "account";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }
    else{
      request = {};
      request.resellerId = 0;
      request.orgId = 0;
      request.page = 0;
      request.size = 10;
      request.value = "reseller";
      request.vehModel = "All";
      request.searchText = "";
      region = {};
      region.region = "All";
    }
    let response = await treeviewService.getOrgGridViewForTreeview(request, region)
    let responseData1 = await response.data.data;
    if (responseData1 !== []) {
      setExpanded("panel" + responseData1.content[0].key);
      setCountStatus(false);
      var arrayList1 = [];
      arrayList1 = filterData(searchQuery, responseData1.content);
      setDataFiltered(arrayList1);
      var request1 = {};
      var region1 = {};
      var region2 = {};
      if(orgType===2){ 
        request1={};
        request1.resellerId = arrayList1[0].key;
        //request.regionId = prop.selectedObject.regionId;
        //request.dealerId = index;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "customer";
        request1.searchText = "";
        request1.vehModel = "1";
        region1 = {};
        region1.region = 1;        
      }else if(orgType===5 || orgType===6){ 
        request1={};
        request1.dealerId = arrayList1[0].key;
        //request.regionId = prop.selectedObject.regionId;
        //request.dealerId = index;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "customer";
        request1.searchText = "";
        request1.vehModel = "1";
        region1 = {};
        region1.region = 1;        
      }else if(orgType===7){
        request1={};
        //request1.resellerId = index;
        request1.regionId = arrayList1[0].key;
        //request.regionId = prop.selectedObject.regionId;
        //request.dealerId = index;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "dealer";
        request1.searchText = "";
        request1.vehModel = "1";
        region1 = {};
      }else if(orgType===8){
        request1={};
        //request1.resellerId = index;
        request1.customerId = arrayList1[0].key;
        //request.regionId = prop.selectedObject.regionId;
        //request.dealerId = index;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "fleet";
        request1.searchText = "";
        region1 = {};
        request1.vehModel = "1";        
      }else if(orgType===9){ 
        request1={};
         //request1.resellerId = index;
         request1.customerId = arrayList1[0].key;
         //request.regionId = prop.selectedObject.regionId;
         //request.dealerId = index;
         request1.orgId = 0;
         request1.page = 0;
         request1.size = 10;
         request1.region = 0;
         request1.vehModel = "2";
         request1.value = "fleet";
         request1.searchText = "";
         region1 = {};
         request1.vehModel = "1";     
      }
       else if(orgType===4){ 
        request1={};
        request1.resellerId = arrayList1[0].key;
        //request.regionId = prop.selectedObject.regionId;
        //request.dealerId = index;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "customer";
        request1.searchText = "";
        request1.vehModel = "1";
        region2 = {};
        region2.region = 1;        
      }else if(orgType===10){ 
        request1={};
        request1.resellerId = 0;
        request1.orgId = arrayList1[0].key;
        request1.page = 0;
        request1.size = 10;
        request1.value = "vehicle";
        request1.vehModel = "1";
        request1.searchText = "";
        region2 = {};
        region2.region = "1";
      }else if(orgType===11){ 
        request1={};
        request1.resellerId = 0;
        request1.orgId = arrayList1[0].key;
        request1.page = 0;
        request1.size = 10;
        request1.value = "account";
        request1.vehModel = "1";
        request1.searchText = "";
        region2 = {};
        region2.region = "1";
      }else if(orgType===12){ 
        request1={};
        request1.resellerId = 0;
        request1.orgId = 0;
        request1.accountId = arrayList1[0].key;
        request1.page = 0;
        request1.size = 10;
        request1.value = "vehicle";
        request1.vehModel = "1";
        request1.searchText = "";
        region2 = {};
        region2.region = "1";
      }else{
        request1.resellerId = arrayList1[0].key;
        request1.orgId = 0;
        request1.page = 0;
        request1.size = 10;
        request1.value = "region";
        request1.searchText = "";
        request1.vehModel = "1";
        region2 = {};
        region2.region = 1;
      }     
       await treeviewService.getOrgGridViewForTreeview(request1, region2)
        .then(res => {
          var stockdata = res.data.data;
          if(orgType===8){ 
            //request1.checked=true;
            //prop.expandFleetDataShowfn(request1);
            var request2 = {};
            request2.resellerId = 0;
            request2.regionId = 0;
            request2.dealerId = 0;
            request2.customerId = arrayList1[0].key;
            request2.accountId = 0;
            request2.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
            request2.page = 0;
            request2.size = 10;
            request2.region = 0;
            request2.vehModel = "2";
            request2.value = "vehicle";
            request2.searchText = "";
            request2.vehModel = "1";
            var region2 = {};
            region2.region = 1;
            var stockdata1 = {};
            treeviewService.getOrgGridViewForTreeview(request2, region2)
            .then(res1=> {
              stockdata1 = res1.data.data; 
              setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,request2,stockdata1.content));                
              }).catch(err=>{})
             }else if(orgType===10){                 
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===11){                
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===12){                 
                setElementList(elementData(request,arrayList1, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));                
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }
              else{
                setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,null,null)); 
              }                
        })
        .catch(err => {
          console.log(err);
        });  
    }
    return "";
  }

  useEffect(() => {
    fetchData();
  }, []);
  const handleChange2 =
   (index, panel, dataObj) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
        if (newExpanded) {        
        setCountStatus(true);
        var request1 = {};
        var region1 = {};
        var region2 = {};
        if(orgType===2){ 
            request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===4){ 
            request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===5 || orgType===6){ 
            //request1.resellerId = index;
            request1.dealerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "customer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===7){ 
            //request1.resellerId = index;
            request1.regionId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "dealer";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===8){ 
            //request1.resellerId = index;
            request1.customerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "fleet";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===9){ 
            request1 = {};
            //request1.resellerId = index;
            request1.customerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "fleet";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===10){ 
            request1 = {};
            //request1.resellerId = index;
            //request.regionId = prop.selectedObject.regionId;
            //request.dealerId = index;
            //console.log("index value:"+index);
            request1.orgId = index;
            request1.page = 0;
            request1.size = 10;
            request1.region = 0;
            request1.vehModel = "2";
            request1.value = "vehicle";
            request1.searchText = "";
            request1.vehModel = "1";
            region1 = {};
            region1.region = 1;        
          }else if(orgType===11){ 
            request1.resellerId = 0;
            request1.orgId = index;
            request1.page = 0;
            request1.size = 10;
            request1.value = "account";
            request1.vehModel = "1";
            request1.searchText = "";
            region2 = {};
            region2.region = "1";
          }else if(orgType===12){ 
            request1.resellerId = 0;
            request1.accountId = index;
            request1.page = 0;
            request1.size = 10;
            request1.value = "vehicle";
            request1.vehModel = "1";
            request1.searchText = "";
            region2 = {};
            region2.region = "1";
          }else{
            request1.resellerId = index;
            request1.orgId = 0;
            request1.page = 0;
            request1.size = 10;
            request1.value = "region";
            request1.searchText = "";
            request1.vehModel = "1";
            region2 = {};
            region2.region = 1;                
          }     
         treeviewService.getOrgGridViewForTreeview(request1, region2)
          .then(res => {           
            var stockdata = res.data.data;
            if(orgType===8){ 
              request1.checked=true;
              prop.expandFleetDataShowfn(request1);
              var request2 = {};
              request2.resellerId = 0;
              request2.regionId = 0;
              request2.dealerId = 0;
              request2.customerId = index;
              request2.accountId = 0;
              request2.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
              request2.page = 0;
              request2.size = 10;
              request2.region = 0;
              request2.vehModel = "2";
              request2.value = "vehicle";
              request2.searchText = "";
              request2.vehModel = "1";
              var region2 = {};
              region2.region = 1;
              var stockdata1 = {};
              treeviewService.getOrgGridViewForTreeview(request2, region2).then(res1 => { 
                stockdata1 = res1.data.data;
                setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,request2,stockdata1.content));              
              }).catch(err => {
                console.log(err);
              });             
              }else if(orgType===10 || orgType===11){              
                setElementList(elementData(request1,stockdata.content, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));            
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else if(orgType===12){              
                setElementList(elementData(request1,dataObj, list1DivStyle, scrolldiv, countStatus,stockdata.content,request1,stockdata.content));            
                request1.checked=true;
                prop.expandFleetDataShowfn(request1);
              }else{
                setElementList(elementData(request1,dataObj, list1DivStyle, scrolldiv, countStatus,stockdata.content,null,null)); 
               }
              })
          .catch(err => {
            console.log(err);
          });         
        } else {       
          if(orgType===10 || orgType===11 || orgType===12){
            request1={};
            request1.checked=false;
            prop.expandFleetDataShowfn(request1);
          }
      }
    };

  const [hoverVal1,setHoverVal1]=useState("dragMenu");
  const [hoverVal2,setHoverVal2]=useState("dragMenu");
  const [isItalic, setItalic] = useState(false);
  const menuItemClassName = ({ hover }) =>(hover ? 'my-menuitem-hover' : 'dragMenu');
  function fnhoverVal1(){
    // setHoverVal2("my-menuitem-hover");
    // setHoverVal1("dragMenu");
    setHoverVal2("dragMenu");
    setHoverVal1("my-menuitem-hover1");
  }
  function fnhoverVal2(){
    setHoverVal1("dragMenu");
    setHoverVal2("my-menuitem-hover1");
  }
  function fnhoverValRevert(){
    setHoverVal1("dragMenu");
    setHoverVal2("dragMenu");
  }
  function italicVal(e){
    setItalic(e.target.checked);
  } 
  
  const listItems = dataFiltered.map((dataObj, index) => (
    <div
      className="text"
      key={dataObj.key}
    >
      <Accordion expanded={expanded === "panel" + dataObj.key} onChange={handleChange2(dataObj.key, 'panel' + dataObj.key, dataObj)} >
        <AccordionSummary sx={listDivStyle}  >
        <ListAltSharpIcon sx={{width:"18px",height:"18px",marginTop:"-0.3%",marginLeft:"-6px"}} />
        {/* &nbsp;{dataObj.value} */}
        &nbsp;{orgType===7 && ((dataObj.value === "1") ? "North" : ((dataObj.value === "2") ? "South" : (dataObj.value === "3") ? "East" : (dataObj.value === "4") ? "West" : (dataObj.value === "2") ? "South" : "NorthEast")
                )} {orgType!==7 && dataObj.value} ({dataObj.count})
          <IndeterminateCheckBoxIcon
            sx={{
              marginLeft: "auto",
              pointerEvents: "auto",
              color:addBoxIconColor,
              marginTop:"-0.7%"
            }} style={{ display: (expanded === "panel" + dataObj.key) ? 'block' : 'none' }}
          /><AddBoxIcon
            sx={{
              marginLeft: "auto",
              pointerEvents: "auto",
              color:addBoxIconColor,
              marginTop:"-0.7%"
            }} style={{ display: (expanded === "panel" + dataObj.key) ? 'none' : 'block' }}
          />
        </AccordionSummary>
        <AccordionDetails>
          {elementList}        
        </AccordionDetails>
      </Accordion>
      <Divider light />
    </div>
  ));
  return (
    <div>
     
        <div style={{ background: "#b5c7d3" }}  >
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}  onClick={prop.testfunctn()}/>
        </div>
        <div style={{ marginTop: '2%', marginBottom: '2%' }}>
          <Grid container >
            <Grid item xs={3}>
              &nbsp;&nbsp;&nbsp;<FormatListBulletedOutlinedIcon
                sx={{
                  marginLeft: "auto",
                  pointerEvents: "pointer"
                }}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={2}  >
              <Menu  id="dragMenu"   onItemClick={(e) => fnhoverValRevert()}
              menuClassName="my-menu" menuButton={<MenuButton>
                <SettingsSuggestIcon
                              sx={{
                                marginLeft: "auto",
                                pointerEvents: "pointer",
                              }}
                              id="basic-button"
                            />
              </MenuButton>}>
                                    <MenuItem value="label"  style={{border:"1px"}} className={menuItemClassName} onMouseEnter={fnhoverValRevert}  ><input type="checkbox" checked={isItalic} onChange={e=>italicVal(e)} id="autoFrt1"></input>
              <label for="autoFrt1">Auto Refresh (3 min)</label></MenuItem>
              <MenuItem value="hide lines"  style={{border:"1px"}} className={menuItemClassName} onMouseEnter={fnhoverValRevert}><input type="checkbox" checked={isItalic} onChange={e => setItalic(e.target.checked)} id="hideLines"></input>
              <label for="hideLines">Hide Lines</label></MenuItem>
              <MenuItem value="hide lines"  style={{border:"1px"}} className={menuItemClassName} onMouseEnter={fnhoverValRevert}><input type="checkbox" checked={isItalic} onChange={e => setItalic(e.target.checked)} id="shwGeofence"></input>
              <label for="shwGeofence">Show Geofence</label></MenuItem>
              <MenuItem value="Show Fuel Alert"  style={{border:"1px"}} className={menuItemClassName} onMouseEnter={fnhoverValRevert}><input type="checkbox" checked={isItalic} onChange={e => setItalic(e.target.checked)}  id="showFuelAlert"></input>
              <label for="showFuelAlert"> Show Fuel Alert1</label></MenuItem>
              <div onMouseEnter={fnhoverVal1}>
                <SubMenu label="Show Alerts" id="dragMenu" className={hoverVal1}  onMouseEnter={fnhoverVal1}>
                        <RadioGroup>
                          <MenuItem className={menuItemClassName}  ><Radio value="label1" sx={radioStyle} id="l1" name="test" size="small"  /><label for="l1">Geo Zone</label></MenuItem>
                          <MenuItem  className={menuItemClassName}><Radio value="label2"  sx={radioStyle} id="l2" name="test" size="small" /><label for="l2">Over Speed</label></MenuItem>   
                          <MenuItem  className={menuItemClassName}><Radio value="label3"  sx={radioStyle} id="l3" name="test" size="small" /><label for="l3">Ignition</label></MenuItem>   
                          <MenuItem className={menuItemClassName} ><Radio value="label4"  sx={radioStyle} id="l4" name="test" size="small" /><label for="l4">Ideal</label></MenuItem>                        
                        </RadioGroup>
                      </SubMenu>
                      </div>
                      <div onMouseEnter={fnhoverVal2}>
                      <SubMenu label="Operators/Dealers" id="dragMenu" className={hoverVal2}  onMouseEnter={fnhoverVal2}>
                          <RadioGroup sx={{padding:"1px"}}>
                          <MenuItem className={menuItemClassName} ><Radio value="label1"  sx={radioStyle} id="ll1" name="test" size="small"  /><label for="ll1">Operator View</label></MenuItem>
                          <MenuItem  className={menuItemClassName}><Radio value="label2"  sx={radioStyle} id="ll2" name="test" size="small" /><label for="ll2">Dealers View</label></MenuItem>   
                          </RadioGroup>
                        </SubMenu>
                        </div>
                      </Menu>          
            </Grid>
          </Grid>
        </div>
      
          <div style={{ maxHeight: scrolldiv.maxHeight, overflow: scrolldiv.overflow,fontWeight: "bold", fontSize: "16px",fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif" }}>
            {listItems}
          </div>
       
      </div>
   
  );
}

export default React.memo(MainList);