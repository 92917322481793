import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React,{useState,useEffect} from 'react'
import InputWrapper from './InputWrapper'
import { useTheme } from '@mui/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DatePicker = ({ label, name, defaultValue }) => {
    const theme = useTheme()
    const [value, setValue] = useState(defaultValue);  
    useEffect( async ()=>{
      setValue(defaultValue);
    },[]);

    const labelStyle = {
        fontSize: theme.text.fontSize,
        color: 'black',
        fontWeight: 600
      }
        
      const handleChange = (newValue) => {
        setValue(newValue);
      };

    return (
      <InputWrapper>
        <FormControl >
        <FormLabel sx={labelStyle}>{label}</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>    
          <DesktopDatePicker            
              inputFormat="dd/MM/yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} name={name} />}
            />        
        </LocalizationProvider>       
        </FormControl>
      </InputWrapper>
    )
  }
export default DatePicker