import  React from 'react';
import Box from '@mui/material/Box';
import {Grid, Button, Input} from '@mui/material';

import { useTheme } from '@mui/styles';
import { ORGANIZATION_DETAIL,  ORGANIZATION_ADMIN_DETAIL} from '../../constants/accountFormFields';
import TextInput from '../shared/TextInput';
import RadioInput from '../shared/RadioInput';
import SelectInput from '../shared/SelectInput';
import organizationServices from '../../services/organizationServices';
import { useState, useEffect } from 'react';

export default function AccountSettings() {

 

  const [org, setOrg] = useState({})
  useEffect( async ()=>{
    var orgId=0;
    var jsonObj={};
    if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
    } else{
      orgId=JSON.parse(localStorage.getItem("userData")).orgId;    
    }
    jsonObj.orgId=orgId;
   
        const responseData = (await organizationServices.getOrganization(jsonObj));
        setOrg(responseData.data.data);
      
    
  }, [setOrg]);
    
  const id = 1;
  console.log(id);   

   
  const theme = useTheme()

  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: 'none',
    color: '#fff',
    width:"100px",
    m:1
  }

  const FormLayout = ({ children, title }) => {
    return (
      <Box sx={{ marginY: '40px',marginX: '20px', '& .MuiOutlinedInput-root':{height:'25px'}, 
      '& .MuiFormControlLabel-label':{fontSize: theme.text.fontSize},
      '& .MuiSvgIcon-root':{ height:'15px', width:'15px'} }}>
        <fieldset style={{ border: '1px solid black' }}>
          <legend style={{fontWeight: 600}}> {title} </legend>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </fieldset>
      </Box> 
    )
  }


  const handleSubmit = (e) => {
    e.preventDefault();
      const { target } = e;
      updateOrganization(e,Object.fromEntries(new FormData(target)));
  }
  
  
  const updateOrganization = async (e,obj) => {
    e.preventDefault();
    
    const responseData=(await organizationServices.updateOrganization(obj))
    
    var obj1={};  
      obj1.dialogButtonText="Update Status";
      obj1.submitButtonText="";
      obj1.closeButtonText="Close";
      obj1.dialogText=responseData.data.resultText;
  }


  const FormFields = ({ fields }) => {
    return (<>
      {
        fields.map((field, index)=> {
          if (field.type === 'text') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput  label={field.label} name={field.name} defaultValue={org!==null?org[field.name]:''}/>
              </Grid>
            )
          } else if (field.type === 'select') {
            return (
              <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center'}} >
                <SelectInput name={field.name} label={field.label } options={field.options} defaultValue={org!==null?(org[field.name]):(field.options[1].value)}/>
                </Grid>
            )
          }
          else if (field.type === 'radio') {
            return(
            <Grid key={index} item md={6} sx={{ display: 'flex', justifyContent: 'center'}}>
              <RadioInput name={field.name} options={field.options} />
              </Grid>
            )
          }
        })
      }
    </>
    )
  }

 
  return (
    <form onSubmit={handleSubmit} >
      <FormLayout title={'Organization Detail'}>
        <FormFields fields={ORGANIZATION_DETAIL} />
      </FormLayout>
      <FormLayout title={'Organization Admin Detail'}>
        <FormFields fields={ORGANIZATION_ADMIN_DETAIL} />
      </FormLayout>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>

      <Button variant="contained" sx={buttonStyle} type='submit'>Update</Button>
      <Button variant="contained" sx={buttonStyle} >Cancel</Button>
      <input name='id'  value={id} type="hidden"></input>
      </Grid>
      
    </form>
  )
}

