import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { DataTable } from '../shared/data_table/DataTable';
import { Grid, Paper } from '@mui/material';
import customerService from '../../services/customerService';
import SimServices from '../../services/simServices';
import { SIM_LIST_DETAIL } from '../../constants/simFormFields';
import AdminSelectInput from '../shared/AdminSelectInput';



const ResellerId = 0;
const ListSim = ({ onEditSim }) => {

  const [responseData, setResponseData] = useState([])




  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'buttonCheckBox'
        },
        {

          Header: 'Customer',
          accessor: 'customerId'
        },
        {
          Header: 'Sim Number',
          accessor: 'simNumber'
        },
        {
          Header: 'SIM 1',
          columns: [
            {
              Header: 'Mobile Number',
              accessor: 'mobileNumber'
            },
            {
              Header: 'Provider Name',
              accessor: 'simProviderId'
            },
            {
              Header: 'Plan Name',
              accessor: 'simPlanId'
            },
          ]
        },
        {
          Header: 'SIM 2',
          columns: [
            {
              Header: 'Mobile Number',
              accessor: 'mobileNumber1'
            },
            {
              Header: 'Provider Name',
              accessor: 'simProviderId1'
            },
            {
              Header: 'Plan Name',
              accessor: 'simPlanId1'
            },
          ]
        },
        {
          Header: 'Status',
          accessor: 'simStatus'
        },
        {
          Header: 'Assign To Device',
          accessor: 'simTestActivationDate'
        },
        {
          Header: 'Last Connected',
          accessor: 'simExpiredDate'
        },
        {
          Header: 'Vehicle Status',
          accessor: 'status'
        },
        {
          Header: 'Created Date',
          accessor: 'createdDate'
        }
        ,
        {
          Header: 'Action',
          accessor: 'buttonEditDelete'
        },
      ]

  )

  const options = [
    {
      id: 1,
      value: 'test1',
      title: 'test1'
    },
    {
      id: 2,
      value: 'test2',
      title: 'test2'
    },
    {
      id: 3,
      value: 'test3',
      title: 'test3'
    }
  ]

  const selectSettings = {
    includeSearchBox: true,
    handleSearchTextChange: async (newText) => {
      const customerList = await customerService.getSubResellerList({ ResellerId })
    },
    includeAllOption: true,
    searchBoxStyles: {

    }
  }

  const handleSelectChange = async (id) => {
    const { id: ResellerId } = options.find(option => option.id === id)
    const customerList = await customerService.getSubResellerList({ ResellerId })
    console.log({ customerList })
  }

  const onEdit = (handleSimEdit) => {
    onEditSim(handleSimEdit)
  }

  const fetchOrgDetails = async () => {
    var request = {};
    request.resellerId = 12;
    request.orgId = 0;
    request.page = 0;
    request.size = 100;
    request.searchText = "String";


    try {
      const responseData = (await SimServices.getSim(request)).data
      setResponseData(responseData.data.content);
    } catch (error) {

    }


  }

  useEffect(async () => {
    await fetchOrgDetails()

  }, [])

  const onDelete = async (itemDeleted) => {
    console.log(itemDeleted.id)

    try {
      await SimServices.deleteSim(itemDeleted.id)
      await fetchOrgDetails()
    } catch (err) {
      console.error('something went wrong')
      console.log({ err })
    }
  }

  const OrgListDropDown = () => {

    const [hideFleetDropDown, setHideFleetDropDown] = useState(false)

    const handleClick = (event) => {
      const orgType = event.target.value
      if (orgType == 'Fleet') {
        setHideFleetDropDown(true)
      } else {
        setHideFleetDropDown(false)
      }
    }

    return (
      <Grid container>
        <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <AdminSelectInput
            handleClick={handleClick}
            name={SIM_LIST_DETAIL[0].name}
            label={SIM_LIST_DETAIL[0].label}
            options={SIM_LIST_DETAIL[0].options}
            defaultVal={SIM_LIST_DETAIL[0].options[0].title} />
        </Grid>
        <Grid item
          md={2}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <AdminSelectInput
            handleClick={handleClick}
            name={SIM_LIST_DETAIL[1].name}
            label={SIM_LIST_DETAIL[1].label}
            options={SIM_LIST_DETAIL[1].options}
            defaultVal={SIM_LIST_DETAIL[1].options[0].title} />
        </Grid>
        <Grid item
          md={2}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <AdminSelectInput
            handleClick={handleClick}
            name={SIM_LIST_DETAIL[2].name}
            label={SIM_LIST_DETAIL[2].label}
            options={SIM_LIST_DETAIL[2].options}
            defaultVal={SIM_LIST_DETAIL[2].options[0].title} />
        </Grid>
        <Grid item
          md={2}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <AdminSelectInput
            handleClick={handleClick}
            name={SIM_LIST_DETAIL[3].name}
            label={SIM_LIST_DETAIL[3].label}
            options={SIM_LIST_DETAIL[3].options}
            defaultVal={SIM_LIST_DETAIL[3].options[0].title} />
        </Grid>
        {hideFleetDropDown && <Grid item
          md={2}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <AdminSelectInput
            handleClick={handleClick}
            name={SIM_LIST_DETAIL[2].name}
            label={SIM_LIST_DETAIL[2].label}
            options={SIM_LIST_DETAIL[2].options}
            defaultVal={SIM_LIST_DETAIL[2].options[0].title} />
        </Grid>}
      </Grid>
    )
  }

  const handleSearch = (searchText) => {
    console.log({ searchText })
  }


  return (
    <>
      <Paper elevation={5} sx={{ height: '10vh' }}>

        <OrgListDropDown />
      </Paper>
      <Box display="flex" flexDirection="column">
        <DataTable includeSearchBox onSearch={handleSearch} onEdit={onEdit} onDelete={onDelete} columns={columns} data={responseData} styles={{ maxWidth: '89vw' }} numberOfStickyColumns={1} />
      </Box>
    </>
  )
}

export default ListSim