import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import PlayBackMarker from "./PlayBackMarker";
import { GOOGLE_MAP_KEY } from "./../../constants/appConstant";
import playBackService from "../../services/playBackService";

const containerStyle = {
  height: "95vh",
  width: "100%",
};

const defaultProps = {
  center: { lat: 18.560927, lng: -68.402459 },
  zoom: 9,
};

const libraries = ["drawing", "places", "geometry"];

const GoogleMapTracker = () => {
  const [path, setPath] = useState([]);

  useEffect(async () => {
    const data = (await playBackService.getPlayBackData()).data.data;
    setPath(data);
  }, []);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} libraries={libraries}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
      >
        <PlayBackMarker path={path} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapTracker;
