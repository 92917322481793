import React, { useEffect } from "react";
import { Divider, Grid, IconButton, Link, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import NameDialog from "./ps-name";
import EmailDialog from "./ps-email";
import PhoneDialog from "./ps-phone";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import userService from "../../services/userService";
import UserImage from "./ps-userImage";

export default function ProfileDetails({ onEditUser }) {
  const [open, setOpen] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openPhone, setOpenPhone] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  // useEffect(async () => {
  //   const responseData = (await userService.editUserById(userLogginData.id))
  //     .data.data;
  //   setId(responseData);
  // }, [setId]);

  useEffect(async () => {
    var userLogginData = {};
    if (
      "null" === localStorage.getItem("userData") ||
      null === localStorage.getItem("userData")
    ) {
    } else {
      userLogginData = JSON.parse(localStorage.getItem("userData"));
    }
    console.log("console:" + userLogginData.id);
    const responseData = (await userService.editUserById(userLogginData.id))
      .data.data;
    setName(responseData.firstName + " " + responseData.lastName);
    setEmail(responseData.email);
    setPhone(responseData.mobile);
    //await fetchUserDetails();
  }, []);

  // const fetchUserDetails = async () => {
  //   // var request = {};
  //   // request.id = id;
  //   const responseData = (await userService.editUserById(id)).data.formData;
  //   setResponseData(responseData.data.content);
  // };

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenEmail = () => {
    setOpenEmail(true);
  };
  const handleClickOpenPhone = () => {
    setOpenPhone(true);
  };

  const onEdit = (itemEdited) => {
    onEditUser(itemEdited.id);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        flexDirection: "row",
        bgcolor: "background.paper",
        borderLeft: "0.1em solid #eeeeee",
        marginLeft: 8.5,
        marginTop: 2,
      }}
    >
      <Grid item xs={12} md={6}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: 400 },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography fontWeight="Bold" sx={{ color: "#2196f3" }}>
            About Me
          </Typography>
          <FormControl disabled variant="standard">
            <InputLabel
              htmlFor="userName"
              style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
            >
              Name
            </InputLabel>
            <Input
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon
                    onClick={handleClickOpen}
                    sx={{ fontSize: "18px", color: "#2196f3" }}
                  />
                  <NameDialog
                    open={open}
                    setOpen={setOpen}
                    onEdit={onEdit}
                    setName={setName}
                    name={name}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <FormControl disabled variant="standard">
            <InputLabel
              htmlFor="email"
              style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
            >
              Email
            </InputLabel>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={handleChangeEmail}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon
                    onClick={handleClickOpenEmail}
                    sx={{ fontSize: "18px", color: "#2196f3" }}
                  />
                  <EmailDialog
                    openEmail={openEmail}
                    setOpenEmail={setOpenEmail}
                    onEdit={onEdit}
                    setEmail={setEmail}
                    name={name}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <FormControl disabled variant="standard">
            <InputLabel
              htmlFor="mobile"
              style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
            >
              Phone
            </InputLabel>
            <Input
              id="mobile"
              name="mobile"
              value={phone}
              onChange={handleChangePhone}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon
                    onClick={handleClickOpenPhone}
                    sx={{ fontSize: "18px", color: "#2196f3" }}
                  />
                  <PhoneDialog
                    openPhone={openPhone}
                    setOpenPhone={setOpenPhone}
                    onEdit={onEdit}
                    setPhone={setPhone}
                    name={name}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            flexDirection: "row",
            bgcolor: "background.paper",
            marginLeft: 8,
            marginTop: 7,
          }}
        >
          <UserImage />
        </Box>
      </Grid>
    </Grid>
  );
}
