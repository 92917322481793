const FEATURE_DETAIL = [ 
    {
      type: 'text',
      label: 'Feature ID *(Ex: FeaTracking)',
      name: 'linkfeatureId'
    },
    {
      type: 'text',
      label: 'Feature Title *(Ex: Tracking)',
      name: 'featuretitle'      
    },
    {
      type: 'text',
      label: 'Feature Link * (Ex: /Tracking)',
      name: 'featurelink'     
    },   
    {
      type: 'select',
      label: 'Link Group',
      name: 'linkgroup',
      options: [
        {
          id: 1,
          title: 'Select',
          value:'0'
        }
      ]
    },
    {
        type: 'text',
        label: 'Link Order *',
        name: 'linkorder'
      },
    {
      type: 'select',
      label: 'Status',
      name: 'status',
      options: [
        {
          id: 1,
          title: 'select',
          value:'0'
        },
        {
          id: 2,
          title: 'Active',
          value:'1'
        },
        {
          id: 3,
          title: 'Inactive',
          value:'2'
        }
      ]
    }    
  ]
   
  module.exports = {
    FEATURE_DETAIL   
  }