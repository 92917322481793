import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme } from "@mui/styles";

const AdminSelectInput = ({
  handleClick,
  label,
  name,
  options = [],
  defaultVal,
}) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "1.5px 4px",
    },
  };

  return (
    <InputWrapper>
      <FormControl size="small" fullWidth>
        <FormLabel sx={labelStyle}>{label}</FormLabel>
        <Select
          defaultValue={defaultVal}
          MenuProps={MenuProps}
          name={name}
          onChange={handleClick}
          sx={selectDropdown}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                sx={{ fontSize: theme.text.fontSize, lineHeight: "1" }}
                value={option.title}
              >
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </InputWrapper>
  );
};

export default AdminSelectInput;
