import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
//import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
//import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
//import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
//import BatteryFullIcon from "@mui/icons-material/BatteryFull";


import BatteryChargingFullIcon from '../../assets/images/batteryCharged.png';
import BatteryCharging50Icon from '../../assets/images/batteryCharging.png';
import BatteryAlertIcon from '../../assets/images/Fault.png';
import LowBattery from '../../assets/images/Low.png';
import SlotEmpty from '../../assets/images/Slot_Empty.png';


import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import StyledChair from "../shared/StyledChair";
import Maps from "./MapsExample";
import SortDetails from "./slotDetail";
import { Paper, Typography } from "@mui/material";

export default function RecentActivity({ data }) {
  const [slot, setSlot] = React.useState("");
  const [elementData, setElementData] = React.useState();
  //console.log("data::"+data.slots[0]);
  const Infos = [
    {
      icon: BatteryChargingFullIcon, text: "Charged",
    },
    {
      icon: BatteryCharging50Icon, text: "Charging",
    },
    { icon: LowBattery, text: "LowCharge" },
    { icon: SlotEmpty, text: "Slot Empty" },
    { icon: BatteryAlertIcon, text: "Fault" },

  ];

  const callslot = (items) => {

 
     return <SortDetails item={items} />;

  };
  useEffect(() => {
    //setSlot("");
    //setSlot(slot.slotStatus);
    console.log("data selSlotId::" + data.selSlotId);
    if (data.selSlotId !== undefined) {
      setElementData("");
      setElementData(callslot(data.slotsSel[0]));
    }
  }, []);
  const onSlot1 = (slot) => {
    setSlot("");
    setSlot(slot.slotStatus);
    setElementData("");
    setElementData(callslot(slot));
  };

  return (
    <div>
      <Grid container sx={{ padding: "25px" }}>
        <Grid xs={4}>
          <Paper elevation={4}>
            <Maps objData={data} />
          </Paper>
        </Grid>

        <Grid xs={8} style={{paddingLeft:"10px"}}>              
              <div sx={{width:"100%"}}>
              <Paper elevation={5}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    ml: 2,
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <div >
                <Stack
                  direction="row"
                  alignItems="left"
                  mt={3}
                  gap={2}
                >
                  {Infos.map((item) => (
                    <Stack direction="row" alignItems="left" gap={1} >
                      {/* {(item.text==="Not Available") && <img src={item.icon} height="70%" width="16%"></img> } */}
                      {(item.text==="Fault") && <img src={item.icon} height="50%" width="20%"></img> }
                       {(item.text==="Charging") && <img src={item.icon} height="50%" width="20%"></img> }
                       {(item.text==="Slot Empty") && <img src={item.icon} height="50%" width="20%"></img> }
                       {(item.text==="Charged") && <img src={item.icon} height="50%" width="20%"></img> }
                       {(item.text==="LowCharge") && <img src={item.icon} height="50%" width="20%"></img> }
                       <Typography sx={{ fontSize: 14,marginTop:"5px" }}>{item.text}</Typography>
                    </Stack>
                  ))}
                  </Stack>
                  </div>
                  </Box>
                  <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    ml: 3,
                    width: "100%",
                    mt: 5,
                    pb: 2
                  }}
                >
                 {data.slots.length>0 && data.slots.map((item) => (
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center" 
                    sx={{padding:1}}
                  >
                    <StyledChair 
                      swapStatus={item.slotStatus}
                      onSlot={(e)=>onSlot1(item)}
                      item={item}                      
                    />
                  </Grid>
                ))}
                  </Box>
                </Paper>
                </div>

          <div>{elementData}</div>
        </Grid>
      </Grid>
    </div>
  );
}
