import React, { useState,useCallback } from "react";
//import MainDataAccount3 from "./MainDataAccount3";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';


const MainDataLine5 = (prop) => {
    const [checkedAnalysis,setCheckedAnalysis] =useState();   
  var orgType=prop.orgType;
  var list2DivStyleLeft=2;  
  var list2DivStylepngLeft="30px";
  var marginLeftSltNamediv="-5%";
  var marginRightSltNamediv="80%";
  var marginTopSltNamediv="6%";
  if(orgType===5){
     list2DivStyleLeft=2;
     list2DivStylepngLeft="10px";
     marginLeftSltNamediv="0%";
     marginRightSltNamediv="30%";
     marginTopSltNamediv="4%";
  }
  if(orgType===2){
    list2DivStyleLeft=68;  
    list2DivStylepngLeft="78px";
    marginLeftSltNamediv="-2%";
    marginRightSltNamediv="0%";
    marginTopSltNamediv="6%";
 }
  //color: "#ffffff"
  const list2DivStyle = { minHeight: '27px !important', maxHeight: '37px !important', gap: 0.5, pointerEvents: "auto", background: "#ffff !important",color:"black", paddingLeft:list2DivStylepngLeft
, fontWeight: "bold", fontSize: "14px",fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",position: "relative",
paddingTop:"0px",marginBottom: "-2.2%"
};

  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");

  const onClickfn = useCallback((checkval,val) => {     
      //console.log("swapstationId::"+prop.selectedObj.swapstationId+":slot Id:"+val+":checkval:"+checkval);
      prop.onClickSlotfn(checkval.checked,val,prop.selectedObj.swapstationId);  
      return null;
  }, []);

  const expandValChk = useCallback((val) => {  
    prop.expandValChk(val);
    return null;
  }, []);

  const expandFleetDataShowfn = useCallback((request) => {    
    //console.log("request,prop.selectedObj.swapstationId"+request+"::"+prop.selectedObj.swapstationId) 
    //prop.callbackSlotfn(request,prop.selectedObj.swapstationId);     
      return null;
  }, []);

  const handleChange5 = () => {
    }; 
  const onloadMoreLastfn = useCallback(async (count,index,requestObj) => {  
    var request = {};
        request.resellerId = prop.selectedObject.resellerId;
        request.regionId = prop.selectedObject.regionId;
        request.dealerId = prop.selectedObject.dealerId;
        request.customerId = requestObj.customerId;
        request.accountId = 0;
        request.orgId = 0;
        request.page = 0;
        request.size = parseInt(requestObj.size)+parseInt(10);
        request.region = 0;
        request.vehModel = "2";
        request.value = "fleet";
        request.searchText = "";
        request.vehModel = "1";
        var region = {};
        region.region = 1;
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
        let stockdata = {};
        stockdata = await responseData.data.data;
        var request1 = {};
        request1.resellerId = prop.selectedObject.resellerId;
        request1.regionId = prop.selectedObject.regionId;
        request1.dealerId = prop.selectedObject.dealerId;
        request1.customerId = requestObj.customerId;
        request1.accountId = 0;
        request1.orgId = stockdata.content !== null ? stockdata.content[0].key : 0;
        request1.page = 0;
        request1.size = parseInt(requestObj.size)+parseInt(10);
        request1.region = 0;
        request1.vehModel = "2";
        request1.value = "vehicle";
        request1.searchText = "";
        request1.vehModel = "1";
        var region1 = {};
        region1.region = 1;
        let responseData1 = await treeviewService.getOrgGridViewForTreeview(request1, region1)
        let stockdata1 = {};
        stockdata1 = await responseData1.data.data;
        setElementListServer(elementDataServer(request, stockdata.content, request1, stockdata1.content));
     return null;
  }, []);


  const elementDataServer = (request, dataArray, request1, dataArray1) => {
    if(4===prop.orgType){
      //return <MainDataAccount3 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandFleetDataShowfn={expandFleetDataShowfn} expandValChk={expandValChk} orgType={orgType} />;
    }if(6===prop.orgType){
      //return <MainDataAccount3 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandFleetDataShowfn={expandFleetDataShowfn} expandValChk={expandValChk} orgType={orgType} />;
    }else{
      //return <MainDataLine5 selectedObj={request} dataArray={dataArray} selectedObj1={request1} dataArray1={dataArray1} onClickfn={onClickfn} loadMoreLast={onloadMoreLastfn} expandValChk={expandValChk} orgType={orgType} />;
    }
  }
  const handleChange3 =
    (index, panel) => async (newExpanded) => {
      //console.log("Its callinnnn")
      if (expandVal === true) {
        setExpandVal(false);
        setExpandedInsdL1(false);
        prop.expandValChk(false);
        var request = {};
        request.checked=false;
        prop.expandFleetDataShowfn(request);
      } else {
        prop.expandValChk(true);
       var request1={};
       var request={};
       var stockdataContent=[{"orgName":"Group A","location":"28.575665,77.195367,,,,,,,","orgId":"109927"}];
       var stockdataContent1=stockdataContent;
        setElementListServer(elementDataServer(request, stockdataContent, request1, stockdataContent1));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
      }
    };
  const returnData = (checkboxList1) => {    
    return <div>
      {checkboxList1.map((d, index) => (
        <div
          className="text"
          key={d.slotId}
        >
          <Accordion sx={{borderTop:"1px solid gray"}} expanded={expandedInsdL1 === "panelInside" + d.slotId}  >
            <AccordionSummary sx={list2DivStyle}>
                            <Grid container>
                            <Grid item xs={1}>
                                </Grid>
                                 <Grid item xs={2}  sx={{ marginTop: '0%'}} >
                                    <FormControlLabel sx={{marginLeft:marginRightSltNamediv}} control={ <Checkbox onClick={(e)=>{if(d.slotId+"radio"){setCheckedAnalysis(d.slotId);}onClickfn(e.target,d.slotId)}}  checked={d.slotId===checkedAnalysis?true:false} />} >
                                    </FormControlLabel>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: marginTopSltNamediv,marginLeft:marginLeftSltNamediv, fontSize:"98%" }}>
                                 <span>&nbsp;&nbsp;{d.slotNumber}</span>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={1} style={{ marginTop: '-1%'}} >  
                                   
                                </Grid>
                            </Grid>
                         
                    <Divider />            
            </AccordionSummary>            
          </Accordion>
          
        </div>
      ))}
    </div>;
  }
  const listElementData = returnData(prop.dataArray);  
  return (
    <div>{listElementData}   
    </div>
  );
}
export default React.memo(MainDataLine5);
