import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import SelectInput from "../shared/SelectInput";
import { Box, Stack, Typography } from '@mui/material';
function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export default function DailogBoxui({open,dialogButtonText,dialogText,endTime,closeButtonText,submitButtonText,handleSbmtClose,handleClearCls,dropdownValues,callBackFn}) {
    //const [open, setOpen] = React.useState(true);  
    
    const handleClose = () => {   
     handleClearCls();
    };
    const handleSubmitClose = () => {
        handleSbmtClose();  
        handleClearCls();  
      };    
    return (
      <div>      
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title" maxWidth = "sm"
          fullWidth
        >
          <DialogTitle style={{ cursor: 'pointer' }} id="draggable-dialog-title">
            {dialogButtonText}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* {dialogText} */}
              <Stack direction={'row'} columnGap={2}>
                  <Box>
                    <Typography variant='body2'>Start Time</Typography>
                    <Typography variant='body1' color={'text.primary'} sx={{fontWeight:500}}>{dialogText}</Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2'>End Time</Typography>
                    <Typography variant='body1' color={'text.primary'} sx={{fontWeight:500}}>{endTime}</Typography>
                  </Box>
              </Stack>
              <br />
              <SelectInput
                  name="slotavail"
                  label="Slot Available"
                  options={dropdownValues} 
                  defaultValue={0}
                  fullWidth={true}             
                  //defaultValue={device !== null? (switchCaseVal(field.name)==""?device[field.name]:switchCaseVal(field.name)):(switchCaseVal(field.name)===""?(commonList!==null?commonList[0].value:0):switchCaseVal(field.name))}
                  callBackFn={callBackFn}
                  callbackfnStatus={true}
                  //callbackfnStatus={field.callbackfnStatus===undefined?false:(field.callbackfnStatus==='true'?true:false)}
                />   

              {/* {dropdownValues} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='error' variant='outlined' size='small'>{closeButtonText}</Button>
            <Button autoFocus onClick={handleSubmitClose} size='small' color='primary' variant='contained'>
            {submitButtonText}
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }