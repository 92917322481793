import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  DEVICE_DETAIL,
  DOMAIN_DETAIL,
  SIM_DETAIL,
  SENSOR,
} from "../../constants/deviceFormFields";
import TextInput from "../shared/TextInput";
import DraggableDialog from "../shared/DailogDrag";
import RadioInput from "../shared/RadioInput";
import SelectInput from "../shared/SelectInput";
import { FormLabel } from "@mui/material";
import DatePicker from "../shared/DatePicker";
import DeviceService from "../../services/deviceService";
import moment from "moment";

//const RESTRICTED_ID = DOMAIN_DETAIL[0].options[1].value
const RESTRICTED_ID = 1;

export default function FormPropsTextFields({ deviceToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState(null);
  const [licensekey, setLicensekey] = useState([]);
  const [planList, setPlanList] = useState([]);

  const [devicePlanList, setDevicePlanList] = useState([]);
  const [mfgsitecodeList, setMfgsitecodeList] = useState([]);
  const [partNumberList, setPartNumberList] = useState([]);
  const [deviceModelNameList, setDeviceModelNameList] = useState([]);
  const [deviceFrameWareList, setDeviceFrameWareList] = useState([]);
  const [deviceHardWareList, setDeviceHardWareList] = useState([]);
  const [simPlanStatusList, setSimPlanStatusList] = useState([]);
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const [deviceSimNumberList, setDeviceSimNumberList] = useState([]);
  const [assignedobj, setAssignedobj] = useState({});
  const [statusEdit,setStatusEdit] =useState(false);
  //var assignedObj={};
  useEffect(async () => {
    if(deviceToEdit) {
        setStatusEdit(true);
        var payload={};
        payload.resellerId=11;
        payload.userid=11;
        payload.orgtype=2;
        payload.id=deviceToEdit;
        payload.createdBy="UserId";
        payload.type="Edit";
        const deviceSimNumberLists = (await DeviceService.getDeviceSimNumberLists(payload)).data;  
        setDeviceSimNumberList(deviceSimNumberLists); 
        const responseData = (await DeviceService.editOrgById(deviceToEdit)).data;
        setDevice(responseData.formData);
        const licensekeyList = (await DeviceService.getLicenseKeyList(payload)).data;  
        setLicensekey(licensekeyList);
        const planLists = (await DeviceService.getlistofPlansList(payload)).data;  
        setPlanList(planLists);   
        const devicePlanLists = (await DeviceService.getlistofDevicePlans(payload)).data;  
        setDevicePlanList(devicePlanLists); 
        const mfgsitecodeLists = (await DeviceService.getlistofMFGSiteCode(payload)).data;  
        setMfgsitecodeList(mfgsitecodeLists);   
        const partNumberLists = (await DeviceService.getlistofPartNumberList(payload)).data;  
        setPartNumberList(partNumberLists);   
        payload.type="modelname";
        const deviceModelNameLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceModelNameList(deviceModelNameLists);  
        payload.type="frameware";
        const deviceFrameWareLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceFrameWareList(deviceFrameWareLists); 
        payload.type="hardware";
        const deviceHardWareLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceHardWareList(deviceHardWareLists); 
        const simPlanStatusLists = (await DeviceService.getSimPlanStatusList(payload)).data;  
        setSimPlanStatusList(simPlanStatusLists);   
        const deviceStatusLists = (await DeviceService.getDeviceStatusList(payload)).data;  
        setDeviceStatusList(deviceStatusLists);            
      } else {
        setDevice(null);
        var payload={};
        payload.resellerId=11;
        payload.userid=11;
        payload.orgtype=2;
        payload.createdBy="UserId";
        payload.type="Create";
        const licensekeyList = (await DeviceService.getLicenseKeyList(payload)).data;  
        setLicensekey(licensekeyList);
        const planLists = (await DeviceService.getlistofPlansList(payload)).data;  
        setPlanList(planLists);
        const devicePlanLists = (await DeviceService.getlistofDevicePlans(payload)).data;  
        setDevicePlanList(devicePlanLists); 
        const mfgsitecodeLists = (await DeviceService.getlistofMFGSiteCode(payload)).data;  
        setMfgsitecodeList(mfgsitecodeLists);  
        const partNumberLists = (await DeviceService.getlistofPartNumberList(payload)).data;  
        setPartNumberList(partNumberLists);
        const deviceSimNumberLists = (await DeviceService.getDeviceSimNumberLists(payload)).data;  
        setDeviceSimNumberList(deviceSimNumberLists); 
        payload.type="modelname";
        const deviceModelNameLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceModelNameList(deviceModelNameLists);  
        payload.type="frameware";
        const deviceFrameWareLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceFrameWareList(deviceFrameWareLists);  
        payload.type="hardware";
        const deviceHardWareLists = (await DeviceService.getDeviceModelNameList(payload)).data;  
        setDeviceHardWareList(deviceHardWareLists); 
        const simPlanStatusLists = (await DeviceService.getSimPlanStatusList(payload)).data;  
        setSimPlanStatusList(simPlanStatusLists);           
        const deviceStatusLists = (await DeviceService.getDeviceStatusList(payload)).data;  
        var deviceStatusLst=[];
        if(deviceStatusLists.length>1){
            deviceStatusLst.push(deviceStatusLists[0]);
            deviceStatusLst.push(deviceStatusLists[1]);
        }
        setDeviceStatusList([...deviceStatusLst]); 
    }    
  }, []);


  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    width:"100px",
    color: "#fff",
    m: 1,
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "25px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: theme.fieldset.border, borderRadius: "12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary, fontSize: "16px" }}>
            {title}
          </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    if (!deviceToEdit) {     
      saveDeviceDetails(e, Object.fromEntries(new FormData(target)));
    } else {      
      updateDeviceDetails(e, Object.fromEntries(new FormData(target)));
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const saveDeviceDetails = async (e, obj) => {
    obj.resellerId=11;
    obj.createdBy="username";
    const responseData = await DeviceService.saveDeviceDetails(obj);
    var obj1 = {};
    if (responseData.data === 1) {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateDeviceDetails = async (e, obj) => {
    obj.resellerId=11;
    e.preventDefault();
    const responseData = await DeviceService.updateDeviceDetails(obj);
    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  
  const callBackFn=async (val)=>{    
    setStatusEdit(false);
    var obj={};
    obj.firstMobileNum=val;
    obj.firstServiceProvider=val;
    obj.selectedVal=val;
    var payload={};
    payload.simId=val;
    const simDetailsLists = (await DeviceService.getDeviceSimDetails(payload)).data;  
    var obj={};
    if(simDetailsLists!==undefined && simDetailsLists.length>0){
      obj.firstMobileNum=simDetailsLists[0].mobileNumber;
      obj.secondMobileNum=simDetailsLists[0].mobileNumber1;
      obj.simStatus=simDetailsLists[0].simStatus;
      obj.firstServiceProvider=simDetailsLists[0].simProviderName;
      obj.simProviderName1=simDetailsLists[0].simProviderName1;
      obj.selectedId=val;
    }
    setAssignedobj(obj); 
  }

  const DisplayCustomSelect=({field})=>{
    var commonList=[];
    commonList=[...field.options]
    if(field.name==="licensekey" && licensekey.length>0){
      commonList=[];
      commonList=[...licensekey];
    }   
    if(field.name==="plan" && planList.length>0){
      commonList=[];
      commonList=[...planList];
    }   
    if(field.name==="devicePlan" && devicePlanList.length>0){
      commonList=[];
      commonList=[...devicePlanList];
    }
    if(field.name==="mfgSiteCode" && mfgsitecodeList.length>0){
      commonList=[];
      commonList=[...mfgsitecodeList];
    }   
    if(field.name==="partNumber" && partNumberList.length>0){
      commonList=[];
      commonList=[...partNumberList];
    }   
    if(field.name==="deviceModel" && deviceModelNameList.length>0){
      commonList=[];
      commonList=[...deviceModelNameList];
    }
    if(field.name==="fameWare" && deviceFrameWareList.length>0){
      commonList=[];
      commonList=[...deviceFrameWareList];
    }   
    if(field.name==="hardware" && deviceHardWareList.length>0){
      commonList=[];
      commonList=[...deviceHardWareList];
    }
    if(field.name==="simStatus" && simPlanStatusList.length>0){
      commonList=[];
      commonList=[...simPlanStatusList];
    }
    if(field.name==="deviceStatus" && deviceStatusList.length>0){
      commonList=[];
      commonList=[...deviceStatusList];
    }
    if(field.name==="simNumber" && deviceSimNumberList.length>0){
      commonList=[];
      commonList=[...deviceSimNumberList];
    } 
   
    return (    
      <SelectInput
                  name={field.name}
                  label={field.label}
                  options={commonList}                  
                  disabledKey={field.disabledkey===undefined?false:(field.disabledkey==='true'?true:false)}
                  defaultValue={device !== null? (switchCaseVal(field.name)==""?device[field.name]:switchCaseVal(field.name)):(switchCaseVal(field.name)===""?(commonList!==null?commonList[0].value:0):switchCaseVal(field.name))}
                  callBackFn={callBackFn}
                  callbackfnStatus={field.callbackfnStatus===undefined?false:(field.callbackfnStatus==='true'?true:false)}
                />             
           );
      }


      function switchCaseVal(param) {
        switch(param) {
          case "firstMobileNum":
            if(device!=null){
              if(statusEdit===true){
                return device['firstMobileNum']=device['firstMobileNum'];
              }else{
                return device['firstMobileNum']=assignedobj.firstMobileNum===undefined?"":assignedobj.firstMobileNum;
               }
            }          
            return assignedobj.firstMobileNum===undefined?"":assignedobj.firstMobileNum;
          case "secondMobileNum":
            if(device!=null){
              if(statusEdit===true){
                return device['secondMobileNum']=device['secondMobileNum'];
              }else{
                return device['secondMobileNum']=assignedobj.secondMobileNum===undefined?"":assignedobj.secondMobileNum;
               }
            }          
            return assignedobj.firstMobileNum===undefined?"":assignedobj.firstMobileNum;
          case "firstServiceProvider":
            if(device!=null){
              if(statusEdit===true){
                return device['firstServiceProvider']=device['firstServiceProvider'];
              }else{
                return device['firstServiceProvider']=assignedobj.firstServiceProvider===undefined?"":assignedobj.firstServiceProvider;
               }
            }            
          return assignedobj.firstServiceProvider===undefined?"":assignedobj.firstServiceProvider;
          case "simStatus":
            if(device!=null){
              if(statusEdit===true){
                return device['simStatus']=device['simStatus'];
              }else{
                return device['simStatus']=assignedobj.simStatus===undefined?"":assignedobj.simStatus;
              }
            }            
          return assignedobj.simStatus===undefined?"":assignedobj.simStatus;
          case "secondServiceProvider": 
            if(device!=null){
              if(statusEdit===true){
                return device['secondServiceProvider']=device['secondServiceProvider'];
              }else{
                return device['secondServiceProvider']=assignedobj.simProviderName1===undefined?"":assignedobj.simProviderName1;
              }               
            }            
          return assignedobj.simProviderName1===undefined?"":assignedobj.simProviderName1;
         case "simNumber":
            if(device!=null){
              if(statusEdit===true){
                return device['simNumber']=device['simNumber'];
              }else{
                return device['simNumber']=assignedobj.selectedId;
              }             
              } else{
              return assignedobj.selectedId===undefined?"":assignedobj.selectedId;
              }
        default:
          if(device!=null){
            return device[param];
          }  else{
            return "";
          }          
        }        
      }

  const FormFields = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TextInput
                  label={field.label}
                  name={field.name}
                  disabledKey={field.disabledkey===undefined?false:(field.disabledkey==='true'?true:false)}
                  defaultValue={device !== null ? (switchCaseVal(field.name)==""?device[field.name]:switchCaseVal(field.name)) : switchCaseVal(field.name)}
                />
               
              </Grid>
            );
             {/* defaultValue={switchCaseVal(field.name)} */}
          } else if (field.type === "select") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >  
                <DisplayCustomSelect field={field} ></DisplayCustomSelect>
              {/* {
                  <SelectInput
                    name={field.name}
                    label={field.label}
                    options={field.options}
                    defaultValue={
                      device !== null
                        ? device[field.name]
                        : field.options[0].value
                      }
                    />
                  } */}
              </Grid>
            );
          } else if (field.type === "radio") {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <RadioInput
                  name={field.name}
                  options={field.options}
                  defaultVal={device !== null ? device[field.name] : "1"}
                />
              </Grid>
            );
          } else if ((field.type = "date")) {
            return (
              <Grid
                key={index}
                item
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <DatePicker
                  name={field.name}
                  label={field.label}
                  defaultValue={
                    device === null
                      ? Date()
                      : moment(device[field.name], "DD/MM/YYYY")
                  }
                />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  const SensorDetailForm = () => {
    const [hideSensor, setHideSensor] = useState(false);
    const handleRadioClick1 = (event) => {
      if (event.target.value === RESTRICTED_ID) {
        setHideSensor(true);
      } else {
        setHideSensor(true);
      }
    };
    return (
      <Grid container>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
          <FormLabel sx={{ color: "black" }}>
            Air Conditioner
            <RadioInput
              handleRadioClick={handleRadioClick1}
              name={SENSOR[0].name}
              options={SENSOR[0].options}
              defaultVal={device !== null ? device[SENSOR[0].name] : "1"}
            />
          </FormLabel>
        </Grid>
        <Grid
          item
          md={6}
          sx={{ display: "flex", justifyContent: "center", color: "black" }}
        >
          <FormLabel sx={{ color: "black" }}>
            Fuel Integrated
            <RadioInput
              handleRadioClick={handleRadioClick1}
              name={SENSOR[1].name}
              options={SENSOR[0].options}
              defaultVal={device !== null ? device[SENSOR[1].name] : "1"}
            />
          </FormLabel>
        </Grid>
        <Grid
          item
          md={6}
          sx={{ display: "flex", justifyContent: "center", color: "black" }}
        >
          <FormLabel sx={{ color: "black" }}>
            Type Pressure Integrated
            <RadioInput
              handleRadioClick={handleRadioClick1}
              name={SENSOR[2].name}
              options={SENSOR[0].options}
              defaultVal={device !== null ? device[SENSOR[2].name] : "1"}
            />
          </FormLabel>
        </Grid>
        {/* <input type="hidden" name="id" value={device!=null?device.id:""} /> */}
      </Grid>
    );
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={device}>
        <FormLayout title={"Device Detail"}>
          <FormFields fields={DEVICE_DETAIL} />
        </FormLayout>
        <FormLayout title={"License key Details"}>
          <FormFields fields={DOMAIN_DETAIL} />
        </FormLayout>
        <FormLayout title={"Sim Details"}>
          <FormFields fields={SIM_DETAIL} />
        </FormLayout>
        <FormLayout title={"Sensor Details"}>
          <SensorDetailForm />
        </FormLayout>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input
            type="hidden"
            name="id"
            value={device != null ? device.id : ""}
          />
          <Button variant="contained" sx={buttonStyle} type="submit">
            {deviceToEdit ? "Update" : "Submit"}
          </Button>
          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Grid>
      </form>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
