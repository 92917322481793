import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ paddingTop: "10px" }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function StyledTabs({ tabs = [], handleChange, value }) {
  const theme = useTheme();

  const tabHeaderStyle = {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 12,
    color: theme.text.primary,
    borderRadius: "10px 10px 0 0",
    "&.Mui-selected": {
      color: "#61CE70",
    },
  };

  const tabIndicatorStyles = {
    background: "#61CE70",
    height: theme.tabs.header.highlightSize,
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box display="flex" justifyContent={"end"}>
          <Tabs
            TabIndicatorProps={{ style: tabIndicatorStyles }}
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            sx={{ minHeight: "0px", height: "40px !important" }}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab
                  sx={tabHeaderStyle}
                  label={tab.label}
                  key={index}
                  disabled={
                    (tab.label === "Settings" && tab.id === 0) ||
                    tab.label === "Slots"
                      ? true
                      : false
                  }
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>
      {tabs.map((tab, index) => {
        return (
          <TabPanel value={value} key={index} index={index}>
            {tab.children}
          </TabPanel>
        );
      })}
    </Box>
  );
}
