import React, { useState, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
//import InputAdornment from '@material-ui/core/InputAdornment';
import InputAdornment from "@mui/material/InputAdornment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160,
    },
  },
};

function MultipleSelect({
  label,
  options,
  onChange,
  optionsDisplayKey = "value",
  uniqueKey = "key",
  onSearch,
  isShow=true,
  fieldName
}) {
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState(options && options[0] && options[0][uniqueKey] || -1)

  const handleSearch = (searchText) => {
    onSearch(searchText);
  };

  React.useEffect(() => {
    setSelectedValue(options[0]?.[uniqueKey])
  }, [options])

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const theme = useTheme();

  const handleChangeValue = (event) => {
    setSelectedValue(event.target.value)
    onChange(event.target.value);
  };

  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: "1",
  };
  const searchDropdown = {
    padding: "3px 7px !important",
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "5px 14px",
    },
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
    },
  };

  return (
    <>
     {isShow && (
    <FormControl sx={{ m: 1, width: 160 }} size="small">
      <FormLabel sx={labelStyle}>{label}</FormLabel>
      <Select
        defaultValue={options!==null && options.length>0 ? options[0][uniqueKey] : -1}
        onChange={handleChangeValue}
        MenuProps={MenuProps}
        sx={selectDropdown}
        name={fieldName}
        onClose={() => setSearchText("")}
        value={selectedValue}
      >
        <TextField
          sx={searchDropdown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ fontSize: "inherit" }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
        {options.map((option) => (
          <MenuItem
            key={option[uniqueKey]}
            value={option[uniqueKey]}
            sx={menuStyles}
          >
            {option[optionsDisplayKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    )}
    </>
  );
}
export default MultipleSelect;
