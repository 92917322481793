import React, { Fragment, useState } from 'react';
import { CREATE_GEOZONE } from '../../constants/GeozoneFormFields';
import TextInput from '../shared/TextInput';
import SelectInput from '../shared/SelectInput';
import { Grid, Box, Button } from '@mui/material';
import { TextField, FormLabel, FormControl } from "@mui/material";
import { useTheme } from "@mui/styles";
import { styled } from '@mui/material/styles';


const CreateZone = () => {
  const theme = useTheme();
  const ButtonAlign = styled(Button)(
    () => ({
      textTransform: "none",
      backgroundColor: '#374850',
      color: 'white',
      fontSize: '12px',
      marginRight: '5px',
      padding: '1px 12px'
    }))
 

  const FormLayout = ({ children }) => {
    return (
      <Box sx={{
        marginY: '20px', '& .MuiOutlinedInput-root': { height: '25px' },
        '& .MuiFormControlLabel-label': { fontSize: theme.text.fontSize },
        '& .MuiSvgIcon-root': { height: '15px', width: '15px' }
      }}>
        {/* <fieldset style={{ border: 'white' }}> */}
          <Grid container spacing={0}>
            {children}
          </Grid>
        {/* </fieldset> */}
      </Box>
    );
  };
  const FormFields = ({ fields }) => {
    return (<>
      {
        fields.map((field, index) => {
          if (field.type === 'text') {
            return (
              <TextInput
                defaultValue={""}
                label={field.label}
                name={field.name}
                key={index}
              />
            )
          } else if (field.type === 'select') {
            return (
              <SelectInput name={field.name} label={field.label} options={field.options} key={index} defaultValue={field.options[0].value} />
            )
          }
        })
      }
    </>
    )
  }
  return (
    <FormLayout title={"Geozone Detail"}>
      <FormFields fields={CREATE_GEOZONE} />
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ marginLeft: '5vw', marginTop: '1.5vh' }} ><ButtonAlign
          type="submit"
          variant="contained" >Save</ButtonAlign>
        </Box>
        <Box sx={{ marginTop: '1.5vh' }}><ButtonAlign
          type="submit"
          variant="contained" >Cancel</ButtonAlign>
        </Box>
      </Grid>
    </FormLayout>
  )
}
export default CreateZone