import { FormControl, FormLabel, TextField } from "@mui/material";
import { React, useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

const PasswordInput = ({
  label,
  name,
  disabledKey,
  defaultValue,
  onChange,
  placeholder,
  textInputCallBackFn,
  callbackfnStatus = false,
}) => {
  const [text, setText] = useState("");
  const theme = useTheme();
  const labelStyle = {
    // fontSize: theme.text.fontSize,
    fontSize: "12px !important",
    color: "black",
    fontWeight: 600,
  };
  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });
  const [defaultVal, setDefaultVal] = useState(defaultValue);

  const textFontStyle = {
    ".MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 5px",
      
    },
  };
  const textFontStyleDisabled = {
    ".MuiInputBase-input": {
      fontSize: 12,
      paddingBottom: "5px",
      paddingLeft: "7px",
      backgroundColor: "#eee",
    },
  };

  // const handleChange = (e) => {
  //   const value = e.target.value.replace(/\D/g, "");
  //   setText(value);
  // };
  const callbackfn = (e) => {
    setDefaultVal(e.target.value);
    textInputCallBackFn(e.target.value, name);
  };
  return (
    <InputWrapper>
      <FormControl fullWidth>
        <ThemeProvider theme={asterisk}>
          {/* <FormLabel sx={labelStyle} required>
            {label}
          </FormLabel> */}
        </ThemeProvider>
        <TextField
          size="small"
          //value={text}
          type="password"
          name={name}
          disabled={disabledKey === true ? true : false}
          defaultValue={defaultValue}
          sx={{
            width: "400px", // Set width to 400px
            height: "20px", // Set height to 20px
            // padding: "20px", // Set padding to 20px
            ...(disabledKey === true ? textFontStyleDisabled : textFontStyle) // Merge disabled and normal text styles
          }}
          placeholder={placeholder}
          onBlur={(e) => {
            callbackfnStatus === true
              ? callbackfn(e)
              : setDefaultVal(e.target.value);
          }}
          //onChange={handleChange}
          // error={text === ""}
          // helperText={text === "" ? "This is Required field!" : " "}
          // onInput={(e) => {
          //   e.target.value = Math.max(0, parseInt(e.target.value))
          //     .toString()
          //     .slice(0, 12);
          // }}
        />
      </FormControl>
    </InputWrapper>
  );
};

export default PasswordInput;
