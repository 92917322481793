import React, { useState,useCallback } from "react";
import MainDataLine4 from "./MainDataLine4";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import GroupsIcon from '@mui/icons-material/Groups';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import swaptreeviewService from '../../services/swaptreeviewService';

const list2DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "auto", background: "#6684b3 !important", color: "#ffffff",paddingLeft:"54px",
position: "relative",
paddingTop:"5px",
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 42,
    top: 0,     
    borderLeft: "1px solid white",
    height: "19px",
    fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 42,
        top: 18,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
        }
     };
   
const MainDataLine1 = (prop) => {  
  var  orgType=prop.orgType;
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");
  const [sizeCount,setSizeCount] = useState(10);
  const [loadmorediv,setLoadmorediv] = useState({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
  const [nameClicked,setNameClicked] =useState(false);
  const onClickSlotfn = useCallback((checkval,val,swapstationId) => {   
    prop.onClickSlotfn(checkval,val,swapstationId);     
      return null;
  }, []);
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);     
      return null;
  }, []);

  const callbackDlrfn = useCallback((request) => {     
    prop.callbackDlrfn(request);     
      return null;
  }, []);

  const callbackSwpStnfn = useCallback((request) => {     
    prop.callbackSwpStnfn(request);     
      return null;
  }, []);

  const expandValChk = useCallback((val) => {    
    if(true===val){
      setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
    }else{
      setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    }
    return null;
  }, []);

  const elementDataServer = (request, dataArray1) => {
    if(orgType===3){
      //return <MainDataAccount2 selectedObject={request} dataArray={dataArray1} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />; 
    }else{
      return <MainDataLine4 selectedObject={request} dataArray={dataArray1} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} callbackSwpStnfn={callbackSwpStnfn} />; 
    }
  }
  const returnData = (checkboxList) => {
    return <div>
      {checkboxList?.map((d, index) => (
        <div
          className="text"
          key={d.resellerOrgId}
        >
            <div className="text" key={d.resellerOrgId}>
              <Accordion sx={{borderTop:"1px solid white"}} expanded={expandedInsdL1 === "panelInside" + d.resellerOrgId} onChange={handleChange3(d.resellerOrgId, 'panelInside' + d.resellerOrgId)} >
                <AccordionSummary sx={list2DivStyle} >
                <span className={expandVal === true ?"listdataLine1":""} onClick={()=>setNameClicked(true)} ></span>
                <GroupsIcon  sx={{marginTop:"-1%",width:"18px",height:"25px"}} onClick={()=>setNameClicked(true)}  />&nbsp;<span sx={{pointerEvents: "auto !important",cursor: "pointer !important"}} onClick={()=>{setNameClicked(true);callbackDlrfn(d.resellerOrgId);}}>{d.resellerName}</span> 
                  <IndeterminateCheckBoxIcon
                    sx={{
                      marginLeft: "auto",
                      marginTop:"-1.7%",
                      pointerEvents: "auto",
                      color: "#f0dfdf"
                    }}
                    onClick={()=>setNameClicked(false)} 
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.resellerOrgId)) ? "block" : 'none' }}
                  />
                  <AddBoxIcon sx={{
                    marginLeft: "auto",
                    marginTop:"-1.7%",
                    pointerEvents: "auto",
                    color: "#f0dfdf"
                  }}
                  onClick={()=>setNameClicked(false)} 
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.resellerOrgId)) ? "none" : 'block' }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {elementListServer}       
                </AccordionDetails>
              </Accordion>
            </div>
          </div>     
      ))}     
    </div>;
  }
     {/* <Divider light /> */}
    {/* <div style={loadmorediv} >
          <Grid container spacing={3}>
              <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
                <span onClick={()=>loadMore(d.reSellerOrgId)}>Load More</span>
            </Grid>
            <Grid item xs={4}>                        
            </Grid>
          </Grid>
          </div> */}
  const handleChange3 =
    (index, panel) => async (newExpanded) => {     
      if (expandVal === true) {
          setExpandVal(false);
          setExpandedInsdL1(false);
          setSizeCount(10);
          //setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
        } else {
          console.log("call here::"+prop.selectedObject.regionId);
          setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
          var request = {};
          //request.resellerId = prop.selectedObject.resellerId;
          //request.regionId = prop.selectedObject.regionId;
          //request.dealerId= prop.selectedObject.dealerId;
          request.dealerId = index;
          request.regionId = prop.selectedObject.regionId;
          //request.orgId = 0;
          request.flag = 2;
          request.page = 0;
          //request.size = 10;
          //request.region = 0;
          //request.vehModel = "2";
          //request.value = "customer";
          request.searchText = "";
          //request.vehModel = "1";
          //var region = {};
          //region.region = 1;
          //let stockdata = {};
          var response = await swaptreeviewService.getMapViewTreeCommonData(request, null)
          var stockdataContent1 = response.data.data;
          //var stockdataContent=[{"swapstationName":"Swap Station 04","NoOfslots":"4","swapstationId":"2","NoOfAvailableslots":"200"}];
          //var stockdataContent1=stockdataContent;

          //const stockdataContent1=[{"orglatlong":"None","orgAddressValue":"2","reSellerOrgId":"109926","isDealerOperator":"0","orgGeocodeValue":"3","OrgType":3,"reSellerName":"Customer A","agencyId":0,"Orgs":[],"orgAddress":"","orgMapValue":"1"}];
            setTimeout(function() { //Start the timer
            //console.log("nameClicked:"+nameClicked);
            if(nameClicked===false){
              setElementListServer(elementDataServer(request, stockdataContent1));
              setExpandVal(true);
              setExpandedInsdL1((newExpanded ? panel : false));
            } 
          }, 400)             
                    
        /*
        return await treeviewService.getOrgGridViewForTreeview(request, region)
        .then(res => {
        stockdata = res.data.data;
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
          return res;
        })
        .catch(err => {
          console.log(err);
        });
        */
      }
    };
    const loadMore =async (index)=>{  
      var sizeCnt=sizeCount+10; 
      setSizeCount(sizeCnt);
      var request = {};
      request.resellerId = prop.selectedObject.resellerId;
      request.regionId = prop.selectedObject.regionId;
      request.dealerId = index;
      request.orgId = 0;
      request.page = 0;
      request.size = sizeCnt;
      request.region = 0;
      request.vehModel = "2";
      request.value = "customer";
      request.searchText = "";
      request.vehModel = "1";
      var region = {};
      region.region = 1;
      let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
      let stockdata = {};
      stockdata = await responseData.data.data;      
      setElementListServer(elementDataServer(request, stockdata.content));
      setExpandVal(true);
      setExpandedInsdL1("panelInside" + index);
    }

  const listElement = returnData(prop.dataArray);
  return (
    <div>{listElement}</div>
  );
}
export default React.memo(MainDataLine1);
