import React, { useState,useCallback } from "react";
import MainDataLine1 from "./MainDataLine1";
//import MainDataAccount2 from "./MainDataAccount2";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import GroupsIcon from '@mui/icons-material/Groups';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PublicIcon from '@mui/icons-material/Public';
import swaptreeviewService from '../../services/swaptreeviewService';

const list2DivStyle = { minHeight: '23px !important', maxHeight: '33px !important', gap: 0.5, pointerEvents: "auto", background: "#465d80 !important", color: "#ffffff",paddingLeft:"34px",
position: "relative",
paddingTop:"5px",
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 22,
    top: 0,     
    borderLeft: "1px solid white",
    height: "35px",
    fontWeight:"400px"
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 22,
        top: 18,     
        borderBottom: "1px solid white",        
        fontWeight:"400px"
        }
     };
   
const SwapMainDataLine1 = (prop) => {  
  var  orgType=prop.orgType;
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);
  const [elementListServer, setElementListServer] = useState("");
  const [sizeCount,setSizeCount] = useState(10);
  const [loadmorediv,setLoadmorediv] = useState({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
  const [nameClicked,setNameClicked] =useState(false);
  const onClickSlotfn = useCallback((checkval,val,swapstationId) => {   
    prop.onClickSlotfn(checkval,val,swapstationId);     
      return null;
  }, []);
  const callbackDlrfn = useCallback((request) => {     
    prop.callbackDlrfn(request);     
      return null;
  }, []);
  const expandFleetDataShowfn = useCallback((request) => {     
    prop.expandFleetDataShowfn(request);     
      return null;
  }, []);
  
  const callbackRegionfn=(regionId)=>{
    prop.callbackRgnfn(regionId);
  }

  const callbackSwpStnfn=(regionId)=>{
    prop.callbackSwpStnfn(regionId);
  }


  const expandValChk = useCallback((val) => {    
    if(true===val){
      setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
    }else{
      setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
    }
    return null;
  }, []);


  const elementDataServer = (request, dataArray1) => {
    if(orgType===3){
      //return <MainDataAccount2 selectedObject={request} dataArray={dataArray1} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />; 
    }else{
        //console.log("dataline or:"+orgType);
      //return <MainDataLine1 selectedObject={request} dataArray={dataArray1} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />; 
      return <MainDataLine1 selectedObject={request} dataArray={dataArray1} onClickSlotfn={onClickSlotfn} expandValChk={expandValChk} expandFleetDataShowfn={expandFleetDataShowfn} callbackDlrfn={callbackDlrfn} orgType={orgType} callbackSwpStnfn={callbackSwpStnfn} />;
    }
  }
  const returnData = (checkboxList) => {
    return <div>
      {checkboxList?.map((d, index) => (
        <div
          className="text"
          key={d.regionId}
        >
              { d.regionId!==0 &&
              <Accordion expanded={expandedInsdL1 === "panelInside" + d.regionId} onChange={handleChange3(d.regionId, 'panelInside' + d.regionId)} >
                <AccordionSummary sx={list2DivStyle} >
                <span className={expandVal === true ?"listdataLine1":""} onClick={()=>setNameClicked(true)} ></span>
                <PublicIcon  sx={{marginTop:"-1%",width:"18px",height:"25px"}} onClick={()=>setNameClicked(true)}  />&nbsp;<span sx={{pointerEvents: "auto !important",cursor: "pointer !important"}} onClick={()=>{setNameClicked(true);callbackRegionfn(d.regionId)}}>{d.regionName}</span> 
                  <IndeterminateCheckBoxIcon
                    sx={{
                      marginLeft: "auto",
                      marginTop:"-1.7%",
                      pointerEvents: "auto",
                      color: "#f0dfdf"
                    }}
                    onClick={()=>setNameClicked(false)} 
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.regionId)) ? "block" : 'none' }}
                  />
                  <AddBoxIcon sx={{
                    marginLeft: "auto",
                    marginTop:"-1.7%",
                    pointerEvents: "auto",
                    color: "#f0dfdf"
                  }}
                  onClick={()=>setNameClicked(false)} 
                    style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.regionId)) ? "none" : 'block' }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {elementListServer}       
                </AccordionDetails>
              </Accordion>
              }
            <Divider light />
          </div>     
      ))}
    </div>;
  }
     {/* <Divider light /> */}
    {/* <div style={loadmorediv} >
          <Grid container spacing={3}>
              <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
                <span onClick={()=>loadMore(d.reSellerOrgId)}>Load More</span>
            </Grid>
            <Grid item xs={4}>                        
            </Grid>
          </Grid>
          </div> */}
  const handleChange3 =
    (index, panel) => async (newExpanded) => {     
      if (expandVal === true) {
          setExpandVal(false);
          setExpandedInsdL1(false);
          setSizeCount(10);
          //setLoadmorediv({ width: '90%',padding: '3px',display:"none", pointerEvents: "auto",cursor:"pointer" });
        } else {
          setLoadmorediv({ width: '90%',padding: '3px',display:"block", pointerEvents: "auto",cursor:"pointer" });
          var request = {};
          request.resellerId = prop.selectedObject.resellerId;
          //request.region = prop.selectedObject.regionId;
          var orgId="";
          if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
          } else{
            orgId=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")).orgId:0;
          }
          request.orgid = "109914";
          request.regionId=index;
          request.flag = 10;
          //request.dealerId = index;
          //request.orgId = 0;
          request.page = 0;
          request.size = 10;
          //request.region = 0;
          //request.vehModel = "2";
          //request.value = "customer";
          request.searchText = "";
          //request.vehModel = "1";
          //var region = {};
          //region.region = 1;
          var response = await swaptreeviewService.getMapViewTreeCommonData(request, null)
          var responseData1 = response.data.data;
          //const stockdataContent1=[{"reSellerOrgId":"109918","reSellerName":"Dealer A","Orgs":[]}];
          //const stockdataContent1=[{"orglatlong":"None","orgAddressValue":"2","reSellerOrgId":"109926","isDealerOperator":"0","orgGeocodeValue":"3","OrgType":3,"reSellerName":"Customer A","agencyId":0,"Orgs":[],"orgAddress":"","orgMapValue":"1"}];
            setTimeout(function() { //Start the timer
            //console.log("nameClicked:"+nameClicked);
            if(nameClicked===false){
              setElementListServer(elementDataServer(request, responseData1));
              setExpandVal(true);
              setExpandedInsdL1((newExpanded ? panel : false));
            } 
          }, 400)             
                    
        /*
        return await treeviewService.getOrgGridViewForTreeview(request, region)
        .then(res => {
        stockdata = res.data.data;
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
          return res;
        })
        .catch(err => {
          console.log(err);
        });
        */
      }
    };
    const loadMore =async (index)=>{  
      var sizeCnt=sizeCount+10; 
      setSizeCount(sizeCnt);
      var request = {};
      request.resellerId = prop.selectedObject.resellerId;
      request.regionId = prop.selectedObject.regionId;
      request.dealerId = index;
      request.orgId = 0;
      request.page = 0;
      request.size = sizeCnt;
      request.region = 0;
      request.vehModel = "2";
      request.value = "customer";
      request.searchText = "";
      request.vehModel = "1";
      var region = {};
      region.region = 1;
      let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
      let stockdata = {};
      stockdata = await responseData.data.data;      
      setElementListServer(elementDataServer(request, stockdata.content));
      setExpandVal(true);
      setExpandedInsdL1("panelInside" + index);
    }

  const listElement = returnData(prop.dataArray);
  return (
    <div>{listElement}</div>
  );
}
export default React.memo(SwapMainDataLine1);
