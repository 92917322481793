import { FormControl, FormLabel, TextField } from "@mui/material";
import { React, useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

function DateTime({
  label,
  name,
  defaultValue,
  callBackFn,
  callbackfnStatus = false,
}) {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const theme = useTheme();

  const handleDateChange = (event) => {
    //console.log(event.target.value);
    setSelectedDate(event.target.value);
  };
  const callBackFn1 = (e, name) => {
    setSelectedDate(e.target.value);
    callBackFn(e.target.value, name);
  };

  const labelStyle = {
    // fontSize: theme.text.fontSize,
    fontSize: "12px !important",
    color: "black",
    fontWeight: 600,
  };
  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });

  return (
    <InputWrapper>
      <FormControl fullWidth>
        <ThemeProvider theme={asterisk}>
          {/* <FormLabel sx={labelStyle} required>
            {label}
          </FormLabel> */}
        </ThemeProvider>
        <TextField
          id="time"
          size="small"
          name={name}
          type="time"
          defaultValue={defaultValue}
          value={selectedDate}
          onChange={(e) => {
            callbackfnStatus === true
              ? callBackFn1(e, name)
              : handleDateChange(e);
          }}
          sx={{
            "& .MuiInputBase-input": {
              height: "14px",
              fontSize: "14px",
            },
          }}
        />
      </FormControl>
    </InputWrapper>
  );
}

export default DateTime;
