import React, { Suspense } from 'react';
import './assets/css/style.scss';
import { Provider } from "react-redux";
import ThemeProvider from './theme/ThemeProvider';
import store from "./store";
import RouterView from "./Route/RouterView";

export default class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <Suspense fallback={<div>Please wait...</div>}>
        <Provider store={store}>
          <ThemeProvider>
            <RouterView></RouterView>
          </ThemeProvider>
        </Provider>
      </Suspense>
    );
  }
}
