import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/system';
import { Typography, Paper,Button,ToggleButtonGroup, Grid, styled} from '@mui/material';
import {Breadcrumb} from '../shared/Breadcrumbs';
import DropDown from '../shared/DropDown';
import TransferList from '../alerts/assignUsers';
import SimpleAccordion from './assignAlerts';
import MuiToggleButton from "@mui/material/ToggleButton";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor
  } 
}));




const Alerts = () =>{

const[hideDetails, setHidedetails] = useState("assign_users");

const [alignment, setAlignment] = React.useState("assign_users");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChange = (event) =>{
    setHidedetails(event.target.value)
    console.log(event.target.value)
  }

return (
    
    <Box sx={{padding:'15px'}}>
    <Breadcrumb mainDiv='Alerts' subDiv='Assign Users & Alerts'></Breadcrumb>
    <Typography  sx={{fontSize:'14px', color:'#2a3eb1'}}>Assign Users & Alerts</Typography>
    <Paper elevation={5} sx={{ height:'10vh'}}>
       <DropDown />
       <Button variant='contained' size='small' sx={{color:'white', marginTop:'20px', textTransform:'none'}}>Submit</Button>
     </Paper>
     <ToggleButtonGroup
      value={alignment} exclusive onChange={handleAlignment}
      Value={hideDetails}
      onClick={handleChange}
      sx={{marginTop:'20px'}}
      size="small"
      >
        <ToggleButton value={"assign_users"}  selectedColor="#1e88e5" sx={{textTransform:'none'}}>
        Assign Users
      </ToggleButton>
      <ToggleButton value={"assign_alerts"} selectedColor="#1e88e5" sx={{textTransform:'none'}}>
        Assign Alerts
      </ToggleButton>
      </ToggleButtonGroup>
    
    <Typography sx={{marginTop:'10px', fontSize:'10px'}}> Note: Email's which are already verified  will be listed.</Typography>
    
    {hideDetails ==="assign_users" && (
  <TransferList />
        )}
        {hideDetails ==="assign_alerts" && (
  <SimpleAccordion/>
        )}
        
    <Grid sx={{ display: 'flex', justifyContent: 'center',margin:'10px' }}>
    <Button variant='contained' size='small' sx={{color:'white', textTransform:'none'}}>Save</Button>
    <Button variant='contained' size='small' sx={{marginLeft:'5px', color:'white', textTransform:'none'}}>Cancel</Button>
    </Grid>
    </Box>
    
    
  )
}

export default Alerts