import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import DraggableDialog from "../shared/DailogDrag";
import Box from "@mui/material/Box";
import ListImage from "./ImageList";
import SwapStationServices from "../../services/swapStationService";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const ImageUpload = ({ swapToEdit, onCancel }) => {
  const ButtonAlign = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#374850",
    color: "white",
    fontSize: "12px",
    marginRight: "5px",
    // padding: "1px 12px",
  }));

  const [file, setFile] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);

  function uploadSingleFile(e) {
    setFile([...file, e.target.files[0]]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    const payload = { file: file[0] };
    const request = {
      stationId: swapToEdit,
    };
    const responseData = await SwapStationServices.uploadSwapImage(
      payload,
      swapToEdit
    );
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Uploaded Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    console.log(s);
  }
  const handleCancelClick = () => {
    onCancel();
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <form onSubmit={handleUpload} encType="multipart/form-data">
              <div className="form-group preview">
                {file.length > 0 &&
                  file.map((item, index) => {
                    return (
                      <div key={item}>
                        <img
                          src={URL.createObjectURL(item)}
                          alt=""
                          width={"250px"}
                        />
                        <button type="button" onClick={() => deleteFile(index)}>
                          delete
                        </button>
                      </div>
                    );
                  })}
              </div>

              <div className="form-group">
                <input
                  id="myInput"
                  style={{ display: "none" }}
                  type={"file"}
                  disabled={file.length === 5}
                  className="form-control"
                  onChange={uploadSingleFile}
                />
                <label htmlFor="myInput" style={{ ml: 5 }}>
                  <AddPhotoAlternateIcon style={{ fontSize: "40px" }} />
                </label>
              </div>
              <ButtonAlign sx={{ m: 2 }} type="submit" variant="contained">
                Upload
              </ButtonAlign>
              <ButtonAlign onClick={handleCancelClick} variant="contained">
                Cancel
              </ButtonAlign>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <ListImage swapToEdit={swapToEdit} />
          </Box>
        </Grid>
      </Grid>

      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
};

export default ImageUpload;
