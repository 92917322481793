import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { Grid, Button,FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ORGANIZATION_DETAIL,
  SWAP_STATION_DETAIL,
} from "../../constants/swapFormFields";
import TextInput from "../shared/TextInput";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import SelectInput from "../shared/SelectInput";
import SwapStationServices from "../../services/swapStationService";
import DraggableDialog from "../shared/DailogDrag";
import ReactTable from "./CusTable";
import DateTime from "../shared/DateTime";
import AutocompleteInput from "../shared/AutocompleteInput";
import OrganizationInput from "./OrganiztionDeatil";
import SlotSelectInput from "../shared/SelectSlot";
import NumberInput from "../shared/NumberInput";
import AlertDialogSlide from "../shared/ValidationDialog";

export default function CreateSwapStationForm({
  swapToEdit,
  onCancel,
  swapToView,
}) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [swap, setSwap] = useState(null);

  const [slotCount, setSlotCount] = useState(2);
  const [commonSlotDetails, setCommonSlotDetails] = useState([]);
  const [location, setLocation] = useState("");
  const [editSlotList, setEditSlotList] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [error, setError] = useState(false);
  const [textData, setTextData] = useState("");
  const [assignedobj, setAssignedobj] = useState({});

  const [dealersArry, setDealersArry] = useState([]);
  const [regionArry, setRegionArry] = useState([]);

  const [selRegion, setSelRegion] = useState();
  const [selDealer, setSelDealer] = useState();
  const [statusEdit, setStatusEdit] = useState(false);
  const [swapDef, setSwapDef] = useState([]);
  const [regionName, setRegionName] = useState("");
  const [dealerName, setDealerName] = useState("");

  const [regionName1, setRegionName1] = useState("");
  const [dealerName1, setDealerName1] = useState("");
  const [status1, setStatus1] = useState(false);

  // const libraries = ["drawing", "places", "geometry"];

  useEffect(async () => {
    if (swapToEdit) {
      setStatusEdit(true);
      setStatus1(true);
      const responseData = (await SwapStationServices.editSwapById(swapToEdit))
        .data.data;
      setSwap(responseData);
      setEditSlotList(responseData.slotlist);
      setSlotCount(responseData.slotlist.length);
      setSwapDef(responseData);
      setAssignedobj(responseData);
      setRegionName1(responseData.regionName);
      setDealerName1(responseData.dealerName);
      console.log("responseData regionId:" + responseData.regionId);
    }

    if (swapToView) {
      setStatusEdit(true);
      const responseData = (await SwapStationServices.editSwapById(swapToView))
        .data.data;
      setSwap(responseData);
      setEditSlotList(responseData.slotlist);
      setSlotCount(responseData.slotlist.length);
      setSwapDef(responseData);
      setAssignedobj(responseData);
    }

    var slotdata = [];
    for (var i = 0; i < 2; i++) {
      var array = {};
      array.number = `slot${i + 1}Number`;
      array.outputVoltage = `slot${i + 1}OutputVoltage`;
      array.model = `battery${i + 1}Model`;
      array.voltage = `slot${i + 1}Voltage`;
      array.capacity = `slot${i + 1}Capacity`;
      slotdata.push(array);
    }
    setCommonSlotDetails(slotdata);
  }, [setSwap]);
  // const checkval1=(val)=>{
  //       setLatlng(val);
  // }

  function switchCaseVal(param) {
    switch (param) {
      case "stationType":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["stationType"] = swap["stationType"]);
          } else {
            return (swap["stationType"] =
              assignedobj.stationType === undefined
                ? ""
                : assignedobj.stationType);
          }
        }
        return assignedobj.stationType === undefined
          ? ""
          : assignedobj.stationType;

      case "dimensions":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["dimensions"] = swap["dimensions"]);
          } else {
            return (swap["dimensions"] =
              assignedobj.dimensions === undefined
                ? ""
                : assignedobj.dimensions);
          }
        }
        return assignedobj.dimensions === undefined
          ? ""
          : assignedobj.dimensions;

      case "connectorType":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["connectorType"] = swap["connectorType"]);
          } else {
            return (swap["connectorType"] =
              assignedobj.connectorType === undefined
                ? ""
                : assignedobj.connectorType);
          }
        }
        return assignedobj.connectorType === undefined
          ? ""
          : assignedobj.connectorType;
      case "name":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["name"] = swap["name"]);
          } else {
            return (swap["name"] =
              assignedobj.name === undefined ? "" : assignedobj.name);
          }
        }
        return assignedobj.name === undefined ? "" : assignedobj.name;
      case "contactNo":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["contactNo"] = swap["contactNo"]);
          } else {
            return (swap["contactNo"] =
              assignedobj.contactNo === undefined ? "" : assignedobj.contactNo);
          }
        }
        return assignedobj.contactNo === undefined ? "" : assignedobj.contactNo;
      case "address":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["address"] = swap["address"]);
          } else {
            return (swap["address"] =
              assignedobj.address === undefined ? "" : assignedobj.address);
          }
        }
        return assignedobj.address === undefined ? "" : assignedobj.address;
      case "startTime":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["startTime"] = swap["startTime"]);
          } else {
            return (swap["startTime"] =
              assignedobj.startTime === undefined
                ? "00:00"
                : assignedobj.startTime);
          }
        }
        return assignedobj.startTime === undefined
          ? "00:00"
          : assignedobj.startTime;
      case "endTime":
        if (swap != null) {
          if (statusEdit === true) {
            return (swap["endTime"] = swap["endTime"]);
          } else {
            return (swap["endTime"] =
              assignedobj.endTime === undefined
                ? "00:00"
                : assignedobj.endTime);
          }
        }
        return assignedobj.endTime === undefined
          ? "00:00"
          : assignedobj.endTime;
      default:
        if (swap != null) {
          return swap[param];
        } else {
          return "";
        }
    }
  }

  const textInputCallBackFn = (val, name) => {
    callBackFn1(val, name);
  };
  const callBackFn1 = async (val, name) => {
    var obj = { ...assignedobj };

    setStatusEdit(false);
    if (name === "stationType") {
      obj.stationType = val;
    } else if (name === "dimensions") {
      obj.dimensions = val;
    } else if (name === "connectorType") {
      obj.connectorType = val;
    } else if (name === "name") {
      obj.name = val;
    } else if (name === "contactNo") {
      obj.contactNo = val;
    } else if (name === "address") {
      obj.address = val;
    } else if (name === "startTime") {
      obj.startTime = val;
    } else if (name === "endTime") {
      obj.endTime = val;
    }

    setAssignedobj(obj);
  };

  const callBackFnChk = (val) => {
    //console.log("val:"+val);
    setSlotCount(val);
    var slotdata = [];
    for (var i = 0; i < parseInt(val); i++) {
      var array = {};
      array.number = `slot${i + 1}Number`;
      array.outputVoltage = `slot${i + 1}OutputVoltage`;
      array.model = `battery${i + 1}Model`;
      array.voltage = `slot${i + 1}Voltage`;
      array.capacity = `slot${i + 1}Capacity`;
      slotdata.push(array);
    }
    setCommonSlotDetails(slotdata);
  };

  const callBackFn = useCallback((val) => {
    callBackFnChk(val);
  }, []);

  const theme = useTheme();
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };

  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "35px",width:"350px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: theme.fieldset.border,borderRadius:"12px" }}>
          <legend style={{ fontWeight: 600, color: theme.text.primary,fontSize:"16px" }}>
            {title}
          </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };

  const handleSubmit = (e) => {
    if (!swapToEdit) {
      e.preventDefault();

      const { target } = e;
      var arrayVal = [...commonSlotDetails];

      var commondata = {};
      commondata = Object.fromEntries(new FormData(target));
      if (commondata.regionId === "-1") {
        setTextData("Please Select Region");
        setError(true);
      } else if (commondata.dealerId === "-1") {
        setTextData("Please Select Dealer");
        setError(true);
      } else if (commondata.dimensions === "-1") {
        setTextData("Please Select Dimensions");
        setError(true);
      } else if (commondata.startTime === "00:00") {
        setTextData("Please Select StartTime");
        setError(true);
      } else if (commondata.endTime === "00:00") {
        setTextData("Please Select EndTime");
        setError(true);
      } else if (commondata.address === "") {
        setTextData("Please Enter Address");
        setError(true);
      } else if (commondata.contactNo === "") {
        setTextData("Please Enter ContactNumber");
        setError(true);
      } else if (commondata.name === "") {
        setTextData("Please Enter Name");
        setError(true);
      } else if (commondata.stationType === "-1") {
        setTextData("Please Select StationType");
        setError(true);
      } else if (commondata.connectorType === "-1") {
        setTextData("Please Select ConnectorType");
        setError(true);
      } else {
        var differ1arr = [];

        for (var i = 0; i < arrayVal.length; i++) {
          var testval = false;
          var testval1 = false;
          var testval2 = false;
          var testval3 = false;
          var testval4 = false;
          Object.keys(commondata).forEach(function (key) {
            if (arrayVal[i].number === key) {
              if (testval === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval = true;
              }
            } else if (arrayVal[i].outputVoltage === key) {
              if (testval1 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval1 = true;
              }
            } else if (arrayVal[i].model === key) {
              if (testval2 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval2 = true;
              }
            } else if (arrayVal[i].voltage === key) {
              if (testval3 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval3 = true;
              }
            } else if (arrayVal[i].capacity === key) {
              if (testval4 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval4 = true;
              }
            }
          });
        }

        Object.keys(commondata).forEach(function (key) {
          for (var i = 0; i < differ1arr.length; i++) {
            let testval = false;

            if (key === differ1arr[i].name) {
              if (testval === false) {
                delete commondata[key];

                testval = true;
              }
            }
          }
        });

        var map = {};
        for (var i = 0; i < differ1arr.length; i++) {
          var key = differ1arr[i].name;
          var value = differ1arr[i].value;

          if (key in map) {
            map[key].push(value);
          } else {
            map[key] = value;
          }
        }
        var finalArry = [];
        //console.log(map);
        for (let i = 1; i < slotCount + 1; i++) {
          var finalmap1 = {};
          Object.keys(map).forEach(function (key) {
            if (key === "slot" + i + "Number") {
              finalmap1.slotNumber = map[key];
            }
            if (key === "slot" + i + "OutputVoltage") {
              finalmap1.slotOutputVoltage = map[key];
            }
            if (key === "battery" + i + "Model") {
              finalmap1.batteryModel = map[key];
            }
            if (key === "slot" + i + "Voltage") {
              finalmap1.slotVoltage = map[key];
            }
            if (key === "slot" + i + "Capacity") {
              finalmap1.slotCapacity = map[key];
            }
          });
          finalArry.push(finalmap1);
        }
        //console.log("commondata:" + commondata);
        let finalData = commondata;
        commondata.createdBy = userData.id;
        commondata.resellerId = userData.resellerId;
        commondata.slotlist = finalArry;
        saveSwapDetails(e, finalData);
      }
    } else {
      e.preventDefault();
      const { target } = e;
      var arrayVal = [...commonSlotDetails];

      var commondata = {};
      commondata = Object.fromEntries(new FormData(target));
      if (commondata.regionId === "-1") {
        setTextData("Please Select Region");
        setError(true);
      } else if (commondata.dealerId === "-1") {
        setTextData("Please Select Dealer");
        setError(true);
      } else if (commondata.dimensions === "-1") {
        setTextData("Please Select Dimensions");
        setError(true);
      } else if (commondata.startTime === "00:00") {
        setTextData("Please Select StartTime");
        setError(true);
      } else if (commondata.endTime === "00:00") {
        setTextData("Please Select EndTime");
        setError(true);
      } else if (commondata.address === "") {
        setTextData("Please Enter Address");
        setError(true);
      } else if (commondata.contactNo === "") {
        setTextData("Please Enter ContactNumber");
        setError(true);
      } else if (commondata.name === "") {
        setTextData("Please Enter Name");
        setError(true);
      } else if (commondata.stationType === "-1") {
        setTextData("Please Select StationType");
        setError(true);
      } else if (commondata.connectorType === "-1") {
        setTextData("Please Select ConnectorType");
        setError(true);
      } else {
        var differ1arr = [];

        for (var i = 0; i < arrayVal.length; i++) {
          var testval = false;
          var testval1 = false;
          var testval2 = false;
          var testval3 = false;
          var testval4 = false;
          Object.keys(commondata).forEach(function (key) {
            if (arrayVal[i].number === key) {
              if (testval === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval = true;
              }
            } else if (arrayVal[i].outputVoltage === key) {
              if (testval1 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval1 = true;
              }
            } else if (arrayVal[i].model === key) {
              if (testval2 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval2 = true;
              }
            } else if (arrayVal[i].voltage === key) {
              if (testval3 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval3 = true;
              }
            } else if (arrayVal[i].capacity === key) {
              if (testval4 === false) {
                var differ1 = {};
                differ1.name = key;
                differ1.value = commondata[key];
                differ1arr.push(differ1);
                testval4 = true;
              }
            }
          });
        }

        Object.keys(commondata).forEach(function (key) {
          for (var i = 0; i < differ1arr.length; i++) {
            let testval = false;

            if (key === differ1arr[i].name) {
              if (testval === false) {
                delete commondata[key];

                testval = true;
              }
            }
          }
        });

        var map = {};
        for (var i = 0; i < differ1arr.length; i++) {
          var key = differ1arr[i].name;
          var value = differ1arr[i].value;

          if (key in map) {
            map[key].push(value);
          } else {
            map[key] = value;
          }
        }
        var finalArry = [];
        //console.log(map);
        for (let i = 1; i < slotCount + 1; i++) {
          var finalmap1 = {};
          Object.keys(map).forEach(function (key) {
            if (key === "slot" + i + "Number") {
              finalmap1.slotNumber = map[key];
            }
            if (key === "slot" + i + "OutputVoltage") {
              finalmap1.slotOutputVoltage = map[key];
            }
            if (key === "battery" + i + "Model") {
              finalmap1.batteryModel = map[key];
            }
            if (key === "slot" + i + "Voltage") {
              finalmap1.slotVoltage = map[key];
            }
            if (key === "slot" + i + "Capacity") {
              finalmap1.slotCapacity = map[key];
            }
          });
          finalArry.push(finalmap1);
        }
        //console.log("finalArry:" + finalArry);
        //console.log("commondata:" + commondata);
        let finalData = commondata;
        commondata.createdBy = userData.id;
        commondata.resellerId = userData.resellerId;
        commondata.slotlist = finalArry;
        updateSwapDetails(e, finalData);
      }
    }
  };
  const handleCancelClick = () => {
    onCancel();
  };
  const saveSwapDetails = async (e, obj) => {
    const responseData = await SwapStationServices.saveSwapDetails(obj);
    var obj1 = {};
    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const updateSwapDetails = async (e, obj) => {
    e.preventDefault();
    obj.createdBy = userData.id;
    obj.resellerId = userData.resellerId;
    const responseData = await SwapStationServices.updateSwapDetails(obj);
    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => onCancel(), 2000);
    }
  };

  const InputWrapper = ({ children }) => {
    return (
      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
        {children}
      </Grid>
    );
  };

  const FormFields = ({ fields, callBackFn }) => {
    return (
      <>
        {fields.map((field, index) => {
          if (field.type === "text") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
            >
              <FormLabel sx={{ fontSize: "16px", fontWeight: "600",textAlign: "center" }}>{field.label}</FormLabel>
              <TextInput
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
            </Grid>
             
            );
          } else if (field.type === "number") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" ,padding:"10px"}}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center"}}>{field.label}</FormLabel>
               <NumberInput
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
            </Grid>
              
            );
          } else if (field.type === "select") {
            //console.log("field.callbackfnStatus" + field.callbackfnStatus);
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" ,padding:"10px"}}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <SelectInput
                  name={field.name}
                  options={field.options}
                  error={error}
                  // defaultValue={
                  //   swap !== null ? swap[field.name] : field.options[0].value
                  // }
                  defaultValue={
                    swap !== null
                      ? switchCaseVal(field.name) == ""
                        ? swap[field.name]
                        : switchCaseVal(field.name)
                      : switchCaseVal(field.name) === ""
                      ? field.options !== null
                        ? field.options[0].value
                        : 0
                      : switchCaseVal(field.name)
                  }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                />
            </Grid>
              
            );
          } else if (field.type === "slot-select") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px" ,padding:"10px"}}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <SlotSelectInput
                  name={field.name}
                  options={field.options}
                  callBackFn={callBackFn}
                  //onChange={handleSlotChange}
                  callbackfnStatus={true}
                  defaultValue={
                    swap !== null
                      ? field.name === "slots"
                        ? slotCount
                        : swap[field.name]
                      : field.name === "slots"
                      ? slotCount
                      : field.options[0].value
                  }
                  value={slotCount}
                />
            </Grid>
            
            );
          } else if (field.type === "time") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <DateTime
                  name={field.name}
                  defaultValue={switchCaseVal(field.name)}
                  // defaultValue={
                  //   swap !== null
                  //     ? switchCaseVal(field.name) == ""
                  //       ? swap[field.name]
                  //       : switchCaseVal(field.name)
                  //     : switchCaseVal(field.name) === ""
                  //     ? field.options !== null
                  //       ? field.options[0].value
                  //       : 0
                  //     : switchCaseVal(field.name)
                  // }
                  callBackFn={callBackFn1}
                  callbackfnStatus={
                    field.callbackfnStatus === undefined
                      ? false
                      : field.callbackfnStatus === "true"
                      ? true
                      : false
                  }
                />
            </Grid>
             
            );
          } else if (field.type === "autocomplete") {
            return (
              <Grid
              key={index}
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}
            >
               <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}>{field.label}</FormLabel>
               <AutocompleteInput
                  name={field.name}
                  onPlaceSelected={(lat, lng, val) => {
                    assignedobj.address = val;
                    assignedobj.location = lat + "," + lng;
                    console.log("lat" + lat + " lng" + lng + "val::" + val);
                    setLocation(val);
                  }}
                  // defaultValue={swap !== null ? swap[field.name] : ""}
                  defaultValue={switchCaseVal(field.name)}
                  textInputCallBackFn={textInputCallBackFn}
                  callbackfnStatus={true}
                />
            </Grid>
              
            );
          }
        })}
      </>
    );
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const handleClear = () => {
    setError(false);
  };

  const callbackReturnVals = (
    selRegion,
    dealersAr,
    selDealer,
    regionsAr,
    regionNm,
    dealerNm
  ) => {
    setStatus1(false);
    console.log("coming status");
    setSelRegion(selRegion);
    setRegionArry(regionsAr === undefined ? [] : regionsAr);
    setDealersArry(dealersAr === undefined ? [] : dealersAr);
    setSelDealer(selDealer);
    console.log("dealerNm:" + dealerNm);
    setRegionName(regionNm);
    setDealerName(dealerNm);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} defaultValue={swap}>
        <FormLayout title={"Organization Detail :"}>
          <OrganizationInput
            // swap={swap !== null ? swap : null}
            callbackReturnVals={callbackReturnVals}
            dealersArry={dealersArry}
            selRegn={selRegion}
            selDr={selDealer}
            regionArry={regionArry}
            swapDef={swapDef}
            selRegnNm={regionName}
            status1={status1}
          />
        </FormLayout>
        <FormLayout title={"Station Detail :"}>
          <FormFields fields={SWAP_STATION_DETAIL} callBackFn={callBackFn} />

          {/* <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Autocomplete
              apiKey={GOOGLE_MAP_KEY}
              libraries={libraries}
              name="location"
              style={{ width: "38%", height: "26px" }}
             
                <input
                  type="hidden"
                  name="locationTest"
                  value={place.geometry.location.lat()}
                />;
              }}
              options={{
                types: ["(regions)"],
                // componentRestrictions: { country: "ru" },
              }}
            />
          </Grid> */}
        </FormLayout>

        <FormLayout title={"Slot Detail :"}>
          <ReactTable
            slotCount={slotCount}
            editSlotData={editSlotList}
            swapToEdit={swapToEdit}
            swapToView={swapToView}
          />
        </FormLayout>

        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <input type="hidden" name="id" value={swap != null ? swap.id : ""} />
          {/* <input type="text" name="location" value={assignedobj.address} /> */}
          <input type="hidden" name="location" value={assignedobj.location} />
          <input
            type="hidden"
            name="regionName"
            value={status1 === true ? regionName1 : regionName}
          />
          <input
            type="hidden"
            name="dealerName"
            value={status1 === true ? dealerName1 : dealerName}
          />
          {swapToView === "" && (
            <Button
              variant="contained"
              sx={buttonStyle}
              type="submit"
              // onClick={handleCancelClick}
            >
              {swapToEdit ? "Update" : "Save"}
            </Button>
          )}
          <Button
            variant="contained"
            sx={buttonStyle}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Grid>
      </form>

      {
        <AlertDialogSlide
          open1={error}
          text={textData}
          handleClear={handleClear}
        />
      }
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
