import React, { useState, useEffect, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  FormLabel,
  Box,
  Paper,
  Button,
  Typography,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContentCard from "./PaperContent";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CommonDropDown from "../common/commonDropdown";
import SlotServices from "../../services/slotService";
import { padding } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const containsText = (text, searchText) =>
  text.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) >
  -1;
const Regions = ["All", "North", "East", "West", "South", "UnAssigned"];
const Dealers = ["All", "Dealer A"];

const AssignSlots = () => {
  const [swapStationSlotList, setSwapStationSlotList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [battery, setBattery] = useState();
  const [slot, setSlot] = useState();
  const [batteryList, setBatteryList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [hideBatteryDetails, setHideBatteryDetails] = useState(false);
  const [disable, setDisable] = useState(false);
  const [assign, setAssign] = useState();
  const [unAssign, setUnAssign] = useState();
  const [slotId, setSlotId] = useState();
  const [slotId1, setSlotId1] = useState();

  const [swap, setSwap] = useState("");

  // const [disableButton, setDisableButton] = useState(true);
  const theme = useTheme();

  useEffect(async () => {
    var payload = {};
    payload.resellerId = 0;
    payload.swapId = swap;
    payload.batteryId = 0;
    payload.slotId = 0;

    const swapStationSlotLists = (
      await SlotServices.getSwapStationSlotLists(payload)
    ).data.data;
    setSwapStationSlotList(swapStationSlotLists);
    const availableBattery = (await SlotServices.getBatteryList(payload)).data
      .data;
    setBatteryList(availableBattery);
    const slotForSwap = (await SlotServices.getSlotList(payload)).data.data;
    setSlotList(slotForSwap);
  }, [swap]);

  const handleChangeSlot = (event) => {
    setSlot(event.target.value);
    const batteryName = event.target.value.batteryName;
    setSlotId(event.target.value);

    if (batteryName === "Assigned") {
      setHideBatteryDetails(true);
      setDisable(true);
      // setDisableButton(false);
    } else {
      setHideBatteryDetails(false);
      setDisable(false);
      // setDisableButton(true);
    }
  };

  const handleChangeAssign = async () => {
    let id = slotId.id;
    let batteryId = slotId1;

    var payload = {};

    payload.swapId = swap;
    payload.slotId = id;
    payload.batteryId = batteryId;
    const assignBattery1 = (await SlotServices.getAssignBattery(payload)).data
      .data;
    setAssign(assignBattery1);
    var payload = {};
    payload.resellerId = 0;
    payload.swapId = swap;
    payload.batteryId = 0;
    payload.slotId = 0;

    const swapStationSlotLists = (
      await SlotServices.getSwapStationSlotLists(payload)
    ).data.data;
    setSwapStationSlotList(swapStationSlotLists);
    setHideBatteryDetails(true);
    setDisable(true);
  };
  const handleChangeUnAssign = async () => {
    let id = slotId.id;
    console.log(id);
    var payload = {};
    payload.slotId = id;
    payload.swapId = swap;
    const unAssignBattery1 = (await SlotServices.getUnAssignBattery(payload))
      .data.data;
    setUnAssign(unAssignBattery1);

    var payload = {};
    payload.resellerId = 0;
    payload.swapId = swap;
    payload.batteryId = 0;
    payload.slotId = 0;

    const swapStationSlotLists = (
      await SlotServices.getSwapStationSlotLists(payload)
    ).data.data;
    setSwapStationSlotList(swapStationSlotLists);
    setHideBatteryDetails(!{ ...hideBatteryDetails });
    setDisable(false);
  };

  const handleChangeBattery = (event) => {
    setBattery(event.target.value);
    setSlotId1(event.target.value.id);
  };

  const displayedSlot = useMemo(
    () => slotList.filter((slot) => containsText(slot, searchText)),
    [searchText]
  );
  const displayedBattery = useMemo(
    () => batteryList.filter((battery) => containsText(battery, searchText)),
    [searchText]
  );

  const labelStyle = {
    fontSize: theme.text.fontSize,
    fontWeight: 600,
    fontSize:"16px",
    marginBottom:"10px"
  };
  const menuStyles = {
    fontSize: theme.text.fontSize,
    lineHeight: "1",
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": {
      fontSize: theme.text.fontSize,
      padding: "8.5px 14px"
    },
  };
  const handleSubmit = (formData) => {
    console.log({ formData });
    setSwap(formData.swapId);
  };
  const buttonStyle = {
    fontSize: theme.text.fontSize,
    textTransform: "none",
    color: "#fff",
    width:"100px",
    padding: "10px 30px",
    m: 1,
    backgroundColor: "#374850",
    "&:hover": {
      backgroundColor: "#61CE70",
    },
  };
  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Admin" subDiv="Assign Slot & Battery"></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}>
            <CommonDropDown
              onSubmit={handleSubmit}
              isShowStation={true}
              isShowType={false}
              shouldIncludeAllOption={true}
            />
          </Paper>
          <Box sx={{marginTop:"20px" }}>
            <Typography
              sx={{ m: 1, fontSize: 18, fontWeight: 500, color: " #212121 " }}
            >
              Assign Slot {"&"} Battery:
            </Typography>
            <Box sx={{display:"flex",flexDirection:"row"}}>
            <Box>
            <FormControl sx={{ m: 1, width: 160 }} size="small">
              <FormLabel sx={labelStyle}>Slots</FormLabel>

              <Select
                value={slot}
                onChange={handleChangeSlot}
                MenuProps={MenuProps}
                sx={selectDropdown}
                onClose={() => setSearchText("")}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    sx={{
                      width: "auto-width",
                      mt: 1,
                      marginBottom: 1,
                      "& .MuiInputBase-input": {
                        height: "14px",
                        fontSize: "14px",
                      },
                    }}
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ fontSize: "14px" }} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {slotList.map((slot) => (
                  <MenuItem value={slot} sx={menuStyles}>
                    {slot.slotNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!hideBatteryDetails && (
              <FormControl sx={{ m: 1, width: 160 }} size="small">
                <FormLabel sx={labelStyle}>Battery</FormLabel>
                <Select
                  value={battery}
                  onChange={handleChangeBattery}
                  MenuProps={MenuProps}
                  sx={selectDropdown}
                  onClose={() => setSearchText("")}
                  // disabled={slot.batteryId === "Assigned" ? true : false}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      sx={{
                        width: "auto-width",
                        mt: 1,
                        marginBottom: 1,
                        "& .MuiInputBase-input": {
                          height: "14px",
                          fontSize: "14px",
                        },
                      }}
                      autoFocus
                      placeholder="Type to search..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: "14px" }} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {batteryList.map((battery) => (
                    <MenuItem value={battery} sx={menuStyles}>
                      {battery.batteryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )} 
            </Box> 
            <Box  sx={{marginTop:"40px"}}>
            <Button
              variant="contained"
              disabled={disable}
              size="small"
              onClick={handleChangeAssign}
              sx={{
                buttonStyle
              }}
            >
              Assign
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={!hideBatteryDetails}
              onClick={handleChangeUnAssign}
              sx={{
                buttonStyle
              }}
            >
              UnAssign
            </Button>
            </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop:"10px" }}>
            <Typography
              sx={{ m: 1, fontSize: 18, fontWeight: 500, color: " #212121 " }}
            >
              Stations Slot:{" "}
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  width: 300,
                  height: 180,
                },
              }}
            >
              {swapStationSlotList.map((slot) => (
                <Paper
                  elevation={5}
                  sx={{
                    background:
                      slot.batteryName === "Assigned" ? "#4caf50" : "#9e9e9e",
                    border: " 1px solid black",
                    m: 1,
                  }}
                >
                  <ContentCard slot={slot} />
                </Paper>
              ))}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AssignSlots;
