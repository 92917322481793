import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

const SelectInput = ({
  label,
  name,
  disabledKey,
  defaultValue,
  options = [],
  callBackFn,
  callbackfnStatus = false,
  onChange,
  value,
  fullWidth=false
}) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const labelStyle = {
    fontSize: 12,
    color: "black",
    fontWeight: 600,
  };
  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });

  const callBackFn1 = (e, name) => {
    setV(e.target.value);
    callBackFn(e.target.value, name);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": { fontSize: 16, padding: "1.5px 8px",width: "400px", },
  };

  const selectDropdownDisabled = {
    ".MuiInputBase-input": {
      fontSize: 12,
      padding: "1px",
      paddingLeft: "7px",
      backgroundColor: "#eee",
    },
  };

  const [v, setV] = useState(defaultValue);
  // if (defaultValue) {
  //   console.log(defaultValue)
  //   const initialValue = options.find(val => val.title === defaultValue)
  //   setV(initialValue)
  // }
  const handleChange = (e) => {
    if (value) {
      onChange(e);
    } else {
      setV(e.target.value);
    }
  };

  return (
    <InputWrapper fullWidth={fullWidth}>
      <FormControl size="small" fullWidth>
        <ThemeProvider theme={asterisk}>
          {/* <FormLabel sx={labelStyle} required>
            {label}
          </FormLabel> */}
        </ThemeProvider>
        <Select
          required
          value={value || v}
          MenuProps={MenuProps}
          name={name}
          onChange={(e) => {
            callbackfnStatus === true ? callBackFn1(e, name) : handleChange(e);
          }}
          sx={selectDropdown}
          fullWidth={fullWidth}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                value={option.value}
              >
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
        {/* <FormHelperText>Error</FormHelperText> */}
      </FormControl>
    </InputWrapper>
  );
};

export default SelectInput;
