const BATTERY_DETAILS = [
  {
    type: "select",
    label: "Battery Model",
    name: "batteryModel",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "Model A",
        value: "Model A",
      },
      {
        id: 3,
        title: "Model B",
        value: "Model B",
      },
      {
        id: 4,
        title: "Model C",
        value: "Model C",
      },
      {
        id: 5,
        title: "Model D",
        value: "Model D",
      },
    ],
  },
  {
    type: "select",
    label: "Battery Type",
    name: "batteryType",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "Type 1",
        value: "Type 1",
      },
      {
        id: 3,
        title: "Type 2",
        value: "Type 2",
      },
    ],
  },
  {
    type: "select",
    label: "Battery Voltage(Volt)",
    name: "batteryVoltage",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "48",
        value: 48,
      },
      {
        id: 3,
        title: "52",
        value: 52,
      },
    ],
  },
  {
    type: "select",
    label: "Battery Capacity(Ampere Hour)",
    name: "batteryCapacity",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "18.6",
        value: 18.6,
      },
      {
        id: 3,
        title: "24",
        value: 24,
      },
    ],
  },
  {
    type: "number",
    label: "Battery Id(Eg:12)",
    name: "batteryId",
    placeholder: "BATTERY ID",
  },
  {
    type: "text",
    label: "Battery Name(Eg:Battery XXX)",
    name: "batteryName",
    placeholder: "BATTERY NAME",
  },
  {
    type: "select",
    label: "Battery Weight(Kilogram)",
    name: "batteryWeight",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "8",
        value: 8,
      },
      {
        id: 3,
        title: "10",
        value: 10,
      },
    ],
  },
  {
    type: "select",
    label: "Battery Size(Centimeter)",
    name: "batterySize",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Select",
        value: -1,
      },
      {
        id: 2,
        title: "35",
        value: 35,
      },
      {
        id: 3,
        title: "40",
        value: 40,
      },
      {
        id: 4,
        title: "50",
        value: 50,
      },
    ],
  },
];

module.exports = {
  BATTERY_DETAILS,
};
