import { FormControl, FormLabel, MenuItem, Select, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import InputWrapper from "../shared/InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import dropdownServices from "../../services/dropdownServices";

const OrganizationInput = ({
  callbackReturnVals,
  selRegn,
  dealersArry,
  selDr,
  regionArry,
  swapDef,
  selRegnNm,
  status1,
}) => {
  const [name, setName] = useState();
  const [regions, setRegions] = useState(regionArry);
  const [dealers, setDealers] = useState(dealersArry);
  const [region, setRegion] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [regionName, setRegionName] = useState("");
  const [selectedResellerId, setSelectedResellerId] = useState(null);
  const [dealerName, setDealerName] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [selRegion, setSelRegion] = useState(selRegn);
  const [selDealer, setSelDealer] = useState(selDr);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const labelStyle = {
    fontSize: 12,
    color: "black",
    fontWeight: 600,
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "Select",
    };
    return [allOption, ...options];
  };

  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const selectDropdown = {
    ".MuiOutlinedInput-input": { fontSize: 12, padding: "1.5px 8px" },
  };

  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
  }
  useEffect(async () => {
    const resellersListPayload = {
      value: "reseller",
      searchText: "",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
    };
    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;

    const resellerId = resellersList[0].key;
    setSelectedResellerId(resellerId);

    const regionListPayload = {
      value: "region",
    };
    const { data: regionResponseBody } = await dropdownServices.getRegions(
      regionListPayload
    );
    const _regions = getOptions(regionResponseBody);
    setRegions(_regions);
    console.log(
      "region dealer::" + swapDef.regionName + ":dealer:" + swapDef.dealerName
    );
    // console.log(
    //   "region dealer::" + swapDef.regionId + ":dealer:" + swapDef.dealerId
    // );
    const dealerListPayload = {
      value: "dealer",
      resellerId,
      dealerId: 0,
      searchText: "",
      regionId: swapDef != null ? swapDef.regionId : _regions[0].key,
    };

    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;

    //const _dealers = getOptions(dealerList);
    setDealers(dealerList);

    // var payload = {};
    // payload.resellerId = 109914;

    // const dealerLists = (await dropdownServices.getDealerList(payload)).data;
    // setDealerList(dealerLists);
    var payload = {};
    //payload.dealerId = 109918;
    //selRegn

    payload.dealerId = globalOrgId;
    const operatorLists = (await dropdownServices.getOperatorList(payload))
      .data;
    setOperatorList(operatorLists);
    //callbackReturnVals(selRegion, dealerList, selDealer, _regions);
  }, []);

  const handleDealerSelect = async (e) => {
    // const swapStationListPayload = {
    //   dealerId: e.target.value,
    // };
    // setSelDealer(e.target.value);
    // const { data: stationListResponse } = await dropdownServices.getSwapStation(
    //   swapStationListPayload
    // );
    var payload = {};
    //payload.dealerId = 109918;
    payload.dealerId = e.target.value;
    console.log("selRegn:" + selRegn);
    setSelDealer(e.target.value);
    const operatorLists = (await dropdownServices.getOperatorList(payload))
      .data;
    setOperatorList(operatorLists);

    if (status1 === true) {
      setSelRegion(swapDef != null ? swapDef.regionId : regions[0].key);
      const dealerListPayload = {
        value: "dealer",
        resellerId: selectedResellerId,
        dealerId: 0,
        searchText: "",
        regionId: swapDef != null ? swapDef.regionId : regions[0].key,
      };

      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;

      //const _dealers = getOptions(dealerList);
      setDealers(dealerList);

      var data = dealerList;
      var dealerNm = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].key === e.target.value) {
          dealerNm = data[i].value;
          break;
        }
      }
      setDealerName(dealerNm);

      var regionNm = "";
      for (var i = 0; i < regions.length; i++) {
        if (
          regions[i].key ===
          (swapDef != null ? swapDef.regionId : regions[0].key)
        ) {
          regionNm = regions[i].value;
          break;
        }
      }
      setRegionName(regionNm);

      callbackReturnVals(
        selRegion,
        dealerList,
        e.target.value,
        regions,
        regionNm,
        dealerNm
      );
    } else {
      var data = [...dealersArry];
      var dealerNm = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].key === e.target.value) {
          dealerNm = data[i].value;
          break;
        }
      }
      setDealerName(dealerNm);
      callbackReturnVals(
        selRegion,
        dealersArry,
        e.target.value,
        regions,
        selRegnNm,
        dealerNm
      );
    }
  };

  const handleChangeOperator = (e) => {
    setName(e.target.value);
    var data = [...operatorList];
    var operatorNm = "";
    for (var i = 0; i < operatorList.length; i++) {
      if (data[i].key === e.target.value) {
        operatorNm = data[i].value;
        break;
      }
    }
    setOperatorName(operatorNm);
  };

  const handleRegionSelect = async (e) => {
    const dealerListPayload = {
      value: "dealer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText: "",
      regionId: e.target.value,
    };
    setSelRegion(e.target.value);
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;

    setDealers(dealerList);
    var data = [...regions];
    var regionNm = "";
    for (var i = 0; i < regions.length; i++) {
      if (regions[i].key === e.target.value) {
        regionNm = regions[i].value;
        break;
      }
    }
    setRegionName(regionNm);
    callbackReturnVals(
      e.target.value,
      dealerList,
      selDealer,
      regions,
      regionNm,
      dealerName
    );
  };
  //console.log(swapDef);

  return (
    <Grid container>
      <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}>
        <InputWrapper>
          <FormControl size="small" fullWidth>
                 <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}>Region</FormLabel>
            <Select
              value={selRegion}
              MenuProps={MenuProps}
              name="regionId"
              onChange={handleRegionSelect}
              sx={selectDropdown}
              defaultValue={swapDef !== undefined ? swapDef.regionId : "-1"}
            >
              {regionArry.length === 0 &&
                regions.length > 0 &&
                regions.map((option) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: 12, lineHeight: "1" }}
                      value={option.key}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
              {regionArry.length > 0 &&
                regionArry.map((option) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: 12, lineHeight: "1" }}
                      value={option.key}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </InputWrapper>
      </Grid>

      {/* <input type="hidden" name="regionName" value={regionName} />
      <input type="hidden" name="dealerName" value={dealerName} /> */}
      <input type="hidden" name="operatorName" value={operatorName} />
      <Grid item md={6}   sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}>
        <InputWrapper>
          <FormControl size="small" fullWidth>
                   <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}>Dealer</FormLabel>
            <Select
              value={selDealer}
              MenuProps={MenuProps}
              name="dealerId"
              onChange={handleDealerSelect}
              sx={selectDropdown}
              defaultValue={swapDef !== null ? swapDef.dealerId : "-1"}
            >
              {dealersArry.length === 0 &&
                dealers.length > 0 &&
                dealers.map((option) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: 12, lineHeight: "1" }}
                      value={option.key}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
              {dealersArry.length > 0 &&
                dealersArry.map((option) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: 12, lineHeight: "1" }}
                      value={option.key}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </InputWrapper>
      </Grid>
      <Grid item md={6}   sx={{ display: "flex", justifyContent: "flex-start",alignItems: "flex-start",flexDirection: "column", gap: "10px",padding:"10px" }}>
        <InputWrapper>
          <FormControl size="small" fullWidth>
          <FormLabel sx={{ fontSize: "16px", fontWeight: "600", textAlign: "left" }}>Operator</FormLabel>

            <Select
              value={name}
              MenuProps={MenuProps}
              name="operatorName"
              onChange={handleChangeOperator}
              sx={selectDropdown}
            >
              {operatorList.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{ fontSize: 12, lineHeight: "1" }}
                    value={option.key}
                  >
                    {option.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default OrganizationInput;
