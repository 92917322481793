import Service from "./service";

const userServices = {
  // getGridViewForUser: function (resource) {
  //   return Service.post('user/listUser')
  // },

  // deleteUserById: (orgId) => {
  //   return Service.get('/deleteuserbyid', {orgId})
  // },

  //test
  getPublicContent() {
    return Service.get("/test/all");
  },
  getUserBoard() {
    return Service.get("/test/user");
  },
  getModeratorBoard() {
    return Service.get("/test/mod");
  },
  getAdminBoard() {
    return Service.get("/test/admin");
  },
  deleteUserById: (data) => {
    return Service.post("user/deleteUser", data,null);
  },
  updateUser: (data) => {
    return Service.post("/user/updateUser ", data, null);
  },
  getUser: function (resource) {
    return Service.post("/user/listUser", resource, null);
  },
  saveUser: function (data) {
    return Service.post("/user/createUser", data, null);
  },
  editUserById: (id) => {
    return Service.post("/user/getUser", { id });
  },
};

export default userServices;
