import React, { useEffect, useState,useCallback } from "react"; //useContext
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MainDataLine1 from "./MainDataLine1";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import treeviewService from '../../services/treeviewService';
import Divider from '@mui/material/Divider';
import LanguageIcon from '@mui/icons-material/Language';

const MainDataLine = (props) => {
  var list1DivStyle = props.list1DivStyle;
  var orgType=props.orgType;
  console.log("orgType line::"+orgType);
  const [expandedInsdL1, setExpandedInsdL1] = useState('');
  const [expandVal, setExpandVal] = useState(false);  
  const [elementListServer, setElementListServer] = useState("");

  const onClickfn = useCallback((checkval,val) => {     
      props.onClickfn(checkval,val);  
      return null;
  }, []);
  
  const expandFleetDataShowfn = useCallback((request) => {     
    props.expandFleetDataShowfn(request);  
    return null;
  }, []);

  const elementDataServer = (requestData, dataArray) => {
    return <MainDataLine1 selectedObject={requestData} dataArray={dataArray} onClickfn={onClickfn} expandFleetDataShowfn={expandFleetDataShowfn} orgType={orgType} />;
  }

  const handleChange3 =
    (index, panel) => async (newExpanded) => {
      if (expandVal === true) {
        setExpandVal(false);
        setExpandedInsdL1(false);
      } else {
        var request = {};
        request.resellerId = props.dataObj.key;
        request.regionId = index;
        request.orgId = 0;
        request.page = 0;
        request.size = 10;
        request.value = "dealer";
        request.searchText = "";
        request.vehModel = "1";
        let region = {};
        region.region = 1;
        let responseData = await treeviewService.getOrgGridViewForTreeview(request, region)
        let stockdata = {};
        stockdata = await responseData.data.data;
        setElementListServer(elementDataServer(request, stockdata.content));
        setExpandVal(true);
        setExpandedInsdL1((newExpanded ? panel : false));
      }
    };
    
  const [insided, setInsided] = useState(props.dataArray);
  const returnData = () => {
    return <div>
        {insided.length > 0 && insided.map((d, index) => (
          <div
            className="text"
            key={d.key}
          >
            <Accordion expanded={expandedInsdL1 === "panelInside" + d.key} onChange={handleChange3(d.key, 'panelInside' + d.key)} >
              <AccordionSummary sx={list1DivStyle}  > 
              <span className={expandVal === true ?"listdataLine":""}></span>
              <LanguageIcon sx={{width:"15px",height:"15px",marginTop:"0.9%",marginLeft:"8px"}} />   &nbsp;{(d.value === "1") ? "North" : ((d.value === "2") ? "South" : (d.value === "3") ? "East" : (d.value === "4") ? "West" : (d.value === "2") ? "South" : "NorthEast")
                } ({d.count})
                <IndeterminateCheckBoxIcon
                  sx={{
                    marginLeft: "auto",
                    pointerEvents: "auto",
                    marginTop:"-1.3%",
                    color: "#ccc3c3"
                  }}
                  style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "block" : 'none' }}
                />
                <AddBoxIcon sx={{
                  marginLeft: "auto",
                  pointerEvents: "auto",
                  marginTop:"-1.3%",
                  color: "#ccc3c3"
                }}
                  style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" + d.key)) ? "none" : 'block' }}
                />
                  
              </AccordionSummary>
              <AccordionDetails>
              <div style={{ padding: 0, fontWeight: "bold", fontSize: "14px" }}>
                {elementListServer}
                </div>
              </AccordionDetails>
            </Accordion>
            <Divider light />
          </div>
        ))}
      </div>;
  }
  const listElement = returnData();
  return (
    <div >
      <div style={{ background: "#465d80" }} >
      </div>
      <div>{listElement}</div>
     
    </div>
  );
}

export default React.memo(MainDataLine);
