import Service from "./service";


const simServices = {

  deleteSim: (id) => {
      return Service.post('/sim/deleteSim', {id})
    },
  
    updateSim: (data) => {
      return Service.post('/sim/updateSim',data,null)
    },
    getSim: function (resource) {
      return Service.post('/sim/listSim',resource,null)
    },
      saveSim: function (data){
        return Service.post('/sim/createSim',data,null)
    },
    editSimById: function (id) {
      return Service.get('/sim/getSim', {id})
    },
  };
  
  export default simServices;