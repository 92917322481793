import { createTheme } from '@mui/material';

const colors = {
  white: '#FFFFFF',
  primary: '#4fbe37',
  black: '#000',
  secondary: '#6E759F',
  grey: '#9e9e9e',
  dividerBg: '#F6F8FB',
  blue: '#4fbe37',
  green: '#76ff03',
  gray: 'rgba(0, 0, 0, 0.12)',
  secondaryGrey: '#9e9e9e',
  disabled: '#cccccc',
  lightBlue: 'rgb(40, 150, 235)',
  whitesmoke: '#f5f5f5',
  skyBlue: '#B7E9F7',
  lightGray:"hsl(199, 18%, 76%)",
  darkGray:'#374850',

  shadows: {
    cardSm: '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    sidebar: '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    paper: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    cardShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
    dropdown: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
}


const default_theme = createTheme({ 
  palette: {
    background: {
      default: 'blue',
      paper: 'white',
      whitesmoke: colors.whitesmoke,
      primary: '#4fbe37'
    },
    primary: {
      contrastText: '#fff',
      main: '#4fbe37',
      dark:'#399425',
      highlighted: '#0087CC',
      grey: colors.grey
    },
    shadow: {
      primary: colors.shadows.paper,
      secondary: colors.shadows.cardShadow,
      boxShadow: colors.shadows.dropdown
    }
  },
  fieldset:{
    border:'1px solid '
  },
  title:{
    borderLine:'1px solid #D3D3D3'
  },
  border: {
    color: {
      secondary: '#3fa5f2',
      gray: 'gray',
      white: colors.white
    }
  },
  text: {
    primary: '#000000',
    secondary: '#000000',
    fontSize: {
      lg: '18px',
      md: '16px',
      sm: '14px',
      xs: '12px'
    },
    fontWeight: {
      s: 600,
      xs: 500,
      md:400,
    },
    color: {
      secondary: '#6e6d6d',
      contrastText: '#000',
      gray: colors.gray,
    },
    contrastText: '#fff',
    lineHeight: 2.5
  },
  table: {
    header: {
      text: {
        color: colors.white,
      },
      backgroundColor: colors.darkGray
    },
    dataRowHighlightColor: colors.gray
  },
  backgroundColor: '#fff',
  header: {
    height: '45px',
    background: colors.white,
    boxShadow: colors.shadows.cardSm,
    textColor: colors.primary,
    borderColor: colors.gray,
    iconColor: colors.green,
    Color: colors.grey,
    backgroundColor: colors.grey,
    text: {
      fontSize: {
        lg: '18px',
        md: '16px',
        sm: '14px',
        xs: '12px'
      },
    },
    fontWeight: 'bold',
  },
  logo: {
    height: '40px'
  },
  sidebar: {
    width: '80px',
    menuColor: colors.lightGray,
    selectedColor: colors.lightBlue,
    clickableColor: colors.skyBlue,
    background: colors.darkGray
  },
  tabs: {
    header: {
      color: colors.lightBlue,
      highlightSize: '4px'
    }
  },
  subheader: {
    fontSize: {
      xl: '40px',
      l: '30px',
      m: '20px'
    },
    textColor: colors.black
  },
  card: {
    blueBackground: colors.blue,
    background: {
      primary: colors.white,
      secondary: colors.blue,
    },
    borderRadius: {
      primary: '20px'
    },
    border: {
      primary: colors.secondaryGrey,
      secondary: colors.blue
    },
    hover: {
      primary: '#F5F5F5'
    }
  },
  button: {
    width: '125px',
    textTransform: 'capitalize',
    height: '35px',
    borderRadius: '64px',
    border: {
      primary: colors.grey
    },
    background: {
      primary: colors.blue,
      secondary: colors.gray,
      disabled: colors.disabled,
      accent: colors.white
    },
    color: {
      primary: colors.black,
      secondary: colors.white
    },
    fontWeight: 550,
  },
  borderRadius: {
    primary: '20px'
  },


  //Components
  components:{
    MuiAppBar:{
      styleOverrides:{
        root:({theme}) => ({
            background:'#FFF',
            boxShadow:'none',
            borderBottom:`.5px solid ${theme.palette.divider}`
        })
      }
    },
    MuiToolbar:{
      styleOverrides:{
        root:({theme}) => ({
            minHeight:'55px !important'
        })
      }
    }
  }


});


export function themeCreator(themeName) {
  return default_theme
}