import React, { Fragment, useState } from 'react';
import { Circle, InfoWindow, Marker } from '@react-google-maps/api';

const options = {
  strokeColor: "#0000A5"
}

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 10
}

const GeoZoneMarker = ({ values }) => {
  const[openInfoWindowMarkerId,setOpenInfoWindowMarkerId]= useState('')

  const openMarkerInfo=(marker)=>{
     setOpenInfoWindowMarkerId(marker)
  }

  return (
    <>
      {values.map((place,i) => {
        return (
          <Fragment key={i}>
            <Marker
              position={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude)
              }}
              onMouseOver={() => openMarkerInfo(i)}
            />
            <Circle
              center={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude)
              }}
              radius={place.radius}
              options={options}
            />
             {openInfoWindowMarkerId===i && (
            <InfoWindow options={{ pixelOffset: new window.google.maps.Size(0, -20) }}
            position={{
              lat: parseFloat(place.latitude),
              lng: parseFloat(place.longitude)
            }} >
              <div style={divStyle}>
                <h6>InfoWindow</h6>
              </div>
            </InfoWindow>
            )}
          </Fragment>
        );
      })}
    </>
  )

}

export default GeoZoneMarker