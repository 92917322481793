
import { Box, Drawer, List, ListItem, Tab, Tabs, alpha, styled } from '@mui/material'
export const HeaderComp = styled(Box)(({theme}) => ({
    width:'100%',
    
}))

export const SidebarComp= styled(Drawer)(({theme}) => ({
    width:'100%',
    maxWidth:'180px',
    overflow:'hidden',
    '& .MuiDrawer-paper':{
        width:'180px',
        background:theme.palette.primary.dark,
        height:'100%',
        overflow:'hidden',
        border:'none'
    }
}))

export const MenuList = styled(List)(({theme}) => ({
    padding:'0px',
    '& a':{
        textDecoration:'none',
        '&:hover':{
            color:theme.palette.primary.contrastText
        },
        '&.active':{
            background:alpha(theme.palette.primary.main, .5),
            '&:hover':{
                color:theme.palette.primary.contrastText
            },
            color:'#fff',
            '& .MuiListItemIcon-root':{
                color:'#fff',
            }
        }
    }
}))

export const MenuListItem = styled(ListItem)(({theme}) => ({
    borderRadius:'5px',
    overflow:'hidden',
    color:'#fff',
    '& .MuiListItemIcon-root':{
        fontWeight:'500',
        color:theme.palette.primary.contrastText
    }
}))

export const TabsList = styled(Tabs)(({theme}) => ({
    minHeight:'auto',
}))

export const TabItem = styled(Tab)(({theme}) => ({
    minHeight:'auto',
    minWidth:'60px',
    padding:'12px 12px',
    textTransform:'capitalize',
    fontWeight:500,
    color:theme.palette.text.primary,
  
}))

export const MaincontentArea = styled(Box)(({theme}) => ({
    padding:'10px'
}))