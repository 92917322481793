import Service from "./service";

const SwapStationServices = {
  getGridViewForSwap: function (resource) {
    //return Service.post('/gridviewfororg')
    //return Service.get('/gridviewfordevice')
    return Service.post("/swapstation/listSwapStation", resource, null);
  },
  saveSwapDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/swapstation/createSwapStation", data, null);
  },
  editSwapById: (id) => {
    return Service.post("/swapstation/getSwapStation", { id });
  },
  updateSwapDetails: function (data) {
    return Service.post("/swapstation/updateSwapStation", data, null);
  },
  getConnectorList: function (data) {
    return Service.post("/swapstation/getConnectorType", data, null);
  },
  saveSwapStationTiming: function (data) {
    return Service.post("/swapstation/updateSwapStationTiming", data, null);
  },
  getSlotDetails: function (data) {
    return Service.post("/swapstation/showSubTable", data, null)
  },
  getSwapStationList: function (data) {
    return Service.post("/service/getnearestSwapStation", data, null);
  },
  getImageList: function (stationId) {
    var stationObj = {};
    stationObj.stationId = stationId;
    return Service.post("/swapstation/getSwapStationTiming", null, stationId);
  },
  uploadSwapImage: function (data, swapStationId) {
    return Service.post(
      `/swapstation/uploadSwapStationImg`,
      data,
      { swapStationId },
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
};

export default SwapStationServices;
