import React, { useState, useEffect } from "react";
import {
  Divider,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import userService from "../../services/userService";
import DraggableDialog from "../shared/DailogDrag";

export default function PhoneDialog(props) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  useEffect(async () => {
    var userLogginData = {};
    if (
      "null" === localStorage.getItem("userData") ||
      null === localStorage.getItem("userData")
    ) {
    } else {
      userLogginData = JSON.parse(localStorage.getItem("userData"));
    }
    console.log("console:" + userLogginData.id);
    setId(userLogginData.id);

    // if (userToEdit) {
    //   const responseData = (await userService.editUserById(userToEdit)).data
    //     .formData;
    //   setUser(responseData);
    // } else {
    //   setUser(null);
    // }
  }, [setId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    updatePhoneNo(e, Object.fromEntries(new FormData(target)));
    props.setPhone(e.target.mobile.value);
  };
  const updatePhoneNo = async (e, obj) => {
    e.preventDefault();

    const responseData = await userService.updatePhoneNo(obj);

    var obj1 = {};
    obj1.dialogButtonText = "Update Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
  };

  const handleClosePhone = () => {
    props.setOpenPhone(false);
  };
  const handleClearCls = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.openPhone}
        onClose={handleClosePhone}
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item>
              <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                Edit Your Phone Number
              </DialogTitle>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClosePhone}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="mobile"
              name="mobile"
              placeholder="Phone Number"
              type="text"
              variant="outlined"
              //onChange={(event) => props.setPhone(event.target.value)}
            />
            <input type="hidden" name="id" value={id} />
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleClosePhone}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleClose={handleClosePhone}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleClose={handleClosePhone}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </div>
  );
}
