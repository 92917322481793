import produce from "immer";
import { PACKAGES_INIT } from "../constants/actionType"

const initState = () => ({
  packages: []
});
export default (state = initState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PACKAGES_INIT:
        draft.packages = action.payload.data;
        break;
      default:
        return state;
    }
  });