import React, { useState, useEffect } from "react";
import {
  FormLabel,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import userService from "../../services/userService";
import { useForm } from "react-hook-form";
import DraggableDialog from "../shared/DailogDrag";

const useStyles = makeStyles(() => ({
  input1: {
    height: 10,
  },
}));

const initialState = {
  password: "",
  newPassword: "",
  currentPassword: "",
  showPassword: false,
};

export default function ChangePassword() {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  // const onSubmit = (data) => {
  // console.log(data);
  // };

  //console.log(watch("password"));

  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });
  const [values, setValues] = useState(initialState);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  useEffect(async () => {
    var userLogginData = {};
    if (
      "null" === localStorage.getItem("userData") ||
      null === localStorage.getItem("userData")
    ) {
    } else {
      userLogginData = JSON.parse(localStorage.getItem("userData"));
    }
    console.log("console:" + userLogginData.id);
    setId(userLogginData.id);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    ChangeUserPassword(e, Object.fromEntries(new FormData(target)));
  };

  const ChangeUserPassword = async (e, obj) => {
    e.preventDefault();

    const responseData = await userService.ChangeUserPassword(obj);

    var obj1 = {};
    obj1.dialogButtonText = "change Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = responseData.data.resultText;
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };
  return (
    <Grid Container spacing={0}>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            border: "1px solid white",
            marginBottom: 4,
            borderLeft: "0.1em solid #eeeeee",
            padding: "1.2em",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#2196f3", fontWeight: "Bold" }}
          >
            Change Password
          </Typography>
          <Box sx={{ mt: 2, width: 300, mb: 3 }}>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit}>
                <FormLabel required sx={{ fontSize: "16px", color: "black" }}>
                  Password
                </FormLabel>
                <TextField
                  margin="dense"
                  required
                  InputProps={{ classes: { input: classes.input1 } }}
                  id="currentPassword"
                  type="password"
                  name="currentPassword"
                  value={values.currentPassword}
                  onChange={handlePasswordChange("currentPassword")}
                  autoComplete="current-password"
                />
                <br />
                <br />
                <FormLabel required sx={{ fontSize: "16px", color: "black" }}>
                  New Password
                </FormLabel>
                <TextField
                  {...register("newPassword", {
                    required: true,
                    minLength: 5,
                  })}
                  margin="dense"
                  required
                  type={values.showPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={handlePasswordChange("newPassword")}
                  value={values.newPassword}
                  id="newPassword"
                  autoComplete="new-password"
                  InputProps={{ classes: { input: classes.input1 } }}
                />
                {errors?.newPassword?.type === "required" && (
                  <Typography>This field is required</Typography>
                )}
                {errors?.newPassword?.type === "minLength" && (
                  <Typography>
                    password cannot less than 5 characters
                  </Typography>
                )}

                <Grid container justify="flex-end">
                  <Link
                    variant="body2"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      marginLeft: 140,
                    }}
                  >
                    {values.showPassword ? "HidePassword" : "ShowPassword"}
                  </Link>
                </Grid>

                <br />

                <FormLabel required sx={{ fontSize: "16px", color: "black" }}>
                  Confirm New Password
                </FormLabel>
                <TextField
                  {...register("password", { required: true })}
                  margin="dense"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="confirm-new-password"
                  InputProps={{ classes: { input: classes.input1 } }}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                />
                {watch("password") !== watch("newPassword") &&
                getValues("password") ? (
                  <Typography>password not match</Typography>
                ) : null}
                <input type="hidden" name="id" value={id} />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, ml: 3 }}
                >
                  Change Password
                </Button>
              </form>
            </ThemeProvider>
          </Box>
        </Box>
        {dataStatusConfirm !== false && (
          <DraggableDialog
            open={open}
            dialogButtonText={dialogContentConfirm.dialogButtonText}
            handleClose={handleSbmtClose}
            submitButtonText={dialogContentConfirm.submitButtonText}
            dialogText={dialogContentConfirm.dialogText}
            closeButtonText={dialogContentConfirm.closeButtonText}
            handleClearCls={handleClearCls}
          />
        )}
        {dataStatus !== false && (
          <DraggableDialog
            open={open}
            dialogButtonText={dialogContent.dialogButtonText}
            handleClose={handleSbmtClose}
            submitButtonText={dialogContent.submitButtonText}
            dialogText={dialogContent.dialogText}
            closeButtonText={dialogContent.closeButtonText}
            handleClearCls={handleClearCls}
          />
        )}
      </Grid>
    </Grid>
  );
}
